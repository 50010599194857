import csstype.*


fun PropertiesBuilder.characterText() {
    textAlign = TextAlign.center
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    borderRadius = 50.pct
    height = 32.px
    width = 32.px
    flexShrink = number(0.0)
    backgroundColor = Colors.surfaceContainerHigh
    margin = Margin(left = 4.px, right = 4.px, bottom = 2.px, top = 0.px)
}

fun PropertiesBuilder.message_narrator() {
    display = Display.flex
    alignItems = AlignItems.flexEnd
    margin = Margin(horizontal = 0.px, vertical = 2.px)
    fontStyle = FontStyle.italic
}

fun PropertiesBuilder.message_player() {
    display = Display.flex
    alignItems = AlignItems.flexEnd
    margin = Margin(top = 2.px, bottom = 2.px, left = 64.px, right = 0.px)
    justifyContent = JustifyContent.flexEnd
}

fun PropertiesBuilder.message_woman() {
    display = Display.flex
    alignItems = AlignItems.flexEnd
    margin = Margin(top = 2.px, bottom = 2.px, left = 0.px, right = 64.px)
}

fun PropertiesBuilder.message_man() {
    display = Display.flex
    alignItems = AlignItems.flexEnd
    margin = Margin(top = 2.px, bottom = 2.px, left = 0.px, right = 64.px)
}

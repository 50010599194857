package xyz.lacunae.itsadate

import xyz.lacunae.story.GoToScene
import xyz.lacunae.story.Story
import xyz.lacunae.story.scene
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator

object ChapterFinal2 {
    private const val name = "chapter-final-2"
    const val intro = "$name/intro"
    const val coban = "$name/coban"
    const val end = "$name/end-final-2"
}

internal fun Story.chapterFinal2() {
    scene(ChapterFinal2.intro, IntroContext("Épilogue")) {
        narrator {
            -"Quelques mois plus tard."
            -"Vous avez repris votre vie normale."
            -"Mais cette histoire ne vous quitte pas."
            -"${reporter.name} ne vous a pas recontacté, et rien n’est sorti dans les médias."
            -"Aucun signe de vie de ${elea.name} non plus."
            -elea.withGender("Qu’est-il devenu ?", "Qu’est-elle devenue ?")
            -"C’est à se demander si ce que vous avez vécu est bien réel."
            -"Peut-être que ${coban.name} et ses patrons ont plus de ressources que ${reporter.name} et Tim semblent le croire."
            -"Peut-être qu’ils s’en sont sortis, et qu’ils ne seront jamais inquiétés."
            -"Peut-être est-il arrivé quelque chose de grave ?"
            -"Et c’est pour ça que ${reporter.name} ne donne plus signe de vie…"
            -"Vous avez beau relire les vieux messages de ${elea.name} et de ${reporter.name} pour vous convaincre que tout ceci est bien réel."
            -"Mais ça ne vous apporte qu’un fort sentiment d’amertume."
            -"Auriez-vous pu faire de meilleurs choix ?"
            -"Et puis, un beau jour…"
        }
        redirect(ChapterFinal2.coban)
    }
    scene(ChapterFinal2.coban, MessengerCobanHomeContext) {
        characters(mysteriousCoban)
        mysteriousCoban {
            -"Salut ${player.name}."
            -"Ça faisait longtemps…"
        }
        choose {
            "${elea.name} ?" {
                mysteriousCoban {
                    -"Hahaha, que c’est ironique !"
                    -"Non, je ne suis pas ${elea.name}."
                }
                mysteriousCoban {
                    -"C’est moi, ${coban.name}."
                    -"Tu n’as pas eu le nez creux, sur ce coup !"
                }
            }
            "${reporter.name} ?" {
                mysteriousCoban {
                    -"${reporter.name} ?"
                    -"Non, tu te trompes."
                    -"Je t’aurais cru plus perspicace $player."
                }
                mysteriousCoban {
                    -"C’est moi, ${coban.name}."
                    -"Tu n’as pas eu le nez creux, sur ce coup !"
                }
            }
            "${coban.name} ?" {
                mysteriousCoban {
                    -"Tu es perspicace, ${player.name}."
                    -"J’ai toujours su que tu avais le nez creux."
                }
            }
        }
        choose {
            "Laisse mon nez tranquille !" {
                stateMachine.game.finalCoban = true
            }
            "Qu’est-ce que tu me veux, ${coban.name} ?" {
                stateMachine.game.finalCoban = true
            }
        }
        coban {
            -"Je voulais simplement te remercier."
            -"Grâce à toi, cette affaire est en passe d’être terminée."
        }
        choose {
            -"Explique-toi !"
        }
        coban {
            -"Une image vaut mieux qu’un long discours."
            -"Jette un oeil à cet article :"
            photo("img_article.jpeg", "article")
        }
        choose {
            -"Non, c’est impossible…"
        }
        coban {
            -"Merci ${player.name}."
            -"Tu nous as bien aidés."
            -"Sans toi, nous n’aurions jamais pu mettre la main sur ${elea.name}"
            -"Mais grâce aux infos que tu nous as fournies, et les messages qu’${
                elea.withGender("il", "elle")
            } t’a laissé sous le nom de « Nikola Tesla » sur CupidLov, nous avons pu tracer son IP."
            -"Ce fût ensuite un jeu d’enfant pour mes hommes de retrouver sa trace."
            -"Quant à ${
                reporter.withGender("son complice le journaliste", "sa complice la journaliste")
            }, c'était encore plus simple de lui mettre la main dessus."
        }
        choose {
            -"Vous les avez… ${elea.withGender("tués", "tuées")} ?"
        }
        coban {
            -"Oh, tout de suite de vilains mots…"
            -"J'imagine qu'il s'agit d'un malheureux accident…"
            -"Ne viens pas nous accuser ! Tu n'as aucune preuve."
        }
        choose {
            -"… ${reporter.name}… Tu mens ! Salaud ! Tu vas me le payer !"
        }
        coban {
            -"Allons, allons…"
            -"Je comprends ta colère."
            -"C’est parfois un sentiment noble."
            -"Mais tu devrais te calmer un peu."
            -"Pense à ce que tu nous as fait."
            -"Tu as osé me trahir, ${player.name}."
            -"Et je peux te jurer une chose."
            -"C’est que la colère que tu ressens aujourd’hui, va bientôt laisser place à un autre sentiment, beaucoup plus puissant :"
            -"La peur."
            -"tu es ${player.withGender("le prochain", "la prochaine")} sur ma liste."
            -"Nous nous verrons bientôt ${player.name}."
            -"Il est trop tard pour fuir."
        }
        narrator {
            -"« Toc, toc toc. »"
            -"Tiens ! On frappe à ta porte. Tu ne veux pas aller ouvrir ?"
            -"FIN"
        }
        redirect(ChapterFinal2.end, GoToScene.Option.Timeout(10_000))
    }
    scene(ChapterFinal2.end, EndContext("Fin 12", EndContext.Type.Deadly)) {
        stateMachine.achievements.end12 = true
        narrator {
            -"${player.name}, je ne sais pas si je dois vous féliciter."
            -"Vous avez réussi à finir le jeu, ce qui est en soit une réussite."
            -"Mais celle-ci a un goût amer."
            -"Votre choix de trahir ${elea.name} vous amène à cette terrible fin 12."
            -"Peut-être la pire que vous puissiez avoir !"
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
            -"Essayez de toutes les découvrir, et de débloquer tous les succès."
            -"En attendant la suite !"
        }
    }
}
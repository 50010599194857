package xyz.lacunae.itsadate

import xyz.lacunae.story.*

object Characters {
    val narrator = PnjCharacter("Narrator", true)
    val bot = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender
            get() = Gender.WOMAN
        override val lookingFor: Gender get() = Gender.WOMAN
        override val name: String
            get() = "Cupidon"
        override val img: String
            get() = "/characters/cupidon.svg"
    }
    val coban = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender
            get() = Gender.MAN
        override val lookingFor: Gender get() = Gender.WOMAN
        override val name: String
            get() = "Coban"
    }
    val roland = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender
            get() = Gender.MAN
        override val lookingFor: Gender get() = Gender.WOMAN
        override val name: String
            get() = "Roland"
    }
    val tim = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender
            get() = Gender.MAN
        override val lookingFor: Gender get() = Gender.WOMAN
        override val name: String
            get() = "Tim"
    }
    val waiter = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender
            get() = Gender.MAN
        override val lookingFor: Gender get() = Gender.WOMAN
        override val name: String
            get() = "Serveur"
    }
}

val StateMachine.player: GenderedCharacter
    get() = object : GenderedCharacter() {
        override fun toString(): String {
            return name
        }

        override val isPlayer: Boolean
            get() = true
        override val gender: Gender
            get() = this@player.game.gender
        override val lookingFor: Gender
            get() = this@player.game.looking_for
        override val name: String
            get() = game.player_name
        override val img: String?
            get() = game.player_image
    }

val StateMachineBuilder.player
    get() = stateMachine.game.player

val StateMachineBuilder.elea
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Simon", "Léa")
        override val info: DatingInformation
            get() = DatingInformation(
                movie = "Le Prestige",
                book = "La Jangada",
            )
    }
val StateMachineBuilder.reporter
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String  get() = withGender("Louis", "Lona")
    }

val StateMachineBuilder.mysteriousReporter
    get() = this.reporter.mysterious { stateMachine.game.metReporter }
val StateMachineBuilder.mysteriousElea
    get() = this.elea.mysterious { stateMachine.game.finalElea }
val StateMachineBuilder.mysteriousCoban
    get() = Characters.coban.mysterious { stateMachine.game.finalCoban }

fun GenderedCharacter.mysterious(condition: () -> Boolean): GenderedCharacter {
    return object : GenderedCharacter(){
        override fun toString() = name
        override val gender: Gender get() = this@mysterious.gender
        override val lookingFor: Gender get() = this@mysterious.lookingFor
        override val name: String get() = if(condition()){this@mysterious.name} else { "????" }

        override val img: String?
            get() = if (condition()) {
                this@mysterious.img
            } else {
                null
            }
        override val info: DatingInformation
            get() = if(condition()){this@mysterious.info} else { DatingInformation() }
    }
}
val StateMachineBuilder.pnj2
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Mathieu", "Noémie")
        override val info: DatingInformation
            get() = DatingInformation(
                job = "CDI",
                pets = "Chat",
                book = withGender("Queer Eye", "La méthode Marie Kondo"),
                hobbies = withGender("Yoga acrobatique", "Architecture d’intérieur en amateur")
            )
    }
val StateMachineBuilder.pnj3
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Sébastien", "Manon")
        override val info: DatingInformation
            get() = DatingInformation(
                hobbies = withGender("Tunning auto", "boire entre amis"),
                movie = withGender("Fast & Furious", "Dobermann"),
            )
    }
val StateMachineBuilder.pnj4
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Samuel", "Marie")
        override val info: DatingInformation
            get() = DatingInformation(
                book = withGender(null, "Da Vinci Code"),
                show = withGender(null, "The floor is lava"),
                sport = withGender("Frisbee", null),
                hobbies = withGender("Jeux vidéos", null),
            )
    }
val StateMachineBuilder.pnj5
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("François", "Faustine")
        override val info: DatingInformation
            get() = DatingInformation(
                movie = withGender("L’effet papillon", "Ocean’s 8"),
                show = withGender("Rick et Morty", "Fleabag"),
            )
    }
val StateMachineBuilder.pnj6
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Alexandre", "Laure")
        override val info: DatingInformation
            get() = DatingInformation(
                book = withGender(null, "Les liaisons dangereuses"),
                movie = withGender(null, "Mon curé chez les nudistes"),
                hobbies = withGender("Naturisme", null),
                job = withGender("Vendeur chez Jules", null),
            )
    }
val StateMachineBuilder.pnj7
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Yoann", "Émilie")
        override val info: DatingInformation
            get() = DatingInformation(
                hobbies = withGender("Jeux à gratter", "Football (allez les Verts !)"),
                job = "Chômage",
            )
    }
val StateMachineBuilder.pnj8
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Cyril", "Mélanie")
        override val info: DatingInformation
            get() = DatingInformation(
                hobbies = "Passer du temps en famille",
                job = withGender("Directeur d’un centre aéré", null),
                religion = withGender(null, "Dévote")
            )
    }
val StateMachineBuilder.pnj9
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Yanis", "Lella")
        override val info: DatingInformation
            get() = DatingInformation(
                job = withGender("Concepteur de concepts", "PDG d’un grand groupe céréalier"),
                hobbies = withGender("Peinture sur cravate", null),
                sport = withGender(null, "MMA")
            )
    }
val StateMachineBuilder.pnj10
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Grégoire", "Camille")
        override val info: DatingInformation
            get() = DatingInformation(
                job = withGender("Vendeur en boulangerie", "En reconversion"),
                movie = withGender(null, "Le chocolat"),
                book = withGender("99 francs", null),
            )
    }

val StateMachineBuilder.pnj11
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Jérémy", "Marion")
        override val info: DatingInformation
            get() = DatingInformation(
                job = withGender("Employé Free Telecom", "Youtubeuse gaming"),
                sport = withGender(null, "Roller derby"),
                hobbies = withGender("Courses hippiques", null),
            )
    }

val StateMachineBuilder.pnj12
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Mehdi", "Amélie")
        override val info: DatingInformation
            get() = DatingInformation(
                job = withGender(
                    "Leader d’un mouvement de libération de l’autre au travers des cycles lunaires",
                    "Voyante, astrologue, tireuse de cartes, hypnotérapeute, aromathérapeute, sonothérapeute, thérathérapeute\n"
                ),
                movie = withGender("Rocky 5", null),
                hobbies = withGender(null, "Écouter, aider, voir, ressentir, vivre, toucher"),
            )
    }

val StateMachineBuilder.pnj13
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Aurélien", "Anne-Sophie")
        override val info: DatingInformation
            get() = DatingInformation(
                job ="Guitariste",
                hobbies = "Musique",
            )
    }
val StateMachineBuilder.pnj14
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Hicham", "Sarah")
        override val info: DatingInformation
            get() = DatingInformation(
                job = withGender("Influenceur Linkedin", "Influenceuse Linkedin"),
                hobbies = withGender(null, "Créer des quiz"),
                sport = withGender("Billard thailandais", null)
            )
    }
val StateMachineBuilder.pnj15
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String get() = withGender("Enzo", "Anaïs")
        override val info: DatingInformation
            get() = DatingInformation(
                pets = "Chien",
                movie = "Tous les disney 💕",
            )
    }

val StateMachineBuilder.nikolaMars
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = elea.gender
        override val lookingFor: Gender get() = elea.lookingFor
        override val name: String get() = "Nikola Mars"
        override val img: String? = null
        override val info: DatingInformation
            get() = DatingInformation(
                book = "da vinci CODE: 10071856",
                serie = "CODE quantum: 10071856",
            )
    }

val StateMachineBuilder.mysterious
    get() = object : GenderedCharacter() {
        override fun toString() = name
        override val gender: Gender get() = stateMachine.game.looking_for
        override val lookingFor: Gender get() = stateMachine.game.gender
        override val name: String
            get() = "????"
        override val img: String?
            get() = null
    }

abstract class GenderedCharacter : BaseCharacter(), ImageCharacter, DatingCharacter {
    abstract val gender: Gender
    abstract val lookingFor: Gender
    override val img: String?
        get() = "/characters/${name.replace(" ", "_").lowercase()}.jpeg"
}

fun <T> GenderedCharacter.withGender(masculine: T, feminine: T) = when (gender) {
    Gender.MAN -> masculine
    Gender.WOMAN -> feminine
}

fun <T> GenderedCharacter.withLookingFor(masculine: T, feminine: T) = when (lookingFor) {
    Gender.MAN -> masculine
    Gender.WOMAN -> feminine
}

interface ImageCharacter : Character {
    val img: String?
}

data class DatingInformation(
    val cityOfBirth: String? = null,
    val movie: String? = null,
    val serie: String? = null,
    val book: String? = null,
    val show: String? = null,
    val sport: String? = null,
    val hobbies: String? = null,
    val job: String? = null,
    val pets: String? = null,
    val religion: String? = null,
)

interface DatingCharacter {
    val info: DatingInformation
        get() = DatingInformation()
}
package xyz.lacunae.itsadate

import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.roland
import xyz.lacunae.story.*

object Chapter5 {
    private const val name = "chapter-5"
    const val intro = "$name/intro"
    const val roland = "$name/roland"
    const val interview = "$name/interview"
    const val interlude = "$name/interlude"
    const val reporterSuite = "$name/reporter-suite"
    const val end4 = "$name/end-4"
    const val end5 = "$name/end-5"
    internal const val trustingNewPlayer = "$name/fragment/trusting-new-player"
    internal const val continueInterview = "$name/fragment/continue-interview"
    internal const val doItYourself = "$name/fragment/do-it-yourself"
    internal const val meet = "$name/fragment/meet"
}

internal fun Story.chapter5() {

    scene(Chapter5.intro, IntroContext("Chapitre 5")) {
        narrator {
            -"Une dizaine de jours ont passé depuis cette étrange soirée."
            -"Dix jours que vous êtes sans nouvelle de ${elea.name}. Aucun signe de vie de sa part, même furtif."
            -"${elea.withGender("Il", "Elle")} a tout simplement disparu…"
            -"${coban.name} ne s’est pas manifesté non plus."
            -"La déception passée, vous vous êtes fait une raison ;"
            -"${elea.name} est une personne surprenante et mystérieuse."
            -"${elea.withGender("Il", "Elle")} est sûrement déjà ${elea.withGender("passé", "passée")} à autre chose."
            -"Il en va ainsi des relations virtuelles, parfois elles se terminent avant d’avoir vraiment commencé…"
            -"Vous n’avez pas le temps de vous laisser abattre, et votre vie reprend doucement son cours…"
            -"La routine, épuisante et lassante, du métro-boulot-dodo…"
            -"Il faut faire le deuil de cette relation naissante et passer à autre chose."
            -"Et puis, un beau jour…"
        }
        appBadge(Apps.work)
        redirect(Chapter5.roland)
    }

    scene(Chapter5.roland, WorkContext) {
        characters(roland)
        roland {
            -"Tu sais, à ta place, je ne me prendrai pas la tête."
            -"« Pas de nouvelles, tant pis pour elle. »"
            -"C’est ce que je me dis à chaque fois que je me prends une veste."
        }

        choose {
            "Et ça marche ?" {

                roland {
                    -"Bien sûr que non !"
                    -"J’ai les boules pendant quelques jours, je m’en remets."
                    -"Tout ça pour te dire que tu devrais passer à autre chose."

                }
            }
            "Je ne me suis pas pris une veste…" {
                roland {

                    -"Appelle ça comme tu veux…"
                    -"Mais quelqu’un qui ne te donne plus signe de vie, c’est qu’il ne veut plus te voir."
                    -"Simple. Basique."
                }
            }
        }

        //NOTIFICATION <appli cryptée>

        roland {
            -"Écoute, juste une chose…"
            -"NE TE PRENDS PAS LA TÊTE !"
            -"Sur ce, je retourne bosser."
        }

        //CLIC sur la notification
        redirect(Chapter5.interview)
    }

    fragment(Chapter5.trustingNewPlayer) {
        mysteriousReporter {
            -"Ce que j’ai à vous dire risque de prendre un moment, et je préfère que nous en parlions « seul à seul »."
            -"Mieux vaut ne prendre aucun risque."
            -"Agissez normalement."
            -"Je vous recontacte ce soir, après les horaires de bureau."
            -"Au revoir."
        }
        narrator {
            -mysteriousReporter.disconnect()
        }
        redirect(Chapter5.interlude)
    }

    scene(Chapter5.interview, VirtualContext(App.SMS, Place.WORK, Ambiance.WORK)) {
        characters(mysteriousReporter)
        mysteriousReporter {
            -"Bonjour."
            -"Je suis à la recherche de ${elea.name} ?"
            -"Vous êtes ${player.withGender("un de ses amis", "une de ses amies")} ?"
        }
        choose {
            "Oui, qui êtes-vous ?" {
                mysteriousReporter {
                    -"Je vais tout vous raconter."
                    -"Je veux juste m’assurer que vous êtes bien dans un lieu sécurisé."
                }
                choose {
                    -"Euh, je suis au boulot."
                }
                include(Chapter5.trustingNewPlayer)
            }
            "On ne peut pas dire qu’on était si proches." {
                mysteriousReporter {
                    -"Ah bon…"
                    -"Il m’a pourtant semblé que c’était le cas."
                    -"Écoutez, est-ce que je peux vous recontacter ce soir afin de vous expliquer tout ça en détail ?"
                }
                choose {
                    "Oui, bien sûr !" {
                        include(Chapter5.trustingNewPlayer)
                    }
                    "Non, ça ne m’intéresse pas." {
                        mysteriousReporter {
                            -"Ah… Très bien."
                            -"Excusez-moi de vous avoir dérangé."
                            -"Bonne journée."
                        }
                        narrator {
                            -mysteriousReporter.disconnect()
                        }
                        redirect(Chapter5.end4, GoToScene.Option.Timeout(10_000))
                    }
                }
            }
        }
    }

    scene(Chapter5.end4, EndContext("Fin 4")) {
        stateMachine.achievements.end4 = true
        narrator {
            -"Cet inconnu a l’air d’en savoir long sur ${elea.name}."
            -"Qui est-il ? Que vous veut-il ?"
            -"Si seulement vous pouviez accélérer le temps, afin de tout de suite en savoir plus !"
        }
    }

    scene(Chapter5.interlude, IntroContext()) {
        //SYMBOLISER LE TEMPS QUI PASSE : une horloge téléphone dont le temps défile de plus en plus vite jusqu’au soir.
        narrator {
            -"« Cet inconnu a l’air d’en savoir long sur ${elea.name}."
            -"Qui est-il ? Que vous veut-il ?"
            -"Si seulement vous pouviez accélérer le temps, afin de tout de suite en savoir plus !"
            -"Votre journée de travail se termine, enfin !"
            -"Vous rentrez chez vous."
            -"Toujours pas de nouvelles."
            -"Le temps passe lentement, et puis, alors que vous ne l'attendiez plus, votre téléphone vibre. »"
        }
        //UN MINI JEU SE LANCE UN TRUC FACILE A PROGRAMMER POUR TOI, AVEC UNE NOTIFICATION QUI INTERROMPT OU FAIT PERDRE LE ${player.name}.
        //@Nico : Genre un memory ? ou un truc gratuit qu’on peut intégrer au jeu ? Je ne sais pas trop ce qu’on peut faire.
        // Un memory avec des petites illus à toi ça peut être cool non ?
        appBadge(Apps.sms)
        redirect(Chapter5.reporterSuite)
    }

    fragment(Chapter5.doItYourself) {
        reporter {
            -"Ah…"
            -"Je suis ${reporter.withGender("désolé", "désolée")} que tu le prennes comme ça."
            -"Je te croyais un peu plus empathique."
            -"Je ne vais pas te mentir : ta réaction me déçoit."
            -"Je croyais que ${elea.name} comptait un peu plus que ça à tes yeux."
            -"Pfff, j’ai été ${
                reporter.withGender("fou", "folle")
            } de vouloir faire confiance à ${player.withGender("un parfait inconnu", "une parfaite inconnue")}."
            -"Je ne te dérangerai plus."
            -"Bonne soirée."
        }
        narrator {
            -reporter.disconnect()
        }
        redirect(Chapter5.end5, GoToScene.Option.Timeout(10_000))
    }

    fragment(Chapter5.meet) {
        reporter {
            -"J’ai l’impression que je peux te faire confiance."
            -"Tu as l’air ${player.withGender("concerné", "concernée")} par le sort de ${elea.name}."
            -"Je vais prendre un risque, et te proposer un truc…"
            -"Je pense qu’on devrait se rencontrer."
            -"On pourrait parler plus en détail de cette affaire."
            -"Et je pourrais te montrer la tablette de ${elea.name}."
            -"Je suis ${
                reporter.withGender("sûr", "sûre")
            } que tu pourras m’aider à décrypter les infos qu’elle contient."
        }
        choose {
            "OK, faisons ça !" {
                reporter {
                    -"Super !"
                    -"Voilà ce que je te propose :"
                    -"Je te recontacte dans quelques jours, afin qu’on trouve un endroit où se rencontrer."
                    -"D’ici là, je vais continuer mes recherches, et essayer d’en savoir plus sur ${coban.name}."
                    -"Il est suspect, je dois enquêter sur lui."
                    -"Et puis d’ici là, je ne perds pas espoir d’avoir des nouvelles de ${elea.name}."
                    -"Allez, je m’y jette !"
                    -"Il y a du boulot."
                    -"Merci ${player.name} !"
                }
                choose {
                    -"Bonne soirée ${reporter.name}."
                    -"Merci à toi."
                }
                reporter {
                    -"Toi aussi. ☺️"
                    -"A très vite !"
                }
                narrator {
                    -reporter.disconnect()
                }
                redirect(Chapter6.intro)
            }
            "Non, c’est trop dangereux." {
                choose {
                    -"Je préfère m’arrêter là."
                }
                include(Chapter5.doItYourself)
            }
        }
    }

    fragment(Chapter5.continueInterview) {
        mysteriousReporter {
            -"Excusez-moi pour tout à l’heure, j’ai été un peu vite en besogne."
            -"Je n’ai même pas pris la peine de me présenter."
            -"Je m’appelle ${reporter.name}, je connais très bien ${elea.name}."
        }
        choose {
            "Dites-m’en plus" {
                stateMachine.game.metReporter = true
            }
        }
        reporter {
            -"Très bien."
            -"Je vais vous parler de manière très directe, et j’attends que vous me répondiez avec franchise."
            -"Avez-vous des informations concernant la disparition de ${elea.name} ?"
        }
        choose {
            "${elea.name} a disparu ?" {
                reporter {
                    -"Oui, cela fait plusieurs semaines que je suis sans nouvelles."
                    -"Je…"
                    -"Pfff, ça paraît fou, je ne sais même pas par où commencer."
                    -"Écoute ${player.name}…"
                    -"Déjà, commençons par nous tutoyer, ce sera plus simple."

                }
            }
            "Je n’ai rien à vous dire" {
                reporter {
                    -"Je…"
                    -"Écoute ${player.name}"
                    -"Bon, tutoyons-nous, ce sera un bon début."
                    -"Je comprends ta réticence…"
                    -"On ne se connait pas, tu connais peut-être à peine ${elea.name}, mais j’ai vraiment besoin de ton aide…"
                }
            }
        }
        reporter {
            -"Je te dois quelques explications, sans quoi tu ne vas jamais croire à cette histoire."
            -"Je vais juste te dire tout ce que je sais, ensuite ce sera à toi de me croire et de m’aider…"
            -"… Ou bien de ne plus rien avoir à faire avec ${elea.name} et moi."
        }
        choose {
            -"Faisons comme ça."
            -"Ai-je vraiment le choix ?"
        }
        reporter {
            -"J’en ai pour 5 minutes."
            -"Voilà toute mon histoire."
            -"Je connais ${elea.name} depuis presque toujours."
            -"On était en classe ensemble, dès la maternelle, et on est toujours ${
                elea.withGender("restés", "restées")
            } proches."
            -"Mais le monde des enfants et celui des adultes sont assez différents."
        }
        choose {
            -"Comment ça ?"
        }
        reporter {
            -"Les enfants ne se soucient que du lendemain."
            -"De l’école, des copains et des vacances."
            -"Alors que la vie nous réserve son lot de surprises et de changements."
            -"Ses parents ont déménagé, assez soudainement, en milieu d’année scolaire."
            -"C’était pendant les vacances, et nous n’avons pas pu nous dire au revoir…"
            -"Un jour ${elea.withGender("il", "elle")} n’était plus là, et c’en était fini de notre amitié."
        }
        choose {
            "Vous auriez pu vous écrire, vous téléphoner…" {
                reporter {
                    -"A notre âge, on n’avait pas accès à une connexion internet si facilement que ça."
                    -"Et ne sachant pas qu’${
                        elea.withGender(
                            "il",
                            "elle"
                        )
                    } partait, je n’ai jamais eu sa nouvelle adresse."
                    -"C’était si soudain…"
                    -"Brutal, même."
                    -"Un jour, on s’amuse avec notre meilleur ami, sans savoir que c’est pour la dernière fois."
                    -"Et des années plus tard, on se rend compte qu’on pense à lui, de temps en temps…"
                    -"Que ces moments passés ensemble étaient précieux."
                    -"On n’a donc jamais pu garder contact."
                }
            }
            "C’est la vie." {
                reporter {
                    -"C’est la vie, oui…"
                    -"Rien d’exceptionnel, tu as raison."
                    -"Tous les gamins vivent ça."
                    -"Un jour, on s’amuse avec notre meilleur ami, sans savoir que c’est pour la dernière fois."
                    -"Et des années plus tard, on se rend compte qu’on pense à lui, de temps en temps…"
                    -"Que ces moments passés ensemble étaient précieux."
                    -"Si j’avais su qu’${
                        elea.withGender(
                            "il",
                            "elle"
                        )
                    } partait, j’aurais essayé de récupérer sa nouvelle adresse."
                    -"Mais quand j’ai appris son départ, il était déjà trop tard…"
                    -"Je n’ai plus jamais eu de nouvelles."
                }
            }
        }
        reporter {
            -"Et puis, les années ont passé."
            -"Comme tous les enfants qui grandissent, j’ai un peu oublié cette amitié."
            -"Jusqu’à tout récemment."
            -"${elea.name} m’a envoyé un mail il y a deux mois."
            -"${elea.withGender("Il", "Elle")} y expliquait avoir besoin de mes conseils, pour une affaire ${
                elea.withGender("le", "la")
            } concernant."
        }
        choose {
            -"C’est suspect."
            -"Pourquoi recontacter ${elea.withGender("un ami", "une amie")} d’enfance des années plus tard ?"
        }
        reporter {
            -"Je suis d’accord avec toi !"
            -"Mais même si moi j’avais perdu sa trace, il faut croire qu’${
                elea.withGender("il", "elle")
            } n’avait pas cessé de suivre mon parcours."
            -"Je suis journaliste ${
                reporter.withGender(
                    "indépendant",
                    "indépendante"
                )
            }, je travaille en free-lance pour différents petits journaux régionaux."
            -"Rien de bien folichon, j’essaie encore de faire décoller ma carrière."
            -"Mais j’ai été ${reporter.withGender("surpris", "surprise")} de cette reprise de contact."
            -"${elea.withGender("Il", "Elle")} disait avoir besoin de moi, que je pourrais l’aider."
            -"Et pour ne rien te cacher, j’étais un peu ${
                reporter.withGender("énervé", "énervée")
            } de cette demande sur le coup."
        }
        choose {
            "Pourquoi ça ?" {
                reporter {
                    -"Pourquoi ça ?"
                    -"Mets-toi à ma place."
                    -"Comment réagirais-tu si une personne à qui tu n’as pas parlé depuis des années…"
                    -"Te recontactait pour te demander un coup de main ?"
                    -"C’est plutôt vexant !"
                }
            }
            "Je comprends…" {}
        }
        reporter {
            -"Quoi qu’il en soit, j’ai quand même décidé d’écouter ce qu’${
                elea.withGender("il", "elle")
            } avait à me dire."
            -"C’était ${reporter.withGender("mon plus proche ami", "ma plus proche amie")} après tout."
            -"Et puis… son mail m’a paru… étrange."
        }
        choose {
            -"Comment ça ?"
            -"Haha, ${elea.withGender("il", "elle")} aime bien les mystères."
        }
        reporter {
            -"${elea.withGender("Il", "Elle")} m’a parlé de documents à protéger."
            -"Qu’${elea.withGender("il était poursuivi", "elle était poursuivie")}, par des gens mal intentionnés."
            -"Se sentant ${
                elea.withGender("seul, et menacé, il", "seule, et menacée, elle")
            } ne souhaitait pas voir ses secrets tomber entre de mauvaises mains."
        }
        choose {
            -"Des documents, des secrets ?"
            -"C’est une blague, c’est ça ?"
        }
        reporter {
            -"Oui, je sais, ça paraît dingue."
            -"Je me suis d’abord dit qu’en quelques années, ${elea.withGender("il", "elle")} était ${
                elea.withGender("devenu", "devenue")
            } complètement ${elea.withGender("fou", "folle")}."
            -"Mais dans mon métier, on doit s’attendre à tout."
            -"On ne sait jamais, un bon article peut faire décoller une carrière."
            -"Alors j’ai décidé d’écouter son histoire."
            -"Et de lui faire confiance."
            -"D’après ${elea.withGender("lui", "elle")}, ses révélations pourraient changer la face du monde."
        }
        choose {
            -"Rien que ça…"
            -"Euh, tu n’exagères pas un peu ?"
        }
        reporter {
            -"Je ne fais que te répéter ce qu’on s’est dit."
            -"Tu sais ce que je pense ?"
            -"Que dans un monde où le contrôle des Etats et de certaines puissances est presque total,"
            -"Nous nous devons de garantir l’indépendance de l’information, et surtout la libre circulation des idées."
        }
        choose {
            -"Quel est le rapport avec ${elea.name} ?"
            -"C’est une blague cette histoire ?"
        }
        reporter {
            -"Si tu trouves ça dingue, attends de lire la suite…"
            -"La grande question concernant les documents de ${elea.name}…"
            -"C’est que je ne sais même pas de quoi il s’agit !"
            -"${elea.withGender("Il", "Elle")} n’a pas voulu m’en dire beaucoup, préférant en parler de vive voix."
            -"Malgré ma réticence initiale, ${elea.withGender("il", "elle")} a su me convaincre."
            -"On devait se retrouver à Paris pour en discuter, et voir ensemble comment traiter cette histoire."
            -"Mais ${elea.name} n’est jamais ${elea.withGender("venu", "venue")} à notre rendez-vous."
        }

        choose {

            "${elea.withGender("Il", "Elle")} était peut-être en retard ?" {
                reporter {

                    -"Oh non, ça n’est pas le genre à être en retard."
                    -"Impossible également de ${
                        elea.withGender("le", "la")
                    } joindre sur son portable."
                    -"${
                        elea.withGender("Il", "Elle")
                    } ne répond plus aux mails, et je ne connais pas son adresse exacte."
                    -"Je l’ai ${elea.withGender("cherché", "cherchée")} pendant des jours…"
                    -"Sans succès."
                }

                choose {
                    -"Comment as-tu eu mon contact ?"
                    -"Qu’est-ce que tu as fait après ça ?"
                }
            }
            "Je crois que le soir où ${elea.withGender("il", "elle")} a disparu, nous parlions ensemble." {
                reporter {
                    -"Ah oui ?"
                    -"Il faut que tu me racontes ça !"
                    -"C’est peut-être le début d’une piste."
                    -"Laisse-moi d’abord te dire comment j’ai eu ton contact."
                }
            }
        }
        reporter {
            -"Les instructions de ${elea.name} étaient très claires."
            -"J’ai l’impression qu’${
                elea.withGender("il", "elle")
            } avait tout prévu, au cas où quelque chose de grave arriverait… "
            -"${elea.withGender("Il", "Elle")} a bien couvert ses arrières, heureusement."
            -"Sans ça, il n’y aurait aucun moyen de retrouver sa trace."
            -"La dernière fois qu’on a pu se parler, ${elea.withGender("il", "elle")} m’a donné un code."
            -"C’était celui d’une consigne, à proximité de la Gare de Lyon, en plein centre de Paris."
        }
        choose {
            -"On se croirait en plein polar."
            -"Euh, tu me dis la vérité là ?"
        }
        reporter {
            -"Oui !"
            -"A l’intérieur de cette consigne, j’ai trouvé un paquet…"
            -"Qui contenait une tablette tactile."
            -"La tablette avait été mise à jour le matin précédant sa disparition."
            -"J’y ai trouvé ton contact, ainsi que celui d’un certain ${coban.name}."
        }
        choose {
            silent("Lui en dire plus sur ${coban.name}") {
                choose {
                    -"${coban.name} est un ami à ${elea.withGender("lui", "elle")}."
                }
                choose {
                    -"Ils parlaient régulièrement ensemble."
                }
                reporter {
                    -"C’est ce que j’ai cru comprendre oui !"
                    -"J’ai pu parler un peu avec ce ${coban.name}."
                }
            }
            silent("Ne rien dire.")
        }
        reporter {
            -"${
                elea.withGender("Il", "Elle")
            } échangeait avec ${
                coban.withLookingFor(
                    "lui",
                    "elle"
                )
            } depuis plus longtemps, je l’ai donc contacté avant toi."
            -"Malheureusement, il n’a rien pu me dire sur ce qui a pu arriver à ${elea.name}."
            -"Il semblait même apprendre sa disparition."
            -"Mais il s’est montré très… insistant."
            -"Il voulait absolument me rencontrer, récupérer des infos, échanger nos contacts, etc."
            -"D’instinct, mais j’ai trouvé ça louche."
        }
        choose {
            "${coban.name} est quelqu’un de confiance !" {
                reporter {
                    -"Ah oui, tu penses ?"
                    -"Je n’en suis pas si ${reporter.withGender("sûr", "sûre")}…"
                    -"Dans mon métier, j’ai appris à me méfier."
                    -"Et son enthousiasme à vouloir absolument me rencontrer ne m’inspire vraiment pas confiance."
                    -"Je l’ai même trouvé un peu agressif, à insister malgré mes réticences."
                    -"Mais je me fais peut-être des idées."
                    -"C’est pour ça que j’ai besoin de me faire un avis."
                }
            }
            "Comment puis-je t’aider ?" {}
        }
        reporter {
            -"Tu connais apparemment ${coban.name}."
            -"Tu peux m’en dire plus sur lui ?"
        }
        choose {
            "Si tu veux, voilà la discussion que nous avons eu le soir de la disparition de ${elea.name}" {
                upload("conversation_capture.txt")
                reporter {
                    -"Oh, merci beaucoup ${player.name} !"
                    -"Je regarde ça tout de suite."
                    -"Je te recontacte dès que j’ai lu tout ça !"
                }
                narrator {
                    -"${reporter.name} est ${reporter.withGender("absent", "absente")}"
                }
                //[Notification au joueur après 5-10 minutes]
                reporter {
                    -"Hum…"
                    -"ok…"
                    hesitation()
                    -"Au premier abord, il a effectivement l’air très sympa ce ${coban.name}."
                    hesitation()
                    -"Mais y’a un truc qui me chiffonne."
                    -"Il n’est pas net, c’est sûr !"
                }

                choose {
                    "Oh ! Je vois de quoi tu parles !" {
                        reporter {
                            -"Bien joué, tu fais attention aux détails."
                            -"Tu t’es ${
                                player.withGender("aperçu", "aperçue")
                            } qu’il savait précisément dans quel quartier ${elea.name} se trouvait."
                            -"C’est sûr que tu vas pouvoir m’aider."
                        }
                    }
                    "D’après moi, ${coban.name} n’a rien d’un suspect…" {

                        reporter {
                            -"Et pourtant… Je crois bien que si !"

                            -"A la fin de votre conversation, quand ${elea.name} s’est ${
                                elea.withGender("déconnecté", "déconnectée")
                            }."
                            -"${coban.name} s’est trahi."
                            -"A AUCUN moment, ${elea.name} n’a mentionné précisément l’endroit où ${
                                elea.withGender("il", "elle")
                            } se trouvait."
                            -"${elea.withGender("Il", "Elle")} semblait ${
                                elea.withGender("méfiant", "méfiante")
                            }, et est ${
                                elea.withGender("resté", "restée")
                            } volontairement vague sur sa localisation."
                            -"Mais ${coban.name} a l’air d’en savoir plus long qu’il ne semble le dire."
                            -"Tiens, regarde cet extrait de la discussion."
                        }
                        narrator {
                            -"${player.name.uppercase()}: Il se passe quelque chose !"
                            -"${coban.name.uppercase()}: Mais non, son quartier est très animé ce soir. Y’a même un concert gratuit juste à côté."
                            -"${coban.name.uppercase()}: ${elea.name} a rencontré un voisin et ils sont allés faire la fête, c’est tout."
                        }
                        reporter {
                            -"Tu vois ?"
                            -"Il parle d’un concert gratuit dans le quartier de ${elea.name} !"
                            -"Comment pourrait-il être au courant, vu qu’il affirmait lui-même avant ne pas savoir où ${elea.name} se trouvait ?"
                            -"${coban.name} est impliqué là -dedans, j’en suis ${elea.withGender("sûr", "sûre")} !"
                        }
                    }
                }
                choose {
                    "Tu devrais appeler les flics !" {
                        reporter {
                            -"Pas possible."
                            -"${elea.name} a été très ${elea.withGender("clair", "claire")} à ce sujet."
                            -"Il est trop tôt pour contacter les autorités, quoi qu’il arrive."
                            -"Je ne sais pas encore de quoi il s’agit, mais ${
                                elea.withGender("il", "elle")
                            } a disparu à cause des infos qu’${
                                elea.withGender("il", "elle")
                            } avait."
                            -"On ne peut pas prendre de risques tant qu’on n’en sait pas plus."
                            -"Et puis tu penses que la police dirait quoi ?"
                            -"${elea.name} est ${elea.withGender("un", "une")} adulte, responsable et indépendant."
                            -"Des milliers de personnes disparaissent ainsi chaque année."
                            -"Ils ne feraient aucune recherche."
                        }
                        include(Chapter5.meet)
                    }
                    "Comment puis-je t’aider ?" {
                        reporter {
                            -"Je savais que je pourrais compter sur toi. 🙂"
                        }
                        include(Chapter5.meet)
                    }
                    "C’est trop pour moi. Débrouille-toi ${
                        reporter.withGender("tout seul", "toute seule")
                    }. Je ne veux plus JAMAIS entendre parler de cette histoire." {
                        include(Chapter5.doItYourself)
                    }
                }
            }
            "Non. Débrouille-toi ${
                reporter.withGender("tout seul", "toute seule")
            }. Je ne veux plus JAMAIS entendre parler de cette histoire." {
                include(Chapter5.doItYourself)
            }
        }
    }

    scene(Chapter5.reporterSuite, VirtualContext(App.SMS, Place.HOME, Ambiance.HOME)) {
        characters(mysteriousReporter)
        mysteriousReporter {
            -"${player.name} ?"
            -"Vous êtes là ?"
        }
        choose {
            "Oui." {
                include(Chapter5.continueInterview)
            }
            "Ouais." {
                include(Chapter5.continueInterview)
            }
            "Yep." {
                include(Chapter5.continueInterview)
            }
            "Non !" {
                reporter {
                    -"Je vais faire comme si c’était un trait d’humour de votre part."
                }
                include(Chapter5.continueInterview)
            }
        }
    }

    scene(Chapter5.end5, EndContext("Fin 5")) {
        stateMachine.achievements.end5 = true
        narrator {
            -"${reporter.name} ne semble pas vous inspirer confiance… ${
                reporter.withGender("Il", "Elle")
            } devra donc se débrouiller ${
                reporter.withGender("seul", "seule")
            } pour en savoir plus sur ${coban.name}."
            -"Vous n’aurez plus jamais de ses nouvelles, et ne saurez jamais ce qui est arrivé à ${elea.name}."
            -"Vous avez suivi votre instinct, et avez préféré vous préserver."
            -"Qui sait, cette affaire aurait pu être dangereuse ?"
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 5."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }

}
package xyz.lacunae.itsadate

fun Long.formatTime(): String {
    val hours = this / 3_600
    val minutes = (this - hours * 3_600) / 60
    return if (hours > 0) {

        "$hours h "+"$minutes".padStart(2, '0')
    } else {
        "$minutes minutes"
    }
}
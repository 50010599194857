package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.roland

object Chapter10 {
    private const val name = "chapter-10"
    const val intro = "$name/intro"
    const val reporterStart = "$name/reporter-start"
    const val reporterTaxi = "$name/reporter-taxi"
    const val reporterQuietBar = "$name/reporter-quiet-bar"
    const val reporterBusyBar = "$name/reporter-busy-bar"
    const val reporterCode = "$name/reporter-code"
    const val reporterSafeBar = "$name/reporter-safe-bar"
    const val roland = "$name/roland"
    const val cobanStart = "$name/coban-start"
    const val cobanBar = "$name/coban-bar"
    const val cobanCode = "$name/coban-code"
    const val cobanMad = "$name/coban-mad"
    const val end10 = "$name/end-10"
    const val introEncrypted = "$name/intro-encrypted"
    const val findCode = "$name/find-code"
    const val revelations = "$name/revelations"
    const val failure = "$name/failure"
    internal const val drunkGuy = "$name/fragment/drunk-guy"
    internal const val playerGuy = "$name/fragment/player-guy"
    internal const val idiot = "$name/fragment/idiot"
    internal const val threeErrors = "$name/fragment/three-errors"
    internal const val fiveErrors = "$name/fragment/five-errors"
}

internal fun Story.chapter10() {
    scene(Chapter10.intro, IntroContext("Chapitre 10")) {
        narrator {
            -"L’étau se resserre. Vous avez l’impression que cette affaire prend des proportions qui vous dépassent."
            -"Chaque fois que votre téléphone sonne, vous vous jetez dessus, ${
                player.withGender("partagé", "partagée")
            } entre l’espoir d’une bonne nouvelle, et la peur d’une mauvaise."
            -"Où est ${elea.name} ? Que va faire ${reporter.name} de ces documents cryptés ?"
            -"${coban.name} prépare-t-il un plan machiavélique à vos dépends ?"
            -"Que va-t-il vous faire ?"
            -"…"
            -"Quelques jours plus tard. "
            -"${coban.name} ne s’est toujours pas manifesté."
            -"Mais ${reporter.name} vous contacte enfin."
            -"La situation s’envenime, et vous devez l’aider."
            -"Sa vie est entre vos mains."
        }
        appBadge(Apps.sms)
        redirect(Chapter10.reporterStart)
    }

    scene(Chapter10.reporterStart, SmsRunningContext) {
        characters(reporter)
        reporter {
            -"${player.name} !"
            -"J’ai besoin de toi, c’est urgent !"
        }
        choose {
            -"Je suis là !"
        }
        choose {
            -"Comment ça va ${reporter.name} ?"
        }
        reporter {
            -"Mal…"
            -"Très mal."
            -"Je suis à mon hôtel, dans le quartier de la Bastille à Paris."
            -"Les hommes de ${coban.name} ont retrouvé ma trace !"
        }
        choose {
            if (!stateMachine.game.fuckCoban) {
                "Je l’ai pourtant envoyé sur une fausse piste" {
                    reporter {
                        -"Et je t’en remercie."
                        -"Sans ça, il m’aurait retrouvé bien plus tôt."
                        -"Et qui sait ce qui serait arrivé…"
                        -"J’ai rencontré mon ami cryptologue dans un lieu public et discret."
                        -"Et quand il est rentré chez lui, son appart avait été cambriolé !"
                    }
                    choose {
                        -"Ils sont prêts à tout…"
                    }
                    reporter {
                        -"Bien sûr qu’ils sont prêts à tout."
                        -"Tu en doutais ?"
                    }
                    choose {
                        "Oui, j’avais des doutes."{
                            reporter {
                                -"Eh bien j’espère que tu n’en as plus, et que je vais pouvoir compter sur toi."
                            }
                        }
                        "Non, ça me confirme le mal que je pense de ${coban.name}."{
                            reporter {
                                -"Et encore, tu ne sais pas tout…"
                            }
                        }
                    }
                    reporter {
                        -"Ils se sont pointés à l’hôtel dans lequel je suis descendu :"
                        -"le réceptionniste me dit que des hommes sont venus me voir alors que je n’étais pas là…"
                    }
                    choose {
                        "Ils doivent encore être dans le coin, tu dois fuir !"{
                            reporter {
                                -"Oui, je crois que c’est la meilleure chose à faire…"
                            }
                        }
                        "Contacte la police, c’est plus sûr !"{
                            reporter {
                                -"Hum, je ne crois pas."
                                -"Si c’était si simple, ${elea.name} l’aurait déjà fait."
                                -"${coban.name} semble avoir le bras long… C’est peut-être le meilleur moyen de me faire repérer."
                                -"Et de toute façon, je n’ai aucune preuve contre eux…"
                                -"Tout ce qu’on a, ce sont les plans cryptés de ${elea.name}."
                                -"Non, mieux vaut ne pas prendre ce risque."
                                -"Je pense que je ferai mieux de m’enfuir au plus vite."
                            }
                        }
                    }
                }
            }
            "Le salaud, il n’a pas perdu de temps" {
                reporter {
                    -"Oh merde, je crois qu’ils sont encore là !"
                    -"Je dois quitter cet endroit au plus vite."
                }
            }
        }
        reporter {
            -"OK, j’ai deux possibilités :"
            -"Je peux passer par l’entrée principale de l’hôtel, et tenter de me mêler à la foule."
            -"Ou bien je peux essayer de passer par une sortie de secours."
            -"Ce serait plus discret."
        }
        choose {
            "L’entrée principale"{
                stateMachine.game.mainDoor = true
                reporter {
                    -"Tu crois ?"
                    -"Hum…"
                    -"Il y a du monde dans la rue, ils ne pourront rien tenter contre moi."
                    -"La contrepartie c’est que je ne pourrai pas les semer."
                    -"Merde, ils sont là et ne se cachent même plus."
                    -"Ils me regardent méchamment…"
                    -"Oh, un taxi qui se libère, juste devant l’hôtel !"
                    -"Je fonce."
                    -"Je te recontacte vite."
                }
            }
            "Trouve une autre issue, ce serait plus sûr !"{
                stateMachine.game.mainDoor = false
                reporter {
                    -"Ok, tu as raison !"
                    -"Ils ont l’air sûrs d’eux, ils ne se cachent même pas."
                    -"Ils sont deux, à proximité de l’entrée de l’hôtel."
                    -"J’ai une idée !"
                    -"Je te recontacte si ça a marché !"
                }
            }
        }
        appBadge(Apps.messenger)
        redirect(Chapter10.cobanStart)
    }

    scene(Chapter10.cobanStart, MessengerCobanRunContext) {
        characters(coban)
        coban {
            -"Bonjour, ${player.name}."
        }
        choose {
            "Qu’est-ce que tu me veux ?"{
                coban {
                    -"Du calme, ${player.withGender("mon cher", "ma chère")}."
                    -"Je viens un peu aux nouvelles."
                }
            }
            "Salut ${coban.name}, je suis un peu ${player.withGender("occupé", "occupée")} là…"{
                coban {
                    -"A d’autres !"
                }
            }
        }
        coban {
            -"Tu es en train de discuter avec ${reporter.withGender("ce", "cette")} journaliste, n’est-ce pas ?"
            -"Mes hommes ${reporter.withGender("le", "la")} suivent de près."
            -"Ce n’est qu’une question de minutes avant qu’on lui mette le grappin dessus."
        }
        choose {
            "Ah, tant mieux !"{
                coban {
                    -"Content de te voir aussi enthousiaste."
                    -"Ce sera grâce à toi, tu peux en être ${player.withGender("fier", "fière")}."
                    -"Tiens-moi au courant ${reporter.withGender("s’il", "si elle")} tente de s’enfuir."
                    -"Je te recontacte dans quelques minutes."
                }
            }
            "Vous ne réussirez jamais à l’arrêter."{
                coban {
                    -"Ah oui, tu crois ça ?"
                    -"Tu risques d’être ${player.withGender("surpris", "surprise")}."
                    -"On en reparle dans quelques minutes."
                }
            }
        }
        appBadge(Apps.sms)
        redirect(Chapter10.reporterTaxi)
    }

    scene(Chapter10.reporterTaxi, SmsRunningContext) {
        characters(reporter)

        reporter {
            -"${player.name} ?"
            -"T’es par là ?"
        }
        choose {
            -"Oui, je suis là!"
            -"Tout va bien ?"
        }
        reporter {
            -"J’ai pu prendre un taxi, je pense que je les ai semés."
            -"Pfiou, ça n’a pas été facile."
        }
        if (!stateMachine.game.mainDoor) {
            reporter {
                -"J’ai laissé mon manteau sur un siège de l’entrée de l’hôtel."
                -"Puis je me suis ${reporter.withGender("dirigé", "dirigée")} vers les toilettes."
                -"Et au lieu d’aller aux toilettes, j’ai foncé à l’arrière de l’hôtel !"
                -"J’ai pu prendre une porte de service."
                -"Les hommes de ${coban.name} n’ont pas pu me suivre."
                -"J’ai gagné du temps !"
                -"Tu avais raison, merci ${player.name}."
            }
            choose {
                -"A ton service !"
                -"Reste ${reporter.withGender("vigilant", "vigilante")} !"
            }
        }
        reporter {
            -"Le taxi roule un peu au pif, dans Paris."
            -"Je crois qu’on arrive vers la place de la République."
        }
        choose {
            "${coban.name} m’a contacté !"{
                reporter {
                    -"Oh non…"
                    -"Qu’est-ce qu’il a prévu de faire."
                }
                choose {
                    "Il est à ta poursuite. Je n’en sais pas plus."{
                        reporter {
                            -"Mince, qu’est-ce que je vais pouvoir faire… J’espère pouvoir compter sur toi pour me sortir de là !"
                            -"Faisons le point sur les options que j’ai :"
                        }
                    }
                    "Tu n’as rien à craindre."{
                        reporter {
                            -"Ah oui, tu crois ?"
                            -"Je ne suis pas de cet avis."
                            -"S’ils me retrouvent, je suis ${reporter.withGender("foutu", "foutue")}."
                            -"Bon, quelles sont mes options…"
                        }
                    }
                }
            }
            silent("Ne rien dire.")
        }
        reporter {
            -"Pour le moment, je ne vois plus les hommes de ${coban.name}."
            -"J’espère les avoir semés."
            -"Continuons à brouiller les pistes."
            -"Deux endroits à proximité pourraient éventuellement me servir de planque :"
            -"un bar bondé et très populaire, « Chez Prune »."
            -"Du monde tout le temps, jusque tard dans la nuit."
            -"J’y passerai sûrement ${reporter.withGender("inaperçu, perdu", "inaperçue, perdue")} dans la foule."
            -"Ou alors je vois aussi un petit bar de quartier plus discret le « Bar Bichette »."
            -"J’y serai à l’abri des regards, tranquille."
            -"T’en penses quoi ?"
        }
        choose {
            "Le bar discret"{
                stateMachine.game.quietBar = true
                reporter {
                    -"OK !"
                    -"J’ai un peu peur d’être à leur merci s’ils me retrouvent dans un bar isolé et pratiquement sans témoin."
                    -"Et en même temps, ça paraît plus sûr."
                    -"On aura le temps de discuter des conclusions du cryptologue."
                    -"Je te recontacte quand on arrive au bar."
                    -"A tout à l’heure ${player.name}."
                    -"Et encore merci pour ton aide…"
                }
            }
            "Le bar bondé"{
                stateMachine.game.busyBar = true

                reporter {
                    -"Ça va être dur d’y trouver un endroit tranquille pour discuter avec toi."
                    -"Mais c’est peut-être la meilleure solution :"
                    -"La foule est mon alliée."
                    -"Les hommes de ${coban.name} ne peuvent pas prendre le risque de m’arrêter en public."
                    -"Déjà, ils n’ont pas le droit de le faire."
                    -"Et ils veulent sûrement éviter les regards."
                    -"Ils doivent rester dans l’ombre, sinon toute cette histoire pourrait leur péter à la gueule."
                    -"C’est là mon seul avantage sur eux."
                    -"Je vais te laisser, et je te recontacte une fois ${
                        reporter.withGender("arrivé", "arrivée")
                    } au bar."
                    -"Il me tarde de t’expliquer ce que m’a dit le cryptologue."
                    -"A tout à l’heure ${player.name}."
                    -"Et encore merci pour tout…"
                }
            }
        }
        appBadge(Apps.messenger)
        redirect(Chapter10.cobanBar)
    }

    scene(Chapter10.cobanBar, MessengerCobanRunContext) {
        characters(coban)

        coban {
            -"${player.name} !"
            -"J’ai besoin de toi."
            -"Où se trouve ${reporter.name} ?"
            -"Nous avons momentanément perdu sa trace."
        }

        choose {
            "${reporter.withGender("Il", "Elle")} est au « Bar Bichette », un bar discret."{
                stateMachine.game.lyingToCoban = !stateMachine.game.quietBar
                coban {
                    -"OK, merci."
                    -"Je préviens mes hommes."
                }
            }
            "${reporter.withGender("Il", "Elle")} est « Chez Prune », un bar populaire"{
                stateMachine.game.lyingToCoban = !stateMachine.game.busyBar
                coban {
                    -"OK, merci."
                    -"Je préviens mes hommes."
                }
            }
            "${
                reporter.withGender("Il", "Elle")
            } est au « Bar Toidla Saltype », un endroit que tu apprécierais particulièrement." {
                stateMachine.game.fuckCoban2 = true
                coban {
                    -"Décidément, tu ne rates jamais une occasion d'ouvrir ta petite gueule."
                    -"Il me semble que tu n’as pas bien compris une chose, ${player.name}."
                    -"Je n’ai pas besoin de toi."
                    -"Et par ta faute, ton ${
                        reporter.withGender("ami", "amie")
                    } ${
                        reporter.withGender("le", "la")
                    } journaliste n’a plus beaucoup de temps devant ${
                        reporter.withGender("lui", "elle")
                    }."
                }
            }
        }
        if (stateMachine.game.quietBar) {
            redirect(Chapter10.reporterQuietBar)
        } else {
            redirect(Chapter10.reporterBusyBar)
        }
    }

    fragment(Chapter10.drunkGuy) {
        reporter {
            -"Ne t’inquiète pas."
            -"C’est juste un mec bourré."
            -"Il a fait un scandale pour qu’on le serve, alors qu’il tient à peine debout."
            -"Le serveur l’a mis dehors."
            -"Au début, j’ai cru qu’il s’agissait d’un des hommes de ${coban.name}."
            -"Mais c’est bon, le calme est revenu."
        }
        redirect(Chapter10.roland)
    }

    scene(Chapter10.reporterSafeBar, SmsRunningContext) {
        reporter {
            -"${player.name} ?"
            -"Tu es là ?"
        }
        choose {
            -"Oui, me voilà !"
        }
        reporter {
            -"Ouf, c’est bon."
            -"Il n’a pas insisté bien longtemps."
            -"Surtout qu’un serveur est intervenu pour m’aider."
        }
        redirect(Chapter10.reporterCode)
    }

    fragment(Chapter10.playerGuy) {
        reporter {
            -"C’est juste un mec lourd, et passablement éméché."
            -"Il insiste pour m’offrir un verre."
        }
        choose {
            "Accepte ! Faut savoir te faire plaisir."{
                reporter {
                    -"Euh ${player.name}…"
                    -"T’es pas sérieux là ?!"
                    -"Je vais me débarrasser de lui, laisse-moi 5 minutes."
                }
            }
            -"Dis-lui que tu attends ton mec."
            -"Sois directe : envoie-le bouler."
        }
        redirect(Chapter10.roland)
    }

    scene(Chapter10.reporterQuietBar, SmsRunningContext) {
        characters(reporter)

        reporter {
            -"${player.name} !"
            -"Me revoilà."
            -"Je suis dans un bar tamisé, dans une rue quasiment déserte."
            -"C’est flippant, surtout que le patron n’a pas l’air hyper sympa…"
            -"Il y a très peu de clients : un pilier de bar déjà bien éméché."
            -"Deux mecs qui parlent doucement tout en jetant des regards paranos tout autour d’eux."
            -"Et un couple qui préfère se faire discret pour… hum."
            -"Je ne vais pas te décrire ce qu’ils font, mais c’est à la limite de l’indécence."
            -"Bref !"
            -"Je me suis trouvé une alcôve isolée, avec vue sur l’entrée."
        }
        choose {
            -"Tant mieux !"
            -"Et ces fichiers cryptés ?"
        }
        reporter {
            -"N’oublions pas notre enquête."
            -"Les fichiers cryptés de la tablette de ${elea.name} !"
            -"J’y viens."
            -"D’après le cryptologue, le système de codage utilisé est finalement assez simple."
            -"Je…"
            -"Oh non…"
            -"…"
        }
        if (stateMachine.game.lyingToCoban) {
            choose {
                -"Un problème ?"
                -"Les hommes de ${coban.name}, déjà ?"
            }
            if (reporter.gender == Gender.WOMAN) {
                include(Chapter10.playerGuy)
            } else {
                include(Chapter10.drunkGuy)
            }
        } else if (stateMachine.game.fuckCoban2) {
            redirect(Chapter10.failure)
        } else {
            redirect(Chapter10.failure)
        }
    }

    scene(Chapter10.reporterBusyBar, SmsRunningContext) {
        characters(reporter)

        reporter {
            -"Ouf, j’ai trouvé une table."
            -"Le chauffeur avait raison ; c’est un bar TRÈS populaire."
            -"Du bruit, du monde, de l’agitation…"
            -"Parfait non ?"
            -"Je me suis trouvé une table, au milieu d’étudiants fêtards."
            -"Je dénote un peu dans cette ambiance de fête, à être ${
                reporter.withGender("tout seul", "toute seule")
            } sur mon téléphone."
            -"Mais je pense au moins être en sécurité."
        }
        choose {
            -"Tant mieux !"
            -"Et ces fichiers cryptés ?"
        }
        reporter {
            -"Bon, n’oublions pas la raison de ma présence ici."
            -"Que nous racontent ces fichiers cryptés trouvés sur la tablette de ${elea.name}…"
            -"J’y viens."
            -"d’après le cryptologue, le système de codage utilisé est finalement assez simple."
            -"Je…"
            -"Oh non…"
            -"…"
        }
        if (stateMachine.game.lyingToCoban) {
            choose {
                "Un problème ?"{
                    reporter {
                        -"On peut dire ça."
                        if (reporter.gender == Gender.WOMAN) {
                            -"Le problème qu’ont presque toutes les filles qui se baladent seules dans la rue."
                            -"Ou qui prennent un verre dans un bar sans être accompagnées."
                        }
                    }
                }
                "Les hommes de ${coban.name}, déjà ?"{
                    reporter {
                        -"Non, il ne s’agit pas des hommes de ${coban.name}, heureusement."
                    }
                }
            }
            if (reporter.gender == Gender.WOMAN) {
                include(Chapter10.playerGuy)
            } else {
                include(Chapter10.drunkGuy)
            }
        } else if (stateMachine.game.fuckCoban2) {
            redirect(Chapter10.failure, GoToScene.Option.Timeout(10_000))
        } else {
            redirect(Chapter10.failure, GoToScene.Option.Timeout(10_000))
        }
    }

    fragment(Chapter10.idiot) {
        reporter {
            -"…"
            -"Eh ben !"
            -"T’es à côté de la plaque toi !"
            -"${player.name}, enfin, c’est évident !"
            -"La clé du code est un mot ou une phrase !"
        }
    }



    scene(Chapter10.roland, SmsContext) {
        characters(roland)
        roland {
            -"${player.name} !"
            -"Ça roule ?"
        }
        choose {
            "Ça va et toi ?"{
                roland {
                    -"Ben oui !"
                    -"Je profite de mon week-end."
                    -"J’en avais marre de me prendre des vents sur CupidLov."
                    -"Alors je me suis dit que j’allais prendre des nouvelles de ${
                        player.withGender("mon petit", "ma petite")
                    } ${player.name} !"
                }
                choose {
                    "Écoute, rien à raconter mon vieux."{
                        choose {
                            -"Je glandouille tranquille sur mon canapé"
                        }
                        choose {
                            -"Tout en résolvant à la cool un complot mondial"
                        }
                        choose {
                            -"La situation est critique, mais je vais m’en sortir."
                        }
                        choose {
                            -"Voilà, voilà…"
                        }
                    }
                    "C’est trop ouf ce qui m’arrive !"{
                        choose {
                            -"Je suis en train d’aider ${
                                reporter.withGender("un", "une")
                            } journaliste poursuivie par de méchants hommes de main."
                        }
                        choose {
                            -"Ils veulent s’emparer de plans cryptés pour assouvir leur soif de pouvoir."
                        }
                        choose {
                            -"Et moi, je vais les empêcher de nuire !"
                        }
                    }
                }

            }
            "Je n’ai pas le temps de discuter, ${roland.name} !"{
                choose {
                    -"Je suis en plein polar !"
                }
                choose {
                    -"Et actuellement, la situation est critique."
                }
                choose {
                    -"Les méchants sont en passe de s’emparer de plans cryptés protégés par ${
                        reporter.withGender("un", "une")
                    } journaliste en détresse !"
                }
            }
        }
        roland {
            -"Ouais, ok, t’es en train de jouer aux jeux vidéos."
            -"J’ai compris, je te fous la paix."
            -"Tu me diras quand même de quel jeu tu parles, il a l’air cool."
            -"Ciao !"
        }
        redirect(Chapter10.reporterSafeBar)
    }
    scene(Chapter10.reporterCode, SmsRunningContext) {
        characters(reporter)
        reporter {
            -"Bon, revenons-en à notre histoire…"
            -"Je te disais que le système de codage utilisé par ${elea.name} est finalement assez simple."
            -"Est-ce que tu connais le chiffre de Vigénère ?"
        }
        choose {
            "Oui, bien sûr !"{
                reporter {
                    -"Ah oui ?"
                    -"Décidément, tu m’étonnes !"
                    -"Si j’avais su que tu étais si ${
                        player.withGender("doué", "douée")
                    }, j’aurais fait appel à tes services plus tôt."
                    -"Tu as le profil parfait d’${player.withGender("un enquêteur", "une enquêtrice")} !"
                    -"Bref !"
                    -"Le document de ${elea.name} que nous avons pu ouvrir grâce au code que tu avais…"
                    -"Est crypté selon le chiffre de Vigénère."
                }
            }

            "Non, pas du tout…"{
                reporter {
                    -"Ça ne me disait rien non plus."
                    -"d’après mon ami, il s’agit d’un système assez simple en fait."
                    -"Pour déchiffrer un texte codé comme le notre, il faut une clé."
                    -"Cette clé est généralement un mot, ou une phrase."
                    -"Plus la clé est longue et variée, et mieux le texte est chiffré."
                }
                choose {
                    "OK, je comprends, passe à la suite"{
                        reporter {
                            -"On va voir si tu suis :"
                        }
                    }
                    "Je ne comprends rien."{
                        reporter {
                            -"Un exemple vaut mieux qu’un long discours."
                            -"Si je veux coder la phrase « Bonjour monsieur. »"
                            -"Je choisis une clé"
                            -"Disons que cette clé est le mot « sucre »."
                            -"On remplace les lettres de « Bonjour monsieur » par les lettres de la clé."
                            -"Ici, c’est le mot sucre."
                            -"« Bonjour monsieur. » devient :"
                            -"« Sucresu cresucre. »"
                            -"Tu vois ? c’est la clé qui se répète, sur la totalité du texte codé."
                        }
                        choose {
                            -"Ok, je comprends"
                            -"Euh… Hum… c’est pas simple."
                        }
                        reporter {
                            -"Ensuite, tu te reportes à une « table de Vigenère »."
                            -"C’est un tableau de correspondance des lettres, que tu trouveras facilement sur le net."
                            -"En voilà un, si tu veux :"
                            photo("img_vig.png", "Table de Vigénère")
                            -"On remplace les lettres de la clé par la lettre correspondante dans le tableau."
                            -"Si tu te réfères à celui que j’ai envoyé,"
                            -"Notre phrase « Bonjour monsieur », codée en « Sucresu cresucre. » devient :"
                            -"« Tipasml ofrkcglv. »"
                        }
                        choose {
                            -"Oui, c’est facile à comprendre."
                            -"Euh…"
                        }
                        reporter {
                            -"En code Vigénère, « Bonjour monsieur. » se code donc en « Tipasml ofrkcglv. »"
                            -"Je sais, ça paraît compliqué, mais on s’y fait… 😛"
                        }
                    }
                }
            }

        }
        reporter {
            -"La clé permettant de décrypter le code est donc…"
        }
        choose {
            "Un chiffre !"{
                include(Chapter10.idiot)
            }
            "Un son"{
                include(Chapter10.idiot)
            }
            "Un code couleur !"{
                include(Chapter10.idiot)
            }
            "Un mot ou une phrase !"{
                reporter {
                    -"🙂"
                    -"Bingo !"
                }
            }

        }
        reporter {
            -"Tout le texte trouvé dans la tablette de ${elea.name} est codé selon ce principe."
            -"Et Martin le cryptologue ne m’a pas dépanné que sur la compréhension de ce code."
            -"En fouinant un peu sur la tablette, il a trouvé une interface liée au document."
            -"Il nous suffit d’y entrer la bonne clé pour tout décoder !"
        }
        choose {
            -"Mais c’est super !"
            -"Tu aurais pu me dire ça tout de suite !"
        }
        reporter {
            -"Je voulais que tu en saches autant que moi."
            -"On y est presque : on a le document, le principe du code, et l’interface permettant de le déchiffrer."
            -"Il nous manque une seule chose : la clé."
            -"Le mot unique qui nous permettra de tout déchiffrer."
        }
        choose {
            -"On a droit à 3 essais ?"
            -"Tu as une idée de ce que peut être la clé ?"
        }
        reporter {
            -"Sois ${reporter.withGender("rassuré", "rassurée")} : nous pouvons essayer autant de fois que nous le voulons ; cette interface ne semble pas protégée par un système « d’autodestruction »."
            -"Quant à ce que pourrait être la clé…"
            -"J’ai déjà essayé plein de mots au hasard…"
            -"Sans succès !"
            -"Je comptais sur toi pour ça; tu assures quand il faut trouver les mots de passe. 😉"
            -"Tu ne saurais pas comment trouver cette clé ?"
        }
        choose {
            "Non, aucune idée…"{
                reporter {
                    -"Dommage."
                    -"${player.name}…"
                    -"Il n’y a que toi qui peux m’aider."
                    -"Je t’en prie tente ta chance."
                }
            }
            "Oui, peut-être bien !"{
                reporter {
                    -"Super ! 🙂"
                    -"J’étais ${reporter.withGender("sûr", "sûre")} que je pouvais compter sur toi !"
                }
            }

        }
        reporter {
            -"Je dois pouvoir partager avec toi l’interface du code."
            -"Attends un peu…"
            -"J’ai besoin de quelques minutes pour t’envoyer ça."
            -"Je te préviens quand c’est bon."
            -"A tout à l’heure ${player.name} !"
        }
        appBadge(Apps.messenger)
        redirect(Chapter10.cobanCode)
    }

    scene(Chapter10.cobanCode, MessengerCobanRunContext) {
        characters(coban)
        coban {
            -"Tu joues avec mes nerfs, ${player.name}."
            -"Je vais te laisser une dernière chance."
            -"Où est ${reporter.name} ?"
        }
        choose {
            "Tu peux toujours courir, mon pote." {
                coban {
                    -"OK, tu le prends comme ça."
                    -"J’espère que tu sais ce que tu fais."
                    -"Tu es dans ma ligne de mire, ${player.name}."
                    -"Attends-toi au pire."
                }
                choose {
                    -"Tu ne me fais pas peur, ${coban.name}."
                    silent("Le bloquer.") {
                        coban {
                            block()
                        }
                    }
                }
                appBadge(Apps.sms)
                redirect(Chapter10.introEncrypted)
            }
            "Très bien, je vais tout te dire." {
                choose {
                    silent("Envoyer la position de ${reporter.name}") {
                        player {
                            photo("img_map_republique.jpeg", "Carte république")
                        }
                        coban {
                            -"Merci, ${player.name}."
                            -"Mes hommes sont juste à côté de sa position."
                            -"On va l’avoir !"
                        }
                        redirect(Chapter10.end10)
                    }
                    "🖕"{
                        coban {
                            -"OK, tu le prends comme ça."
                            -"J’espère que tu sais ce que tu fais."
                            -"Tu es dans ma ligne de mire, ${player.name}."
                            -"Attends-toi au pire."
                        }
                        choose {
                            -"Tu ne me fais pas peur, ${coban.name}."
                            silent("Le bloquer.") {
                                coban {
                                    block()
                                }
                            }
                        }
                        appBadge(Apps.sms)
                        redirect(Chapter10.introEncrypted)
                    }
                }
            }
        }
    }

    fun checkCode(code: String): Boolean {
        return code.lowercase().split(" ").any { it in listOf("jangada", "prestige") }
    }

    fragment(Chapter10.threeErrors) {
        reporter {
            -"${player.name}, je ne veux pas être pessimiste."
            -"Mais tu as déjà échoué 3 fois…"
            -"Tu es sûr que lors de tes échanges avec ${elea.name}, aucun indice n’a pu filtrer ?"
            -"Ça pourrait être n’importe quoi…"
            -"Un lieu qu’${elea.withGender("il", "elle")} aime, ou peut-être un film, ou un livre, le titre d’une chanson ?"
            -"Peut-être même une personne qu’${elea.withGender("il", "elle")} admire ?"
            -"Ça te dit quelque chose ?"
        }
        choose {
            -"Non, ça ne me dit rien…"
            -"Son film ou son livre préféré ? Je dois pouvoir retrouver ça quelque part…"
        }
        reporter {
            -"Je te laisse essayer à nouveau, mais dépêche-toi je t’en prie !"
            -"Les hommes de ${coban.name} vont finir par me retrouver."
        }
    }

    fragment(Chapter10.fiveErrors) {
        reporter {
            -"Écoute ${player.name}…"
            -"Rendons-nous à l’évidence :"
            -"Tu n’as aucune idée de ce qu’est cette clé…"
            -"Sans ça, nous n’arriverons jamais à retrouver ${elea.name}"
            -"Pfff, je suis ${reporter.withGender("désespéré", "désespérée")}…"

        }
    }

    fun SceneBuilder.startGame(tryCount: Int) {
        narrator {
            -"Entrer le code"
        }
        answer { code ->
            if (checkCode(code)) {
                reporter {
                    -"Oui !!!"
                    -"Bien joué ${player.name} !"
                    -"T’es ${player.withGender("le meilleur", "la meilleure")} !!!"
                    -"ok, ok, ok…"
                    -"Les infos débloquées semblent intéressantes."
                    -"Laisse-moi quelques minutes pour étudier tout ça calmement."
                }
                redirect(Chapter10.cobanMad, GoToScene.Option.Timeout(5_000))
            } else {
                if (tryCount == 3) {
                    include(Chapter10.threeErrors)
                } else if (tryCount == 5) {
                    include(Chapter10.fiveErrors)
                }
                if (tryCount < 5) {
                    startGame(tryCount + 1)
                } else {
                    redirect(Chapter10.failure, GoToScene.Option.Timeout(5_000))
                }
            }
        }
    }

    scene(Chapter10.introEncrypted, SmsRunningContext) {
        reporter {
            -"${player.name}."
            -"${reporter.withGender("Désolé", "Désolée")}, j’ai été un peu ${reporter.withGender("long", "longue")}."
            -"La connexion est mauvaise ici."
        }
        choose {
            -"T’en fais pas, j’en ai profité pour discuter un peu avec ${coban.name}…"
        }
        reporter {
            -"Ah…"
            -"L’étau se resserre."
            -"On ferait mieux de se dépêcher."
        }
        download("vig.app")
        reporter {
            -"Voilà ça devrait être bon !"
            -"Tu dois pouvoir accéder à l’interface servant à décrypter les fichiers."
        }
        choose {
            -"C’est bien reçu !"
            -"Je dois cliquer ? J'y connais rien à tous ces trucs moi."
        }

        redirect(Chapter10.findCode)
    }

    scene(Chapter10.findCode, EncryptedBluePrintContext()) {
        reporter {
            -"Comme tu peux le voir, c’est très simple :"
            -"il y a juste un champ où entrer un mot."
            -"J’ai déjà essayé tout ce qui de mon point de vue pouvait être lié à ${elea.name} :"
            -"Tesla, électricité, moteur, éther, ondes, énergie, etc."
            -"Sans succès."
            -"A toi de jouer à présent !"
        }
        startGame(1)
    }

    scene(Chapter10.cobanMad, MessengerCobanRunContext) {
        characters(coban)
        coban {
            -"${player.name.uppercase()} !"
            -"J’ai la preuve que tu me mens !"
        }
        choose {
            "Mais non, pourquoi tu dis ça ?"{
                coban {
                    -"Tiens, regarde ça."
                    photo("img_map_republique.jpeg", "Carte république")
                    -"On a enfin réussi à localiser ${reporter.name}"
                    -"On cerne le quartier."
                    -"Et ${reporter.withGender("il", "elle")} n’est dans le bar dont tu m’as parlé."
                    -"${reporter.withGender("Il", "Elle")} ne peut plus nous échapper."
                    -"Je ne peux plus te faire confiance, je le sais maintenant."
                }
            }
            "Tu ne croyais quand même pas que j’allais t’aider ?"{
                coban {
                    -"Je savais que je n’aurais jamais dû te faire confiance."
                    -"J’ai été trop gentil, mais là ça va changer."
                    -"Tiens regarde :"
                    photo("img_map_republique.jpeg", "Carte république")
                    -"On a enfin réussi à localiser ${reporter.name}"
                    -"${reporter.withGender("Il", "Elle")} ne peut plus nous échapper."
                }
            }
        }

        coban {
            -"Tu sais ce qu’il va se passer maintenant ?"
        }

        choose {
            -"Tu vas perdre la partie."
            -"Tu vas bien gentiment aller te faire f*****."
        }

        coban {
            -"Hahaha, tu me fais rire."
            -"Et tu m’énerves."
            -"Monumentale erreur."
            -"Une fois qu’on se sera occupé de tes ${elea.withGender("petits copains", "petites copines")}…"
            -"Ce sera ton tour."
            -"Et crois-moi, tu vas me payer cher ton attitude déplacée."
            -"Surveille bien tes arrières, ${player.name}."
        }
        appBadge(Apps.sms)
        redirect(Chapter10.revelations)
    }

    scene(Chapter10.failure, SmsRunningContext) {
        characters(reporter)
        reporter {
            -"Oh non !"
            -"Non, non non !"
            -"${coban.name} et ses hommes !"
            -"${player.name}, ils sont là !"
            -"Ils m’ont retrouvé !"
        }
        choose {
            -"Fuis ! Dépêche-toi !"
            -"Trouve une arme, défends-toi !"
        }
        reporter {
            -"Il n’y a rien à faire…"
            -"Ils m’ont eu."
            -"Ils sont armés, je suis ${reporter.withGender("coincé", "coincée")}."
            -"Ils ont dû me suivre depuis l’hôtel."
            -"Je…."
            -"NON !!!!"
        }

        narrator {
            -"${reporter.name} s’est ${reporter.withGender("déconnecté", "déconnectée")}"
            -"[CONNEXION PERDUE]"
        }

        redirect(Chapter10.end10, GoToScene.Option.Timeout(10_000))
    }

    scene(Chapter10.revelations, SmsRunningContext) {
        characters(reporter)
        reporter {
            -"${player.name} ?"
            -"Tu es là ?"
        }
        choose {
            -"Oui, je me suis occupé de ${coban.name}."
        }
        reporter {
            -"J’espère qu’il ne nous retrouvera pas…"
            -"En attendant, on dirait qu’on a touché le gros lot."
            -"Ce sont les plans détaillés d’une machine à énergie libre."
            -"Wouah, le père de ${elea.name} est donc arrivé à mettre ça	au point."
        }
        choose {
            -"C’était donc vrai…"
            -"Un indice concernant ${elea.name} ?"
        }
        reporter {
            -"Il y a aussi une lettre, écrite peu de temps avant sa mort."
            -"Il y parle de… Mon dieu…"
        }
        choose {
            -"Il y parle de quoi ?"
            -"Qu’est-ce qui se passe ?"
        }
        reporter {
            -"Il… met en cause la société qui emploie ${coban.name}."
            -"Il affirme qu’on a tenté de l’assassiner à plusieurs reprises, après l’avoir menacé."
            -"Il nomme des gens, des lieux, il a même des preuves !"
            -"Des lettres, des photos, des enregistrements audios…"
        }
        choose {
            -"Il aurait été assassiné ?"
            -"Il faut donner tout ça à la police !"
        }
        reporter {
            -"Avec ça, on tient une bombe…"
            -"Une révolution énergétique, déjà."
            -"Ainsi que la preuve que des gens sont prêts à tuer pour obtenir cette technologie."
        }
        choose {
            -"Il faut que ${coban.name} paie pour ses crimes !"
            -"Tu ne peux pas garder tout ça pour toi !"
        }
        reporter {
            -"Je ne peux pas donner ça à la police."
            -"C’est le meilleur moyen pour que cette affaire soit étouffée."
            -"Et ${elea.name} n’aurait pas voulu ça…"
        }
        choose {
            -"Et ${elea.name} dans tout ça ? Où est-${elea.withGender("il", "elle")} ?"
        }
        reporter {
            -"Toujours aucune trace d’${elea.withGender("lui", "elle")}…"
            -"Je suis donc ${reporter.withGender("le seul", "la seule")} en possession de ces documents."
            -"…"
            -"C’est trop important, je me sens complètement ${reporter.withGender("dépassé", "dépassée")}"
            -"${player.name}, je sais que je peux compter sur toi."
            -"En attendant de trouver la meilleure solution pour exploiter ces informations,"
            -"Je vais t’envoyer ces documents."
            -"Je te les transmets dans un fichier zippé."
        }
        download("archive.zip")
        reporter {
            -"Garde précieusement ce fichier."
            -"Fais-en des copies, conserve-le sur différents supports."
            -"S’il m’arrive quelque chose, et que tu n’as pas de mes nouvelles dans quelques jours…"
            -"Je compte sur toi pour agir !"
            -"En attendant, il vaut mieux que je quitte Paris."
        }
        choose {
            -"Qu’est-ce que tu vas faire ?"
            -"C’est quoi la suite pour nous ?"
        }
        reporter {
            -"Je vais faire jouer mes réseaux."
            -"Je suis journaliste, ne l’oublie pas. 🙂"
            -"Je vais contacter des lanceurs d’alerte et des collègues de la presse nationale."
            -"Ils sauront quoi faire, on peut avoir confiance en eux."
            -"Et pour ${elea.name}…"
            -"Je ne sais pas…"
            -"J’espérais tant que ces documents nous apprennent ce qu’${
                elea.withGender("il est devenu", "elle est devenue")
            }…"
        }
        choose {
            "Je suis sûr qu’${elea.withGender("il", "elle")} va bien."{
                reporter {
                    -"J’aimerais pouvoir partager ton optimisme. 🙂"
                    -"Tu es quelqu’un de bien, ${player.name}."
                    -"Sans toi, qui sait ce que je serais ${elea.withGender("devenu", "devenue")} ?"
                    -"Mais je vais m’inspirer de ton attitude :"
                    -"Ne perdons pas espoir de ${elea.withGender("le", "la")} retrouver !"
                }
            }
            "Et si ${coban.name} l’avait eu ?"{
                reporter {
                    -"Ne parle pas de malheur…"
                    -"Si jamais ${elea.withGender("il est tombé", "elle est tombée")} entre leurs mains…"
                    -"Nous ne ${elea.withGender("le", "la")} retrouverons jamais…"
                    -"Mais tant que nous ne le saurons pas, nous devons enquêter !"
                    -"Je ne m’arrêterai pas avant de l’avoir ${elea.withGender("retrouvé", "retrouvée")} !"
                }
            }
        }
        reporter {
            -"Attends, je…"
            -"Oh non !"
            -"Ils sont là !"
        }
        choose {

            "Qui ça ?"{
                reporter {
                    -"A ton avis ?"
                    -"Le pape et ma soeur !"
                }
            }
            -"Les hommes de ${coban.name} ?"
        }
        reporter {
            -"Bien sûr, que ce sont eux !"
            -"Ils sont trois."
            -"Je ne sais pas si ${coban.name} est avec eux"
            -"OK, ils ne m’ont pas encore vu…"
        }
        choose {
            -"Tu dois fuir !"
            -"Fais attention !"
        }
        reporter {
            -"Je vais détruire la tablette de ${elea.name}, elle ne doit pas tomber entre leurs mains."
        }
        choose {
            -"Ne fais pas ça, ce sont nos seules preuves !"
            -"Oui, tu as raison, ne prends pas de risque."
        }
        reporter {
            -"On n’a pas le choix, c’est la seule solution."
            -"Ça veut dire que tu seras la seule personne à avoir ces plans."
            -"Tout dépend de toi, maintenant."
            -"Je te fais confiance…"
            -"Je dois essayer de leur échapper."
            -"Je te recontacte au plus vite."
        }
        choose {
            -"Prends soin de toi ${reporter.name}"
            -"Salut !"
        }
        reporter {
            -"Au revoir ${player.name}…"
            -"Merci pour tout…"
        }
        narrator {
            -"${reporter.name} s’est ${reporter.withGender("déconnecté", "déconnectée")}"
        }
        redirect(Chapter11.intro)
    }
    scene(Chapter10.end10, EndContext("Fin 10")) {
        stateMachine.achievements.end10 = true
        narrator {
            -"FIN 10 :"
            -"${reporter.name} a manqué de discernement lors de sa fuite."
            -"Vous ne saurez jamais ce qui lui est arrivé…"
            -"Mais on peut imaginer que ça n’est pas réjouissant."
            -"${coban.name} ne vous recontactera pas non plus, sûrement parce qu’il n'a plus besoin de vous."
            -"Bravo, vous avez été un bon outil, qui s'est bien laissé utiliser."
            -"Auriez-vous pu aider ${reporter.name} à lui échapper ?"
            -"Peut-être…"
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 10."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }
}


package xyz.lacunae.itsadate

import xyz.lacunae.story.Story
import xyz.lacunae.story.scene
import xyz.lacunae.story.AnswerType
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.waiter

object Chapter8 {
    private const val name = "chapter-8"
    const val intro = "$name/intro"
    const val reporter = "$name/reporter"
}

internal fun Story.chapter8() {
    scene(Chapter8.intro, IntroContext("Chapitre 8")) {
        narrator {
            -"On peut dire que ce rendez-vous s’est bien passé."
            -"Vous avez une piste ; dans quelques jours, vous retrouverez peut-être la trace de ${elea.name}."
            -"D’ici là, vous avez enfin droit à un vrai moment de détente, en compagnie de ${reporter.name}."
            -"L’occasion d’en apprendre un peu plus sur ${reporter.withGender("lui", "elle")}."
            -"En savoir plus sur ${reporter.name} vous sera peut-être utile à l’avenir…"
            -"Vous pouvez aussi choisir de voir les choses autrement, et décider de juste vous détendre et lui faire confiance ;"
            -"c’est l’occasion de passer un bon moment en compagnie d’une personne que vous commencez à apprécier."
        }
        redirect(Chapter8.reporter)
    }

    scene(Chapter8.reporter, BarContext) {
        characters(reporter)
        reporter {
            -"Asseyons-nous à cette table, vers la fenêtre."
        }
        waiter {
            -"…"
            -"Bonjour. Vous avez choisi ?"
            -"…"
        }
        reporter {
            -"Oui, un gin tonic pour moi."
        }
        choose {
            "Pour moi aussi !"{
                reporter {
                    -"Après toutes ces émotions, on a bien mérité un cocktail un peu corsé !"
                }
                choose {
                    -"Cocktail mérité, mais avec modération."
                }
                reporter {

                    -"Oui, bien sûr !"
                }
            }
            "Euh, je vais me contenter d’un café allongé."{
                reporter {
                    -"Oui, je sais, il est un peu tôt pour un cocktail."
                }
            }
        }
        reporter {
            -"Mais c’est une occasion spéciale : on a enfin une piste tangible pour retrouver ${elea.name}."
            -"Tu ne peux pas savoir comme je suis ${reporter.withGender("heureux", "heureuse")}."
        }
        choose {
            -"On dirait que tu tiens bien à ${elea.withGender("lui", "elle")}."
        }
        reporter {
            -"Plus que je ne le pensais, oui."
            -"Les années ont passées, et je me rends compte que ${elea.name} n’a jamais cessé de compter à mes yeux…"
            -"Je commence même à penser que ma vocation de journaliste est peut-être née de cette amitié frustrée."
        }
        choose {
            "Comment ça ?"{
                reporter {
                    -"Eh bien, mon métier, c’est enquêter."
                    -"Trouver une histoire, chercher, me documenter, et la partager au plus grand nombre."
                    -"${elea.name} était mon ${
                        elea.withGender("ami", "amie")
                    }, et ${elea.withGender("il", "elle")} a disparu si soudainement…"
                    -"C’était terrible à supporter pour l’enfant que j’étais."
                    -"Et encore plus dur à vivre pour ${elea.withGender("lui", "elle")}, j’imagine."
                    -"Je me souviens que je me désespérais de ${elea.withGender("le", "la")} revoir."
                    -"J’en rêvais la nuit, je n’ai pensé qu’à ça pendant des mois."
                    -"Je m’imaginais en train de ${
                        elea.withGender("le", "la")
                    } chercher, comme si j’étais ${
                        elea.withGender("un", "une")
                    } détective ${elea.withGender("privé", "privée")}."
                }
                choose {
                    -"Mais tu es journaliste, pas détective."
                }
                reporter {
                    -"Oui, je sais."
                    -"Le métier est certes un peu différent, mais en grandissant, on affine nos choix, nos envies."
                    -"Et si je n’étais pas ${
                        reporter.withGender("devenu", "devenue")
                    } journaliste, ${elea.name} ne m’aurait peut-être jamais recontacté."
                }
                choose {
                    -"Oui, je comprends. C’est peut-être vrai."
                    "Pourquoi ça ?"{
                        reporter {
                            -"C’est simple à comprendre."
                            -"${elea.name} a eu, pour des raisons que l’on ignore encore, des problèmes avec les employeurs de ${coban.name}."
                            -"Et quand ${
                                elea.withGender("il", "elle")
                            } a eu besoin d’un coup de main, c’est vers moi qu’${
                                elea.withGender("il", "elle")
                            } s’est ${elea.withGender("tourné", "tournée")}."
                            -"Parce qu’entre notre amitié passée, et mon métier, je pouvais l’aider."
                            -"Malheureusement, je suis ${
                                reporter.withGender("arrivé", "arrivée")
                            } trop tard, et c’est pour ça que nous en sommes là aujourd’hui…"
                        }
                        choose {
                            -"Ça nous a permis de nous rencontrer, c’est une bonne chose."
                            -"Tu n’as rien à te reprocher."
                        }
                        reporter {
                            -"Merci ${player.name}, j’apprécie."
                            -"Faire ta connaissance est le seul rayon de soleil de cette terrible affaire."
                            -"Voilà que je redeviens sombre, alors que la journée nous a permis d’avancer sur la piste de ${elea.name}"
                            -"Changeons de sujet !"
                        }
                    }
                }
            }
        }
        reporter {
            -"Je ne veux pas monopoliser la conversation."
            -"Parlons un peu de toi."
        }
        choose {
            -"Qu’est-ce que tu aimerais savoir ?"
        }
        reporter {
            -"Tu as quel âge ?"
        }
        answer(type = AnswerType.NUMBER) { ageString ->
            val age = ageString.toIntOrNull()
            when {
                age == null -> {
                    reporter {
                        -"$ageString, sérieux ?"
                    }
                }
                age > 50 -> {
                    reporter {
                        -"Haha, impossible."
                        -"Ou alors, il faut que tu me donnes des conseils bien-être, tu ne fais pas du tout cet âge."
                    }
                }
                else -> {
                    reporter {
                        -"Ah, c’est marrant, tu ne les fais pas du tout."
                        -"Et c’est un compliment !"
                        -"Tu as des frères ?"
                    }
                    answer { brotherString ->
                        val brother = brotherString.toIntOrNull() ?: 0
                        stateMachine.game.brother = brother
                        reporter {
                            -"ou des sœurs ?"
                        }
                        answer { sisterString ->
                            val sister = sisterString.toIntOrNull() ?: 0
                            stateMachine.game.sister = sister
                            val siblings = brother + sister
                            when {
                                siblings > 5 -> {
                                    reporter {
                                        -(listOfNotNull(
                                            if (brother == 0) null else if (brother == 1) "$brother frère" else "$brother frères",
                                            if (sister == 0) null else if (sister == 1) "$sister sœur" else "$sister sœurs"
                                        ).joinToString() + " ???")
                                        -"Ah oui, ok, c’était la fête à la maison chez vous !"
                                        -"Ça ne devait pas être simple à gérer pour tes parents."
                                        -"Sacré contraste avec moi qui suis ${
                                            reporter.withGender("fils", "fille")
                                        } unique."
                                        -"D’où ma relation particulière avec ${elea.name}, qui était durant mon enfance ${
                                            elea.withGender("le frère", "la sœur")
                                        } que j’ai toujours rêvé d’avoir."
                                    }
                                }
                                siblings == 0 -> {
                                    reporter {
                                        -"Moi je suis ${reporter.withGender("fils", "fille")} unique."
                                        -"D’où ma relation particulière avec ${elea.name}, qui était durant mon enfance ${
                                            elea.withGender("le frère", "la sœur")
                                        } que j’ai toujours rêvé d’avoir."
                                    }
                                }
                                else -> {
                                    reporter {
                                        -(listOfNotNull(
                                            if (brother == 0) null else "$brother frères",
                                            if (sister == 0) null else "$sister sœurs"
                                        ).joinToString() + " ???")
                                        -"C’est super ça."
                                        -"J’espère que vous avez de bons liens; il n’y a rien de plus important que la famille dans la vie."
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        narrator {
            -"…."
            -"Le temps passe."
            -"Les consommations s’enchaînent, dans la joie et la bonne humeur."
            -"Qui aurait cru que vous vous entendriez si bien avec ${reporter.name} ?"
            -"Sûrement pas ${coban.name}."
            -"Il vous ment, c’est sûr :"
            -"Comment ${elea.name} et ${reporter.name} pourraient-${
                elea.withGender("ils", "elles")
            } être les ${elea.withGender("dangereux criminels", "dangereuses criminelles")} qu’il vous a ${
                elea.withGender("décrits", "décrites")
            } ? ?"
            -"L’heure file, et ${reporter.name} va bientôt devoir partir."
            -"C’est le moment où jamais de savoir la vérité."
        }
        choose {
            -"${reporter.name}… J’ai une question délicate à te poser."
        }
        reporter {
            -"Vas-y, je t’écoute."
        }
        choose {
            -"Est-ce que ${elea.name} est ${elea.withGender("un criminel condamné", "une criminelle condamnée")} ?"
        }
        choose {
            -"${coban.name} m’a montré des documents qui laissent à penser que oui."
        }
        reporter {
            -"Hum…"
            -"Pfiou…"
            -"Bon, tu es en droit de savoir la vérité."
            -"Je te prie de ne pas m’interrompre, je vais te dire tout ce que je sais."
        }
        choose {
            silent("Ne rien dire.")
            "Ok, je t’écoute." {
                reporter {
                    -"Euh…"
                    -"Ok, je ne vais pas compter ça comme une interruption."
                }
            }
            "J’aimerais bien avoir la possibilité de t’interrompre quand je le souhaite."{
                reporter {
                    -"Euh…"
                    -"Ok, je ne vais pas compter ça comme une interruption."
                }
            }
        }
        reporter {
            -"${elea.name} a effectivement un casier judiciaire contenant quelques condamnations pénales ."
            -"Rien de bien grave, je te passe les détails."
            -"Mais un truc me chiffonne…"
            -"${elea.withGender("le", "la")} connaissant plutôt bien, c’est même surprenant."
        }
        choose {
            -"Qu’est-ce que tu veux dire par là ?"
        }
        reporter {
            -"Eh bien… ${elea.name} ne m’a jamais caché l’existence de ces infractions."
            -"${
                elea.withGender("Il", "Elle")
            } m’en a parlé dès notre reprise de contact, en m’affirmant que des personnes puissantes et haut placées en voulaient à sa vie."
            -"Que grâce à leurs contacts dans les plus hautes sphères juridiques et politiques, "
            -"ces personnes auraient créé de toutes pièces des éléments à charge contre ${
                elea.withGender("lui", "elle")
            }."
            -"Tout cela dans le but de lui nuire, et de ${elea.withGender("le", "la")} discréditer."
        }
        choose {
            -"C’est impossible. Je ne peux pas y croire."
            -"C’est hallucinant, mais ça expliquerait beaucoup de choses…"
        }
        reporter {
            -"J’ai eu la même réaction que toi."
            -"Dans un premier temps, j’ai eu du mal à y croire."
            -"Qui serait assez haut placé pour ainsi créer un faux casier judiciaire de toute pièce ?"
            -"Et dans quel but ?"
        }
        choose {
            -"Les documents de ${elea.name} seraient si importants que ça ?"
            -"${coban.name} aurait autant de pouvoir que ça ?"
        }
        reporter {
            -"Peut-être bien…"
            -"Regarde la situation dans laquelle nous nous trouvons actuellement."
            -"C’est quand même dingue non ?"
            -"Cette disparition, ces hommes à nos trousses, qui veulent mettre la main sur ces documents…"
            -"Sans vouloir tomber dans la parano, on se croirait dans un film ou un jeu vidéo !"
        }
        choose {
            -"C’est exactement ce que je ressens !"
        }
        reporter {
            -"Alors pour répondre à ta question :"
            -"Non, ${elea.name} n’est pas ${elea.withGender("un criminel", "une criminelle")}."
            -"${elea.withGender("Il", "Elle")} est en danger."
            -"${elea.withGender("Poursuivi", "Poursuivie")} par des gens qui EUX sont dangereux."
            -"Tu sais ce qu’il nous reste à faire ?"
        }
        choose {
            -"Oui !"
            -"Non !"
        }
        reporter {
            -"Nous devons aller au bout de notre enquête !"
            -"Décrypter ces documents."
            -"Retrouver ${elea.name}."
            -"Empêcher ${coban.name} et ses sbires de nuire."
            -"Et pour ça, je dois aller à Paris, au plus vite."
            -"Merde, tu as vu l’heure ?!"
        }
        choose {
            -"Ton train est dans 10 minutes !"
            -"Tu es en retard !"
        }
        reporter {
            -"${reporter.withGender("Désolé", "Désolée")} de devoir partir si vite."
            -"C’était génial de te rencontrer, et de discuter un peu avec toi."
        }
        choose {
            -"C’est réciproque."
            -"Fonce, ton train !"
        }
        reporter {
            -"Je me dépêche ! A très vite ${player.name}."
            -"Et encore merci pour tout !"
        }

        narrator {
            -"${reporter.name} vous quitte précipitamment."
            -"Cette discussion vous laisse songeur…"
            -"Comment cette affaire va-t-elle se résoudre ?"
        }
        waiter {
            -"Hum, hum."
            -"Excusez-moi."
            -"J’ai terminé mon service."
            -"Voici la note."
            -"Ça fera 48,50 €."
            -"Vous réglez en espèces ou en CB ?"
        }
        choose {
            -"En espèces. Voilà 50 €, gardez la monnaie."
            -"En CB."
        }
        waiter {
            -"Service ! En vous remerciant."
            stateMachine.game.reporterDrink = true
        }
        redirect(Chapter9.intro)
    }
}
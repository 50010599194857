import csstype.*
import emotion.react.css
import kotlinx.browser.window
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.progress
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG
import react.dom.svg.ReactSVG.path
import react.useEffect

external interface UploadMessageProps : Props {
    var name: String
    var time: Int
    var completed: Boolean
    var onUploaded: () -> Unit
}

val UploadMessageComponent = FC<UploadMessageProps>("UploadMessageComponent") { props ->

    useEffect(Unit) {
        val timer = if (!props.completed) {
            window.setTimeout({
                props.onUploaded()
            }, props.time)
        } else {
            null
        }
        cleanup { timer?.let(window::clearTimeout) }
    }

    div {
        css {
            width = 60.pct
            margin = Margin(vertical = 10.px, horizontal = Auto.auto)
            padding = Padding(horizontal = 4.px, vertical = 4.px)
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            backgroundColor = Colors.surfaceContainer
            borderRadius = 20.px
        }

        ReactSVG.svg {
            css {
                color = Colors.onSurface
            }
            height = 64.0
            width = 64.0
            viewBox = "0 0 24 24"
            path {
                fill = "currentColor"
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
            }
        }
        span {
            css {
                margin = Margin(horizontal = 0.px, vertical = 4.px)
                color = Colors.onSurface
            }
            +props.name
        }
        progress {
            max = 100.0
            if (props.completed) {
                value = "100"
            }
        }
    }
}
import csstype.*
import emotion.react.css
import xyz.lacunae.story.StateMachine
import xyz.lacunae.story.clear
import xyz.lacunae.itsadate.GameStateMachine
import xyz.lacunae.itsadate.IntroContext
import kotlinx.browser.localStorage
import kotlinx.browser.window
import react.*
import react.dom.html.ReactHTML.div

val App = FC<Props>("App") { _ ->
    val stateMachine: StateMachine = LocalStorageStateMachine(localStorage)

    val (screen, setScreen) = useState<String?>(null)
    val (messageSpeed, setMessageSpeed) = useState(stateMachine.settings.messageSpeed)
    var media by useState("/audio/peaceful-morning-yeti-music-main-version-00-35-3025.mp3")
    var volume by useState(0.1)

    useEffect(messageSpeed) {
        stateMachine.settings.messageSpeed = messageSpeed
    }

    useEffect(Unit) {
        window.onpopstate = {
            setScreen(window.location.pathname)
        }
        setScreen(window.location.pathname)
    }

    fun redirectTo(url: String) {
        window.history.pushState(null, "", url)
        setScreen(url)
    }

    fun redirectToHome() = redirectTo("/")
    fun redirectToGame() = redirectTo("/play")
    fun redirectToAchievements() = redirectTo("/achievements")
    fun redirectToNewsletter() = redirectTo("/newsletter")
    fun redirectToShare() = redirectTo("/share")

    div {
        css {
            position = Position.relative
        }
        when (screen) {
            "/play" -> Game {
                this.stateMachine = stateMachine
                onClear = {
                    stateMachine.clear(GameStateMachine.namespace)
                    redirectToHome()
                }
                onAudio = { m, v ->
                    media = m
                    volume = v
                }
            }

            "/achievements" ->
                EnvironmentComponent {
                    context = IntroContext("")
                    device = true
                    appProps = null
                    backgroundImage = "/bg_home.jpg"
                    backgroundColor = rgb(21, 31, 42).toString()
                    NotesAppComponent {
                        lines = stateMachine.getSuccess()
                        onExit = {
                            redirectToHome()
                        }
                    }
                }

            "/share" ->
                ShareComponent {
                    onExit = { redirectToHome() }
                }

            "/" -> HomeComponents {
                this.stateMachine = stateMachine
                onNewGame = {
                    stateMachine.clear(GameStateMachine.namespace)
                    redirectToGame()
                }
                onContinue = {
                    redirectToGame()
                }
                onAchievements = {
                    redirectToAchievements()
                }
                onNewsLetter = {
                    redirectToNewsletter()
                }
                onShare = {
                    redirectToShare()
                }
                fastMode = messageSpeed == 10
                onFastMode = {
                    setMessageSpeed(if (it) 10 else 200)
                }
            }

            "/newsletter" -> NewsletterPage {
                onExit = {
                    redirectToHome()
                }
            }
        }

        div {
            css {
                position = Position.absolute
                top = 0.px
                right = 0.px
                margin = 16.px
            }
            AudioComponent {
                this.media = media
                this.volume = volume
            }
        }
    }

}

package xyz.lacunae.itsadate

import xyz.lacunae.story.*

data class DownloadData(
    val name: String
)

fun SceneBuilder.download(name: String) {
    line(CustomLine("download", DownloadData(name)))
}

data class AppBadge(val name: String)

fun SceneBuilder.appBadge(app: String) {
    event(AppBadge(app))
}

data class UploadData(
    val name: String
)

fun SceneBuilder.upload(name: String) {
    line(CustomLine("upload", UploadData(name)))
}

data class PhotoData(
    val name: String,
    val desc: String,
)

class PhotoLineBuilder(private val character: Character, private val name: String, private val desc: String) :
    LineBuilder {
    override fun build(): Line {
        return CustomLine(
            "photo", PhotoData(
                name,
                desc
            ),
            character
        )
    }
}


fun CharacterContext.photo(name: String, desc: String) {
    lines = lines + PhotoLineBuilder(character, name, desc)
}

data class HesitationData(
    val time: Int
)

class HesitationLineBuilder(private val character: Character, private val time: Int) :
    LineBuilder {
    override fun build(): Line {
        return CustomLine(
            "hesitation",
            HesitationData(time),
            character
        )
    }
}

fun CharacterContext.hesitation(time: Int = 2_000) {
    lines = lines + HesitationLineBuilder(character, time)
}

data class BadgeData(
    val given: Boolean
)

class BadgeLineBuilder(private val character: Character, private val given: Boolean) : LineBuilder {
    override fun build(): Line {
        return CustomLine(
            "badge", BadgeData(
                given,
            ),
            character
        )
    }
}


fun CharacterContext.badge(given: Boolean) {
    lines = lines + BadgeLineBuilder(character, given)
}

data class ProfileData(
    val character: Character
)

fun QuestionBuilder.profile(character: Character, cc: SceneBuilder.() -> Unit = {}) {
    choices = choices + ChoiceBuilder(ProfileData(character)) {
        scene.clone().apply(cc).lines
    }
}

data class SilentChoiceData(
    val content: String
)

fun QuestionBuilder.silent(content: String, cc: SceneBuilder.() -> Unit = {}) {
    choices = choices + ChoiceBuilder(SilentChoiceData(content)) {
        scene.clone().apply(cc).lines
    }
}
data class ConditionalChoiceData(
    val content: String,
    val name: String,
    val condition: () -> Boolean,
) {
    override fun toString(): String {
        return "$name($content)"
    }
}

fun QuestionBuilder.conditional(
    content: String,
    name: String,
    condition: StateMachine.() -> Boolean,
    cc: SceneBuilder.() -> Unit = {}
) {
    choices = choices + ChoiceBuilder(
        ConditionalChoiceData(content, name) {
            stateMachine.condition()
        }
    ) {
        scene.clone().apply(cc).lines
    }
}

object BlockData

class BlockLineBuilder(private val character: Character) : LineBuilder {
    override fun build(): Line {
        return CustomLine(
            "badge", BlockData,
            character
        )
    }
}


fun CharacterContext.block() {
    lines = lines + BlockLineBuilder(character)
}


fun Character.disconnect(): String {
    return "${this.name} s’est " + if (this is GenderedCharacter) {
        this.withGender("déconnecté", "déconnectée")
    } else {
        "déconnecté(e)"
    }
}
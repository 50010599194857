import csstype.*
import emotion.react.css
import kotlinext.js.js
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.circle
import react.dom.svg.ReactSVG.svg
import web.events.Event
import web.events.EventType
import web.html.HTMLDivElement
import xyz.lacunae.itsadate.Gender
import xyz.lacunae.itsadate.GenderedCharacter
import xyz.lacunae.itsadate.ImageCharacter
import xyz.lacunae.story.Character

data class GroupedMessages(
    val character: Character?,
    val messages: MutableList<IMessage> = mutableListOf()
)

sealed class IMessage
interface CharacterMessage {
    val character: Character
}

data class TextMessage(
    override val character: Character,
    val content: String
) : IMessage(), CharacterMessage

data class PhotoMessage(
    override val character: Character,
    val name: String,
    val desc: String
) : IMessage(), CharacterMessage

data class DownloadMessage(
    val name: String,
    val time: Int = 5000,
    val completed: Boolean = false
) : IMessage()

data class UploadMessage(
    val name: String,
    val time: Int = 5000,
    val completed: Boolean = false
) : IMessage()

data class BadgeMessage(
    val character: Character,
    val given: Boolean,
) : IMessage()

data class BlockMessage(
    val character: Character,
) : IMessage()

external interface MessagesProps : Props {
    var characters: List<Character>
    var messages: List<IMessage>
    var writing: Character?
    var onDownloaded: (DownloadMessage) -> Unit
    var onUploaded: (UploadMessage) -> Unit
}

val MessagesComponent = FC<MessagesProps>("MessagesComponent") { props ->

    val (groupedMessages, setGroupedMessages) = useState(emptyList<GroupedMessages>())
    val (zoom, setZoom) = useState<PhotoMessage?>(null)

    fun getColorForCharacter(
        characters: List<Character>, character: Character
    ) = when {
        character.isPlayer -> Colors.primaryContainer to Colors.onPrimaryContainer
        character.isNarrator -> NamedColor.transparent to Colors.onSurface
        else -> when (characters.indexOf(character)) {
            0 -> Colors.surfaceContainerLow to Colors.onSurface
            else -> Colors.surfaceContainer to Colors.onSurface
        }
    }

    useEffect(props.messages) {
        val groups = mutableListOf<GroupedMessages>()
        var group: GroupedMessages? = null
        props.messages.forEach { message ->
            val c = if (message is CharacterMessage) message.character else null
            group = if (group != null && group?.character == c) {
                group
            } else {
                GroupedMessages(c).also {
                    groups.add(it)
                }
            }?.apply {
                messages.add(message)
            }
        }
        setGroupedMessages(groups)
    }

    val messageContainer = useRef<HTMLDivElement>()
    var autoScroll by useState(true)

    useEffect(groupedMessages, props.writing, autoScroll) {
        if (autoScroll) {
            messageContainer.current?.let { div ->
                div.lastElementChild?.scrollIntoView(js {
                    behavior = "instant"
                    block = "end"
                })
            }
        }
    }
    val callback = useCallback(messageContainer) { event: Event ->
        messageContainer.current?.let { element ->
            autoScroll = (element.scrollHeight - element.scrollTop - element.clientHeight) < 128.0
        }
        Unit
    }

    useEffect(messageContainer) {
        messageContainer.current?.addEventListener(EventType("scroll"), callback)
        cleanup {
            messageContainer.current?.removeEventListener(EventType("scroll"), callback)
        }
    }

    div {
        css {
            overflowY = Overflow.scroll
            display = Display.flex
            flexDirection = FlexDirection.column
            padding = Padding(horizontal = 4.px, vertical = 0.px)
        }
        ref = messageContainer
        groupedMessages.forEach { group ->
            div {
                val character = group.character
                css {
                    display = Display.flex
                    flexDirection = if (character?.isPlayer == true) {
                        FlexDirection.rowReverse
                    } else {
                        FlexDirection.row
                    }
                    alignItems = AlignItems.flexEnd
                    margin = Margin(top = 8.px, bottom = 0.px, horizontal = 0.px)

                }
                if (character != null) {
                    if (!character.isNarrator) {
                        if (character is ImageCharacter && character.img != null) {
                            img {
                                alt = character.name.first().toString()
                                src = character.img
                                css {
                                    height = 32.px
                                    width = 32.px
                                    margin = Margin(horizontal = 4.px, vertical = 2.px)
                                    borderRadius = 16.px
                                    backgroundColor = Colors.surfaceContainerHighest
                                    objectFit = ObjectFit.cover
                                }
                            }
                        } else {
                            span {
                                css {
                                    characterText()
                                }
                                +(character.name.firstOrNull()?.toString() ?: "")
                            }
                        }
                    }
                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        width = 100.pct
                        if (character?.isPlayer == true) {
                            alignItems = AlignItems.flexEnd
                        }
                    }
                    group.messages.forEach { message ->
                        when (message) {
                            is TextMessage -> {
                                div {
                                    val c = message.character
                                    css {
                                        when {
                                            c.isNarrator -> message_narrator()
                                            c.isPlayer -> message_player()
                                            c is GenderedCharacter -> when (c.gender) {
                                                Gender.WOMAN -> message_woman()
                                                Gender.MAN -> message_man()
                                            }
                                        }
                                    }
                                    span {
                                        css {
                                            borderRadius = 16.px
                                            padding = Padding(horizontal = 16.px, vertical = 8.px)
                                            val (container, onContainer) =
                                                getColorForCharacter(props.characters, message.character)
                                            backgroundColor = container
                                            color = onContainer
                                        }
                                        +message.content
                                    }
                                }
                            }

                            is PhotoMessage -> {
                                div {
                                    val c = message.character
                                    css {
                                        when {
                                            c.isNarrator -> message_narrator()
                                            c.isPlayer -> message_player()
                                            c is GenderedCharacter -> when (c.gender) {
                                                Gender.WOMAN -> message_woman()
                                                Gender.MAN -> message_man()
                                            }
                                        }
                                    }
                                    img {
                                        src = message.name
                                        alt = message.desc
                                        onClick = {
                                            setZoom(message)
                                        }
                                        css {
                                            width = 70.pct
                                            borderRadius = 16.px
                                            val (container, onContainer) =
                                                getColorForCharacter(props.characters, message.character)
                                            backgroundColor = container
                                            color = onContainer
                                        }
                                    }
                                    if (zoom != null) {
                                        ImageZoomedComponent {
                                            src = zoom.name
                                            alt = zoom.desc
                                            onClick = {
                                                setZoom(null)
                                            }
                                        }
                                    }
                                }

                            }

                            is DownloadMessage -> {
                                DownloadMessageComponent {
                                    name = message.name
                                    time = message.time
                                    completed = message.completed
                                    onDownloaded = {
                                        props.onDownloaded(message)
                                    }
                                }
                            }

                            is UploadMessage -> {
                                UploadMessageComponent {
                                    name = message.name
                                    time = message.time
                                    completed = message.completed
                                    onUploaded = {
                                        props.onUploaded(message)
                                    }
                                }
                            }

                            is BadgeMessage -> {
                                BadgeMessageComponent {
                                    this.character = message.character
                                    given = message.given
                                }
                            }

                            is BlockMessage -> BlockMessageComponent {
                                this.character = message.character
                            }
                        }

                    }
                }
            }
        }
        props.writing?.let { character ->
            div {
                css {
                    when {
                        character.isNarrator -> message_narrator()
                        character.isPlayer -> message_player()
                        character is GenderedCharacter -> when (character.gender) {
                            Gender.WOMAN -> message_woman()
                            Gender.MAN -> message_man()
                        }
                    }
                }
                if (!character.isNarrator) {
                    if (character is ImageCharacter && character.img != null) {
                        img {
                            alt = character.name.first().toString()
                            src = character.img
                            css {
                                height = 32.px
                                width = 32.px
                                margin = Margin(horizontal = 4.px, vertical = 2.px)
                                borderRadius = 16.px
                                backgroundColor = Colors.surfaceContainerHighest
                                objectFit = ObjectFit.cover
                            }
                        }
                    } else {
                        span {
                            css {
                                characterText()
                            }
                            +(character.name.firstOrNull()?.toString() ?: "")
                        }
                    }
                }
                val (container, onContainer) =
                    getColorForCharacter(props.characters, character)
                span {
                    css {
                        borderRadius = 16.px
                        padding = Padding(horizontal = 16.px, vertical = 8.px)
                        backgroundColor = container
                        color = onContainer
                    }
                    svg {
                        height = 10.0
                        width = 40.0
                        circle {
                            className = ClassName("dot")
                            cx = 10.0
                            cy = 5.0
                            r = 4.0
                            fill = onContainer.toString()
                        }
                        circle {
                            className = ClassName("dot")
                            cx = 20.0
                            cy = 5.0
                            r = 4.0
                            fill = onContainer.toString()
                        }
                        circle {
                            className = ClassName("dot")
                            cx = 30.0
                            cy = 5.0
                            r = 4.0
                            fill = onContainer.toString()
                        }
                    }
                }
            }
        }
    }

}
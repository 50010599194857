import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div

external interface ImageZoomedProps : Props {
    var src: String
    var alt: String
    var onClick: () -> Unit
}

val ImageZoomedComponent = FC<ImageZoomedProps>("ImageZoomedComponent") { props ->
    div {
        css {
            width = 100.pct
            height = 100.pct
            position = Position.fixed
            backgroundColor = rgba(0, 0, 0, 0.5)
            top = 0.px
            bottom = 0.px
            left = 0.px
            right = 0.px
            zIndex = integer(100)
        }
        onClick = {
            props.onClick()
        }
        ReactHTML.img {
            alt = props.alt
            src = props.src
            css {
                maxWidth = 90.pct
                maxHeight = 90.pct
                position = Position.fixed
                display = Display.block
                borderRadius = 32.px
                overflow = Overflow.hidden
                left = 50.pct
                top = 50.pct
                transform = translate((-50).pct, (-50).pct)
            }
        }
    }

}
import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.useState
import xyz.lacunae.itsadate.*
import xyz.lacunae.itsadate.App.*
import xyz.lacunae.story.Scene
import kotlin.math.max

external interface HeadeProps : Props {
    var scene: Scene
}

val HeaderComponent = FC<HeadeProps>("HeaderComponent") { props ->

    val (zoom, setZoom) = useState<ImageCharacter?>(null)

    div {
        val characters = props.scene.characters
        val context = props.scene.context
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.start
            alignItems = AlignItems.center
            padding = Padding(vertical = 16.px, horizontal = 32.px)
            mobile {
                padding = Padding(vertical = 8.px, horizontal = 16.px)
            }
            mobileLandscape {
                padding = Padding(vertical = 8.px, horizontal = 16.px)
            }
            backgroundColor = Colors.surfaceContainer
            color = Colors.onSurface
            zIndex = integer(1)
            boxShadow = BoxShadow(0.px, -4.px, 8.px, Colors.onSurface)
        }
        characters.forEachIndexed { index, character ->
            if (character is ImageCharacter && character.img != null) {
                img {
                    alt = character.name
                    src = character.img
                    onClick = {
                        setZoom(character)
                    }
                    css {
                        position = Position.relative
                        zIndex = integer(characters.size - index)
                        left = (-10 * index).px
                        width = 48.px
                        height = 48.px
                        borderRadius = when (context) {
                            is AppContext -> when(context.app){
                                WORK -> 12.px
                                else -> 24.px
                            }
                            else -> 24.px
                        }
                        objectFit = ObjectFit.cover
                    }
                }

            } else {
                div {
                    css {
                        position = Position.relative
                        zIndex = integer(characters.size - index)
                        left = (-10 * index).px
                        width = 48.px
                        height = 48.px
                        display = Display.flex
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        fontWeight = FontWeight.bolder
                        borderRadius = when (context) {
                            is AppContext -> when(context.app){
                                WORK -> 12.px
                                else -> 24.px
                            }
                            else -> 24.px
                        }
                        backgroundColor = Colors.tertiaryContainer
                    }
                    +character.name.first().toString()
                }
            }
        }
        if (context is GroupMessengerContext) {
            div {
                css {
                    display = Display.flex
                    val value: Int = 10 * max(0, characters.size - 1)
                    margin = Margin(left = (16 - value).px, top = 0.px, bottom = 0.px, right = 0.px)
                    flexDirection = FlexDirection.column
                }
                h1 {
                    css {
                        display = Display.inline
                        textAlign = TextAlign.center
                        fontSize = FontSize.body
                        margin = Margin(0.px, 0.px)
                    }
                    +context.title
                }
                div {
                    css {
                        display = Display.inline
                        fontSize = FontSize.caption
                        fontStyle = FontStyle.italic
                    }
                    +characters.joinToString(", ")
                }
            }
        } else {
            h1 {
                css {
                    display = Display.inline
                    textAlign = TextAlign.center
                    fontSize = if (characters.size == 1) FontSize.header else FontSize.subhead
                    margin = Margin(0.px, 0.px)
                    val value: Int = 10 * max(0, characters.size - 1)
                    margin = Margin(left = (16 - value).px, top = 0.px, bottom = 0.px, right = 0.px)
                }
                +characters.joinToString(", ")
            }
        }
        val src = zoom?.img
        if (src != null) {
            ImageZoomedComponent {
                this.src = src
                alt = zoom.name
                onClick = {
                    setZoom(null)
                }
            }
        }
    }
}

import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1

external interface IntroAppProps : Props {
    var title: String
    var lines: List<String>
    var end: Next?
    var onEnd: () -> Unit
}

val IntroAppComponent = FC<IntroAppProps>("IntroAppComponent") { props ->

    div {
        css(ClassName("theme-dark")) {
            width = 100.pct
            height = 100.pct
            display = Display.grid
            gridTemplateRows = array(Auto.auto, 1.fr, Auto.auto)
            gridTemplateColumns = 100.pct
            backgroundColor = rgba(0,0,0,.7)
            backdropFilter = blur(7.px)
            fontFamily = string("'Courier New', monospace")
            colorScheme = ColorScheme.dark
            color = Colors.onSurface
        }
        h1 {
            css {
                textAlign = TextAlign.center
                margin = Margin(horizontal = 0.px, vertical = 32.px)
            }
            +props.title
        }
        div {
            css {
                overflowY = Overflow.scroll
                display = Display.flex
                flexDirection = FlexDirection.column
                fontSize = FontSize.body
            }
            props.lines.forEach {
                div {
                    css {
                        padding = Padding(vertical = 2.px, horizontal = 32.px)
                    }
                    +it
                }
            }
        }
        NextComponent {
            next = props.end
            onNext = props.onEnd
        }
    }

}

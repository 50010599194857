import csstype.rgb
import kotlinext.js.js
import kotlinx.browser.window
import react.*
import xyz.lacunae.engine.*
import xyz.lacunae.itsadate.*
import xyz.lacunae.kotlin.Umami
import xyz.lacunae.story.*
import kotlin.time.Duration.Companion.seconds

external interface GameProps : Props {
    var stateMachine: StateMachine
    var onClear: () -> Unit
    var onAudio: (String, Double) -> Unit
}

data class EngineWrapper(
    val current: Engine,
    val previous: EngineWrapper?,
) {
    fun hasLine(): Boolean {
        return current.hasLine() || (previous?.hasLine() ?: false)
    }

    fun readNextLine() {
        if (current.hasLine()) {
            current.readNextLine()
        } else {
            previous?.readNextLine()
        }
    }
}

data class SceneState(
    var scene: Scene? = null,
    var writing: Character? = null,
    var messages: List<IMessage>? = null,
    var codeGame: CodeGameData? = null,
    var answerType: AnswerType? = null,
    var answer: ((String) -> Unit)? = null,
    var choice: ((Int) -> Unit)? = null,
    var choices: List<Any>? = null,
    var running: Boolean? = null,
    var next: Next? = null,
    var nextChapter: (() -> Unit)? = null,
    var apps: List<AppData?>? = null,
    var notes: Boolean? = null,
) : State

val Game = FC<GameProps>("Game") { props ->

    fun sceneName(): String = props.stateMachine.game.scene ?: Chapter1.intro

    val (story, setStory) = useState<Story?>(null)
    val (engine, setEngine) = useState<EngineWrapper?>(null)
    val (state, setState) = useState(SceneState())

    useEffect(props.stateMachine) {
        fun trackState(key: String, value: Boolean) {
            Umami.track("state:$key=$value")
        }
        props.stateMachine.addObserver { key ->
            if (key.endsWith(GameStateMachine.namespace)) {
                val value = props.stateMachine.game[key]
                if (value is String? && (value == "true" || value == "false")) {
                    trackState(key, value.toBoolean())
                } else if (value is Boolean) {
                    trackState(key, value)
                }
            }
        }
    }

    fun onScene(name: String) {
        name.split("/").firstOrNull()?.takeIf { "#$it" != window.location.hash }?.let {
            window.history.pushState(null, "", window.location.origin + window.location.pathname + "#$it")
        }
        fun Long.roundTo(n: Long) : Long {
            // Smaller multiple
            val a = this / n * n
            // Larger multiple
            val b = a + 10
            // Return of closest of two
            return if (n - a > b - n) b else a
        }
        if (name.contains("/end")) {
            Umami.track("end", js {
                this[name] = props.stateMachine.game.time.seconds.toComponents { minutes, _, _ ->
                    minutes.roundTo(5)
                }.toInt().also {
                    console.log(it)
                }
            })
        }
    }

    fun appsFromSM(): List<AppData?> {
        return props.stateMachine.game.apps.map { app ->
            app?.let {
                AppData(
                    id = it,
                    name = when (it) {
                        Apps.dating -> "CupidLov"
                        Apps.work -> "Office"
                        Apps.messenger -> "Telex"
                        Apps.sms -> "SMS"
                        Apps.notes -> "Notes"
                        else -> ""
                    },
                    icon = "/apps/$it.svg",
                    theme = when (it) {
                        Apps.dating -> "theme-dating"
                        Apps.work -> "theme-work"
                        Apps.messenger -> "theme-messenger"
                        Apps.sms -> "theme-sms"
                        Apps.notes -> "theme-notes"
                        else -> ""
                    },
                    enable = it == Apps.notes,
                    light = it == Apps.notes,
                    badge = false,
                )
            }
        }
    }

    fun buildEngine(story: Story, scene: Scene, previous: EngineWrapper? = null): EngineWrapper {
        val time = props.stateMachine.settings.messageSpeed ?: 200
        return EngineWrapper(
            Engine(
                name = scene.context.name,
                dialog = scene.dialog,
                messagePrinter = object : MessagePrinter {
                    override fun print(message: Message) {
                        val timeout = message.content.split(" ").size * time
                        if (scene.context !is IntroContext && scene.context !is EndContext) {
                            if (timeout > 0 && timeout > 4 * time) {
                                window.setTimeout({
                                    setState {
                                        it.copy(
                                            writing = message.character,
                                            running = false,
                                        )
                                    }
                                }, 3 * time)
                            }
                            window.setTimeout({
                                setState {
                                    it.copy(
                                        running = true,
                                        writing = null,
                                        messages = (it.messages ?: emptyList()) + TextMessage(
                                            message.character,
                                            message.content.normalizeSpace()
                                        ),
                                    )
                                }
                            }, timeout)
                        } else {
                            setState {
                                it.copy(
                                    messages = (it.messages ?: emptyList()) + TextMessage(
                                        message.character,
                                        message.content.normalizeSpace()
                                    )
                                )
                            }
                        }
                    }
                },
                questionWithAnswerPrinter = object : QuestionWithAnswerPrinter {
                    override fun print(question: QuestionWithAnswer, callback: (String) -> Unit) {
                        setState {
                            it.copy(
                                writing = null,
                                answerType = question.type,
                                answer = callback,
                                running = false,
                            )
                        }
                    }
                },
                questionWithChoicesPrinter = object : QuestionWithChoicesPrinter {
                    override fun print(question: QuestionWithChoices<*>, callback: (Int) -> Unit) {
                        setState {
                            it.copy(
                                writing = null,
                                choice = callback,
                                choices = question.choices.mapNotNull {
                                    val content = it.content
                                    if (content is String) {
                                        content.normalizeSpace()
                                    } else {
                                        content
                                    }
                                },
                                running = false,
                            )
                        }
                    }
                },
                customPrinter = object : CustomLinePrinter {
                    override fun print(line: CustomLine<*>) {
                        when (val data = line.data) {
                            is DownloadData -> {
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + DownloadMessage(data.name, 1000),
                                        running = false,
                                    )
                                }
                            }

                            is UploadData -> {
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + UploadMessage(data.name, 1000),
                                        running = false,
                                    )
                                }
                            }

                            is PhotoData -> {
                                setState {
                                    it.copy(
                                        messages =
                                        (it.messages ?: emptyList()) + PhotoMessage(
                                            line.character!!,
                                            data.name,
                                            data.desc
                                        )
                                    )
                                }
                            }

                            is BadgeData -> {
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + BadgeMessage(
                                            line.character!!,
                                            data.given
                                        )
                                    )
                                }
                            }

                            BlockData -> {
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + BlockMessage(
                                            line.character!!,
                                        )
                                    )
                                }
                            }

                            is CodeGameData -> {
                                setState {
                                    it.copy(
                                        codeGame = data
                                    )
                                }
                            }

                            is HesitationData -> {
                                setState {
                                    it.copy(
                                        writing = line.character,
                                        running = false,
                                    )
                                }
                                window.setTimeout({
                                    setState {
                                        it.copy(
                                            writing = null,
                                            running = true,
                                        )
                                    }
                                }, data.time)
                            }
                        }
                    }
                },
                fragmentMerger = object : FragmentMerger {
                    override fun merge(line: Include) {
                        if (story.containsFragment(line.name)) {
                            setEngine {
                                val s = story.findFragmentByName(line.name, props.stateMachine)
                                buildEngine(
                                    story, s, it
                                )
                            }
                        } else {
                            console.error("Cannot found include: $line")
                        }
                    }
                },
                sceneRouter = object : SceneRouter {

                    override fun navigateTo(redirection: GoToScene) {
                        onScene(redirection.name)
                        setState {
                            it.copy(
                                next = Next(
                                    when (val opt = redirection.option) {
                                        GoToScene.Option.Auto -> Next.Option.Auto
                                        GoToScene.Option.User -> Next.Option.User
                                        is GoToScene.Option.Timeout -> Next.Option.Timeout(opt.value)
                                    }
                                ),
                                nextChapter = {
                                    val name = redirection.name
                                    if (story.containsScene(name)) {
                                        props.stateMachine.game.scene = name
                                        val s = story.findSceneByName(name, props.stateMachine)
                                        setEngine {
                                            buildEngine(story, s)
                                        }
                                        setState {
                                            it.copy(
                                                scene = s,
                                                apps = appsFromSM(),
                                                messages = emptyList(),
                                                next = null,
                                                nextChapter = null,
                                            )
                                        }
                                    } else {
                                        console.error("Scene not found: $name")
                                    }
                                },
                            )
                        }
                    }
                },
                eventRouter = object : EventRouter {
                    override fun onEvent(event: Event<*>) {
                        when (val data = event.data) {
                            is AppBadge -> setState {
                                it.copy(
                                    apps = it.apps?.map {
                                        if (it?.id == data.name) {
                                            it.copy(enable = true, badge = true)
                                        } else {
                                            it
                                        }
                                    }
                                )
                            }
                        }
                    }
                }
            ),
            previous
        )
    }


    useEffect(Unit) {
        val storyReader = StoryReader().apply { teslaStory() }
        setStory(storyReader)
        props.stateMachine.addObserver { key ->
            if (key.contains("Installed")) {
                setState {
                    it.copy(apps = appsFromSM())
                }
            }
        }
        val name = sceneName()
        val s = storyReader.findSceneByName(name, props.stateMachine)
        onScene(name)
        setEngine {
            buildEngine(storyReader, s)
        }
        setState {
            it.copy(
                scene = s,
                running = true,
                apps = appsFromSM(),
            )
        }
    }

    useEffect(state) {
        val volume = 0.1

        val context = state.scene?.context
        if (context is AmbianceContext) {
            when (context.ambiance) {
                Ambiance.SHORT_END ->
                    props.onAudio("/audio/turn-away-yeti-music-main-version-01-00-3022.mp3", volume)

                Ambiance.HAPPY_END ->
                    props.onAudio("/audio/only-you-northwestern-main-version-02-04-3535.mp3", volume)

                Ambiance.DEADLY_END ->
                    props.onAudio("/audio/gathering-darkness-kevin-macleod-main-version-04-22-8459.mp3", volume)

                Ambiance.INTRO ->
                    props.onAudio("/audio/peaceful-morning-yeti-music-main-version-00-35-3025.mp3", volume)

                Ambiance.PARK ->
                    props.onAudio("/audio/329948__joljo__009-ambiant-recording-parisian-park.mp3", volume)

                Ambiance.BAR ->
                    props.onAudio("/audio/327674__juan-merie-venter__ambience-lekker.mp3", volume)

                Ambiance.STREET ->
                    props.onAudio("/audio/527754__bruno-auzet__rue-st-denis-paris.mp3", volume)

                Ambiance.FLIRT ->
                    props.onAudio("/audio/hope-simon-folwar-main-version-02-44-8586.mp3", volume)

                Ambiance.RUNNING ->
                    props.onAudio("/audio/threatened-alex-besss-main-version-02-20-10156.mp3", volume)

                Ambiance.NOWHERE ->
                    props.onAudio("/audio/lightless-dawn-kevin-macleod-main-version-06-08-8003.mp3", volume)

                Ambiance.HOME ->
                    props.onAudio("/audio/peaceful-morning-yeti-music-main-version-00-35-3025.mp3", volume)

                Ambiance.DATING ->
                    props.onAudio("/audio/poco-roo-walker-main-version-02-22-9624.mp3", volume)

                Ambiance.WORK ->
                    props.onAudio("/audio/563248__globofonia__ambience-office.mp3", volume)
            }
        }
    }

    useEffect(state, engine) {
        if (engine != null && state.running == true) {
            if (engine.hasLine()) {
                engine.readNextLine()
            } else {
                if (state.next == null) {
                    setState { it.copy(next = Next(Next.Option.User)) }
                }
            }
        }
    }

    TimerComponent {
        stateMachine = props.stateMachine
    }
    val scene = state.scene
    if (scene != null) {
        EnvironmentComponent {
            context = scene.context
            backgroundColor = when (val c = scene.context) {
                is IntroContext -> rgb(71, 84, 11)
                is EndContext -> rgb(46, 44, 45)
                is InPlaceContext -> when (c.place) {
                    Place.HOME -> rgb(229, 222, 203)
                    Place.DATING -> rgb(162, 161, 177)
                    Place.WORK -> rgb(220, 208, 200)
                    Place.PARK -> rgb(230, 187, 119)
                    Place.BAR -> rgb(191, 169, 172)
                    Place.STREET -> rgb(138, 139, 133)
                    Place.PARIS -> rgb(63, 71, 67)
                    Place.NOWHERE -> rgb(0, 35, 80)
                }

                else -> rgb(0, 35, 80)
            }.toString()
            backgroundImage = when (val c = scene.context) {
                is EndContext -> when (c.type) {
                    EndContext.Type.Short -> "/bg_end.jpeg"
                    EndContext.Type.Deadly -> "/bg_end_deadly.jpeg"
                    EndContext.Type.Happy -> "/bg_end_happy.jpeg"
                }

                is InPlaceContext -> when (c.place) {
                    Place.HOME -> "/bg_sms.jpeg"
                    Place.DATING -> "/bg_dating.jpeg"
                    Place.WORK -> "/bg_work.jpeg"
                    Place.PARK -> "/bg_park.jpeg"
                    Place.BAR -> "/bg_bar.jpeg"
                    Place.STREET -> "/bg_street.jpeg"
                    Place.PARIS -> "/bg_paris.jpeg"
                    Place.NOWHERE -> "/bg_messenger.jpeg"
                    else -> "/bg_${scene.context.name}.jpeg"
                }

                else -> "/bg_${scene.context.name}.jpeg"
            }
            device =
                scene.context !is IrlContext && scene.context !is EndContext
            appProps = js {
                apps = state.apps ?: listOf(null, null, null, null, null)
                onAppClick = { app: AppData ->
                    when {
                        app.id == Apps.notes -> {
                            setState {
                                it.copy(notes = true)
                            }
                        }

                        app.badge -> {
                            state.nextChapter?.invoke()
                        }

                        else -> {}
                    }
                }
            }
            if (state.notes == true) {
                NotesAppComponent {
                    lines = props.stateMachine.getSuccess()
                    renderGender = props.stateMachine["gender:game"] != null
                    onExit = {
                        setState {
                            it.copy(
                                notes = false
                            )
                        }
                    }
                }
            } else {
                when (val context = scene.context) {
                    is CodeGameContext -> {
                        CodeGameComponents {
                            messages = state.messages
                            data = state.codeGame?.let {
                                CodeGameValues(it.value, context.max, it.content)
                            } ?: CodeGameValues(max = context.max)
                            answer = state.answer != null
                            onAnswer = { input: String ->
                                state.answer?.invoke(input)
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + TextMessage(
                                            props.stateMachine.player,
                                            input
                                        ),
                                        answerType = null,
                                        answer = null,
                                        running = true,
                                    )
                                }
                            }
                            choices = state.choices
                            onChoice = { index, _ ->
                                state.choice?.invoke(index)
                                setState {
                                    it.copy(
                                        choice = null,
                                        choices = null,
                                        running = true,
                                    )
                                }
                            }
                            next = state.next
                            onNext = {
                                state.nextChapter?.invoke()
                            }
                        }
                    }

                    is EncryptedBluePrintContext -> {
                        EncryptedBluePrintComponents {
                            messages = state.messages
                            answer = state.answer != null
                            onAnswer = { input: String ->
                                state.answer?.invoke(input)
                                setState {
                                    it.copy(
                                        messages = (it.messages ?: emptyList()) + TextMessage(
                                            props.stateMachine.player,
                                            input
                                        ),
                                        answerType = null,
                                        answer = null,
                                        running = true,
                                    )
                                }
                            }
                            choices = state.choices
                            onChoice = { index, _ ->
                                state.choice?.invoke(index)
                                setState {
                                    it.copy(
                                        choice = null,
                                        choices = null,
                                        running = true,
                                    )
                                }
                            }
                            next = state.next
                            onNext = {
                                state.nextChapter?.invoke()
                            }
                        }
                    }

                    is IntroContext -> {
                        IntroAppComponent {
                            title = context.title ?: ""
                            lines = state.messages.orEmpty().filterIsInstance<TextMessage>().map { it.content }
                            end = state.next
                            onEnd = {
                                state.nextChapter?.invoke()
                            }
                        }
                    }

                    is EndContext -> {
                        EndAppComponent {
                            title = context.title ?: ""
                            mod = if (context.type == EndContext.Type.Happy) Mod.LIGHT else Mod.DARK
                            lines = state.messages.orEmpty().filterIsInstance<TextMessage>().map { it.content }
                            end = state.next
                            onEnd = {
                                props.onClear()
                            }
                        }
                    }

                    is IrlContext -> {
                        IrlAppComponent {
                            writing = state.writing
                            messages = state.messages
                            choices = state.choices
                            onChoice = { index, content ->
                                state.choice?.invoke(index)
                                setState {
                                    if (content is String) {
                                        it.copy(
                                            messages =
                                            (it.messages ?: emptyList()) + TextMessage(
                                                props.stateMachine.player,
                                                content
                                            )
                                        )
                                    } else {
                                        it
                                    }.copy(
                                        choice = null,
                                        choices = null,
                                        running = true,
                                    )
                                }
                            }
                            answer = state.answerType
                            onAnswer = { input: String ->
                                state.answer?.invoke(input)
                                setState {
                                    when (it.answerType) {
                                        AnswerType.TEXT, AnswerType.NUMBER -> {
                                            it.copy(
                                                messages = (it.messages ?: emptyList()) + TextMessage(
                                                    props.stateMachine.player,
                                                    input
                                                )
                                            )
                                        }

                                        AnswerType.IMAGE -> {
                                            it.copy(
                                                messages = (it.messages ?: emptyList()) + PhotoMessage(
                                                    props.stateMachine.player,
                                                    input,
                                                    ""
                                                )
                                            )
                                        }

                                        else -> it
                                    }.copy(
                                        answerType = null,
                                        answer = null,
                                        running = true,
                                    )
                                }
                            }
                            next = state.next
                            onNext = {
                                state.nextChapter?.invoke()
                            }
                        }
                    }

                    else -> {
                        MessagesAppComponent {
                            this.scene = scene
                            writing = state.writing
                            messages = state.messages
                            choices = state.choices
                            onChoice = { index: Int, content: Any ->
                                state.choice?.invoke(index)
                                setState {
                                    when (content) {
                                        is String -> {
                                            it.copy(
                                                messages =
                                                (it.messages ?: emptyList()) + TextMessage(
                                                    props.stateMachine.player,
                                                    content
                                                )
                                            )
                                        }

                                        is ProfileData -> {
                                            it.copy(
                                                messages =
                                                (it.messages ?: emptyList()) + TextMessage(
                                                    props.stateMachine.player,
                                                    content.character.name
                                                )
                                            )
                                        }

                                        else -> it
                                    }.copy(
                                        choice = null,
                                        choices = null,
                                        running = true,
                                    )
                                }
                            }
                            answer = state.answerType
                            onAnswer = { input: String ->
                                state.answer?.invoke(input)
                                setState {
                                    when (state.answerType) {
                                        AnswerType.TEXT, AnswerType.NUMBER -> {
                                            it.copy(
                                                messages = (it.messages ?: emptyList()) + TextMessage(
                                                    props.stateMachine.player,
                                                    input
                                                )
                                            )
                                        }

                                        AnswerType.IMAGE -> {
                                            it.copy(
                                                messages = (it.messages ?: emptyList()) + PhotoMessage(
                                                    props.stateMachine.player,
                                                    input,
                                                    ""
                                                )
                                            )

                                        }

                                        else -> it
                                    }.copy(

                                        answerType = null,
                                        answer = null,
                                        running = true,
                                    )
                                }
                            }
                            next = state.next
                            onNext = {
                                state.nextChapter?.invoke()
                            }
                            onDownloaded = { message: DownloadMessage ->
                                setState {
                                    it.copy(
                                        messages = it.messages.orEmpty().toMutableList().apply {
                                            remove(message)
                                            add(message.copy(completed = true))
                                        },
                                        running = true,
                                    )
                                }
                            }
                            onUploaded = { message: UploadMessage ->
                                setState {
                                    it.copy(
                                        messages = it.messages.orEmpty().toMutableList().apply {
                                            remove(message)
                                            add(message.copy(completed = true))
                                        },
                                        running = true,
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.progress
import react.dom.html.ReactHTML.span
import react.useEffect
import react.useState
import web.html.EnterKeyHint
import web.html.HTMLFormElement
import web.html.HTMLInputElement
import web.html.InputType
import xyz.lacunae.story.Scene

data class CodeGameValues(
    val value: Int = 0,
    val max: Int = 0,
    val content: String? = null
)

external interface CodeGameProps : Props {
    var scene: Scene
    var data: CodeGameValues
    var messages: List<IMessage>?
    var answer: Boolean
    var onAnswer: (String) -> Unit
    var choices: List<Any>?
    var onChoice: (Int, Any) -> Unit
    var next: Next?
    var onNext: () -> Unit
}

val CodeGameComponents = FC<CodeGameProps>("CodeGameComponents") { props ->

    val (text, setText) = useState("")

    val submitHandler: (FormEvent<HTMLFormElement>) -> Unit = {
        it.preventDefault()
        setText("")
        props.onAnswer(text)
    }

    val changeHandler: (ChangeEvent<HTMLInputElement>) -> Unit = {
        setText(it.target.value)
    }

    useEffect(props.next) {
        if (props.next?.option == Next.Option.Auto) {
            props.onNext()
        }
    }

    ReactHTML.div {
        css(ClassName("theme-dark")) {
            backgroundColor = rgba(0, 0, 0, .8)
            height = 100.pct
            overflow = Overflow.hidden
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        div {
            css {
                height = 20.pct
            }
            NotificationsComponent {
                messages = props.messages
                actions = props.choices.orEmpty().filterIsInstance<String>()
                onAction = { index ->
                    props.choices?.get(index)?.let {
                        props.onChoice(index, it)
                    }
                }
            }
        }
        div {
            css {
                flexGrow = number(1.0)
            }
            form {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    padding = Padding(vertical = 32.px, horizontal = 0.px)
                    justifyContent = JustifyContent.center
                }
                onSubmit = submitHandler
                label {
                    htmlFor = "code"
                    css {
                        color = Colors.onSurface
                    }
                    +(props.data.content ?: "Entrer le code pin")
                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        height = 12.px
                        padding = Padding(8.px, 8.px)
                    }
                    for (i in 0 until props.data.max) {
                        span {
                            css {
                                display = Display.block
                                height = 8.px
                                width = 8.px
                                margin = Margin(2.px, 2.px)
                                borderRadius = 4.px
                                backgroundColor = when {
                                    -1 == props.data.value -> Colors.primary
                                    i < props.data.value -> Colors.error
                                    else -> Colors.onSurface
                                }
                            }
                        }
                    }
                }
                input{
                    name = "code"
                    type = InputType.text
                    css {
                        margin = Margin(horizontal = Auto.auto, vertical = 0.px)
                        padding = Padding(vertical = 8.px, horizontal = 16.px)
                        borderRadius = 32.px
                        fontSize = FontSize.entry
                        width = 60.pct
                        letterSpacing = 6.px
                        textAlign = TextAlign.center
                    }
                    id = "code"
                    onChange = changeHandler
                    value = text
                    disabled = !props.answer
                    enterKeyHint = EnterKeyHint.send
                    pattern = "[0-9]*"
                }
                input {
                    css {
                        marginTop = 8.px
                        backgroundColor = Colors.primary
                        color = Colors.onPrimary
                        padding = Padding(8.px, 16.px)
                        borderRadius = 24.px
                        border = None.none
                        fontSize = FontSize.button
                        disabled {
                            backgroundColor = Colors.onSurface
                            opacity = number(0.38)
                        }
                    }
                    type = InputType.submit
                    value = "Valider"
                    disabled = text.isEmpty()
                }

                progress {
                    css {
                        visibility = when {
                            props.next != null -> Visibility.hidden
                            !props.answer -> Globals.inherit
                            else -> Visibility.hidden
                        }
                        margin = Margin(16.px, 16.px)
                    }
                }
            }
        }
        NextComponent {
            disabled = props.next == null
            next = props.next ?: Next(Next.Option.User)
            onNext = props.onNext
        }
    }
}
import csstype.*
import emotion.react.css
import kotlinx.browser.window
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.strong
import react.dom.html.ReactHTML.ul
import xyz.lacunae.itsadate.ImageCharacter
import xyz.lacunae.kotlin.react.transition.group.TransitionComponent
import xyz.lacunae.kotlin.react.transition.group.timeout

external interface NotificationsProps : Props {
    var messages: List<IMessage>?
    var actions: List<String>?
    var onAction: (Int) -> Unit
}

private const val duration = 400

val NotificationsComponent = FC<NotificationsProps>("NotificationsComponent") { props ->

    val (notificationMessages, setNotificationMessages) = useState(listOf<TextMessage>())
    val (show, setShow) = useState(false)
    val (hideCallback, setHideCallback) = useState<Int?>(null)
    val (clearCallback, setClearCallback) = useState<Int?>(null)

    useEffect(props.messages) {
        props.messages.orEmpty().reversed().filterIsInstance<TextMessage>()
            .firstOrNull()?.let {
                if (!it.character.isNarrator && !it.character.isPlayer) {
                    setNotificationMessages(notificationMessages + it)
                    setShow(true)
                }
            }
    }
    useEffect(show, notificationMessages) {
        if (clearCallback != null) {
            window.clearTimeout(clearCallback)
            setClearCallback(null)
        }
        if (!show && notificationMessages.isNotEmpty()) {
            val timeout = window.setTimeout({
                setNotificationMessages(emptyList())
            }, (1.2 * duration).toInt())
            setClearCallback(timeout)
        }
        cleanup {
            if (clearCallback != null) {
                window.clearTimeout(clearCallback)
                setClearCallback(null)
            }
        }
    }
    useEffect(notificationMessages, props.actions) {
        if (hideCallback != null) {
            window.clearTimeout(hideCallback)
            setHideCallback(null)
        }
        if (notificationMessages.isNotEmpty() && props.actions?.isEmpty() == true) {
            val timeout = window.setTimeout({
                setShow(false)
            }, 5000)
            setHideCallback(timeout)
        }
        cleanup {
            if (hideCallback != null) {
                window.clearTimeout(hideCallback)
                setHideCallback(null)
            }
        }
    }

    TransitionComponent {
        `in` = show
        timeout = timeout(duration)
        children = { state: String ->
            div.create {
                children
                css(ClassName("theme-light")) {
                    transitionProperty = "opacity, transform".unsafeCast<TransitionProperty>()
                    transitionDuration = duration.ms
                    transitionTimingFunction = TransitionTimingFunction.easeInOut
                    opacity = number(0.0)
                    when (state) {
                        "entering" -> {
                            transform = translatey((-160).px)
                            opacity = number(1.0)
                        }

                        "entered" -> {
                            transform = translatey(0.px)
                            opacity = number(1.0)
                        }

                        "exiting" -> {
                            transform = translatey((-160).px)
                            opacity = number(0.0)
                        }

                        "exited" -> {
                            transform = translatey((-160).px)
                            opacity = number(0.0)
                        }
                    }
                    backgroundColor = Colors.surfaceContainerLow
                    borderRadius = 8.px
                    border = Border(
                        width = 1.px,
                        style = LineStyle.solid,
                        color = Colors.surfaceDim
                    )
                    margin = Margin(horizontal = 16.px, vertical = 16.px)
                    overflow = Overflow.hidden
                }

                div {
                    css {
                        padding = Padding(horizontal = 16.px, vertical = 8.px)
                    }
                    val character = notificationMessages.firstOrNull()?.character
                    character?.let {
                        if (character is ImageCharacter) {
                            img {
                                src = character.img
                                css {
                                    width = 48.px
                                    height = 48.px
                                    float = Float.right
                                    borderRadius = 24.px
                                }
                            }
                        }
                        strong {
                            css {
                                display = Display.block
                                padding = Padding(top = 0.px, bottom = 4.px, horizontal = 0.px)
                            }
                            +character.name
                        }
                    }
                    ul {
                        css {
                            padding = Padding(horizontal = 0.px, vertical = 0.px)
                            margin = Margin(horizontal = 0.px, vertical = 0.px)
                            listStyleType = None.none
                        }
                        notificationMessages.forEach {
                            li {
                                css {
                                    margin = Margin(horizontal = 0.px, vertical = 2.px)
                                }
                                +it.content
                            }
                        }
                    }
                }
                props.actions?.let { actions ->
                    ul {
                        css {
                            padding = Padding(horizontal = 0.px, vertical = 0.px)
                            margin = Margin(horizontal = 0.px, vertical = 0.px)
                            listStyleType = None.none
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            backgroundColor = Colors.surfaceContainerLow
                            color = Colors.onSurface
                        }
                        actions.forEachIndexed { index, action ->
                            li {
                                onClick = {
                                    props.onAction(index)
                                    setShow(false)
                                }
                                css {
                                    backgroundColor = NamedColor.transparent
                                    margin = Margin(vertical = 4.px, horizontal = 16.px)
                                    padding = Padding(vertical = 4.px, horizontal = 16.px)
                                    border = Border(
                                        width = 1.px,
                                        style = LineStyle.solid,
                                        color = Colors.outline
                                    )
                                    borderRadius = 4.px
                                    textAlign = TextAlign.center
                                    cursor = Cursor.pointer
                                    hover {
                                        backgroundColor = Colors.primaryContainer
                                    }
                                }
                                +action
                            }
                        }
                    }
                }
            }
        }
    }
}
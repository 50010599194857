import csstype.*
import emotion.react.css
import xyz.lacunae.itsadate.GenderedCharacter
import react.Props
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul

external interface DatingCharacterCardProps : Props {
    var character: GenderedCharacter
}

val DatingCharacterCardComponent = FC<DatingCharacterCardProps>("DatingCharacterCardComponent") { props ->
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            alignItems = AlignItems.center
            textAlign = Globals.initial
        }
        if (props.character.img != null) {
            img {
                alt = props.character.name
                src = props.character.img
                css {
                    width = 64.px
                    height = 64.px
                    borderRadius = 32.px
                    margin = Margin(vertical = 8.px, horizontal = 0.px)
                }
            }
        } else {
            div {
                div {
                    css {
                        display = Display.flex
                        width = 64.px
                        height = 64.px
                        borderRadius = 32.px
                        backgroundColor = Colors.secondaryContainer
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        fontWeight = FontWeight.bolder
                    }
                    span {
                        +props.character.name.first().toString()
                    }
                }
            }
        }
        div {
            css {
                marginLeft = 16.px
            }
            span {
                css {
                    fontWeight = FontWeight.bolder
                    fontSize = FontSize.subhead
                }
                +props.character.name
            }
            val values = props.character.info.let {
                mapOf(
                    "Ville d'origine" to it.cityOfBirth,
                    "Livre favori" to it.book,
                    "Série favorite" to it.serie,
                    "Film favori" to it.movie,
                    "Animaux" to it.pets,
                    "Sport" to it.sport,
                    "Hobbies" to it.hobbies,
                    "Travail" to it.job,
                ).filterValues { value ->
                    value != null
                }
            }
            if (values.isNotEmpty()) {
                ul {
                    css {
                        listStyleType = None.none
                        margin = Margin(0.px, 0.px)
                        padding = Padding(vertical = 4.px, horizontal = 0.px)
                    }
                    values.forEach {
                        li {
                            +"${it.key} : ${it.value}"
                        }
                    }
                }
            }
        }
    }
}

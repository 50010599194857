import xyz.lacunae.story.Character
import csstype.*
import emotion.react.css
import react.Props
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.svg.ReactSVG
import react.dom.svg.ReactSVG.path

external interface BadgeMessageProps : Props {
    var given: Boolean
    var character: Character
}

val BadgeMessageComponent = FC<BadgeMessageProps>("BadgeMessageComponent") { props ->

    div {
        css {
            width = 60.pct
            margin = Margin(vertical = 10.px, horizontal = Auto.auto)
            padding = Padding(vertical = 4.px, horizontal = 4.px)
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            borderRadius = 20.px
            backgroundColor = Colors.surface
        }

        ReactSVG.svg {
            css {
                color = Colors.onSurface
            }
            height = 64.0
            width = 64.0
            viewBox = "0 0 24 24"
            if (props.given) {
                path {
                    fill = "currentColor"
                    d =
                        "M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M10.94,15.54L7.4,12l1.41-1.41l2.12,2.12 l4.24-4.24l1.41,1.41L10.94,15.54z"
                }
            } else {
                path {
                    fill = "currentColor"
                    d =
                        "M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M15.5,14.09l-1.41,1.41L12,13.42L9.91,15.5 L8.5,14.09L10.59,12L8.5,9.91L9.91,8.5L12,10.59l2.09-2.09l1.41,1.41L13.42,12L15.5,14.09z"
                }
            }
        }
        ReactHTML.span {
            css {
                margin = Margin(vertical = 4.px, horizontal = Auto.auto)
                textAlign = TextAlign.center
                color = Colors.onSurface
            }
            if (props.given) {
                +"${props.character.name} vous offre UN BADGE"
                br { }
                +"super ! 😍"
            } else {
                +"${props.character.name} ne vous offre pas de BADGE"
                br { }
                +"dommage. 😔"
            }
        }
    }
}
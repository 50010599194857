import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.strong
import react.useState

enum class Mod {
    DARK, LIGHT
}

external interface EndAppProps : Props {
    var title: String
    var lines: List<String>
    var end: Next?
    var onEnd: () -> Unit
    var mod: Mod
}

val EndAppComponent = FC<EndAppProps>("EndAppComponent") { props ->

    var newsletter by useState<Boolean>(false)

    if (newsletter) {
        div {
            css(ClassName(if(props.mod == Mod.LIGHT) "theme-light" else "theme-dark")) {
                width = 100.pct
                height = 100.pct
                display = Display.grid
                gridTemplateRows = array(1.fr, Auto.auto)
                gridTemplateColumns = 100.pct
                overflow = Overflow.hidden
                backgroundColor = Colors.surfaceContainerLowest
                backdropFilter = blur(7.px)
                fontFamily = string("'Courier New', monospace")
                colorScheme = if (props.mod == Mod.LIGHT) ColorScheme.light else ColorScheme.dark
                color = Colors.onSurface
            }
            NewsLetterComponent {}
            UserNextComponent {
                label = "Retour"
                onNext = { newsletter = false }
            }
        }
    } else {
        div {
            css(ClassName(if(props.mod == Mod.LIGHT) "theme-light" else "theme-dark")) {
                width = 100.pct
                height = 100.pct
                display = Display.grid
                gridTemplateRows = array(Auto.auto, 1.fr, Auto.auto)
                gridTemplateColumns = 100.pct
                backgroundColor = Colors.surfaceContainerLowest
                backdropFilter = blur(7.px)
                fontFamily = string("'Courier New', monospace")
                colorScheme = if (props.mod == Mod.LIGHT) ColorScheme.light else ColorScheme.dark
                color = Colors.onSurface
            }
            h1 {
                css {
                    textAlign = TextAlign.center
                    margin = Margin(horizontal = 0.px, vertical = 32.px)
                }
                +props.title
            }
            div {
                css {
                    overflowY = Auto.auto
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    fontSize = FontSize.body
                }
                props.lines.forEach {
                    div {
                        css {
                            padding = Padding(vertical = 2.px, horizontal = 32.px)
                        }
                        +it
                    }
                }
                br {}
                div {
                    css {
                        padding = Padding(vertical = 2.px, horizontal = 32.px)
                    }
                    +"Restons en contact : Inscrivez vous à notre newsletter pour être au courant de toutes les nouvelles histoires autour d'"
                    strong {
                        +"It's a date"
                    }
                    +"."
                    div {
                        css {
                            textAlign = TextAlign.end
                        }
                        br {}
                        a {
                            css {
                                textDecoration = TextDecoration.underline
                                cursor = Cursor.pointer
                            }
                            onClick = {
                                newsletter = true
                            }
                            +"M'abonnez à la newsletter"
                        }
                    }
                }
            }
            NextComponent {
                next = props.end
                onNext = props.onEnd
            }
        }
    }
}

package xyz.lacunae.itsadate

import xyz.lacunae.story.*

class CodeGameBuilder(private val value: Int, private val content: String?) :
    LineBuilder {
    override fun build(): Line {
        return CustomLine(
            "code-game", CodeGameData(
                value,
                content
            )
        )
    }
}

data class CodeGameData(
    val value: Int, val content: String?
)

fun SceneBuilder.gameState(value: Int,  content: String?=null) {
    lines = lines + CodeGameBuilder(value, content)
}
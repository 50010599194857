package xyz.lacunae.itsadate

import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.narrator

object Chapter7 {
    private const val name = "chapter-7"
    const val intro = "$name/intro"
    const val rencontre = "$name/rencontre"
    const val end6 = "$name/end-6"
    const val confidence = "$name/confidence"
    const val failuresExplanation = "$name/failures-explanation"
    const val dating = "$name/dating"
    const val datingNikolaMars = "$name/dating-nikola-mars"
    const val withCode = "$name/with-code"
    const val goodbye = "$name/goodbye"
    const val end7 = "$name/end-7"
    const val watchingGame = "$name/watching-game"
    const val game = "$name/game"
    const val killTablet = "$name/kill-tablet"
    const val hackTablet = "$name/hack-tablet"
    const val end8 = "$name/end-8"
    internal const val en_avance = "$name/fragment/early"
    internal const val en_retard = "$name/fragment/late"
    internal const val a_l_heure = "$name/fragment/on-time"
    internal const val verite_coban = "$name/fragment/coban-truth"
    internal const val soutirer_information = "$name/fragment/find-information"
    internal const val resultats = "$name/fragment/results"
    internal const val hackSuccess = "$name/fragment/hack-Success"
}

internal fun Story.chapter7() {

    scene(Chapter7.intro, IntroContext("Chapitre 7")) {
        narrator {
            -"C’est le jour J : aujourd’hui vous rencontrez ${reporter.name}."
            -"Les rebondissements de ces derniers jours ont de quoi vous rendre ${
                player.withGender(
                    "nerveux",
                    "nerveuse"
                )
            }."
            -"${reporter.name} vous a donné rendez-vous près d’un lac très fréquenté, ce qui est plutôt rassurant."
            -"La foule sera un atout et dissuadera quiconque de s’en prendre à vous ; Vous êtes en sécurité… pour l’instant."
            -"Pour résumer la situation, deux camps s’affrontent et deux choix s’offrent à vous : aider ${coban.name} à arrêter ${elea.name} et ${reporter.name}."
            -"Ou bien aider ${reporter.name} à retrouver ${elea.name}, tout en déjouant les plans de ${coban.name}."
            -"Choix cornélien… Qui croire ? A qui faire confiance ?"
            -"À vous de voir."
            -"Une seule certitude : restez sur vos gardes."
        }
        redirect(Chapter7.rencontre)
    }

    fragment(Chapter7.en_avance) {
        narrator {
            -"Vous scrutez les alentours, à la recherche d’éventuels hommes de main venus vous arrêter."
            -"La moindre personne croisant votre chemin est suspecte à vos yeux…"
            -"Ce vieil homme en train de lire son journal sur un banc."
            -"Il vous regarde bizarrement depuis tout à l’heure."
            -"Et ces jeunes avec leur drone; ne sont-ils pas là pour vous surveiller ?"
            -"Ce couple, ce jogger, cette femme et son chien, et ce…"
            -"Et eux, là-bas !"
            -"C’est une arme qu’ils dissimulent ?"
            -"Non."
            -"Calmez-vous."
            -"Soufflez un coup."
            -"N’imaginez pas le pire."
            -"Une voix vous fait sursauter."
        }
        reporter {
            -"${player.name} ?"
        }
        narrator {
            -"Vous vous retournez brusquement, ${
                reporter.withGender("persuadé", "persuadée")
            } ${coban.name} de faire face à ${coban.name} et ses sbires."
            -"Non, ça n’est que ${reporter.name}, comme prévu."
            -"${reporter.withGender("Il", "Elle")} vous fait une bonne impression."
            -"Son regard profond est rassurant."
            -"Sa voix assurée vous inspire confiance."
        }
        reporter {
            -"Ça va ?"
            -"Ça fait longtemps que tu attends ?"
        }
        choose {
            "J’ai préféré arriver en avance." {
                reporter {
                    -"J’espère ne pas t’avoir trop fait attendre alors."
                    -"J’aime les gens ponctuels."
                }
            }
            "Je viens juste d’arriver." {
                reporter {
                    -"OK, parfait."
                    -"Je n’aurais pas aimé te faire attendre"
                }

            }
        }
    }

    fragment(Chapter7.en_retard) {
        narrator {
            -"${reporter.name} vous attend à l’endroit convenu :"
            -"près d’une petite maisonnette louant des barques aux touristes désireux de passer un moment romantique sur le lac."
            -reporter.withGender("Il semble nerveux…", "Elle semble nerveuse…")
            -"Qu’est-ce qui lui arrive ? On n’est pas à 5 minutes près, ${
                reporter.withGender("il", "elle")
            } pourrait se détendre un peu !"
            -"Déjà que cette affaire ne vous concerne en rien, ${
                reporter.withGender("il", "elle")
            } devrait être ${
                reporter.withGender("content", "contente")
            } que vous soyez là."
            -"Dès qu’${
                reporter.withGender("il", "elle")
            } vous voit, un sourire soulagé détend un peu ses traits."
            -"${
                reporter.withGender("Il", "Elle")
            } est plutôt ${reporter.withGender("mignon", "mignonne")}…"
        }
        reporter {
            -"${player.name} !"
            -"Tu es en retard !"
        }
        choose {
            "Ouais, ouais, je sais." {
                reporter {
                    -"Et tu t’en fous en plus ?"
                    -"Ok, ça commence bien…"
                    -"Sois ${player.withGender("ponctuel", "ponctuelle")} la prochaine fois."
                    -"On n’est pas là pour déconner."
                }
                choose {
                    -"Tu as raison."
                }
                choose {
                    -"C’était une mauvaise décision de ma part."
                }
                choose {
                    -"Je suis ${player.withGender("désolé", "désolée")}."
                }
                reporter {
                    -"Bon…"
                    -"Pfiou…"
                    -"${reporter.withGender("Désolé", "Désolée")}, je suis un peu sur les nerfs."
                    -"Oublions ça."
                }
            }
            "Excuse-moi…" {
                reporter {
                    -"C’est rien, ça arrive."
                    -"Je me faisais juste un peu de soucis…"
                    -"On a bien assez d’une disparition à gérer."
                }
            }
        }
    }

    fragment(Chapter7.a_l_heure) {
        narrator {
            -"Vous arrivez à l’endroit convenu : une petite maisonnette louant des barques aux touristes désireux de passer un moment romantique sur le lac."
            -"${reporter.withGender("Un jeune homme", "Une jeune fille")} arrive en même temps que vous."
            -"Pas d’erreur possible, il s’agit de ${reporter.name}."
            -"Wow, ${reporter.withGender("il", "elle")} est plutôt ${reporter.withGender("mignon", "mignonne")} !"
        }
        reporter {
            -"Salut ${player.name}"
            -"On arrive en même temps…"
        }
        choose {
            -"Normal, on est tous les deux à l’heure."
        }
        reporter {
            -"Haha, tu as raison"
        }
    }

    fragment(Chapter7.verite_coban) {
        choose {
            -"Écoute ${reporter.name}"
        }
        choose {
            -"Je te dois la vérité."
        }
        choose {
            -"${coban.name} m’a contacté, et m’a proposé de travailler pour lui."
        }
        choose {
            -"Il veut à tout prix vous retrouver, ${elea.name} et toi."
        }
        choose {
            -"Il bosse pour de grosses entreprises privées, qui veulent récupérer toutes les informations que tu aurais sur ${elea.name}"
        }
        choose {
            -"Je pense qu’il est dangereux, et que tu devrais te méfier de lui."
        }
        reporter {
            -"…"
            -"Merci ${player.name}."
            -"En me disant ça, tu me prouves que je peux avoir confiance en toi."
            -"J’étais ${reporter.withGender("sûr", "sûre")} qu’il chercherait à te contacter."
            -"Mais je ne pensais pas qu’il irait aussi loin…"
        }
        choose {
            "Il devait bien se douter que tu tenterais de prendre contact avec moi." {
                reporter {
                    -"Oui, c’est logique…"
                    -"Dieu seul sait de quoi il est capable."
                    -"Bordel, dans quelle affaire on s’est fourrés, tous les deux ?"
                }
            }
            "Tu penses qu’il dit la vérité ?" {
                reporter {
                    -"Je vais t’avouer un truc :"
                    -"Avant qu’on se voit, j’ai enquêté sur lui."
                    -"Je n’ai pas réussi à trouver son vrai nom, mais un indic m’a confirmé son rôle."
                    -"Il est prêt à tout pour nous arrêter."
                    -"Il me fait peur…"
                }
            }
        }
        reporter {
            -"Merci de m’avoir dit la vérité."
            -"Et vu que tu as joué franc-jeu avec moi, nous voilà dans la même galère."
            -"Je vais donc tout te raconter."
            -"Tu veux la version longue ou la version courte ?"
        }
        choose {
            "La version courte" {
                reporter {
                    -"Très bien, je vais abréger."
                    -"Tu le sais déjà, ${elea.name} et moi sommes des ${
                        elea.withGender("amis", "amies")
                    } d’enfance."
                    -"Son père était un inventeur de talent."
                    -"Un jour, il s’est fait virer de son boulot."
                    -"Et il a déménagé, emmenant bien sûr sa famille avec lui."
                    -"Et je n’ai plus jamais eu de nouvelles de ${elea.name}."
                    -"Jusqu’à tout récemment."
                }
                choose {
                    -"ok"
                    -"Tout ça, je le sais déjà."
                }
            }
            "La version longue" {
                reporter {
                    -"Je vais essayer de pas faire trop long."
                    -"Tu le sais déjà, ${elea.name} et moi sommes des ${
                        elea.withGender("amis", "amies")
                    } d’enfance."
                    -"Son père était un inventeur de talent."
                    -"Je me rappelle de son atelier, dans le sous-sol de leur maison."
                    -"Pour les enfants que nous étions, c’était un vrai trésor :"
                    -"outils, inventions ratées, matériaux divers et variés…"
                    -"Avec ${elea.name}, on passait des heures à jouer là-dedans…"
                    -"A construire et à déconstruire de vieux trucs,"
                    -"A tenter d’inventer nous-même quelque chose à partir d’une vieille radio cassée."
                    -"Qu’est-ce qu’on pouvait se marrer…"
                }
                choose {
                    -"Ça me rappelle mon enfance. J’adore bricoler."
                    -"Tu m’impressionnes. Je ne fais pas la différence entre un marteau et un tournevis."
                }
                reporter {
                    -"Haha, c’était juste des jeux de gosses."
                    -"On n’a jamais construit quelque chose de fonctionnel."
                    -"Mais c’était amusant…"
                    -"C’était autre chose que les jeux mobiles un peu débiles qu’ont les gosses d’aujourd’hui."
                }
                choose {
                    -"HAHA, tu n’as pas tort."
                }
                choose {
                    -"Et ensuite ?"
                }
                reporter {
                    -"Le père de ${elea.name} travaillait pour MAGTAL, une société internationale spécialisée dans le secteur de l’énergie."
                    -"Il était à la tête du secteur recherche et développement."
                    -"Un jour, sans que j’en connaisse les détails, il s’est fait virer."
                    -"C’était assez soudain, et inattendu."
                    -"Et quelques semaines plus tard, ${elea.name} et sa famille ont déménagé."
                }
                choose {
                    -"Tu crois qu’ils ont fui quelque chose, ou quelqu’un ?"
                }
                reporter {
                    -"Peut-être… Je n’en sais rien…"
                    -"Je n’ai plus eu de nouvelles de mon ${elea.withGender("ami", "amie")}."
                    -"Jusqu’à tout récemment."
                    -"En enquêtant sur le passé de ${elea.name}, j’ai appris que son père est mort, un an seulement après leur déménagement."
                    -"Officiellement, à cause d’un accident de la route."
                    -"Il se serait endormi au volant…"
                }
                choose {
                    -"Tu dis « officiellement »… Tu ne crois pas à l’accident ?"
                    -"Ce sont des choses qui arrivent, malheureusement…"
                }
                reporter {
                    -"C’était peut-être vraiment un accident. Mais…"
                    -"Après ce qui vient d'arriver à ${elea.name}, je commence à douter."
                    -"Et à me dire que sa disparition est peut-être liée à la mort de son père."
                }
                choose {
                    -"Qu’est-ce qui te fait dire ça ?"
                    -"Tu n’as pas de preuve."
                    -"L’écouter parler."
                }
            }
        }
    }

    fragment(Chapter7.soutirer_information) {
        reporter {
            -"Encore merci d’être venu, ${player.name}."
            -"Je pense vraiment que tu pourrais m’aider."
            -"J’ai une piste qui pourrait nous mener à ${elea.name}."
            -"Une piste très mince."
            -"Mais tu es ${player.withGender("le seul", "la seule")} à pouvoir m’aider !"

        }
        choose {
            -"Tu fais erreur sur la personne."
            -"Je suis ${player.withGender("l’homme", "la femme")} de la situation."
        }
        reporter {
            -"Ne sois pas si ${player.withGender("affirmatif", "affirmative")} ."
            -"Si je souhaitais à tout prix te rencontrer…"
            -"C'est parce que j’ai besoin de toi pour… "
            -"Craquer un code !"
        }
        choose {
            "Euh, je ne comprends pas…" {}
            "Tu fais erreur, je n’y connais rien en hacking." {}
            "Tu peux m’appeler Hackerman !" {
                reporter {
                    -"HAHA !"
                    -"Ok, ça marche « Hackerman »."
                    -"…"
                    -"Non, je préfère t’appeler ${player.name} en fait."
                    -"Pour commencer…"
                }
            }
        }
    }

    fragment(Chapter7.resultats) {
        reporter {
            -"Voilà le résultat de mes recherches"
            -"Peu de temps avant sa mort « accidentelle », le père de ${elea.name} aurait inventé une sorte de moteur d’un genre nouveau."
            -"Ce serait un engin soi-disant révolutionnaire"
            -"Capable de s’auto-alimenter en énergie."
            -"Je t’avoue que j’ai un peu de mal à comprendre tout ça…"
        }
        choose {
            "Ah pas compris non plus !" {
                reporter {
                    -"Ça n’est pas le plus important."
                    -"Ce qui est sûr, c’est que ce truc est lié à la disparition de ${elea.name}"
                }
            }
            "J’ai compris ce que c’est !" {
                reporter {
                    -"Ah oui ?"
                    -"Génial, je savais que je pouvais compter sur toi."
                    -"C’est quoi à ton avis ?"
                }
                choose {
                    "Une porte des étoiles" {
                        reporter {
                            -"ok…"
                            -"…"
                            -"C’est une blague ?"
                        }
                        choose {
                            "Oui. Désolé." {
                                reporter {
                                    -"Pfff, t’es bête."
                                    -"Bah, ça fait du bien de se détendre un peu."
                                    -"Bon passons aux choses sérieuses…"
                                    -"J’avais une bonne raison de te demander si tu savais ce que peut être cette machine."
                                }
                            }
                            "Non, ce serait super qu’il ait inventé ${"une porte des étoiles"}." {
                                reporter {
                                    -"… Ok"
                                    -"On part de loin avec toi."
                                    -"J’avais une bonne raison de te demander si tu savais ce que peut être cette machine."
                                }
                            }
                        }
                    }
                    "La machine de Tesla" {
                        reporter {
                            -"Exactement !"
                            -"Tu sembles t’y connaître, me voilà ${reporter.withGender("rassuré", "rassurée")}."
                        }
                    }
                    "Un convecteur temporel" {
                        reporter {
                            -"ok…"
                            -"…"
                            -"C’est une blague ?"
                        }
                        choose {
                            "Oui. Désolé." {
                                reporter {
                                    -"Pfff, t’es bête."
                                    -"Bah, ça fait du bien de se détendre un peu."
                                    -"Bon passons aux choses sérieuses…"
                                    -"J’avais une bonne raison de te demander si tu savais ce que peut être cette machine."
                                }
                            }
                            "Non, ce serait super qu’il ait inventé ${"un convecteur temporel"}." {
                                reporter {
                                    -"… Ok"
                                    -"On part de loin avec toi."
                                    -"J’avais une bonne raison de te demander si tu savais ce que peut être cette machine."
                                }
                            }
                        }
                    }
                }
            }
        }
        reporter {
            -"Je te testais, pour voir si tu pourrais vraiment m’aider."
            -"C’est bien d’une machine à énergie libre comme celle de Nikola Tesla dont on parle."
            -"Le père de ${elea.name} serait parvenu à en mettre une au point."
            -"Je vois un parallèle entre le destin de Tesla, qui a fini sa vie ruiné et dénigré par ses contemporains,"
            -"et celui du père de ${elea.name} qui a fini dans des conditions similaires."
        }
        choose {
            -"Deux génies morts pauvres et oubliés de tous…"
        }
        reporter {
            -"C’est pas gai tout ça."
            -"Et je commence à croire qu’on est VRAIMENT en danger."
            -"Si on parle bien d’une machine de ce type, je comprends pourquoi ${elea.name} se sentait ${
                elea.withGender("menacé", "menacée")
            }…"
            -"Une machine capable de produire de l’énergie en quantité quasi-illimitée."
            -"Si ça existe vraiment…"
            -"Les conséquences d’une telle invention pourraient être terribles."
        }
        choose {
            "Oui, je sais : ${elea.name} m’en a parlé" {}
            "Pourquoi terrible ?" {
                reporter {
                    -"On est une société dont le système capitaliste repose sur la croissance, la consommation et le renouvellement de produits et de biens."
                    -"Si quelqu’un met au point une machine qui marche à vie, ce système devient bancal"
                    -"Un système plus égalitaire, moins concurrentiel, dans lequel de riches entreprises du secteur de l’énergie n’auraient plus vraiment de raison d’exister…"
                    -"Tu imagines les conséquences ? "
                }
                choose {
                    -"Ce serait une véritable révolution énergétique"
                    -"Ça pourrait changer la face du monde."
                }
                reporter {
                    -"Entre autres oui…"
                    -"Des milliards d’euros dans tous les secteurs qui passeraient de la poche de l’actionnaire à la poche du consommateur."
                    -"Et notre économie capitaliste pourrait ne pas s’en remettre…"
                    -"Et puis avec de l'énergie illimitée… on pourrait créer des armes absolument terrifiantes !"
                    -"A côté de ça, la bombe atomique serait de la rigolade."
                    -"La fin du monde pourrait arriver plus vite qu'on ne le croit !"
                    -"Enfin ça, c'est le pire scénario possible."
                }
            }
        }
        choose {
            -"Tu ne penses pas que ça ne changerait rien ?"
        }
        reporter {
            -"Peut-être que ça ne changerait rien… On ne peut pas le savoir."
            -"On peut par contre imaginer que de nombreuses personnes mal intentionnées ont tout intérêt à s’emparer de cette technologie."
            -"Le premier à avoir un tel procédé entre les mains pourrait aisément devenir plus riche que n’importe qui."
            -"Un tel pouvoir entre les mains d’une mauvaise personne…"
            -"Brrrr quelle horreur !"
            -"De nombreuses personnes seraient prêtes à tout pour s’accaparer un tel savoir."
            -"Même au pire…"
        }
        choose {
            "Sûrement oui…" {
                reporter {
                    -"${player.name}, nous voilà à un tournant."
                    -"Cette affaire est dangereuse."
                    -"Les hommes de l’ombre qui veulent récupérer les informations en ma possession sont prêts à tout."
                    -"J’ai… peur de ce qui pourrait arriver."
                    -"Je dois pouvoir te faire confiance à 100%."
                    -"Est-ce que je peux compter sur toi ?"
                }
                choose {
                    "Oui, bien sûr." {
                        reporter {
                            -"OK, très bien."
                            -"J’espère que je n’aurais pas à le regretter."
                            -"Bon, c’est parti, voilà comment tu peux m’aider !"
                        }
                    }
                    "J’ai quelque chose à t’avouer." {
                        choose {
                            -"Écoute ${reporter.name}"
                        }
                        choose {
                            -"Je te dois la vérité."
                        }
                        choose {
                            -"${coban.name} m’a contacté, et m’a proposé de travailler pour lui."
                        }
                        choose {
                            -"Il était menaçant, et je ne savais pas quoi faire…"
                        }
                        choose {
                            -"Il veut que je récupère des infos sur toi, et sur ${elea.name}."
                        }
                        reporter {
                            -"Wow…"
                            -"Je ne sais pas quoi dire…"
                            -"Je… Merci de m’avoir dit la vérité."
                            -"Cette situation peut nous servir."
                            -"Tu peux jouer le rôle de l’agent double."
                            -"Tu envoies ${coban.name} sur de fausses pistes."
                            -"Pendant que de notre côté on tente de résoudre cette affaire."
                            -"Surtout, NE LE PROVOQUE PAS !"
                            -"C’est primordial de ne pas tomber dans son jeu."
                            -"Tu as compris ?"
                        }
                        choose {
                            -"Compte sur moi, je ne le provoquerai pas."
                            -"Je ferai de mon mieux."
                        }
                        reporter {
                            -"C’est lui faire perdre du temps qui nous sauvera."
                            -"La provocation le pousserait sûrement à bout, et on serait dans la mouise…"
                        }
                    }
                }
                redirect(Chapter7.confidence)
            }
            conditional("${coban.name}… ", name = "notLyingToReporter" , condition = { !game.lyingToReporter }) {
                reporter {
                    -"Oui, ${coban.name}…"
                    -"Il est sûrement très dangereux lui."
                    -"Et quand je dis lui, je pense aussi à ses patrons,"
                    -"Et à tous ces gens qui complotent pour préserver leurs intérêts."
                    -"${player.name}, je vais être ${reporter.withGender("franc", "franche")} avec toi…"
                    -"En continuant à nous aider, ${elea.name} et moi, tu prends des risques."
                    -"De gros risques."
                    -"Je comprendrai que tu veuilles renoncer."
                    -"Tu peux tout arrêter, maintenant."
                    -"Ou tu peux choisir de m’aider à résoudre cette affaire, et à retrouver ${elea.name}."
                    -"Dans ce cas, on doit pouvoir se faire confiance à 100%, quels que soient les risques."
                    -"Quelle est ta décision ?"
                }
                choose {
                    "Je suis de ton côté, continuons !" {
                        reporter {
                            -"Merci ${player.name} !"
                            -"Ensemble, nous pouvons nous en sortir."
                            -"Je crois bien que sans toi, je serais incapable de résoudre cette histoire."
                            -"Récapitulons."
                            -"${coban.name} est à nos trousses."
                            -"Mais il ne sait pas que je suis au courant…"
                            -"Nous devons nous servir de ça."
                        }
                        choose {
                            -"Comment faire ?"
                        }
                        reporter {
                            -"Abreuve-le de fausses infos."
                            -"Comme ça il ne se doutera pas qu’on avance de notre côté."
                            -"Surtout, NE LE PROVOQUE PAS !"
                            -"Tu as compris ?"
                        }
                        choose {
                            -"Compte sur moi, je ne le provoquerai pas."
                            -"Je ferai de mon mieux."
                        }
                        reporter {
                            -"C’est lui faire perdre du temps qui nous sauvera."
                            -"La provocation le pousserait sûrement à bout, et on serait dans la mouise…"
                            -"Concernant la suite…"
                        }
                        redirect(Chapter7.confidence)
                    }
                    "Je préfère m’arrêter là." {
                        reporter {
                            -"…"
                            -"Je comprends…"
                            -"Je t’avoue que je suis ${
                                reporter.withGender("déçu", "déçue")
                            }, mais  c’est vrai que tu n’as aucune raison de nous aider."
                            -"Tu nous connais à peine, ${elea.name} et moi."
                            -"Tu as ta vie, et tu n’as aucune raison de te mettre en danger pour nous."
                            -"Bon, je n’aime pas les adieux."
                            -"Faisons ça vite."
                            -"Au revoir ${player.name}."
                        }
                        redirect(Chapter7.end6, GoToScene.Option.Timeout(10_000))
                    }
                }
            }
        }
    }

    scene(Chapter7.rencontre, ParkContext) {
        characters(reporter)

        choose {
            silent("En avance") { include(Chapter7.en_avance) }
            silent("En retard") { include(Chapter7.en_retard) }
            silent("à l’heure") { include(Chapter7.a_l_heure) }
        }

        reporter {
            -"Marchons un peu, tu veux bien ?"
            -"Ça nous évitera d’attirer les oreilles indiscrètes"
        }
        narrator {
            -"Quel endroit calme et apaisant !"
            -"Les chants des canards et des cygnes se mêlent aux cris des enfants s’amusant à les nourrir."
            -"Le soleil brille, un vent frais vous balaie le visage…"
            -"Cette journée semble parfaite."
            -"Mais ce cadre idyllique masque à grand peine un tourment qui vous déchire : qu’allez-vous faire avec ${reporter.name} ?"
            -"Lui dire la vérité à propos du marché proposé par ${coban.name} ?"
            -"Ou lui cacher cette discussion afin de lui soutirer un maximum d’informations ?"
            -"Vous voilà face à un choix qui ne sera pas sans conséquence…"
        }
        choose {
            silent("Lui dire la vérité sur ${coban.name}") {
                stateMachine.game.lyingToReporter = false
                include(Chapter7.verite_coban)
            }
            silent("Ne rien lui dire et lui soutirer des informations") {
                stateMachine.game.lyingToReporter = true
                include(Chapter7.soutirer_information)
            }
        }
        include(Chapter7.resultats)
    }

    scene(Chapter7.end6, EndContext("Fin 6")) {
        stateMachine.achievements.end6 = true
        narrator {
            -"${reporter.name} s’en va sans un mot de plus."
            -"${player.withGender("Il", "Elle")} semble triste, et terriblement ${player.withGender("seul", "seule")}."
            -"Va-t-${player.withGender("il", "elle")} réussir dans sa quête ?"
            -"Ou bien les hommes de ${coban.name} vont-ils finir par l’arrêter ?"
            -"Vous n’aurez plus jamais de ses nouvelles, et ne saurez jamais le fin mot de cette histoire."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 6."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }


    scene(Chapter7.confidence, ParkContext) {
        characters(reporter)
        reporter {
            -"Notre seule piste aujourd’hui, c’est la tablette tactile que ${elea.name} m’a confiée."
            -"Mis à part quelques applis lambdas, elle ne contient rien d’intéressant."
            -"Enfin, presque rien."
            -"J'ai ce fichier crypté, verrouillé par un code."
            -"Regarde :"
        }
        redirect(Chapter7.watchingGame, GoToScene.Option.Timeout(5_000))
    }

    scene(Chapter7.failuresExplanation, ParkContext) {
        characters(reporter)
        reporter {
            -"Hum… euh…"
            -"C’est trop tentant, aussi !"
            -"Un fichier avec un code…"
            -"Bien sûr que j’en ai essayé un."
            -"Malheureusement, ça n’était pas le bon."
        }
        choose {
            -"Je ne te félicite pas…"
            -"C’est pas très pro de ta part."
        }
        reporter {
            -"Oui, bon, je sais."
            -"J’aurais dû faire gaffe."
            -"Donc pas la peine de tenter la date de naissance de ${elea.name}."
            -"C’est un autre code à 8 chiffres."
            -"C’est pour ça que je pensais à une date de naissance."
            -"${reporter.withGender("Désolé", "Désolée")} ${player.name}."
            -"On n’a plus que deux essais."
            -"Et si on se plante…"
        }
        choose {
            "BOUM" {
                reporter {
                    -"Pas un vrai « BOUM »"
                    -"Enfin, j'espère…»"
                    -"Mais je pense que ça nous met mal."
                }
            }
            "On perd la partie." {
                reporter {
                    -"Y’a de ça… On perd notre seule piste."
                }
            }
            "On reboote la tablette" {
                reporter {
                    -"Non, ça ne marcherait pas."
                    -"J’ai déjà essayé, et on est toujours à 2 essais restants."
                }
            }
        }
        reporter {
            -"On n’a pas le droit à l’erreur."
            -"Plus précisément : on n’a pas le droit à DEUX erreurs."
            -"Sinon, notre enquête est foutue."
            -"C’est là que tu interviens, ${player.name}."
            -"As-tu une idée du code ?"
        }
        choose {
            "Je crois bien oui !" {
                redirect(Chapter7.game)
            }
            "Aucune idée, non…" {
                reporter {
                    -"Ah…"
                    -"Mince, moi qui pensais pouvoir compter sur toi…"
                    -"Tu es ${player.withGender("sûr", "sûre")} que tu n’as pas une idée ?"
                    -"Rien dans tes échanges avec ${elea.name} qui pourrait nous aider ?"
                }
                choose {
                    "Ah, mais si, bien sûr !" {
                        redirect(Chapter7.game)
                    }
                    "Non, rien de rien… C’est mort !" {
                        redirect(Chapter7.goodbye)
                    }
                    "Laisse-moi vérifier un truc…" {
                        appBadge(Apps.dating)
                        redirect(Chapter7.dating)
                    }
                }
            }
        }
    }

    scene(Chapter7.dating, DatingContext) {
        characters(Characters.bot)
        Characters.bot {
            -"Vous avez 1 messages."
            -"Qui veux-tu renconter aujourd’hui"
        }
        choose {
            profile(nikolaMars) {
                redirect(Chapter7.datingNikolaMars)
            }
            "Quitter" {
                redirect(Chapter7.goodbye)
            }
        }
    }

    scene(Chapter7.datingNikolaMars, DatingContext) {
        characters(nikolaMars)
        stateMachine.achievements.nikolaMarsVisited = true
        stateMachine.game.nikolaMarsVisited = true
        nikolaMars {
            -"${player.name}, c’est moi, ${elea.name}."
            -"Je suis ${nikolaMars.withGender("désolé", "désolée")}…"
            -"Je ne voulais pas t’impliquer là-dedans."
            -"Mais je n’ai malheureusement pas eu le choix."
            -"Je n’ai que très peu de temps…"
            -"J’ai peur d’être ${nikolaMars.withGender("surveillé", "surveillée")}."
            -"Note ceci : 10071856"
            -"Tu sauras quoi en faire…"
            -"Je…"
        }
        redirect(Chapter7.withCode)
    }

    scene(Chapter7.withCode, ParkContext) {
        characters(reporter)
        stateMachine.game.codeWithReporter = true
        choose {
            -"J’ai trouvé un code : 10071856."
        }
        choose {
            -"${elea.name} m’a laissé un message sous un autre pseudo, sur CupidLov."
        }
        choose {
            -"${elea.withGender("Il", "Elle")} y a indiqué ce code."
        }

        reporter {
            -"Eh mais c’est super ça !"
            -"${elea.name} a vraiment pensé à tout."
            -"10071856 est vraisemblablement le code qui déverrouille la tablette."
            -"A ton avis, il signifie quelque chose ?"
        }
        choose {
            -"Aucune idée."
            -"C’est peut-être la date de naissance de Nikola Tesla ?"
            -"C’est sûrement un code aléatoire."
        }
        reporter {
            -"Hum, on vérifiera ça plus tard."
            -"Je te laisse taper le code."
            -"10071856. On n’a que deux essais, ne te trompe pas !"
        }

        redirect(Chapter7.game)
    }

    scene(Chapter7.goodbye, ParkContext) {
        characters(reporter)
        reporter {
            -"Ah zut, c’est trop bête !"
            -"Nous n’avons pas d’autre piste."
            -"Si tu ne peux pas m’aider là-dessus, mieux vaut que nos routes se séparent ici."
            -"Tu n’en sais pas assez pour être ${player.withGender("inquiété", "inquiétée")} par les hommes de Coban."
            -"Je vais me débrouiller ${reporter.withGender("seul", "seule")}."
            -"Merci quand même ${player.name}."
            -"J’ai été ${reporter.withGender("ravi", "ravie")} de te rencontrer."
        }
        redirect(Chapter7.end7, GoToScene.Option.Timeout(10_000))
    }

    scene(Chapter7.end7, EndContext("Fin 7")) {
        stateMachine.achievements.end7 = true
        narrator {
            -"${reporter.name} s’en va sans un mot de plus."
            -"Vous n’avez pas assez exploré les recoins de cette histoire pour pouvoir l’aider."
            -"Satané code, êtes-vous ${
                player.withGender("sûr", "sûre")
            } de ne pas savoir où le trouver ?"
            -"Peut-être qu’un autre choix vous aurait permis de connaître le fin mot de cette histoire."
            -"En retournant sur CupidLov par exemple ?"
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 7."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }

    fragment(Chapter7.hackSuccess) {
        gameState(value = -1, content = "Déverouillage fichier…")
        reporter {
            -"Bien joué ${player.name} !"
        }
        gameState(value = -1, content = "Fichier déverrouillé")
        redirect(Chapter7.hackTablet)
    }

    scene(Chapter7.watchingGame, CodeGameContext(max = 3)) {
        characters(reporter)
        gameState(value = 1)
        reporter {
            -"A la base, il y avait 3 essais possibles…"
            -"Il n’en reste plus que deux."
        }
        choose {
            -"Pourquoi n’en reste-il plus que deux ?"
            -"Tu as essayé un code ?"
        }
        redirect(Chapter7.failuresExplanation, option = GoToScene.Option.Auto)
    }

    scene(Chapter7.game, CodeGameContext(max = 3)) {
        characters(reporter)
        val rightAnswer = "10071856"
        gameState(value = 1)
        narrator {
            -"Code à taper"
        }
        answer { code ->
            if (code != rightAnswer) {
                gameState(value = 2)
                reporter {
                    -"Euh…"
                    -"T’es sûr de ce que tu fais $player ?"
                    -"Attention, on n’a plus qu’une chance de réussir !"
                    -"Ne te trompe pas à nouveau, je t’en supplie !"
                }
                narrator {
                    -"Code à taper"
                }
                answer { code ->
                    if (code != rightAnswer) {
                        gameState(value = 3)
                        reporter {
                            -"Oh non !"
                            -"C’était notre dernière chance"
                        }
                        gameState(value = 3, content = "Formatage en cours…")
                        reporter {
                            -"On va tout perdre 😭"
                        }
                        gameState(value = 3, content = "Arrêt en cours…")
                        redirect(Chapter7.killTablet, option = GoToScene.Option.Timeout(5_000))
                    } else {
                        include(Chapter7.hackSuccess)
                    }
                }
            } else {
                include(Chapter7.hackSuccess)
            }
        }
    }

    scene(Chapter7.killTablet, ParkContext) {
        characters(reporter)
        reporter {
            -"Non mais c’est pas possible !"
            -"Tu te rends compte de ce que tu as fait ?!"
            -"T’as tout gâché !"
        }
        choose {
            silent("Ne rien dire") {
                reporter {
                    -"Tout ça n’est donc qu’un jeu pour toi ?"
                    -"Comment je vais faire pour retrouver ${elea.name} ??"
                }
            }
            "Bah toi aussi! J'ai juste essayé de t'aider !" {
                reporter {
                    -"Je sais… Tu as raison."
                    -"On a voulu aller trop vite, il nous manquait des indices."
                    -"Tout est fini maintenant."
                }
            }
        }
        choose {
            -"…"
            -"Je suis ${player.withGender("désolé", "désolée")}."
        }
        reporter {
            -"Dire que j’ai voulu te faire confiance…"
            -"Pfff. Que va devenir ${elea.name} ? "
            -"Merci pour rien."
            -"Restons-en là, ça vaut mieux."
        }
        redirect(Chapter7.end8, GoToScene.Option.Timeout(10_000))
    }

    scene(Chapter7.hackTablet, ParkContext) {
        characters(reporter)
        reporter {
            -"Génial, t’as réussi !"
            -"T’es ${player.withGender("le meilleur", "la meilleure")} !"
            -"Comment as-tu eu connaissance de ce code ?"
        }
        if (!stateMachine.game.codeWithReporter) {
            choose {
                "C’est grâce à ${elea.name}" {
                    reporter {
                        -"${elea.withGender("Il", "Elle")} a vraiment tout prévu, c’est dingue…"
                        -"Bien joué ${player.name}."
                        -"Tu me diras comment tu as pris connaissance de ce code."
                    }
                    choose {
                        -"Avec plaisir !"
                        -"Euh, on en reparlera plus tard."
                    }
                }
                "Coup de chance !" {
                    reporter {
                        -"C’est ça ouais !"
                        -"Reste modeste, c’est mignon."
                        -"Merci !"
                    }
                    choose {
                        -"Avec plaisir !"
                        -"Euh, de rien…"
                    }
                }
                "Mystère…" {
                    reporter {
                        -"Oh tu pourrais me le dire quand même !"
                        -"On en parlera plus tard."
                    }
                }
            }
        }
        reporter {
            -"Revenons à notre tablette."
            -"Bon que contiennent donc ces fichiers ?"
        }
        reporter {
            photo("img_tesla_1.jpg", "dessin Nikola Tesla")
            -"Un plan"
        }
        choose {
            -"Hum"
        }
        reporter {
            photo("img_tesla_2.jpg", "bobine Tesla")
            -"Un autre plan"
        }
        choose {
            -"Effectivement"
        }
        reporter {
            photo("img_tesla_3.jpg", "graph")
            photo("img_tesla_4.jpg", "schema")
            -"Des moteurs ?!"
        }
        choose {
            -"Tu as l'oeil !"
        }
        reporter {
            -"Qu’est-ce que c’est que ce délire ?"
            -"On dirait bien qu’il s’agit d’un dossier concernant cette fameuse machine à énergie libre."
            -"On dirait des schémas, des plans…"
            -"Mais ce langage incompréhensible…"
            -"Là, et encore là ! Sur chaque document !"
            -"Regarde :"
            photo("img_encrypted_1.jpg", "Document crypté 1")
            photo("img_encrypted_2.jpg", "Document crypté 2")
            -"Du texte et un plan incompréhensibles."
            -"Et ça se répète, sur des dizaines et des dizaines de pages."
            -"Je ne comprends rien."
            -"On dirait…"
        }
        choose {
            "Du suédois !" {
                reporter {
                    -"HAHAHA !"
                    -"T’es bête $player !"
                    -"Je ne pense pas que ce soit du suédois."
                    -"Mais bonne vanne, bien joué !"
                }
            }
            silent("ne rien dire.")
            "un langage crypté." {
                reporter {
                    -"Oui, c’est tout à fait ça…"
                }
            }

        }
        reporter {
            -"On dirait bien un langage crypté."
            -"Je crois que le code que tu as trouvé n’était que la première étape."
            -"Il nous faut maintenant trouver la clé pour déchiffrer ce code."
        }
        choose {
            -"Je ne vois pas comment t’aider."
            "Essaie Google Trad !" {
                reporter {
                    -"Hum, c’est peut-être pas si idiot comme remarque."
                    -"J’essaie tout de suite."
                    -"…"
                    -"Mais !"
                    -"…"
                    -"Alors ça…"
                    -"…"
                    -"Oh, c’est dingue !"
                }
                choose {
                    -"Eh eh, je te l’avais dit. Je suis ${player.withGender("doué", "douée")}."
                }
                reporter {
                    -"Je ne dirai pas ça."
                    -"ça ne marche pas du tout !"
                    -"C’est crypté ! C’est pas une autre langue."
                    -"Google Trad ne risquait pas de marcher !"
                    -"Il faut trouver autre chose."
                }
            }
        }
        choose {
            -"Contacte un cryptologue !"
            -"Je ne vois pas quoi faire…"
        }
        reporter {
            -"Je sais !"
            -"Il faut que je vois Martin Forgeur."
            -"C’est un expert en codes et énigmes en tout genre, que j’avais interviewé pour un article."
            -"Il pourra nous aider, c’est sûr !"
            -"Je vais devoir aller lui rendre visite en région parisienne."
            -"Ne perdons pas de temps !"
            -"Encore merci ${player.name}."
            -"Sans toi, nous serions dans une impasse."
        }
        choose {
            -"C’est bosser en équipe qui nous a permis d’avancer."
            -"Pas de temps à perdre ! Fonce !"
        }
        reporter {
            -"L’union fait la force."
        }
        choose {
            "Et l’oignon fait la soupe 😂" {
                reporter {
                    -"Euh ok…"
                }
            }
            "Et que la force soit avec toi !" {
                reporter {
                    -"Merci 😅"
                }
            }
        }
        reporter {
            -"Maintenant, on a une piste pour retrouver ${elea.name} !"
            -"Bon, le prochain train n’est que dans 2h."
            -"Tu veux qu’on aille prendre un verre en attendant ?"
        }
        choose {
            "Non, j’ai à faire." {
                reporter {
                    -"Oh, je comprends !"
                    -"Je file."
                    -"Bye…"
                }
                redirect(Chapter9.intro)
            }
            "Oui, avec plaisir" {
                reporter {
                    -"Super."
                    -"Il y a un bar sympa près de la gare."
                    -"Je t’invite !"
                }
                redirect(Chapter8.intro)

            }
        }

    }

    scene(Chapter7.end8, EndContext("Fin 8")) {
        stateMachine.achievements.end8 = true
        narrator {
            -"${reporter.name} vous quitte précipitamment. Vous n’osez pas ${
                reporter.withGender("le", "la")
            } rattraper."
            -"Satané code, comment avez-vous fait pour vous tromper de la sorte ?"
            -"A cause de cette erreur stupide, vous ne saurez jamais ce qu’il est advenu de ${elea.name}, et n’aurez plus jamais de nouvelles de ${reporter.name}."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 8."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }

}
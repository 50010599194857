import xyz.lacunae.story.StateMachine
import org.w3c.dom.Storage
import org.w3c.dom.get
import org.w3c.dom.set

class LocalStorageStateMachine(private val storage: Storage) : StateMachine {

    private var observers = emptyList<(String) -> Unit>()

    override fun get(key: String): Any? {
        return storage[key]
    }

    override fun set(key: String, value: Any?) {
        if(value == null) {
            storage.removeItem(key)
        }else{
            storage[key] = value.toString()
        }
        observers.forEach { it(key) }
    }

    override fun clone(): StateMachine {
        return this
    }

    override fun clear() {
        storage.clear()
    }

    override fun addObserver(observer: (String) -> Unit) {
        observers += observer
    }

    override val all: Map<String, Any?>
        get() = (0 until storage.length).mapNotNull { storage.key(it) }.associateWith { storage.getItem(it) }
}
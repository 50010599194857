import csstype.*

@Suppress("unused")
object Colors {
    val primary = `var`(Variable<Color>("--theme-color-primary"), NamedColor.black)
    val onPrimary = `var`(Variable<Color>("--theme-color-on-primary"))
    val primaryContainer = `var`(Variable<Color>("--theme-color-primary-container"))
    val onPrimaryContainer = `var`(Variable<Color>("--theme-color-on-primary-container"))
    val secondary = `var`(Variable<Color>("--theme-color-secondary"))
    val onSecondary = `var`(Variable<Color>("--theme-color-on-secondary"))
    val secondaryContainer = `var`(Variable<Color>("--theme-color-secondary-container"))
    val onSecondaryContainer = `var`(Variable<Color>("--theme-color-on-secondary-container"))
    val tertiary = `var`(Variable<Color>("--theme-color-tertiary"))
    val onTertiary = `var`(Variable<Color>("--theme-color-on-tertiary"))
    val tertiaryContainer = `var`(Variable<Color>("--theme-color-tertiary-container"))
    val onTertiaryContainer = `var`(Variable<Color>("--theme-color-on-tertiary-container"))
    val error = `var`(Variable<Color>("--theme-color-error"))
    val onError = `var`(Variable<Color>("--theme-color-on-error"))
    val errorContainer = `var`(Variable<Color>("--theme-color-error-container"))
    val onErrorContainer = `var`(Variable<Color>("--theme-color-on-error-container"))
    val surface = `var`(Variable<Color>("--theme-color-surface"))
    val surfaceBright = `var`(Variable<Color>("--theme-color-surface-bright"))
    val surfaceDim = `var`(Variable<Color>("--theme-color-surface-dim"), NamedColor.lightgrey)
    val surfaceContainerLowest = `var`(Variable<Color>("--theme-color-surface-container-lowest"))
    val surfaceContainerLow = `var`(Variable<Color>("--theme-color-surface-container-low"))
    val surfaceContainer = `var`(Variable<Color>("--theme-color-surface-container"))
    val surfaceContainerHigh = `var`(Variable<Color>("--theme-color-surface-container-high"))
    val surfaceContainerHighest = `var`(Variable<Color>("--theme-color-surface-container-highest"))
    val onSurface: Color = `var`(Variable<Color>("--theme-color-on-surface"), NamedColor.black)
    val onSurfaceVariant = `var`(Variable<Color>("--theme-color-on-surface-variant"), NamedColor.black)
    val outline = `var`(Variable<Color>("--theme-color-outline"), NamedColor.darkgrey)
    val outlineVariant = `var`(Variable<Color>("--theme-color-outline-variant"), NamedColor.grey)
}
import csstype.*
import emotion.react.css
import kotlinx.browser.window
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.progress
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG
import react.dom.svg.ReactSVG.path

external interface DownloadMessageProps : Props {
    var name: String
    var time: Int
    var completed: Boolean
    var onDownloaded: () -> Unit
}

val DownloadMessageComponent = FC<DownloadMessageProps>("DownloadMessageComponent") { props ->

    useEffect(Unit) {
        val timer = if (!props.completed) {
            window.setTimeout({
                props.onDownloaded()
            }, props.time)
        } else {
            null
        }
        cleanup {
            timer?.let(window::clearTimeout)
        }
    }

    div {
        css {
            width = 60.pct
            margin = Margin(vertical = 10.px, horizontal = Auto.auto)
            padding = Padding(4.px, 4.px)
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            backgroundColor = Colors.surface
            borderRadius = 20.px
        }
        ReactSVG.svg {
            css {
                color = Colors.onSurface
            }
            height = 64.0
            width = 64.0
            viewBox = "0 0 24 24"
            path {
                fill="currentColor"
                d="M18.32,4.26C16.84,3.05,15.01,2.25,13,2.05v2.02c1.46,0.18,2.79,0.76,3.9,1.62L18.32,4.26z M19.93,11h2.02 c-0.2-2.01-1-3.84-2.21-5.32L18.31,7.1C19.17,8.21,19.75,9.54,19.93,11z M18.31,16.9l1.43,1.43c1.21-1.48,2.01-3.32,2.21-5.32 h-2.02C19.75,14.46,19.17,15.79,18.31,16.9z M13,19.93v2.02c2.01-0.2,3.84-1,5.32-2.21l-1.43-1.43 C15.79,19.17,14.46,19.75,13,19.93z M13,12V7h-2v5H7l5,5l5-5H13z M11,19.93v2.02c-5.05-0.5-9-4.76-9-9.95s3.95-9.45,9-9.95v2.02 C7.05,4.56,4,7.92,4,12S7.05,19.44,11,19.93z"
            }
        }
        span {
            css {
                margin = Margin(vertical = 4.px, horizontal = 0.px)
                color = Colors.onSurface
            }
            +props.name
        }
        progress {
            max = 100.0
            if (props.completed) {
                value = "100"
            }
        }
    }
}
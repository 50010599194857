import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.iframe

val NewsLetterComponent = FC<Props>("NewsLetterComponent") { _ ->
    iframe {
        css {
            display = Display.block
            backgroundColor = NamedColor.transparent
            colorScheme = ColorScheme.normal
            marginLeft = Auto.auto
            marginRight = Auto.auto
            maxWidth =  100.pct
            width = 100.pct
            height = 100.pct
        }
        width = 540.0
        height = 540.0
        src =
            "https://d10be039.sibforms.com/serve/MUIEAOLuJWfmKYkr5tZHARsGm8r2grYPj8AOd-dHlX9OZDK5o_UPm54xdsNqOP_EyXJUGVKB207KC0hcal4nRlJNrnxwFBcU-5iZsw3AJxhsqndB_pKN7mKK-BnayhPddeYH4L10pojNNMKVG4Xs8BKLlHzKaUt6q1Ov-P7qvvM4GrcMD2qEpDnDGHZxHIYrQBJVO079sYwd2P0Q"
        allowFullScreen = true
        asDynamic()["frameborder"] = "0"
    }
}
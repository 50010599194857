import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import xyz.lacunae.itsadate.GenderedCharacter

data class Success<T>(
    val name: String,
    val progress: Int,
    val finish: Int,
    val details: List<T> = emptyList(),
) {
    val acquired: Boolean get() = progress == finish
}

external interface NotesAppProps : Props {
    var lines: List<Success<*>>
    var renderGender: Boolean
    var onExit: () -> Unit
}

val NotesAppComponent = FC<NotesAppProps>("SettingsAppComponent") { props ->

    div {
        css(ClassName("theme-notes")) {
            width = 100.pct
            height = 100.pct
            display = Display.grid
            gridTemplateRows = array(Auto.auto, 1.fr, Auto.auto)
            gridTemplateColumns = 100.pct
            backgroundColor = rgba(255, 250, 205, .7)
            backdropFilter = blur(7.px)
            fontFamily = string("'Courier New', monospace")
        }
        h1 {
            css {
                textAlign = TextAlign.center
                margin = Margin(horizontal = 0.px, vertical = 32.px)
            }
            +"Notes"
        }
        div {
            css {
                overflowY = Overflow.scroll
            }
            ul {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    listStyleType = None.none
                    margin = Margin(horizontal = 0.px, vertical = 0.px)
                    padding = Padding(horizontal = 0.px, vertical = 0.px)
                }
                props.lines.forEach { success ->
                    li {
                        css {
                            padding = Padding(vertical = 8.px, horizontal = 32.px)
                        }
                        div {
                            css {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                flexGrow = number(1.0)
                                alignItems = AlignItems.center
                            }
                            if (success.acquired) {
                                img {
                                    src = "ic_check.svg"
                                    alt = "Success"
                                    css {
                                        width = 24.px
                                        height = 24.px
                                        padding = Padding(horizontal = 8.px, vertical = 8.px)
                                        borderRadius = 24.px
                                        backgroundColor = Colors.tertiaryContainer
                                    }
                                }
                            } else {
                                img {
                                    src = "ic_timer_sand_empty.svg"
                                    alt = "Blocked"
                                    css {
                                        width = 24.px
                                        height = 24.px
                                        padding = Padding(horizontal = 8.px, vertical = 8.px)
                                        borderRadius = 24.px
                                        backgroundColor = Colors.secondaryContainer
                                    }
                                }
                            }
                            span {
                                css {
                                    margin = Margin(left = 8.px, right = 0.px, top = 0.px, bottom = 0.px)
                                }
                                +"${success.name} (${success.progress}/${success.finish})"
                            }
                        }
                        if (success.details.isNotEmpty()) {
                            ul {
                                css {
                                    display = Display.flex
                                    flexDirection = FlexDirection.column
                                    listStyleType = None.none
                                    margin = Margin(horizontal = 0.px, vertical = 0.px)
                                    padding = Padding(horizontal = 0.px, vertical = 0.px)
                                }
                                success.details.filterNotNull().forEach { detail ->
                                    li {
                                        when (detail) {
                                            is GenderedCharacter -> if (props.renderGender) {
                                                DatingCharacterCardComponent {
                                                    character = detail
                                                }
                                            }

                                            else -> +detail.toString()
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
        UserNextComponent {
            onNext = props.onExit
        }
    }

}

package xyz.lacunae.kotlin.react.transition.group

import kotlinext.js.js

fun timeout(value: Number): TimeoutProps {
    return js {
        enter = value
        exit = value
        appear = value
    }
}

fun timeout(enter: Number, exit: Number, appear: Number): TimeoutProps {
    return js {
        this.enter = enter
        this.exit = exit
        this.appear = appear
    }
}
import xyz.lacunae.story.Character
import csstype.*
import emotion.react.css
import react.Props
import react.FC
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.svg.ReactSVG.svg
import react.dom.svg.ReactSVG.path
import xyz.lacunae.itsadate.GenderedCharacter
import xyz.lacunae.itsadate.withGender

external interface BlockMessageProps : Props {
    var character: Character
}

val BlockMessageComponent = FC<BlockMessageProps>("BadgeMessageComponent") { props ->

    div {
        css {
            width = 60.pct
            margin = Margin(vertical = 10.px, horizontal = Auto.auto)
            padding = Padding(vertical = 4.px, horizontal = 4.px)
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            borderRadius = 20.px
            backgroundColor = Colors.surface
        }
        svg {
            css {
                color = Colors.onSurface
            }
            height = 64.0
            width = 64.0
            viewBox="0 96 960 960"
            path {
                fill = "currentColor"
                d =
                    "M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-60q142.375 0 241.188-98.812Q820 718.375 820 576q0-60.662-21-116.831Q778 403 740 357L261 836q45 39 101.493 59.5Q418.987 916 480 916ZM221 795l478-478q-46-39-102.169-60T480 236q-142.375 0-241.188 98.812Q140 433.625 140 576q0 61.013 22 117.506Q184 750 221 795Z"
            }
        }
        span {
            css {
                margin = Margin(vertical = 4.px, horizontal = Auto.auto)
                textAlign = TextAlign.center
                color = Colors.onSurface
            }
            val character = props.character

            if (character is GenderedCharacter) {
                +"${character.name} a été ${character.withGender("bloqué", "bloquée")}"
            } else {
                +"${character.name} a été bloqué(e)"
            }
            br { }
            +"${character.name} ne pourra plus vous contacter"
        }
    }
}
import csstype.*
import emotion.react.css
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import xyz.lacunae.story.Context

external interface EnvironmentProps : PropsWithChildren {
    var context: Context
    var device : Boolean
    var appProps: AppDraweProps?
    var backgroundImage: String
    var backgroundColor: String
}

val EnvironmentComponent = FC<EnvironmentProps>("EnvironmentComponent") { props ->
    div {
        css(ClassName("fullHeight")) {
            width = 100.vw
            backgroundColor = props.backgroundColor.unsafeCast<BackgroundColor>()
            backgroundImage = url(props.backgroundImage)
            backgroundSize = BackgroundSize.cover
            backgroundRepeat = BackgroundRepeat.noRepeat
            backgroundPosition = GeometryPosition.center
            backgroundAttachment = BackgroundAttachment.fixed
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
        }
        div {
            css(ClassName("fullHeight")) {
                mobileLandscape {
                    width = 100.vw
                }
                mobile {
                    width = 100.vw
                }
                width = 42.vh
                minWidth = min(800.px, 100.pct)
                margin = Margin(Auto.auto, Auto.auto)
                overflow = Overflow.hidden
            }
            div {
                css {
                    display = Display.grid
                    gridTemplateRows = array(1.fr, Auto.auto)
                    gridTemplateColumns = 100.pct
                    mobileLandscape {
                        gridTemplateRows = 100.pct
                        gridTemplateColumns = array(1.fr, Auto.auto)
                    }
                    height = 100.pct
                }
                div {
                    css {
                        overflowY = Overflow.hidden
                        borderBottomLeftRadius = 8.px
                        borderBottomRightRadius = 8.px
                        mobileLandscape {
                            borderBottomLeftRadius = 0.px
                            borderTopRightRadius = 8.px
                        }
                        desktop {
                            borderTopLeftRadius = 8.px
                            borderTopRightRadius = 8.px
                            maxHeight = 800.px
                        }
                        capture {
                            borderBottomLeftRadius = 0.px
                            borderBottomRightRadius = 0.px
                        }
                        height = 100.pct
                        margin = Margin(Auto.auto, 0.px)
                    }
                    +props.children
                }
                if (props.device) {
                    val appProps = props.appProps
                    if (appProps != null) {
                        div {
                            css {
                                width = 100.pct
                                maxWidth = 480.px
                                margin = Margin(Auto.auto, Auto.auto)
                            }
                            child(AppDrawerComponent, appProps)
                        }
                    }
                }
            }
        }
    }
}

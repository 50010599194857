package xyz.lacunae.itsadate

import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.story.*
import kotlin.math.min

object Chapter9 {
    private const val name = "chapter-9"
    const val intro = "$name/intro"
    const val coban = "$name/coban"
    const val end9 = "$name/end-9"
    internal const val fuckCoban = "$name/fragment/fuck-coban"
    internal const val endCoban = "$name/fragment/end-coban"
}

internal fun Story.chapter9() {
    scene(Chapter9.intro, IntroContext("Chapitre 9")) {
        narrator {
            -"Vous voilà de retour chez vous."
            -"Quelle histoire de dingue !"
            -"Vous vous sentez un peu ${
                player.withGender("dépassé", "dépassée")
            } par les événements."
            -"${coban.name} et son organisation sont-ils si mauvais ?"
            -"Après tout, ils ne vous ont rien fait…"
            -"Et si en fait ${elea.name} et ${reporter.name} étaient effectivement de ${
                elea.withGender("dangereux", "dangereuses")
            } activistes ?"
            -"Leur but est-il si noble ?"
            -"${reporter.name} vous a peut-être menti."
            -"Qui choisir ? De quel côté vous ranger ?"
            -"Alors que vous réfléchissez à ces questions, votre téléphone sonne."
        }
        appBadge(Apps.messenger)
        redirect(Chapter9.coban)
    }

    fragment(Chapter9.fuckCoban){
        stateMachine.game.fuckCoban = true
        coban {
            -"OK ${player.name}…"
            -"Tu as décidé de le prendre comme ça."
            -"J’ai pourtant été très patient avec toi."
            -"TROP patient."
            -"Les gens comme toi me débectent."
        }
        choose {
            "Espèce de pourri !"{
                coban {
                    -"Ah oui, carrément ?"
                    -"Des insultes maintenant."
                    -"${player.withGender("Mon", "Ma")} pauvre ${player.name}…"
                    -"Tu n’as pas idée de ce que tu viens de déclencher, en me provoquant de la sorte."
                }
            }
            "Ok, je m’excuse. On repart à zéro ?"{
                coban {
                    -"Ce serait trop facile."
                    -"C’est trop tard ${player.name}."
                    -"Tu n’as pas idée de ce que tu viens de déclencher, en me provoquant de la sorte."
                }
            }
        }
        coban {
            -"Tu fais ${player.withGender("le malin", "la maline")} derrière ton écran, mais crois-moi, je ne vais pas en rester là."
            -"Tu n’es pas ${player.withGender("préparé", "préparée")} à ce qui va t’arriver."
            -"Tu as eu ta chance, tu l’as gâchée."
            -"Attends-toi au pire, ${player.name}."
            -"Tu ne le sais pas encore, mais tu as déjà perdu la partie."
        }
        narrator {
            -"${coban.name} s’est ${coban.withGender("déconnecté", "déconnectée")}"
        }
        redirect(Chapter10.intro)
    }

    fragment(Chapter9.endCoban){
        coban {
            -"Merci beaucoup pour cette information."
            -"Je savais que tu nous serais utile."
            -"Deuxième et dernière question :"
            -"OÙ EST ${reporter.name} ?"
        }
        choose {
            "${
                elea.withGender("Il", "Elle")
            } est chez son ami Martin Forgeur, un cryptologue à Paris. C’est tout ce que je sais."{
                stateMachine.game.fuckCoban = false
                coban {
                    -"Martin Forgeur, cryptologue, Paris."
                    -"On devrait pouvoir le trouver rapidement."
                    -"J’ai tout ce qu’il me faut."
                    -"C’est parfait !"
                    -"Merci ${player.name}, grâce à toi, cette histoire va être vite réglée."
                    -"On te recontacte au plus vite."
                }
                narrator {
                    -"${coban.name} s’est ${coban.withGender("déconnecté", "déconnectée")}"
                }
                redirect(Chapter9.end9, GoToScene.Option.Timeout(10_000))
            }
            "${
                elea.withGender(
                    "Il",
                    "Elle"
                )
            } est chez, euh… Nicolas, un prof spécialiste des symboles, à la Fac de Lorient. Tu peux me croire."{
                stateMachine.game.fuckCoban = false
                coban {
                    -"Nicolas, prof, spécialiste des symboles, à Fac de Lorient."
                    -"Il ne devrait pas être trop dur à retrouver ce Nicolas;"
                    -"Des profils comme le sien ne doivent pas courir les rues en Bretagne."
                    -"On devrait vite mettre la main sur lui."
                    -"Merci ${player.name}, grâce à toi, cette histoire sera vite réglée."
                    -"On te recontacte au plus vite."
                }
                narrator {
                    -"${coban.name} s’est ${coban.withGender("déconnecté", "déconnectée")}"
                }
                redirect(Chapter10.intro)
            }
            "Va chier ${coban.name}, je ne te dirai rien !"{
                include(Chapter9.fuckCoban)
            }
        }
    }

    scene(Chapter9.coban, VirtualContext(App.MESSENGER, Place.HOME, Ambiance.NOWHERE)) {
        characters(coban)
        coban {
            -"${player.name} ?"
            -"Comment ça va ?"
        }
        choose {
            "Bien."{
                coban {
                    -"Tant mieux, tant mieux."
                    -"Tu m’en vois ravi. 🙂"
                }
            }
            "Pas top."{
                coban {
                    -"Ah, mince…"
                    -"J’imagine que tu te poses de nombreuses questions."
                    -"C’est normal, ne t’en fais pas"
                    -"Mais ça ira bien mieux après notre conversation, j’en suis sûr."
                }
            }
            silent("Ne pas répondre.") {
                coban {
                    hesitation()
                    -"OK, disons que tu es prêt à m’écouter."
                    -"Parlons franchement."
                }
            }
        }
        coban {
            -"Je comprends tes doutes."
            -"Tu es partagé entre deux choix."
            -"D’un côté, l’amitié, de l’autre La Loi."
        }
        choose {
            -"La Loi ? C’est toi la Loi ?"
        }
        coban {
            -"Oui, tout ce que je fais s’inscrit globalement dans le cadre d’actions légales."
        }
        choose { -"« Globalement » ?" }

            coban {
            -"Le secret professionnel ne me permet pas de te donner de détails…"
            -"Mais si tu réfléchis un peu, tu verras que la solution à notre problème est très simple :"
            -"D’un côté, tu peux devenir un héros et servir les intérêts économiques de ton pays."
                -"Il te suffit pour cela de m’aider à arrêter deux ${
                elea.withGender("criminels", "criminelles")
            } en puissance."
            -"De l’autre, tu commets un délit, et tu bascules du mauvais côté de la loi, en devenant leur complice."
            -"Un délit qui te vaudrait le tribunal, et peut-être même une peine de prison."
            -"Un choix facile à faire, non ?"
        }
        choose {
            "Oui, mon pote !"{
                coban {
                    -"Haha, bien, bien !"
                    -"« Mon pote » , je vois…"
                    -"Alors dis-moi, « mon pote »."
                    -"J’ai une question pour toi."
                    -"Est-ce que tu as bien compris à qui tu t’adresses ?"
                }
                choose {
                    "A une grande gueule."{
                        choose { -"Au final, toi aussi tu es bien planqué derrière ton écran." }
                        choose { -"Si tu crois que tu me fais peur, tu te trompes." }
                        choose { -"Tu peux prendre tes airs de bad guy, mais ça ne marche pas avec moi." }
                    }
                    "A un type dangereux."{
                        choose { -"J’imagine que tu sais ce que tu fais." }
                        choose { -"Tu es froid et calculateur." }
                        choose { -"Mais tu ne me fais pas plus peur que ça." }
                    }
                }
                coban {
                    -"Tu sais quoi ?"
                    -"Tu n’as pas tort."
                    -"Tu m’as plutôt bien cerné."
                    -"On pourrait peut-être en parler de vive voix ?"
                    -"Je pourrais te rendre une petite visite à ${stateMachine.game.city}…"
                    -"Depuis que tu m’as dit que tu venais de là, j’ai très envie de passer te voir."
                }
            }
            "Je ne pense pas non…"{
                choose { -"la situation est loin d’être si simple" }
                choose { -"Qu’est-ce qui me prouve que tu es du côté des « bons » ?" }
                coban {
                    -"ok je vois…"
                    -"${player.name}, ${player.name}, ${player.withGender("mon p’tit", "ma p’tite")} ${player.name}…"
                    -"Je pense que tu n’as pas encore compris tous les ressorts de cette affaire."
                    -"Ce n’est pas une question de « bon » ou de « mauvais »."
                    -"On n’est pas dans un film avec un gentil et un méchant."
                    -"Tu n’es pas ${
                        player.withGender("un héros", "une héroïne")
                    }, pas plus que je ne suis un sale type."
                    -"La vraie vie ne marche pas comme ça."
                }
                choose {
                    -"C’est pourtant le cas ici. Mes choix ont des conséquences sur la vie d’autres personnes."
                }
                coban {
                    -"Tu as raison."
                    -"Dans le monde réel, il y a des conséquences."
                    -"Des conséquences qui peuvent s’avérer… dangereuses."
                    -"Je sais par exemple que tu habites à ${stateMachine.game.city}"
                    -"C’est toi qui m’en avais parlé."
                }
            }
        }
        choose {
            "Parce que tu crois que je t’ai dit la vérité ?"{}
            "Tu crois que tes menaces me font peur ?"{
                coban {
                    -"Non, pas du tout !"
                    -"Je ne fais que te dire ce que je sais…"
                }
            }
        }
        coban {
            -"Tiens, autre chose."
            -"Si tu savais à quel point il est facile de découvrir des choses sur n’IMPORTE qui."
            -"Toi par exemple…"
            -"Je sais que…"
            val info = mutableListOf<() -> Unit>()
            val brother = stateMachine.game.brother
            val sister = stateMachine.game.sister
            if (brother != null && sister != null) {
                info += {
                    -("Tu as " + listOfNotNull(
                        if (brother == 0) null else if (brother == 1) "$brother frère" else "$brother frères",
                        if (sister == 0) null else if (sister == 1) "$sister sœur" else "$sister sœurs"
                    ).joinToString() + ".")
                }
            }
            stateMachine.game.movie?.let { movie ->
                info += {
                    -"Tu aimes $movie."
                }
            }
            stateMachine.game.book?.let { book ->
                info += {
                    -"Tu aimes $book…"
                }
            }
            stateMachine.game.band?.let { band ->
                info += {
                    -"Je crois que tu aimes bien $band."
                    -"Pas mon style de musique, mais bon."
                }
            }
            stateMachine.game.astrologicalSign?.let { astrologicalSign ->
                info += {
                    -"Ton signe astrologique serait : $astrologicalSign."
                }
            }
            stateMachine.game.phoneNumber?.let { phoneNumber ->
                info += {
                    -"Ton numéro : $phoneNumber."
                }
            }
            if (info.isNotEmpty()) {
                info.shuffle()
                info.subList(0, min(2, info.size)).forEach { it() }
            }
        }

        choose {
            -"Comment as-tu eu ces informations ?"
            -"Tu as piraté mon téléphone ?"
        }
        coban {
            -"Ça n’est pas le plus important."
            -"Je ne t’ai donné que quelques infos superficielles."
            -"Vraies ou fausses, peu m’importe."
            -"Il m’est facile de les obtenir."
            -"Comprends bien que te retrouver n’est pas un problème."
            -"Je n’ai qu’un ordre à donner pour qu’on vienne frapper à ta porte."
            -"Et sache qu’on peut en faire plus. Bien plus."
            -"Tu dois être ${
                player.withGender("conscient", "consciente")
            } d’une chose ${player.name}; c’est que si je veux ;"
            -"d’un simple claquement de doigts."
            -"Je te détruis."
        }
        choose {
            -"Qu’est-ce que tu veux dire ?"
            -"Tu ne me fais pas peur ${coban.name} !"
        }
        coban {
            -"On peut non seulement prendre le contrôle de tes mails, de ton téléphone, de ton ordinateur."
            -"Mais on peut aussi faire des choses qui auraient un tout autre impact sur ta vie."
            -"Effacer toute trace de ta vie en ligne."
            -"Vider ton compte en banque."
            -"Annuler tes contrats d’assurance."
            -"Faire disparaître ton numéro de sécurité sociale."
        }
        choose {
            -"Je t’en prie, je n’ai rien à perdre de toute façon"
            -"Tu bluffes, ${coban.name} !"
        }
        coban {
            -"On pourrait aussi étoffer un peu ton casier judiciaire ?"
            -"C’est marrant ça, tiens."
            -"On pourrait faire de toi ce qu’on veut."
            -"D’un simple claquement de doigts, on peut faire ou défaire une vie."
            -"Alors écoute-moi bien, parce que je ne te le répéterai plus."
            -"Collabore avec nous !"
            -"Laisse tomber ces deux ${elea.withGender("mecs", "filles")} et laisse-les se noyer dans leur délire."
            -"Car je te garantis que tu ne veux pas être mon ennemi."
        }
        choose {
            -"…"
            -"Qu’est-ce que je dois faire ?"
        }
        coban {
            -"${player.name}…"
            -"Ces ${elea.withGender("mecs", "filles")} te manipulent,"
            -"Et moi je te dis la vérité."
            -"C’est leur manière de faire :"
            -"${elea.withGender("ils", "elles")} sèment le chaos et la confusion autour d’${
                elea.withGender("eux", "elles")
            }."
            -"${elea.withGender("Ils", "Elles")} te mentent, et se servent de toi comme bouc-émissaire."
            -"A les aider de la sorte, c’est TOI qui vas avoir des problèmes."
        }
        choose {
            "Sauf si je fais ce que tu me demandes"{
                coban {
                    -"Exactement, mon cher ${player.name}."
                    -"On dirait que tu as compris ce qui est le mieux pour toi."
                }
            }
            "Sauf si on en reste là, et que je ne me mêle plus de cette histoire."{
                coban {
                    -"Oh non, ce serait trop simple ça."
                    -"Que tu le veuilles ou non, tu es trop ${player.withGender("impliqué", "impliquée")} dans cette affaire pour qu’on t’oublie."
                    -"Mais ne t’inquiète pas, ton rôle est simple."
                }
            }
        }
        coban {
            -"Tout ce que je te demande, c’est de jouer leur jeu."
            -"${elea.withGender("Ils", "Elles")} semblent te faire confiance."
            -"Continue à leur parler."
            -"Amène les à se compromettre."
            -"Et dis-moi TOUT ce qui peut me permettre de les retrouver."
        }
        choose {
            -"Qu’est-ce que j’y gagne ?"
            -"Je ne peux pas faire ça…"
        }
        coban {
            -"En échange, je te garantis que tu seras ${player.withGender("lavé", "lavée")} de tout soupçon."
            -"Mes patrons pourraient même te récompenser en fonction de la qualité des infos que tu nous fourniras."
            -"C’est pas ${
                reporter.withGender("ce taré", "cette tarée")
            } de journaliste ni ${
                elea.withGender("cer activiste fou", "cette activiste folle")
            } qui feront de toi ${player.withGender("un homme", "une femme")} riche."
        }
        choose {
            "Je me fous de l’argent."{
                coban {
                    -"On trouvera une récompense à la hauteur de ta contribution à notre mission."
                    -"Ne t’inquiète pas, tu ne seras pas ${player.withGender("déçu", "déçue")}."
                }
            }
            "Combien vous me donnerez ?"{
                coban {
                    -"Chaque chose en son temps."
                    -"Commence par m’apporter des infos, et des preuves de ta loyauté."
                    -"Et dès cette affaire réglée, tu auras de bonnes nouvelles de notre part."
                }
            }
        }
        coban {
            -"Tu vois qui sont les gentils maintenant ?"
        }
        choose {
            -"Tu ne me laisses pas vraiment le choix…"
        }
        coban {
            -"🙂"
            -"C’est bien."
            -"On dirait que tu as compris."
            -"Tu es coriace, tu as mis le temps…"
            -"Mais je savais que tu étais quelqu’un de raisonnable."
            -"J’ai deux questions à te poser."
            -"Et toi, tu as deux réponses à me donner."
            -"Tu vois, c’est simple."
            -"Première question :"
            -"As-tu des nouvelles de ${elea.name} ?"
        }
        choose {
            "Non, aucune depuis notre soirée."{
                coban {
                    -"Hum…"
                    -"OK ${player.name}, je te crois."
                }
                include(Chapter9.endCoban)
            }
            "Oui, ${elea.withGender("il", "elle")}  a essayé de me contacter."{
                coban {
                    -"Ah oui ?"
                    -"Mais c’est génial ça !"
                    -"Par quel biais ?"
                }
                choose {
                    silent("LA VERITE. Un site de rencontres.") {
                        stateMachine.game.ratNikolaMars = true
                        player {
                            -"Un site de rencontres."
                            -"Son pseudo est Nikola Mars."
                            -"Je peux te transmettre notre conversation."
                        }
                        coban {
                            -"Nikola Mars… Ça ne me surprend pas."
                            -"Merci ${player.name}"
                            -"Avec cette information, on devrait pouvoir retrouver son IP, et savoir où ${
                                elea.withGender(
                                    "il",
                                    "elle"
                                )
                            } se trouve."
                        }
                    }
                    silent("MENTIR. Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire.") {
                        player {
                            -"Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire."
                            -"Avant de le faire, j’ai vu que la lettre était postée depuis l’Espagne."
                            -"${elea.withGender("Il", "Elle")} a quitté le pays depuis quelques jours."
                            -"Je n’en sais pas plus."
                        }
                        coban {
                            -"Hum, c’est vague, comme information."
                            -"Je vais voir si je peux mettre quelques hommes sur le coup, mais ça va être compliqué de retrouver sa trace."
                        }
                    }
                }
                include(Chapter9.endCoban)
            }
            "Va chier ${coban.name}, je ne te dirai rien !"{
                stateMachine.game.fuckCoban = true
                include(Chapter9.fuckCoban)
            }
        }
    }
    scene(Chapter9.end9, EndContext("Fin 9")) {
        stateMachine.achievements.end9 = true
        narrator {
            -"${coban.name} ne vous recontactera plus…"
            -"Vous avez bien essayé d’envoyer un message à ${reporter.name}, sans résultat."
            -"Les jours passent, les semaines défilent, et puis vous tombez sur un article, qui évoque la mort d’${
                reporter.withGender("un", "une")
            } journaliste et de son ${elea.withGender("ami", "amie")}, qui seraient ${
                elea.withGender("décédés", "décédées")
            } dans un terrible accident."
            -"Vous avez du mal à y croire !"
            -"Vous vous sentez terriblement coupable d’avoir choisi d’aider ${coban.name}, et de trahir vos ${
                elea.withGender("amis", "amies")
            }."
            -"Trop tard."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 9."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }
}
import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.ul

data class AppData(
    val id: String,
    val name: String,
    val icon: String,
    val theme: String,
    val light: Boolean,
    val enable: Boolean,
    val badge: Boolean
)

external interface AppDraweProps : Props {
    var apps: List<AppData?>
    var onAppClick: (AppData) -> Unit
}

val AppDrawerComponent = FC<AppDraweProps>("AppDrawerComponent") { props ->
    ul {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            mobileLandscape {
                flexDirection = FlexDirection.column
            }
            capture {
                display = None.none
            }
            listStyleType = None.none
            margin = Margin(0.px, 0.px)
            padding = Padding(0.px, 0.px)
            justifyContent = JustifyContent.spaceEvenly
        }
        props.apps.forEach { app ->
            ReactHTML.li {
                css {
                    height = 96.px
                    width = 96.px
                    cursor = if (app == null) {
                        Globals.inherit
                    } else {
                        Cursor.pointer
                    }
                }
                if (app == null) {
                    div {
                        css {
                            height = 100.pct
                            width = 100.pct
                            display = Display.flex
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                        }
                        div {
                            css {
                                width = 8.px
                                height = 8.px
                                borderRadius = 4.px
                                backgroundColor = NamedColor.white
                                textShadow = "1px 1px 2px grey".unsafeCast<TextShadow>()
                            }
                        }
                    }
                } else {
                    onClick = {
                        props.onAppClick(app)
                    }
                    div {
                        css {
                            transitionProperty = "transform".unsafeCast<TransitionProperty>()
                            transitionDuration = 330.ms
                            transitionTimingFunction = TransitionTimingFunction.easeInOut
                            hover {
                                transform = scale(1.1)
                            }
                            height = 100.pct
                            width = 100.pct
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            color = NamedColor.white
                            textShadow = "1px 1px 4px black".unsafeCast<TextShadow>()
                        }
                        div {
                            css(ClassName(app.theme)) {
                                position = Position.relative
                                height = 56.px
                                width = 56.px
                                borderRadius = 32.px
                                display = Display.flex
                                alignItems = AlignItems.center
                                justifyContent = JustifyContent.center
                                backgroundColor = if (app.light) Colors.primaryContainer else Colors.primary
                            }
                            div {
                                css {
                                    position = Position.absolute
                                    top = 0.px
                                    right = 0.px
                                    width = 12.px
                                    height = 12.px
                                    margin = Margin(2.px, 2.px)
                                    backgroundColor = NamedColor.crimson
                                    borderRadius = 6.px
                                    visibility = if (app.badge) Globals.inherit else Visibility.hidden
                                }
                            }
                            div {
                                css {
                                    width = 100.pct
                                    height = 100.pct
                                    backgroundColor = if (app.light) Colors.onPrimaryContainer else Colors.onPrimary
                                    mask = "url(${app.icon}) no-repeat center/36px 36px".unsafeCast<Mask>()
                                }
                            }
                        }
                        div {
                            css {
                                color = NamedColor.white
                                fontSize = FontSize.caption
                                marginTop = 4.px
                                textShadow = "1px 1px 4px black".unsafeCast<TextShadow>()
                            }
                            +app.name
                        }
                    }
                }
            }
        }

    }
}

package xyz.lacunae.itsadate

import xyz.lacunae.story.*


val StateMachine.game
    get() = GameStateMachine(this)


class GameStateMachine(val stateMachine: StateMachine) : StateMachine by stateMachine {
    companion object {
        const val namespace = "game"
    }

    var time: Long by LongState(namespace)
    var scene: String? by NullableStringState(namespace)
    var player_name: String by StringState(namespace)
    var player_image: String? by NullableStringState(namespace)
    var gender: Gender by EnumState(namespace) {
        this?.let { Gender.valueOf(it) } ?: throw IllegalStateException("no gender")
    }
    var looking_for: Gender by EnumState(namespace) {
        this?.let { Gender.valueOf(it) } ?: throw IllegalStateException("no gender")
    }
    var city: String by StringState("game")
    var datingMovie: String? by NullableStringState(namespace)
    var datingSerie: String? by NullableStringState(namespace)
    var datingBook: String? by NullableStringState(namespace)
    var datingSong: String? by NullableStringState(namespace)
    var talkAboutBook: Boolean by BooleanState(namespace)
    var movie: String? by NullableStringState(namespace)
    var book: String? by NullableStringState(namespace)
    var band: String? by NullableStringState(namespace)
    var phoneNumber: String? by NullableStringState(namespace)
    var astrologicalSign: String? by NullableStringState(namespace)
    var eleaVisited: Boolean by BooleanState(namespace)
    var pnj2Visited: Boolean by BooleanState(namespace)
    var pnj3Visited: Boolean by BooleanState(namespace)
    var pnj4Visited: Boolean by BooleanState(namespace)
    var pnj5Visited: Boolean by BooleanState(namespace)
    var pnj6Visited: Boolean by BooleanState(namespace)
    var pnj7Visited: Boolean by BooleanState(namespace)
    var pnj8Visited: Boolean by BooleanState(namespace)
    var pnj9Visited: Boolean by BooleanState(namespace)
    var pnj10Visited: Boolean by BooleanState(namespace)
    var pnj11Visited: Boolean by BooleanState(namespace)
    var pnj12Visited: Boolean by BooleanState(namespace)
    var pnj13Visited: Boolean by BooleanState(namespace)
    var pnj14Visited: Boolean by BooleanState(namespace)
    var pnj15Visited: Boolean by BooleanState(namespace)
    var nikolaMarsVisited: Boolean by BooleanState(namespace)
    var badgeCount: Int by IntState(namespace)
    var datingInstalled: Boolean by BooleanState(namespace)
    var messengerInstalled: Boolean by BooleanState(namespace)
    var metReporter: Boolean by BooleanState(namespace)
    var codeWithRoland: Boolean by BooleanState(namespace)
    var codeWithReporter: Boolean by BooleanState(namespace)
    var brother: Int? by NullableIntState(namespace)
    var sister: Int? by NullableIntState(namespace)
    var reporterDrink: Boolean by BooleanState(namespace)
    var mainDoor: Boolean by BooleanState(namespace)
    var withCoban: Boolean by BooleanState(namespace)
    var ratNikolaMars: Boolean by BooleanState(namespace)
    var fuckCoban: Boolean by BooleanState(namespace)
    var fuckCoban2: Boolean by BooleanState(namespace)
    var quietBar: Boolean by BooleanState(namespace)
    var lyingToCoban: Boolean by BooleanState(namespace)
    var busyBar: Boolean by BooleanState(namespace)
    var lyingToReporter: Boolean by BooleanState(namespace)
    var finalElea: Boolean by BooleanState(namespace)
    var finalCoban: Boolean by BooleanState(namespace)


    val apps: List<String?>
        get() = listOf(
            Apps.sms,
            Apps.work,
            if (datingInstalled) Apps.dating else null,
            if (messengerInstalled) Apps.messenger else null,
            Apps.notes
        )
}

val StateMachine.achievements
    get() = AchievementsStateMachine(this)


class AchievementsStateMachine(val stateMachine: StateMachine) : StateMachine by stateMachine {
    companion object {
        const val namespace = "achievements"
    }

    var time: Long by LongState(namespace)

    var eleaVisited: Boolean by BooleanState(namespace)
    var pnj2Visited: Boolean by BooleanState(namespace)
    var pnj3Visited: Boolean by BooleanState(namespace)
    var pnj4Visited: Boolean by BooleanState(namespace)
    var pnj5Visited: Boolean by BooleanState(namespace)
    var pnj6Visited: Boolean by BooleanState(namespace)
    var pnj7Visited: Boolean by BooleanState(namespace)
    var pnj8Visited: Boolean by BooleanState(namespace)
    var pnj9Visited: Boolean by BooleanState(namespace)
    var pnj10Visited: Boolean by BooleanState(namespace)
    var pnj11Visited: Boolean by BooleanState(namespace)
    var pnj12Visited: Boolean by BooleanState(namespace)
    var pnj13Visited: Boolean by BooleanState(namespace)
    var pnj14Visited: Boolean by BooleanState(namespace)
    var pnj15Visited: Boolean by BooleanState(namespace)
    var nikolaMarsVisited: Boolean by BooleanState(namespace)
    var badgePnj4: Boolean by BooleanState(namespace)
    var badgePnj7: Boolean by BooleanState(namespace)
    var badgePnj9: Boolean by BooleanState(namespace)
    var badgePnj12: Boolean by BooleanState(namespace)
    var badgePnj13: Boolean by BooleanState(namespace)
    var datingInstalled: Boolean by BooleanState(namespace)
    var messengerInstalled: Boolean by BooleanState(namespace)
    var end1: Boolean by BooleanState(namespace)
    var end2: Boolean by BooleanState(namespace)
    var end3: Boolean by BooleanState(namespace)
    var end4: Boolean by BooleanState(namespace)
    var end5: Boolean by BooleanState(namespace)
    var end6: Boolean by BooleanState(namespace)
    var end7: Boolean by BooleanState(namespace)
    var end8: Boolean by BooleanState(namespace)
    var end9: Boolean by BooleanState(namespace)
    var end10: Boolean by BooleanState(namespace)
    var end11: Boolean by BooleanState(namespace)
    var end12: Boolean by BooleanState(namespace)
    var end13: Boolean by BooleanState(namespace)
}

enum class Gender {
    WOMAN, MAN
}

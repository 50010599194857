import csstype.*
import emotion.react.css
import kotlinx.browser.document
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.em
import react.dom.html.ReactHTML.span
import react.useEffect
import xyz.lacunae.itsadate.ConditionalChoiceData
import xyz.lacunae.itsadate.GenderedCharacter
import xyz.lacunae.itsadate.ProfileData
import xyz.lacunae.itsadate.SilentChoiceData
import xyz.lacunae.story.AnswerType

external interface InteractionsProps : Props {
    var next: Next?
    var onNext: () -> Unit
    var choices: List<Any>?
    var onChoice: (Int, Any) -> Unit
    var answer: AnswerType?
    var onAnswer: (String) -> Unit
}

val InteractionsComponent = FC<InteractionsProps>("InteractionsComponent") { props ->

    useEffect(props.choices) {
        val callback: (Event) -> Unit = { event ->
            if (event is KeyboardEvent) {
                val choices = props.choices
                if (choices != null) {
                    val index = when (event.code) {
                        // keyboard digit
                        "Digit1" -> 0
                        "Digit2" -> 1
                        "Digit3" -> 2
                        "Digit4" -> 3
                        "Digit5" -> 4
                        "Digit6" -> 5
                        "Digit7" -> 6
                        "Digit8" -> 7
                        "Digit9" -> 8
                        // tv color button
                        "ColorF0Red" -> 0
                        "ColorF1Green" -> 1
                        "ColorF2Yellow" -> 2
                        "ColorF3Blue" -> 3
                        "ColorF4Grey" -> 4
                        "ColorF5Brown" -> 5
                        else -> null
                    }
                    if (index != null && index in 0..choices.size) {
                        props.onChoice(index, choices[index])
                        event.preventDefault()
                    }
                }
                if (props.next != null && event.code == "Enter") {
                    props.onNext()
                }
            }
        }
        document.addEventListener("keyup", callback)
        cleanup { document.removeEventListener("keyup", callback) }
    }

    div {
        css {
            padding = Padding(vertical = 4.px, horizontal = 0.px)
        }
        when {
            props.next != null -> {
                NextComponent {
                    next = props.next
                    onNext = props.onNext
                }
            }

            props.choices != null -> props.choices?.let { choices ->
                div {
                    css {
                        minHeight = 10.vh
                        margin = Margin(0.px, 0.px)
                        padding = Padding(vertical = 4.px, horizontal = 16.px)
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        /*media(query = " screen and (orientation: landscape) and (max-height: 640px) ") {
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                        }*/
                    }
                    choices.mapIndexed { index, content ->
                        button {
                            css {
                                display = Display.block
                                backgroundColor = Colors.surface
                                color = Colors.onSurface
                                backdropFilter = blur(6.px)
                                margin = Margin(vertical = 4.px, horizontal = 16.px)
                                padding = Padding(vertical = 4.px, horizontal = 16.px)
                                border = Border(
                                    width = 1.px,
                                    style = LineStyle.solid,
                                    color = Colors.outline
                                )
                                borderRadius = 4.px
                                textAlign = TextAlign.center
                                fontSize = FontSize.body
                                cursor = Cursor.pointer
                                hover {
                                    backgroundColor = Colors.surfaceContainerHigh
                                }
                                disabled {
                                    cursor = Cursor.notAllowed
                                    border = Border(
                                        width = 1.px,
                                        style = LineStyle.solid,
                                        color = Colors.onSurface
                                    )
                                    color = Colors.onSurface
                                    backgroundColor = NamedColor.transparent
                                    opacity = number(.38)
                                }
                            }
                            if (content is ConditionalChoiceData) {
                                if (content.condition()) {
                                    onClick = {
                                        props.onChoice(index, content)
                                    }
                                }
                            } else {
                                onClick = {
                                    props.onChoice(index, content)
                                }
                            }
                            title = if (content is ConditionalChoiceData && !content.condition()) {
                                "Choix bloqué"
                            } else {
                                ""
                            }
                            disabled = content is ConditionalChoiceData && !content.condition()

                            when (content) {
                                is String -> +content
                                is SilentChoiceData -> {
                                    em {
                                        +content.content
                                    }
                                }

                                is ConditionalChoiceData -> {
                                    if (content.condition()) {
                                        span {
                                            +content.content
                                        }
                                    } else {
                                        span {
                                            css {
                                                textDecoration = TextDecoration.lineThrough
                                            }
                                            +content.content
                                        }
                                    }
                                }

                                is ProfileData -> DatingCharacterCardComponent {
                                    character = content.character as GenderedCharacter
                                }
                            }
                        }
                    }
                }
            }

            props.answer != null -> props.answer?.let { answer ->
                InputComponent {
                    type = answer
                    this.onSubmit = { input: String ->
                        props.onAnswer(input)
                    }
                }
            }

            else -> div {
                css {
                    minHeight = 10.vh
                    textAlign = TextAlign.center
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.center
                    color = Colors.onSurface
                }
                span {
                    +"…"
                }
            }
        }
    }

}

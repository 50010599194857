import csstype.*
import emotion.react.css
import kotlinext.js.js
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import xyz.lacunae.story.AnswerType
import xyz.lacunae.story.Character


external interface IrlAppProps : Props {
    var writing: Character?
    var messages: List<IMessage>?
    var next: Next?
    var choices: List<Any>?
    var onChoice: (Int, Any) -> Unit
    var answer: AnswerType?
    var onAnswer: (String) -> Unit
    var onNext: () -> Unit
}

val IrlAppComponent = FC<IrlAppProps>("IrlAppComponent") { props ->
    div {
        css {
            display = Display.grid
            gridTemplateRows = array(1.fr, Auto.auto)
            gridTemplateColumns = 100.pct
            height = 100.pct
        }
        IrlMessagesComponent {
            messages = props.messages.orEmpty()
        }
        InteractionsComponent {
            answer = props.answer
            onAnswer = props.onAnswer
            choices = props.choices
            onChoice = props.onChoice
            next = props.next
            onNext = props.onNext
        }
    }
}

package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.bot
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.roland
import kotlin.math.min

object Chapter1 {
    private const val name = "chapter-1"
    const val intro = "$name/intro"
    const val roland = "$name/roland"
    const val rolandInterlude = "$name/roland-interlude"
    const val rolandNight = "$name/roland-night"
    const val end1 = "$name/end-1"
    const val end2 = "$name/end-2"
    const val datingIntro = "$name/dating-intro"
    const val datingChoices = "$name/dating-choices"
    const val datingPnj2 = "$name/dating-pnj2"
    const val datingPnj3 = "$name/dating-pnj3"
    const val datingPnj4 = "$name/dating-pnj4"
    const val datingPnj5 = "$name/dating-pnj5"
    const val datingPnj6 = "$name/dating-pnj6"
    const val datingPnj7 = "$name/dating-pnj7"
    const val datingPnj8 = "$name/dating-pnj8"
    const val datingPnj9 = "$name/dating-pnj9"
    const val datingPnj10 = "$name/dating-pnj10"
    const val datingPnj11 = "$name/dating-pnj11"
    const val datingPnj12 = "$name/dating-pnj12"
    const val datingPnj13 = "$name/dating-pnj13"
    const val datingPnj14 = "$name/dating-pnj14"
    const val datingElea = "$name/dating-elea"
    const val messengerElea = "$name/messenger-elea"
    internal const val pnj10Stop = "$name/fragment/pnj10-stop"
    internal const val pnj10Continue = "$name/fragment/pnj10-continue"
    internal const val astrologicalSign = "$name/fragment/astrological-sign"
    internal const val badAnswer = "$name/fragment/bad-answer"
}

internal fun Story.chapter1() {
    scene(Chapter1.intro, IntroContext("Chapitre 1")) {
        narrator {
            -"Voilà quelques mois que vous avez quitté famille et amis après avoir accepté ce nouveau boulot."
            -"Vous passez vos journées dans un open-space froid et aseptisé, à remplir de chiffres qui n’ont pas beaucoup de sens des tableurs servant à compléter des dossiers que personne ne lira."
            -"Déprimant."
            -"Le déracinement vous pèse, et votre seul véritable contact humain, en dehors de votre boulangère, c’est ${roland.name}, le seul collègue qui daigne vous adresser la parole."
            -"Heureusement qu’il est là pour vous divertir un peu."
        }
        appBadge(Apps.work)
        redirect(Chapter1.roland)
    }

    scene(Chapter1.roland, WorkContext) {
        characters(roland)
        roland {
            -"Hey, t’es par là ?"
        }
        choose {
            -"Oui, Roland. Tu sais bien que je suis là; tu me vois depuis ton bureau, depuis lequel tu fais semblant de bosser."
            -"Non, j’ai installé un clone à mon bureau, et je suis actuellement en train de faire une croisière en bateau."
        }
        roland {
            -"Pfff, j’en ai marre."
            -"Il est à peine 15h, et j’ai déjà fini de traiter mes dossiers."
            -"Et c’est pas tes blagues à la con qui vont m’aider à tenir jusqu’à la fin de la journée."
            -"T’en es où toi ?"
        }
        choose {
            -"Je sais plus quoi faire, j’en vois pas le bout…"
            -"Euh… Tu veux pas me filer un coup de main ?"
        }
        roland {
            -"C’est pas que j’ai pas envie de t’aider mais…"
            -"En fait si : j’ai pas envie de t’aider ; Débrouille-toi un peu, c’est comme ça qu’on apprend. "
            -"Je vais en profiter pour glander un peu en attendant 17h."
        }
        choose {
            -"Sympa…"
            silent("ne rien dire.")
        }
        roland {
            -"T’inquiète pas, tu vas vite t’y faire ; d’ici quelques mois, t’arriveras à te dégager quelques heures par semaine pour te détendre un peu."
        }
        choose {
            "C’est pas ce qu’on m’avait vendu." {
                roland {
                    -"Allez, t’en fais pas."
                    -"C’est un boulot comme un autre."
                    -"Te prends pas la tête, et tu finiras par accepter ton triste sort."
                    -"Plus que 40 ans à tirer, et tu pourras profiter de ta retraite."
                    -"(Si un AVC, une crise cardiaque, ou une pandémie ne viennent pas contrarier tes plans, d’ici-là)"
                }
            }
            "Vivement dans quelques mois alors." {
                roland {
                    -"Oui, tu auras fait le plus dur, et tu te seras résigné à être un prisonnier parmi d’autres, enchaîné à son CDI merdique…"
                    -"à son prêt immobilier aux taux indécents…"
                    -"à sa voiture qui démarre pas …"
                    -"tout ça pour tenter de sauver un couple qui se dirige vers une inévitable séparation…"
                    -"et des enfants qui ne voudront plus entendre parler de toi, parce qu’ils t’en veulent de passer trop de temps au boulot."
                }
            }
        }
        choose {
            -"Ça fait rêver."
            -"Ça fait pas rêver."
        }
        roland {
            -"Ben… c’est le cycle de la vie, quoi. 🙂"
            -"Je savais que j’arriverai à te remonter le moral."
            -"Un bon moyen de rendre ta vie plus agréable, c’est de la partager avec quelqu’un !"
            -"Ça amène du piquant, de l’inattendu, de la surprise."
        }
        choose {
            -"C’est une proposition ?"
            -"Je préfère un peu de solitude à un quotidien avec toi. C’est plus sain."
        }
        roland {
            -"Haha, t’es bête."
            -"Bon, et sinon, les amours ?"
            -"T’en es où ? T’es toujours célibataire ? ?"
        }
        choose {
            -"Entre le taf et cette ville paumée, impossible de trouver le temps de rencontrer quelqu’un…"
            -"no comment, mon vieux."
        }
        roland {
            -"Bon, j’ai peut-être une solution pour toi."
            -"Depuis quelques jours, je suis inscrit sur une appli de dating plutôt sympa…"
            -"Le principe est cool, et un peu différent de ce qu’on trouve sur le marché."
        }
        choose {
            "Bof, ça ne m’intéresse pas trop. Ces applis se ressemblent toutes…" {
                roland {
                    -"Il faut juste trouver chaussette à son pied."
                    -"Avec le bon concept, tu peux vivre de belles histoires."
                    -"Laisse-moi au moins te teaser le truc."
                }
                choose {
                    -"Si ça peut te faire plaisir…"
                    -"OK, ça me fera passer le temps."
                }
            }
            -"Ah oui ? Dis-m’en plus."
        }
        roland {
            -"Une fois que tu as créé ton profil…"
            -"L’appli te met en contact avec d’autres membres."
            -"Vous discutez, vous faites connaissance…"
            -"Et puis vous finissez par vous noter mutuellement."
            -"La personne ne t’a pas plu ? Allez, bye, next !"
            -"Vous avez eu un bon feeling ? Vous vous donnez un badge."
            -"Au bout de 3 badges, tu deviens Certif."
            -"C’est à dire « certifié »."
            -"Et à partir de là, tu accèdes à des rencontres de meilleure qualité."
            -"Pas mal non ?"
        }
        choose {
            "Trop cool !!! C’est quoi cette appli, que je la télécharge ?" {}
            "Mouais, je sais pas trop…" {
                roland {
                    -"Oh, laisse-toi tenter, le principe est plutôt cool."
                }
            }
        }
        roland {
            -"L’appli n’est pas disponible en téléchargement."
            -"Elle marche avec un système de parrainage : seul un membre Certifié peut t’inviter à t’inscrire."
            -"Entre ça, et le système des badges, tu peux vite faire de belles rencontres."
            -"Et puis ça contribue à éviter les relous."
            -"C’est tout le principe de cette appli."
            -"Tu verras, le concept marche super bien !"
        }
        choose {
            -"T’as l’air emballé !"
            -"Et ça marche pour toi ?"
        }
        roland {
            -"Je ne te cache pas que le succès n’est pas encore au rendez-vous."
            -"Je me suis pris quelques vents, mais j’ai bon espoir !"
            -"Ah !"
            -"Zut…"
            -"La boss vient de sortir de son bureau !"
            -"Je vais me remettre à faire semblant de travailler."
            -"J’ai pas envie qu’elle passe encore ses nerfs sur moi."
            -"Mais je t’envoie le lien ce soir, t’en feras ce que t’en voudras."
            -"A+"
        }
        choose {
            -"A toute !"
            -"Ouais, retourne donc bosser fainéant !"
        }
        redirect(Chapter1.rolandInterlude)
    }


    scene(Chapter1.rolandInterlude, IntroContext()) {
        narrator {
            -"Le temps passe… lentement…"
            -"Elles n’en fissent pas ces journées de boulot."
            -"Vous vous rendez compte que les longues heures d’école, passées à écouter des cours parfois inintéressants tenus par des profs chiants comme la mort, n’avaient qu’un but : vous préparer à la réalité du monde du travail."
            -"A l’école, on apprend à s’ennuyer. "
            -"Normal, vu qu’on passe la majeure partie de nos vies à faire un boulot qui ne nous plait pas, sous la direction d’un patron qu’on a du mal à supporter."
            -"40 ans à subir ça, tous les jours, en attendant une retraite qui sera loin d’être dorée, si on a la « chance » de tenir jusque là."
            -"Quelle tristesse…"
            -"Vous êtes envahi de pensées négatives, la déprime guette."
            -"Vous n’êtes pas dans un bon mood DU TOUT en ce moment."
            -"Espérons que ça passe bientôt."
            -"En attendant, c’est la quille !"
            -"La journée est finie, enfin."
            -"Il est temps de rentrer chez vous, dans votre joli petit appartement…"
            -"Le soleil se couche doucement, pendant que vous vous prélassez dans votre confortable sofa."
            -"Vous voilà dans votre bulle, l’esprit libre, enfin."
        }
        appBadge("sms")
        redirect(Chapter1.rolandNight)
    }


    scene(Chapter1.rolandNight, SmsContext) {
        characters(roland)
        roland {
            -"Yo !"
        }
        choose {
            "Yo, mec !" {}
            "Bien le bonsoir, mon cher ${roland.name}." {
                roland {
                    -"Haha, ringard !"
                }
            }
        }
        roland {
            -"Je te dérange pas, j’espère ?"
        }
        choose {
            -"Bien sûr que si !"
            -"Bien sûr que non !"
        }
        roland {
            -"Bah, de toute façon, c’est pas comme si t’avais mieux à faire que de parler avec ton bon vieux Roland."
            -"Bon, t’as réfléchi ?"
            -"Je t’envoie le lien de cette app de rencontre ?"
        }
        choose {
            "Allez, envoie !" {
                download("dating.app")
                stateMachine.game.datingInstalled = true
                stateMachine.achievements.datingInstalled = true
                appBadge(Apps.dating)
                redirect(Chapter1.datingIntro)
            }
            "Non, laisse tomber, ça me tente vraiment pas. Je vais passer la soirée tranquille devant Netflix plutôt." {
                roland {
                    -"Oh, c’est dommage…"
                    -"C’est toi qui vois."
                    -"Passe une bonne soirée, et à demain au boulot !"
                }
                narrator {
                    -roland.disconnect()
                }
                redirect(Chapter1.end1, GoToScene.Option.Timeout(10_000))
            }
        }
    }

    scene(Chapter1.end1, EndContext("Fin 1")) {
        stateMachine.achievements.end1 = true
        narrator {
            -"Vous n’avez pas voulu jouer le jeu, c’est bien dommage…"
            -"Vous passez peut-être à côté d’une belle histoire."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 1."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }

    scene(Chapter1.datingIntro, DatingContext) {
        characters(bot)
        bot {
            -"Bienvenue sur « CupidLov »."
            -"Je suis Cupidon, et tu es sur mon domaine."
            -"Tu es là pour trouver l’Amour."
            -"N’aie pas peur ! Cette quête parait ardue, mais je suis là pour t’aider."
            -"Pour commencer, je vais te demander de te présenter."
            -"Quel nom ou pseudo veux-tu utiliser ?"
        }
        answer { name ->
            stateMachine.game.player_name = name
            if (name.obscene) {
                bot {
                    -"$name… 🧐"
                    -"Pas très malin…"
                }
            } else {
                bot {
                    -"Enchanté $name"
                }
            }
        }
        bot {
            -"Es-tu : "
        }
        choose {
            "une femme" { stateMachine.game.gender = Gender.WOMAN }
            "un homme" { stateMachine.game.gender = Gender.MAN }
        }
        bot {
            -"Tu cherches à rencontrer :"
        }
        choose() {
            "des femmes " {
                stateMachine.game.looking_for = Gender.WOMAN
                bot {
                    -"Très bien ${player.name}. Nous allons t’aider à trouver la femme parfaite"
                }
            }
            "des hommes " {
                stateMachine.game.looking_for = Gender.MAN
                bot {
                    -"Très bien ${player.name}. Nous allons t’aider à trouver l’homme parfait"
                }
            }
        }
        bot {
            -"Peux-tu m’aider à affiner ton profil ?"
        }
        choose {
            "Oui" {
                bot {
                    -"Top !"
                    -"Les profils bien remplis ont toujours plus de succès."
                    -"Quel est ton film préféré ?"
                }
                answer { movie ->
                    stateMachine.game.datingMovie = movie
                    if (movie.obscene) {
                        bot {
                            -"$movie… Pas sûr de le connaître celui-là."
                            -"Je me documenterai"
                        }
                    }
                }
                bot {
                    -"Si tu devais me citer une série que tu aimes plus que les autres ?"
                }
                answer { serie ->
                    stateMachine.game.datingSerie = serie
                    if (serie.obscene) {
                        bot {
                            -"$serie… Pas sûr que ce soit mon goût ou celui de beaucoup de membre."
                        }
                    }
                }
                bot {
                    -"Il n’y a pas que les écrans dans la vie. Quel est ton livre de chevet ?"
                }
                answer { book ->
                    stateMachine.game.datingBook = book
                    if (book.obscene) {
                        bot {
                            -"$book… Je n’ai pas ça dans ma bibliothèque"
                        }
                    }
                }
                bot {
                    -"Une chanson que tu fredonnes souvent, sous la douche ou ailleurs."
                }
                answer { song ->
                    stateMachine.game.datingSong = song
                    if (song.obscene) {
                        bot {
                            -"$song… Que dire"
                        }
                    }
                }
            }
            "Non" {
                bot {
                    -"Quel mystère 🕶"
                    -"Dommage, mais on devrait pouvoir faire sans, mais ce sera moins facile"
                }
            }
        }
        bot {
            -"Et pour finir, sache qu’il est plus facile de se connecter aux autres membres en partageant ta photo."
            -"Peux-tu m’en donner une ?"
        }
        choose {
            "Oui" {
                bot {
                    -"Choisis-en une qui met en avant ta personnalité autant que ton physique."
                }
                answer(AnswerType.IMAGE) { image ->
                    stateMachine.game.player_image = image
                }
                bot {
                    -"Magnifique !! 😍"
                }
            }
            "Non" {
                bot {
                    -"Cette aventure va être un vrai challenge pour toi ! 🤠"
                }
            }
        }
        bot {
            -"Merci pour ces informations, ${player.name}"
            -"Je vais maintenant décocher mes flèches, et te proposer des profils susceptibles de te plaire."
            -"Rencontre, discute, découvre, partage."
            -"Un profil t’a plu ? Offre-lui un badge."
            -"Si tu reçois 3 badges, tu deviens Certifié."
            -"Être Certifié, c’est avoir accès à des rencontres de qualité !"
            -"Alors tu sais ce qu’il te reste à faire ! "
            -"A toi de jouer !"
        }
        redirect(Chapter1.datingChoices, GoToScene.Option.User)
    }

    scene(Chapter1.datingChoices, DatingContext) {
        characters(bot)

        val badgeCount = stateMachine.game.badgeCount

        val uncertifiedVisited = stateMachine.game.pnj2Visited
                && stateMachine.game.pnj3Visited
                && stateMachine.game.pnj4Visited
                && stateMachine.game.pnj5Visited
                && stateMachine.game.pnj6Visited
                && stateMachine.game.pnj7Visited
                && stateMachine.game.pnj8Visited
                && stateMachine.game.pnj9Visited
                && stateMachine.game.eleaVisited
        val certifiedVisited = stateMachine.game.pnj10Visited
                && stateMachine.game.pnj11Visited
                && stateMachine.game.pnj12Visited
                && stateMachine.game.pnj13Visited
                && stateMachine.game.pnj14Visited
        if (uncertifiedVisited && (badgeCount < 3 || certifiedVisited)) {
            bot {
                -"Je n’ai plus de profils à te présenter pour l’instant 😔"
            }
            redirect(Chapter1.end2, GoToScene.Option.Timeout(10_000))
        } else {
            bot {
                val toGoal = 3 - badgeCount
                when {
                    badgeCount == 0 -> {
                        -"Tu n’as pas encore de badge, mais je suis sûr que ça va venir !️"
                        -"À qui veux-tu parler ?"
                    }

                    badgeCount == 1 -> {
                        -"Tu as eu ton premier badge bravo ! ⭐️"
                        -"Plus que $toGoal pour être certifié"
                        -"À qui veux-tu parler maintenant ?"
                    }

                    badgeCount < 3 -> {
                        -"Déjà ${stateMachine.game.badgeCount} badges ! 😲"
                        -"Plus que $toGoal pour être certifié 🌟"
                        -"À qui veux-tu parler maintenant ?"
                    }

                    else -> {
                        -"En tant que membre certifié 🏅, tu as encore plus de chance de trouver l’amour ❤️"
                        -"À qui veux-tu parler maintenant ?"
                    }
                }
            }
            choose {
                var pnjs = emptyList<() -> Unit>()

                if (!stateMachine.game.pnj2Visited) {
                    pnjs = pnjs + {
                        profile(pnj2) {
                            redirect(Chapter1.datingPnj2, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj3Visited) {
                    pnjs = pnjs + {
                        profile(pnj3) {
                            redirect(Chapter1.datingPnj3, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj4Visited) {
                    pnjs = pnjs + {
                        profile(pnj4) {
                            redirect(Chapter1.datingPnj4, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj5Visited) {
                    pnjs = pnjs + {
                        profile(this.pnj5) {
                            redirect(Chapter1.datingPnj5, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj6Visited) {
                    pnjs = pnjs + {
                        profile(pnj6) {
                            redirect(Chapter1.datingPnj6, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj7Visited) {
                    pnjs = pnjs + {
                        profile(pnj7) {
                            redirect(Chapter1.datingPnj7, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj8Visited) {
                    pnjs = pnjs + {
                        profile(pnj8) {
                            redirect(Chapter1.datingPnj8, GoToScene.Option.Auto)
                        }
                    }
                }
                if (!stateMachine.game.pnj9Visited) {
                    pnjs = pnjs + {
                        profile(pnj9) {
                            redirect(Chapter1.datingPnj9, GoToScene.Option.Auto)
                        }
                    }
                }
                if (badgeCount >= 3) {
                    if (!stateMachine.game.pnj10Visited) {
                        pnjs = pnjs + {
                            profile(pnj10) {
                                redirect(Chapter1.datingPnj10, GoToScene.Option.Auto)
                            }
                        }
                    }
                    if (!stateMachine.game.pnj11Visited) {
                        pnjs = pnjs + {
                            profile(pnj11) {
                                redirect(Chapter1.datingPnj11, GoToScene.Option.Auto)
                            }
                        }
                    }
                    if (!stateMachine.game.pnj12Visited) {
                        pnjs = pnjs + {
                            profile(pnj12) {
                                redirect(Chapter1.datingPnj12, GoToScene.Option.Auto)
                            }
                        }
                    }
                    if (!stateMachine.game.pnj13Visited) {
                        pnjs = pnjs + {
                            profile(pnj13) {
                                redirect(Chapter1.datingPnj13, GoToScene.Option.Auto)
                            }
                        }
                    }
                    if (!stateMachine.game.pnj14Visited) {
                        pnjs = pnjs + {
                            profile(pnj14) {
                                redirect(Chapter1.datingPnj14, GoToScene.Option.Auto)
                            }
                        }
                    }
                }
                if (!stateMachine.game.eleaVisited && stateMachine.game.badgeCount > 0) {
                    pnjs = pnjs + {
                        profile(elea) {
                            redirect(Chapter1.datingElea, GoToScene.Option.Auto)
                        }
                    }
                }
                pnjs = pnjs.shuffled()
                val limit = min(2, pnjs.count())
                repeat((0 until limit).count()) { index ->
                    pnjs[index].invoke()
                }
            }
        }
    }

    scene(Chapter1.end2, EndContext("Fin 2")) {
        stateMachine.achievements.end2 = true
        narrator {
            -"Vous vous êtes bien amusé ?"
            -"Ces discussions vous auront fait passer un peu de temps…"
            -"Mais c’est tout."
            -"Un sentiment étrange vous serre la poitrine."
            -"Et si vous étiez ${
                player.withGender("passé", "passée")
            } à côté d’une conversation qui aurait pu aboutir à une jolie histoire ?"
            -"${elea.name} avait l’air sympa."
            -"Peut-être auriez-vous dû accepter son invitation à quitter cette appli de rencontre."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 2."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }

    scene(Chapter1.datingPnj2, DatingContext) {
        characters(pnj2)
        stateMachine.achievements.pnj2Visited = true
        stateMachine.game.pnj2Visited = true
        choose {
            -"Salut !"
            -"Yo, ça roule ?"
        }
        pnj2 {
            -"Salut !"
            -"${pnj2.withGender("Ravi", "Ravie")} de te rencontrer."
            -"Je commence, tu permets ?"
        }
        choose {
            "Je t’en prie !" {}
            "Euh, pourquoi ?" {
                pnj2 { -"C’est comme ça, voilà tout !" }
            }
        }
        pnj2 {
            -"Alors, je m’appelle $name"
            -"J’ai 30 ans, je suis célibataire, obviously !"
            -"Je bosse comme ${
                pnj2.withGender("assistant", "assistante")
            } de direction dans la vente."
            -"J’aime ça, c’est stable et enrichissant."
            -"Situation confortable, j’ai un CDI."
            -"Je viens tout juste de m’acheter un appart, un petit 2 pièces bien cosy."
            -"J’y vis avec Osiris, mon chat."
            -"C’est un sacré de Birmanie"
            photo("pnj-6-chat.jpg", "photo d’Osiris")
            -"Il est cute non ?"
        }
        choose {
            "oui, superbe…" {}
            "euh moi, tu sais les chats, c’est pas mon truc" {
                pnj2 {
                    -"Les chats sont les animaux les plus passionnants qui existent."
                    -"Tu t’y feras, tu verras."
                }
            }
        }
        pnj2 {
            -"Sinon : j’ai un plan sur les 5 ans à venir."
            -"On s’installe dans mon appart."
            -"Je viens de l’acheter, c’est pas pour le quitter."
            -"On se fait plaisir pendant 2 ans : voyages, sorties, etc."
            -"On profite de la vie, de notre couple, quoi !"
            -"Puis ensuite, on fonde une famille."
            -"J’ai 30 ans, tu sais…"
            -"Je veux pas attendre longtemps."
            -"Entre 33 et 35 ans, on a notre enfant, donc faut qu’on achète un appart plus grand, ou une petite maison en banlieue."
            -"Voilà ! T’en penses quoi ?"
        }
        choose {
            "Yes, j’achète ! Trop bien ton plan !" {
                choose {
                    -"J’ai les mêmes projets, tu sais ?"
                }
                choose {
                    -"Fonder une famille, tout ça…"
                }
                choose {
                    -"Et vivre avec un chat, surtout ! J’aime déjà Osiris…"
                }
            }
            "Euh, t’es pas un peu dingue, toi ?" {}
        }
        pnj2 {
            -"…"
            -"Wow… ok !"
            -"Je te sens pas toi, t’es strange…"
            -"No way, ciao !"
            badge(given = false)
        }
        narrator {
            -pnj2.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj2.name} ?"
        }
        choose() {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj3, DatingContext) {
        characters(pnj3)
        stateMachine.achievements.pnj3Visited = true
        stateMachine.game.pnj3Visited = true
        pnj3 {
            -"slt, sa va ?"
        }
        choose {
            "sava & toi ?" {
                pnj3 {
                    -"Oué. Taime bi1 kon svoi IRL ? Tm lé dand pass ?"
                }
                choose {
                    -"Quoi ???"
                    -"Désolé, mais va falloir que tu fasses un effort, je comprends rien…"
                }
                pnj3 {
                    -"Jm pa lé mito."
                    -"Osef ldzaic. Bya d !"
                }
            }
            "ça commence bien, bonjour les fautes.😛" {
                pnj3 {
                    -"MDR tu ma soulé"
                }
            }
        }
        pnj3 {
            badge(given = false)
        }
        narrator {
            -pnj3.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj3.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)

    }

    scene(Chapter1.datingPnj4, DatingContext) {
        characters(pnj4)
        stateMachine.achievements.pnj4Visited = true
        stateMachine.game.pnj4Visited = true
        pnj4 {
            -"Hello."
            -"Tu vas bien ?"
        }
        choose {
            "Oui, merci." {
                pnj4 {
                    -"${
                        pnj4.withGender(
                            "Ravi",
                            "Ravie"
                        )
                    } de faire ta connaissance en tout cas, tu m’as l’air tout à fait sympathique."
                    -"J’adore le principe de ce site"
                }
            }
            "Pfff, j’aime pas trop ce site…" {
                pnj4 {
                    -"Moi non plus en fait ! C’est bizarre comme concept"
                }
            }
        }
        pnj4 {
            -"Il suffit de se faire bien voir dès le départ, et c’est un badge assuré."
            -"C’est presque trop facile."
            -"Hum…"
            -"Trop facile oui…"
        }
        choose {
            -"Je pense que ça force à être sincère"
            -"Je pense que ça incite à mentir pour se faire bien voir ."
        }
        pnj4 {
            -"Tu trouves ?"
            -"Je ne sais pas."
            -"Je suis ${pnj4.withGender("déçu", "déçue")} maintenant."
            -"Bon, tu sais quoi ?"
            -"Tu veux pas qu’on se donne un badge, et comme ça, c’est réglé ?"
            -"Allez, moi je t’en donne un. Bye !"
            badge(given = true)
            stateMachine.game.badgeCount = stateMachine.game.badgeCount + 1
            stateMachine.achievements.badgePnj4 = true
        }
        narrator {
            -pnj4.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj4.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj5, DatingContext) {
        characters(this.pnj5)
        stateMachine.achievements.pnj5Visited = true
        stateMachine.game.pnj5Visited = true
        pnj5 {
            -"Hello ${player.name}"
        }
        choose {
            -"Hello you"
        }
        pnj5 {
            -"${player.name}…"
            -"C’est prénom, ou c’est un pseudo ?"
        }
        choose {
            "C’est un pseudo, tu penses bien !" {
                pnj5 {
                    -"Ouf, tu me rassures !"
                    -"C’est trop bizarre pour être un vrai prénom."
                    -"J’oserai même pas appeler mon chien comme ça."
                }
            }
            "C’est mon vrai prénom !" {
                pnj5 {
                    -"Euh… Tu te fous de moi, c’est ça ?"
                    -"C’est trop bizarre pour être un vrai prénom."
                    -"J’oserai même pas appeler mon chien comme ça."
                }
            }
        }
        choose {
            "OK, on va s’arrêter là." {
                bot {
                    -"Qu’as-tu pensé de ${this.pnj5.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
                pnj5 {
                    badge(given = false)
                }
            }
            "Tu vas tout de suite me présenter tes excuses si tu veux continuer à discuter avec moi." {
                pnj5 {
                    -"C’est une blague ?"
                    -"OK, marre de perdre mon temps avec des ${player.withGender("idiots", "idiotes")}"
                    -"Ciao bye bye !"
                    badge(given = false)
                }
                bot {
                    -"Qu’as-tu pensé de ${this.pnj5.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
            }
        }
        narrator {
            -pnj5.disconnect()
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj6, DatingContext) {
        characters(pnj6)
        stateMachine.achievements.pnj6Visited = true
        stateMachine.game.pnj6Visited = true
        pnj6 {
            -"Yo !"
            -"T’as une photo de nude, poussin ?"
        }
        choose {
            "Euh, non. Pourquoi je t’enverrai un nude ?" {
                pnj6 {
                    -"Ben faire des rencontres, à terme, ça veut dire se voir, non ?"
                    -"Et ensuite plus si affinités."
                    -"Et le physique c’est important, j’ai pas envie d’être ${pnj6.withGender("déçu", "déçue")}"
                    -"J’ai pas de temps à perdre avec quelqu’un dont le physique ne me revient pas."
                }
                choose {
                    "Et moi j’ai pas de temps à perdre avec quelqu’un d’aussi arriéré." {}
                    "T’as raison, voilà une photo." {
                        choose {
                            -"🖕"
                            silent("Envoi d’une photo de crotte") {
                                player {
                                    photo("img_caca_jurassic.gif", "caca")
                                }
                            }
                            silent("Quitter la discussion sans donner de badge.")
                        }
                    }
                }
                pnj6 {
                    badge(given = false)
                }
            }
            "Yes, j’ai une photo. Je te l’envoie, et après tu m’en envoies une aussi ?" {
                choose {
                    silent("Envoi d’une photo random animal nude") {
                        player {
                            photo("img_sphynx.jpeg", "Cat à poil")
                        }
                        pnj6 {
                            -"Euh, tu te fous de moi, c’est ça ?"
                        }
                        choose {
                            -"Oui"
                        }
                        choose {
                            -"ça va, on peut déconner un peu non ?"
                        }
                        pnj6 {
                            -"Non, on n’est pas là pour ça, salut."
                            badge(given = false)
                        }
                    }
                    silent("Envoi d’une photo de poêle") {
                        player {
                            photo("img_poele.jpeg", "Poêle")
                        }
                        pnj6 {
                            -"Euh, tu te fous de moi, c’est ça ?"
                        }
                        choose {
                            -"Oui"
                        }
                        choose {
                            -"ça va, on peut déconner un peu non ?"
                        }
                        pnj6 {
                            -"Non, on n’est pas là pour ça, salut."
                            badge(given = false)
                        }
                    }
                    "🖕" {
                        pnj6 {
                            -"WTF ?????"
                            -"🖕🖕🖕🖕"
                            badge(given = false)
                        }
                    }
                }
            }
        }
        narrator {
            -pnj6.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj6.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj7, DatingContext) {
        characters(pnj7)
        stateMachine.achievements.pnj7Visited = true
        stateMachine.game.pnj7Visited = true
        pnj7 {
            -"Salut ! ASV ?"
        }
        choose {
            "ASV ? C’est quoi ça ?" {
                pnj7 {
                    -"C’est un truc d’ancêtre."
                    -"Ça vient des tréfonds du web, à une époque où on payait des forfaits internet à l’heure, en priant pour que notre modem ne se déconnecte pas."
                    -"Et ASV, sur les vieux chats de l’époque, ça veut dire: Age, Sexe, Ville."
                    -"C’est une manière de se présenter rapidement."
                }
            }
            "Haha, tu viens d’où pour me demander ça ?" {
                pnj7 {
                    -"Je viens du passé."
                    -"Je suis ${
                        pnj7.withGender("un voyageur temporel", "voyageuse temporelle")
                    }, ${pnj7.withGender("tout droit sorti", "toute droit sortie")} des années 90."
                    -"Je suis un fantôme hantant le net, à la recherche de survivants de mon époque, eux aussi fan des chats AOL, caramail et MSN Messenger."
                    -"Je suis ${pnj7.withGender("vieux", "vielle")}, quoi."
                }
            }
        }
        choose {
            "Ah ok… on va en rester là si tu veux bien." {
                pnj7 {
                    -"Ok, pas de souci."
                    -"Bonne continuation !"
                }
            }
            "Haha, je vois. Bon, ASV alors ?" {
                pnj7 {
                    -"39 ans"
                    -pnj7.withGender("Homme", "Femme")
                    -"Saint-Etienne"
                }
                choose {
                    "Ah ok… on va en rester là si tu veux bien." {
                        pnj7 {
                            -"Ok, pas de souci."
                            -"Bonne continuation !"
                        }
                    }
                    "Et tu recherches quoi sur ce site ?" {
                        pnj7 {
                            -"Des rencontres ! J’ai jamais été très sociable dans la vie."
                            -"C’est plus facile pour moi sur le net."
                            -"Voilà, voilà…"
                            -"Ah ! Je dois te laisser !"
                        }
                        choose {
                            -"Euh, ok."
                            -"Déjà ???"
                        }
                        pnj7 {
                            -"Ma maman m’appelle."
                            -"Je peux pas la faire attendre."
                            -"A plus tard !"
                        }
                    }
                }
            }
        }
        pnj7 {
            badge(given = true)
            stateMachine.game.badgeCount = stateMachine.game.badgeCount + 1
            stateMachine.achievements.badgePnj7 = true
        }
        narrator {
            -pnj7.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj7.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj8, DatingContext) {
        characters(pnj8)
        stateMachine.achievements.pnj8Visited = true
        stateMachine.game.pnj8Visited = true
        pnj8 {
            -"Salut !"
            -"Tu aimes les enfants ?"
        }
        choose {
            "Oui, bien sûr." {
                pnj8 {
                    -"Ça tombe bien !"
                    -"J’en ai 6."
                }
                choose {
                    -"6 ?! Mais t’as quel âge ???"
                    -"Haha, tu te fous de moi, c’est pas possible."
                }
                pnj8 {
                    when (pnj8.gender) {
                        Gender.MAN -> {
                            -"Je suis tout à fait sérieux"
                            -"J’adore les enfants."
                            -"Et mon ex aussi."
                            -"J’ai 28 ans."
                            -"On en a eu 6 en 10 ans."
                            -"Je ne te cache pas que la pension alimentaire me coûte cher."
                            -"Et puis on oublie les anniversaires, y’en a trop !"
                            -"C’est chaud, sérieux."
                            -"Mais quel gros kiff !"
                            -"C’est pratique pour jouer à des jeux en multijoueurs."
                        }

                        Gender.WOMAN -> {
                            -"Ben j’ai 28 ans."
                            -"Avec mon ex, on a eu 6 enfants entre mes 18 et 26 ans."
                            -"J’adore être enceinte."
                            -"Quel plus grand bonheur que de donner la vie ?"
                            -"C’est notre but sur Terre : avoir une descendance et assurer la survie de l’Espèce."
                            -"Je contribue de mon mieux à ce noble but."
                        }
                    }
                }
                choose {
                    -"ok… Noble but, mais pas trop mon délire. On en reste là ?"
                    -"Fascinant ! Dommage que l’émission strip-tease ne soit plus diffusée, tu en serais un parfait sujet."
                }
            }
            "Beurk, non ! C’est dégueulasse les mioches !" {
                pnj8 {
                    -"Ah ok…"
                    -"Ta remarque puérile me laisse pourtant penser que tu en es un."
                }
            }
        }
        pnj8 {
            -"Je ne suis pas là pour perdre mon temps, ni pour m’abaisser à ton niveau."
            -"Je m’en vais comme ${pnj8.withGender("un prince", "une princesse")}"
            photo("img_inconnu_prince.gif", "Je m’en vais comme un prince")
            badge(given = false)
        }
        narrator {
            -pnj8.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj8.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj9, DatingContext) {
        characters(pnj9)
        stateMachine.achievements.pnj9Visited = true
        stateMachine.game.pnj9Visited = true
        pnj9 {
            when (pnj9.gender) {
                Gender.MAN -> {
                    -"Yo."
                    -"Tu veux voir une photo de mon engin ?"
                }

                Gender.WOMAN -> {
                    -"Hello handsome."
                    -"Tu veux voir ma touffe ?"
                }
            }
        }
        choose {
            "Yes, avec plaisir !" {
                pnj9 {
                    -"Cool !"
                    when (pnj9.gender) {
                        Gender.MAN -> {
                            photo("img_motor.jpeg", "Moteur")
                        }

                        Gender.WOMAN -> {
                            photo("img_hair.jpeg", "Cheveux")
                        }
                    }
                }
                choose {
                    -"Sérieusement ?"
                    -"Wow, tu concours pour le trophée de la pire vanne de tous les temps."
                    "T’as pas mieux en réserve ?" {
                        pnj9 {
                            -"Hum… oui, j’ai mieux !"
                            -"Tu connais la différence entre un chien et une prostituée ?"
                        }
                        choose {
                            "Euh, non." {
                                pnj9 {
                                    -"Faut vraiment être taré pour pas voir la différence !"
                                    -"Hahahah !"
                                }
                            }
                            -"Ok, je la connais."
                            -"T’as rien de mieux que des blagues pourries ?"
                        }
                    }
                }
                pnj9 {
                    -"C’est tout pour moi."
                    photo("img_sailor_job_done.gif", "sailormoon, my job here is done")
                    badge(given = true)
                    stateMachine.game.badgeCount = stateMachine.game.badgeCount + 1
                    stateMachine.achievements.badgePnj9 = true
                }
            }
            "Non, et tu devrais avoir honte de proposer ça." {
                pnj9 {
                    badge(given = false)
                }
            }
        }
        narrator {
            -pnj9.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj9.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    fragment(Chapter1.pnj10Stop) {
        pnj10 {
            -"OK, je vois…"
            -"Difficile d’imaginer que certaines personnes ont été assez bêtes pour te filer des badges."
            -"Je ne suis pas là pour perdre mon temps."
            -"On va s’arrêter là."
            badge(given = false)
        }
        bot {
            -"Qu’as-tu pensé de ${pnj10.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    fragment(Chapter1.pnj10Continue) {
        answer { answer ->
            if (answer.obscene) {
                pnj10 {
                    -"OK, je vois…"
                    -"Difficile d’imaginer que certaines personnes ont été assez bêtes pour te filer des badges."
                    -"Je ne suis pas là pour perdre mon temps."
                    -"On va s’arrêter là."
                    badge(given = false)
                }
                narrator {
                    -pnj10.disconnect()
                }
                bot {
                    -"Qu’as-tu pensé de ${pnj10.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
            } else {
                pnj10 {
                    -"$answer… Hum."
                    -"Je dois t’avouer que je ne connais pas."
                    -"Enfin, j’en ai déjà entendu parler."
                    -"Mais c’est tout."
                }
                choose {
                    "Ah oui ? C’est une HONTE ! Adieu." {
                        pnj10 {
                            -"Ah oui, carrément ?"
                        }
                    }
                    "Tu devrais essayer de t’y mettre." {
                        pnj10 {
                            -"Je n’aime pas trop qu’on me dise ce que je dois faire."
                            -"Non mais c’est vrai, à la fin ? T’es quoi ${
                                player.withGender(
                                    "un influenceur", "une influenceuse"
                                )
                            } à deux balles ???"
                            -"Bonne soirée !"
                        }
                    }
                }
                pnj10 {
                    badge(given = false)
                }
                narrator {
                    -pnj10.disconnect()
                }
                bot {
                    -"Qu’as-tu pensé de ${pnj10.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
            }
        }
    }

    scene(Chapter1.datingPnj10, DatingContext) {
        characters(pnj10)
        stateMachine.achievements.pnj10Visited = true
        stateMachine.game.pnj10Visited = true
        pnj10 {
            -"Hello !"
            -"Alors, toi aussi tu es un membre « certifié » ?"
        }
        choose {
            -"Oui !"
            -"Je viens tout juste d’avoir mes 3 badges."
        }

        pnj10 {
            -"C’est top ça. 🙂"
            -"Tu es ${
                player.withGender(
                    "le premier membre certifié",
                    "la première membre certifiée"
                )
            } avec qui je parle."
            -"Tu veux discuter de quoi ?"
        }
        choose {
            "De musique !" {
                pnj10 {
                    -"OK, parlons de ça !"
                    -"Alors… Dis-moi."
                    -"Quel est ton groupe préféré ?"
                }
                include(Chapter1.pnj10Continue)
            }
            "De BD et de mangas !" {
                pnj10 {
                    -"OK, parlons de ça !"
                    -"Alors… Dis-moi."
                    -"Quel est ta BD ou ton manga préférée ?"
                }
                include(Chapter1.pnj10Continue)
            }
            "De Cinéma et de séries !" {

                pnj10 {
                    -"OK, parlons de ça !"
                    -"Alors… Dis-moi."
                    -"Quel est ton film ou ta série préférée ?"
                }
                include(Chapter1.pnj10Continue)
            }
            "De cul !" { include(Chapter1.pnj10Stop) }
            "De rien !" { include(Chapter1.pnj10Stop) }
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj11, DatingContext) {
        characters(pnj11)
        stateMachine.achievements.pnj11Visited = true
        stateMachine.game.pnj11Visited = true

        pnj11 {
            -"Hey, salut !"
        }
        choose {
            -"Yo !"
            -"Bonsoir !"
        }
        pnj11 {
            -"Bon, ne perdons pas de temps sur ce site."
            -"Je te propose un truc :"
            -"On s’échange nos numéros et on s’appelle ?"
            -"C’est plus sympa de discuter de vive voix…"
        }
        choose {
            "Yes, avec plaisir !" {
                pnj11 {
                    -"Trop bien ! Voici le mien :"
                    -"06 39 98 38 38"
                    -"Et le tien ?"
                }
                answer { phoneNumber ->
                    when {
                        phoneNumber.isPhoneNumber -> {
                            stateMachine.game.phoneNumber = phoneNumber
                            pnj11 {
                                -"Bah."
                                -"…"
                                -"J’essaie de t’appeler, mais ça ne donne rien !"
                                -"Tu m’as donné un faux numéro ?"
                            }
                            choose {
                                -"Bien sûr que c’est un faux numéro !"
                                -"Mais non, je t’assure, essaie à nouveau."
                            }
                        }

                        phoneNumber.obscene -> {
                            pnj11 {
                                -"Ah oui, je vois le genre…"
                                -"La vulgarité, c’est tellement puéril."
                            }
                        }

                        else -> {
                            pnj11 {
                                -"Eh, mais c’est pas un numéro ça…"
                            }
                        }
                    }
                }
                pnj11 {
                    -"Je n’aime pas trop qu’on se moque de moi."
                    -"Je suis là pour trouver l’Amour, le grand, le vrai !"
                    -"Je vais continuer mes recherches"
                    -"Allez, bonne soirée !"
                    badge(given = false)
                }
                narrator {
                    -pnj11.disconnect()
                }
                bot {
                    -"Qu’as-tu pensé de ${pnj11.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
            }
            "Ça va pas, non ?!" {
                choose {
                    -"Je donne pas mon numéro comme ça."
                }
                choose {
                    -"Restons-en là."
                }
                bot {
                    -"Qu’as-tu pensé de ${pnj11.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
            }
        }
        redirect(Chapter1.datingChoices)
    }

    fragment(Chapter1.astrologicalSign) {
        pnj12 {
            -"Mon Dieu, comme c’est dommage !!!"
        }
        choose {
            -"Quel dommage, oui !"
            -"Oh la la, tu as raison, ne prenons aucun risque !"
        }

        pnj12 {
            -"Les pierres et les étoiles ne mentent pas."
            -"Il faut savoir les écouter, elles guident nos vies."
            -"J’ai été ${pnj12.withGender("sauvé", "sauvée")} par un quartz rose une fois."
            -"Alors je t’en prie, écoute ce message transmis par mon améthyste, ma pierre la plus chargée en énergie lunaire :"
            -"« Ne provoque pas le chien de garde, il risquerait de te mordre. »"
        }
        choose {
            "Wow, ça veut dire quoi ça ?" {
                pnj12 {
                    -"Mon améthyste prévoit l’avenir avec une précision sans égale."
                    -"Je pense qu’elle t’avertit d’un danger à venir."
                    -"Et d’une personne qu’il te faudra éviter d’énerver."
                    -"Sur ce, ${player.withGender("aventurier", "aventurière")} des mondes immatériaux."
                }
            }
            "Promis, je ferai attention !" {}
        }
        pnj12 {
            -"Si les étoiles du destin font que nos chemins se recroisent, je te tirerai les cartes."
            -"Je te souhaite bon vent !"
            badge(given = true)
            stateMachine.game.badgeCount = stateMachine.game.badgeCount + 1
            stateMachine.achievements.badgePnj12 = true
        }
    }

    scene(Chapter1.datingPnj12, DatingContext) {
        characters(pnj12)
        stateMachine.achievements.pnj12Visited = true
        stateMachine.game.pnj12Visited = true
        pnj12 {
            -"Bien le bonsoir, compagnon astral."
        }
        choose {
            -"Euh… Bonsoir"
            -"WTF ? 😲"
        }
        pnj12 {
            -"Je suis ${
                pnj12.withGender("un", "une")
            } adepte de ce que le commun des mortels appelle l’astrologie."
            -"Quel est ton signe astral ?"
        }
        answer { astrologicalSign ->
            stateMachine.game.astrologicalSign = astrologicalSign
            when {
                astrologicalSign.lowercase().split(" ").any {
                    it in listOf(
                        "bélier",
                        "taureau",
                        "gémeaux",
                        "cancer",
                        "lion",
                        "vierge",
                        "balance",
                        "scorpion",
                        "sagittaire",
                        "capricorne",
                        "verseau",
                        "poissons",
                        "poisson"
                    )
                } -> {
                    pnj12 {
                        -"Hum… Tu es donc $astrologicalSign"
                        -"Je suis au regret de t’informer que nous ne sommes pas compatibles."
                        -"La nouvelle lune s’étant levée dans le signe du bélier, elle insuffle joie et sérénité dans ma vie."
                    }
                    choose {
                        -"😳"
                        -"Haha, bonne vanne ! 😂😂"
                    }
                    pnj12 {
                        -"Mes pierres énergétiques sont chargées d’énergie positive."
                        -"Elles vibrent, elles communiquent avec moi et m’avertissent du danger qu’il y a à te côtoyer !"
                        -"Non, $astrologicalSign n’est pas un signe avec lequel je peux partager mon être astral."
                    }
                    include(Chapter1.astrologicalSign)
                }

                astrologicalSign.lowercase().split(" ").any {
                    it in listOf(
                        "rat",
                        "souris",
                        "bœuf",
                        "boeuf",
                        "vache",
                        "buffle",
                        "tigre",
                        "lapin",
                        "chat",
                        "dragon",
                        "serpent",
                        "cheval",
                        "chèvre",
                        "singe",
                        "coq",
                        "phoenix",
                        "chien",
                        "cochon",
                        "sanglier",
                        "éléphant"
                    )
                } -> {
                    pnj12 {
                        -"$astrologicalSign ? C’est de l’astrologie chinoise ça !"
                        -"Malheureusement, ça n’est pas ma spécialité."
                        -"C’est toutefois intéressant, que tu définisses ton signe astro de la sorte."
                        -"J’adorerais que nous soyons compatibles, mais…"
                        -"Non, $astrologicalSign n’est pas un signe avec lequel je peux partager mon être astral."
                    }
                    include(Chapter1.astrologicalSign)
                }

                else -> {
                    pnj12 {
                        -"J’ai bien peur que ça ne soit pas un signe astral."
                        -"Je dirai même que $astrologicalSign est le signe que nous ferions mieux de clore cette discussion ici."
                        -"Bon vent !"
                        badge(given = false)
                    }
                }
            }
        }
        narrator {
            -pnj12.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj12.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }


    fragment(Chapter1.badAnswer) {
        elea {
            -"Ah ! Je t’ai eu ! Tu n’as pas lu le livre."
        }
        choose {
            -"Euh… hum… je… 😬"
            -"Je voulais juste t’impressionner. 🙈"
        }
        elea {
            -"Hum, ne me refais plus ce coup à l’avenir. 😠💥😤"
        }
    }

    scene(Chapter1.datingPnj13, DatingContext) {
        characters(pnj13)
        stateMachine.achievements.pnj13Visited = true
        stateMachine.game.pnj13Visited = true

        pnj13 {
            -"Bonsoir !"
        }
        choose {
            -"Bonsoir !"
            -"Hello, ${player.withGender("ravi", "ravie")} de te rencontrer."
        }
        pnj13 {
            -"J’ai envie de te proposer un petit jeu, pour faire connaissance plus facilement."
            -"Ça te branche ?"
        }
        choose {
            "Non, ça va…" {
                pnj13 {
                    -"Ah ok !"
                    -"Haha, on peut dire que t’es ${player.withGender("franc", "franche")} du collier, toi."
                    -"J’apprécie, au moins on ne va pas perdre de temps."
                    -"Allez, salut !"
                    badge(given = true)
                    stateMachine.game.badgeCount = stateMachine.game.badgeCount + 1
                    stateMachine.achievements.badgePnj13 = true
                }
            }
            "Oui, grave !" {
                pnj13 {
                    -"Cool !!! 🙂"
                    -"Alors…"
                    -"Tu m’envoies une musique que tu aimes."
                    -"Je l’écoute."
                    -"Et je te réponds avec une autre musique. 🙂"
                    -"C’est quoi ton genre préféré ?"
                }
                choose {
                    -"musique classique"
                    -"variété française"
                    -"rap/hip-hop"
                    -"Rock"
                    -"J-Pop"
                    -"électro"
                    -"BO de films et séries"
                    -"jazz"
                }
                pnj13 {
                    -"Ah oui, vraiment ?"
                    -"Bof…"
                    -"Je ne comprends pas comment on peut écouter ce genre de trucs."
                    -"C’est d’un banal."
                    -"Et ça n’est pas du tout ce que j’attends d’une rencontre."
                    -"Si on n’est pas sur le même tempo, on ne peut pas jouer la même partition."
                    -"Je te l’accorde, c’est dur, mais je suis un instrument qui te met un vent."
                    -"Ciao !"
                    badge(given = false)
                }
            }
        }
        narrator {
            -pnj13.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj13.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }
        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingPnj14, DatingContext) {
        characters(pnj14)
        stateMachine.achievements.pnj14Visited = true
        stateMachine.game.pnj14Visited = true

        pnj14 {
            -"Salut à toi."
            -"Tout se passe bien de ton côté sur ce site ?"
        }
        choose {
            "Oui, c’est l’éclate." {
                pnj14 {
                    -"Tant mieux pour toi."
                    -"Je ne peux pas en dire autant…"
                }
            }
            "Bof, je tombe que sur des gens chelous." {
                pnj14 {
                    -"Moi aussi !"
                    -"${pnj14.withGender("Ravi", "ravie")} d’entendre ça."
                }
            }
        }
        pnj14 {
            -"Pour éviter les déceptions, j’ai mis en place un petit questionnaire."
            -"Tu veux y participer ?"
        }
        choose {
            "Non, merci !" {
                pnj14 {
                    -"Je comprends !"
                    -"Passe une bonne soirée !"
                    badge(given = false)
                }
            }
            "Yes, avec plaisir !" {
                pnj14 {
                    -"Trop cool !"
                    -"Allez, c’est parti"
                }
                choose {
                    -"${player.withGender("Végétarien", "Végétarienne")} / Vegan"
                    -"${player.withGender("mangeur", "mangeuse")} de viande"
                }
                pnj14 {
                    -"Ok… Question suivante."
                }

                choose {
                    -player.withGender("fumeur", "fumeuse")
                    -"le tabac, c’est tabou"
                }
                pnj14 {
                    -"Ah oui ? Bon…"
                    -"Continuons"
                }
                choose {
                    -"Windows"
                    -"Mac"
                }
                pnj14 {
                    -"Haha, ça ne me surprend pas."
                }
                choose {
                    -player.withGender("sportif", "sportive")
                    -player.withGender("glandeur", "glandeuse")
                }
                pnj14 {
                    -"Ah ouais, d’accord…"
                }
                choose {
                    -"football"
                    -"rugby"
                }
                pnj14 {
                    -"Eh eh ."
                }
                choose {
                    -"Netflix"
                    -"Le Cinéma"
                }
                pnj14 {
                    -"No comment…"
                    -"Question suivante :"
                }
                choose {
                    -"plage"
                    -"montagne"
                }
                pnj14 {
                    -"Oh, sérieusement ?!"
                }
                choose {
                    -"la bouffe"
                    -"le sexe"
                }
                pnj14 {
                    -"Ah ! Les deux nourrissent, en même temps."
                }
                choose {
                    -"voyages à l’étranger"
                    -"jeux vidéos"
                }
                pnj14 {
                    -"Trop cool !"
                    -"Allez, dernière question :"
                }
                choose {
                    -"collectionner les cartes Magic the Gathering"
                    -"collectionner les timbres"
                }

                pnj14 {
                    -"Ok, merci."
                    -"Maintenant, on compte les points."
                    -"Hum…"
                    -"Bon…"
                    -"OK, alors tu as un score de ${listOf(0, 1, 2, 3, 4).shuffled().first()} sur 10."
                    -"C’est trop peu, tu t’en doutes."
                }
                choose {
                    -"Attends, je peux me rattraper !"
                    -"De toute façon, il est nul ton questionnaire."
                }
                pnj14 {
                    -"Tu peux en penser ce que tu veux, mais mon questionnaire est éprouvé."
                    -"Je suis ${
                        pnj14.withGender("influenceur", "influenceuse")
                    } sur Linkedin, et j’ai peaufiné ce sublime questionnaire pendant de nombreuses semaines."
                    -"Mes followers en sont ravis !"
                    -"Preuve en est, les 12 likes qu’il a reçu."
                    -"Il est parfait, et permet à chaque fois de trouver le partenaire idéal."
                    -"Toi, tu ne l’es pas."
                    -"Next !"
                    badge(given = false)
                }
            }
        }
        narrator {
            -pnj14.disconnect()
        }
        bot {
            -"Qu’as-tu pensé de ${pnj14.name} ?"
        }
        choose {
            -"DONNER UN BADGE"
            -"NE PAS DONNER DE BADGE"
        }

        redirect(Chapter1.datingChoices)
    }

    scene(Chapter1.datingElea, DatingContext) {
        characters(elea)
        stateMachine.game.eleaVisited = true
        stateMachine.achievements.eleaVisited = true
        elea {
            -"Salut !"
        }
        choose {
            "Salut ! Tu commences un sujet ou tu veux que j’en lance " {
                elea {
                    -"Ah, je veux bien me lancer, si tu permets"
                }
            }
            "Salut ! Je suis pas hyper à l’aise sur ce genre d’appli…" {
                elea {
                    -"Comme je te comprends… Je ne tombe que sur des gens bizarres depuis tout à l’heure."
                }
            }
        }
        elea {
            -"« ${player.name} », c’est un pseudo ?"
        }
        choose {
            "Je m’appelle vraiment comme ça." {
                elea {
                    if (player.name.obscene) {
                        -"Allez, je ne vais pas chercher plus loin."
                        -"OK, je t’appellerai « ${player.name} » si c’est ce que tu veux."
                    } else {
                        -"OK, très bien."
                        -"${elea.withGender("Enchanté", "Enchantée")} ${player.name}."
                    }
                }
            }
            "C’est un pseudo, bien sûr." {
                elea {
                    -"Tu as raison d’utiliser un pseudo sur Internet."
                    -"Mais là, on est sur un site de rencontres."
                    -"Tu ne veux pas me dire ton vrai prénom ?"
                }
                choose {
                    "Non, je ne préfère pas." {
                        elea {
                            -"C’est ton choix, je comprends."
                        }
                    }
                    "Oui, si tu veux." {
                        answer { name ->
                            stateMachine.game.player_name = name
                            if (name.obscene) {
                                elea {
                                    -"Allez, je ne vais pas chercher plus loin."
                                    -"OK, je t’appellerai « ${player.name} » si c’est ce que tu veux."
                                }
                            } else {
                                elea {
                                    -"OK, très bien."
                                    -"${elea.withGender("Enchanté", "Enchantée")} ${player.name}."
                                }
                            }
                        }
                    }
                }
            }
        }
        elea {
            -"Tiens, je te propose un petit jeu pour faire plus facilement connaissance."
            -"Cinéma ou Littérature ?"
            -"Je t’aurais bien proposé « Musique » mais c’est pas trop mon truc 😅"
        }

        choose {
            "Cinéma !" {
                stateMachine.game.talkAboutBook = false
                elea {
                    -"C’est quoi ton film préféré ?"
                }
                answer { movie ->
                    stateMachine.game.movie = movie
                    if (movie.lowercase().split(" ").contains("prestige")) {
                        elea {
                            -"C’est pas vrai !"
                            -"C’est mon film préféré à moi aussi !"
                            -"Avec Christian Bale, et Hugh Jackman."
                            -"Batman et Wolverine dans le même film !"
                        }
                        choose {
                            -"Sacré crossover !"
                            -"T’aurais aussi pu dire « Van Helsing et John Connor dans le même film »."
                        }
                        elea {
                            -"Haha, pas mal !"
                            -"Avec mon père, on ADORAIT Le Prestige !"
                            -"Y’a tout dans ce film : l’intrigue, la magie, l’enquête…"
                            -"Je l’oublierai jamais, on l’a regardé des tonnes de fois…"
                            -"C’est un bon souvenir.🙂"
                        }
                    } else {
                        elea {
                            -"Ah, je l’ai jamais vu !"
                            -"Moi, mon film préféré c’est Le Prestige !"
                            -"Avec Christian Bale, et Hugh Jackman."
                            -"Batman et Wolverine dans le même film !"
                        }
                        choose {
                            -"Sacré crossover !"
                            -"T’aurais aussi pu dire « Van Helsing et John Connor dans le même film »."
                        }
                        elea {
                            -"Haha, pas mal !"
                            -"Avec mon père, on l’ADORAIT Le Prestige !"
                            -"Y’a tout dans ce film : l’intrigue, la magie, l’enquête…"
                            -"Je l’oublierai jamais, on l’a regardé des tonnes de fois…"
                            -"C’est un bon souvenir.🙂"
                        }
                        narrator {
                            "Vous avez obtenu une information importante sur le passé de ${elea.name}"
                        }
                        choose {
                            "Je l’ai vu, je l’aime bien aussi." {
                                elea {
                                    -"Ah cool !🙂"
                                    -"J’adore les récits énigmatiques comme celui-là."
                                }
                            }
                            "Ah, moi je l’aime pas trop.." {
                                elea {
                                    -"Ah, dommage."
                                    -"Tu devrais lui donner sa chance, je t’assure qu’il est vraiment top."
                                    -"OK, c’est pas le film du siècle, mais c’est mon film préféré."
                                    -"LE PRESTIGE, remate-le à l’occasion !"
                                }
                            }
                            "Je l’ai jamais vu !" {
                                elea {
                                    -"Ah, dommage."
                                    -"Tu devrais lui donner sa chance, je t’assure qu’il est vraiment top."
                                    -"OK, c’est pas le film du siècle, mais c’est quand même mon film préféré."
                                    -"LE PRESTIGE, mate-le à l’occasion !"
                                }
                            }
                        }
                    }
                }
                elea {
                    -"En tout cas, c’est sympa de parler cinéma, ça aide à bien briser la glace…"
                }
            }
            "Littérature." {
                stateMachine.game.talkAboutBook = true
                elea {
                    -"C’est quoi ton livre préféré ?"
                }
                answer { book ->
                    stateMachine.game.book = book
                    if (book.lowercase().split(" ").contains("jangada")) {
                        elea {
                            -"Non, c’est pas possible ?!"
                            -"C’est le mien aussi ! C’est complètement fou !"
                            -"En plus c’est loin d’être un livre aussi populaire que ça."
                            -"Quelle coïncidence… A croire qu’on s’est déjà rencontrés dans le passé. 🙂"
                            -"Tu te souviens du nom du personnage principal ?"
                        }
                        choose {
                            "Phileas Fogg" { include(Chapter1.badAnswer) }
                            "Joam Garral" {
                                elea {
                                    -"C’est bien ça oui."
                                    -"Je te testais pour savoir si tu l’avais vraiment lu."
                                }
                            }
                            "Capitaine Nemo" { include(Chapter1.badAnswer) }
                            "Docteur Samuel Fergusson" { include(Chapter1.badAnswer) }
                            "Elie Semoun" { include(Chapter1.badAnswer) }
                        }
                        elea {
                            -"Mon père l’adorait ce bouquin, et c’est lui qui me l’a fait découvrir."
                            -"C’est pas le plus connu de Jules Vernes, mais l’intrigue est top, et y’a tout un truc autour d’une énigme chiffrée, et j’adore ça."
                        }
                    } else {
                        elea {
                            -"Ah je l’ai jamais lu !"
                            -"Moi, mon livre préféré c’est La Jangada, de Jules Vernes."
                            -"Mon père l’adorait ce bouquin, et c’est lui qui me l’a fait découvrir."
                            -"C’est pas le plus connu de Jules Vernes, mais l’intrigue est top, et y’a tout un truc autour d’une énigme chiffrée, et j’adore ça."
                        }
                        narrator {
                            -"Vous avez obtenu une information importante sur le passé de ${elea.name}"
                        }
                        choose {
                            "Je l’ai lu, je l’aime bien aussi." {
                                elea {
                                    -"Ah cool !🙂"
                                    -"J’adore les récits énigmatiques comme celui-là."
                                }
                            }
                            "Ah, moi je l’aime pas trop…" {
                                elea {
                                    -"Ah, dommage."
                                    -"Je t’assure qu’il est vraiment top."
                                    -"C’est vraiment un bon bouquin, qui restera un tournant dans mon parcours"
                                    -"Redonne lui sa chance."
                                }
                            }
                            "Je l’ai jamais lu !" {
                                elea {
                                    -"Ah, dommage."
                                    -"Tu devrais lui donner sa chance, je t’assure qu’il est vraiment top."
                                    -"C’est vraiment un bon bouquin, qui restera un tournant dans mon parcours."
                                    -"Retiens bien ce titre: La Jangada."
                                    -"Essaie de le lire à l’occasion."
                                    -"En tout cas, c’est sympa de parler littérature, ça aide à bien briser la glace…"
                                }
                            }
                        }
                    }
                }
                elea {
                    -"En tout cas, c’est sympa de parler littérature, ça aide à bien briser la glace…"
                }
            }
        }
        elea {
            -"Pour moi, les Arts, la Culture, c’est primordial dans nos vies."
            -"Je dirai même qu’on passe notre vie à se cultiver."
        }
        choose {
            -"Tu as raison…"
        }
        elea {
            -"Qu’on le veuille ou non, l’Art et la Culture nous entourent."
            -"On écoute de la musique en marchant dans la rue."
            -"On voit une affiche à chaque arrêt de bus"
            -"On lit des journaux, on écrit sur les réseaux sociaux, on a accès sur Internet à une infinité d’œuvres, d’analyses, de textes, de films, etc."
            -"Et je trouve que c’est plutôt génial, non ?"
        }
        choose {
            -"On a aussi accès au pire de ce qu’on peut produire."
            -"C’est vrai, c’est super."
        }
        elea {
            -"J’aime voir le positif."
            -"Tout n’est pas rose, certes."
            -"Mais je préfère m’émerveiller des bienfaits de l’Humanité, plutôt que de déprimer en pensant à ce qu’on fait mal."
        }
        choose {
            "C’est un point de vue admirable." {
                elea {
                    -"Merci ${player.name}."
                    -"Il y a tellement de choses qui vont mal en ce monde…"
                    -"Pauvreté, inégalités, dérèglement climatique, etc."
                    -"L’égoïsme est une norme."
                    -"Et je déteste ça."
                    -"L’Art, c’est le partage."
                }
            }
            "Je pense que tu te voiles un peu la face." {
                elea {
                    -"Ah oui, tu trouves ?"
                    -"On ne peut pas dire que le monde se porte si bien que ça."
                    -"Guerres, pandémies, dérèglement climatique."
                    -"Si j’étais pessimiste, je dirai même qu’on se rapproche d’une fin affreuse et inéluctable."
                    -"Alors pour ne pas penser à ça, je me réfugie un peu dans différentes formes d’Art."
                    -"L’Art, c’est l’évasion."
                }
            }
        }
        elea {
            -"Et les films, la littérature, la musique, les Arts en général, c’est un bon moyen de connecter les gens."
        }
        choose {
            -"C’est vrai, tu marques un point."
            -"Hum… Tu ne m’as pas convaincu."
        }
        elea {
            -"Regarde, la première chose dont on a parlé, c’est de Culture."
            -"Je pense que la place de la Culture dans un pays est un bon baromètre de l’état d’un peuple, d’une civilisation."
            -"Si une société prend la peine de nourrir l’esprit de ses concitoyens, en permettant à des artistes de vivre de leur Art…"
            -"C’est qu’elle se porte plutôt bien."
        }
        choose {
            "Une Société qui va bien, ce sont des services sociaux performants." {
                elea {
                    -"Oui, tu as raison."
                    -"C’est une Société qui prend soin des plus faibles."
                    -"C’est de la solidarité."
                    -"De l’égalité."
                }
            }
            "Une Société qui va bien, c’est avant tout des gens qui s’enrichissent." {
                elea {
                    -"Je ne suis pas du tout d’accord avec toi."
                    -"Je crois en l’égalité, à la solidarité avec les plus fragiles."
                    -"Le profit, envers et contre tout, ne peut qu’amener à plus de disparités entre les gens."
                }
            }
        }
        elea {
            -"Pour en revenir à la culture,"
            -"Je pense que ceux qui ont la chance de consacrer du temps à la littérature, à la peinture, au théâtre, à la musique, s’offrent ainsi les moyens de s’élever intellectuellement."
            -"C’est une façon de s’affirmer, de progresser, et d’échanger avec les autres."
            -"Pour conclure, je dirai même que l’Art, c’est le ciment d’une Civilisation."
        }
        choose {
            -"Retrouvez ${elea.name} en direct sur Twitch pour des cours de philosophie."
            -"Toi tu t’asseyais toujours au premier rang en classe, non ?"
        }
        elea {
            -"Hahaha."
            -"J’ai un peu monopolisé la conversation, excuse-moi."
            hesitation()
            -"Tu dois trouver mon discours un peu chiant, non ? 😬"
        }
        choose {
            "Non, je pense que tu as raison, et c’est passionnant." {}
            "Pas chiant, mais je ne suis pas d’accord." {
                elea {
                    -"Si tu n’es pas d’accord c’est que tu es assez cultivé pour te faire un avis. 🙂"
                }
                choose {
                    -"Hum, vu comme ça… 🙂"
                    -"Mouais… Changeons de sujet."
                }
            }
        }
        elea {
            -"C’était très sérieux pour une première discussion. 🙂"
            -"On devrait détendre un peu l’atmosphère."
            -"Hum, de quoi on pourrait parler maintenant…"
        }
        choose {
            "de musique" {
                elea {
                    -"Oh…"
                    -"C’est pas mon sujet de prédilection, mais…"
                    -"Allez, si tu veux."
                    -"Tu joues d’un instrument ?"
                }
                choose {
                    "Oui" {
                        elea {
                            -"Super, tu joues de quoi ?"
                        }
                        choose {
                            "de la scie musicale" {
                                elea {
                                    -"Ah oui, c’est très impressionnant !"
                                    -"Je suis en totale admiration.Rencontrer quelqu’un d’aussi talentueux, c’est… wouah…"
                                    -"Je ne me sens pas à la hauteur."
                                    -"J’adorerais assister à un de tes concerts !"
                                }
                                choose {
                                    -"Tu ne devrais pas te moquer ! Je suis ${
                                        player.withGender("le plus talentueux", " la plus talentueuse")
                                    } dans ce domaine."
                                }
                                choose {
                                    -"La scie musicale, c’est un art qui mérite d’être reconnu à sa juste valeur."
                                }
                                elea {
                                    -"HAHAHA ! J’adore ! 😂😂😂"
                                }
                            }
                            "de la guitare" {
                                elea {
                                    -"Ah oui ? C’est génial ça !"
                                    -"Et t’es plutôt bon ?"
                                }
                                choose {
                                    -"Mes voisins ne se sont pas encore plaints en tout cas."
                                    -"Oh, je joue juste pour moi, et ça fait de jolies stories sur Insta."
                                }
                            }
                            "J’arrive à faire des bruits de prout avec mes mains." {
                                elea {
                                    -"Ah oui, c’est très impressionnant !"
                                    -"Je suis en totale admiration.Rencontrer quelqu’un d’aussi talentueux, c’est… wouah…"
                                    -"Je ne me sens pas à la hauteur."
                                    -"J’adorerais assister à un de tes concerts !"
                                }
                                choose {
                                    -"Tu ne devrais pas te moquer ! Je suis ${
                                        player.withGender("le plus talentueux", " la plus talentueuse")
                                    } dans ce domaine."
                                }
                                choose {
                                    -"Péter avec les mains demande de nombreuses heures d’entrainement,une parfaite maîtrise de la moiteur des mains, un sens du rythme et de l’harmonie."
                                }
                                elea {
                                    -"HAHAHA ! J’adore ! 😂😂😂"
                                }
                            }
                        }
                        elea {
                            -"C’est marrant, ça me fait penser à une histoire courte, que j’ai lue quelque part."
                            -"C’est l’histoire de Brian, un jeune mec hyper amoureux de Lucia, une fille de son lycée."
                            -"Malheureusement, ça n’est pas réciproque."
                            -"Lucia n’a d’yeux que pour Fred, un guitariste moyen aux cheveux longs, tout juste capable d’enchaîner 3 accords sur une chanson de Tryo."
                        }
                        choose {
                            "C’est une histoire triste ?" {
                                elea {
                                    -"Non non, promis, c’est plutôt marrant."
                                }
                            }
                            "Haha, on a tous eu un guitariste comme ça au lycée ou en soirée." {
                                elea {
                                    -"Haha, c’est clair !"
                                }
                            }
                        }
                        elea {
                            -"Bref, je continue."
                            -"Notre héros, déterminé à conquérir le coeur de sa belle, se met lui aussi à la guitare !"
                            -"Sait-on jamais, ça pourrait plaire à la jeune fille."
                            -"Alors il s’entraîne, en secret."
                            -"Pendant des mois, il apprend, il pratique, il galère, il se décourage."
                            -"Mais il reste motivé; car il a un but :"
                            -"faire un petit concert à la fête de fin d’année du lycée"
                        }
                        choose {
                            -"Cette fête de fin d’année, ce serait pas la Féérie dansante des Sirènes ?"
                            -"Cette fête de fin d’année, c’est pas un bal en l’honneur de Carrie ?"
                            -"Il devrait plutôt demander à son grand père Rick de lui préparer un Elixir d’Amour !"
                        }

                        elea {
                            -"Eh ! J’aime bien tes refs !"
                            -"Un bon point pour toi ! 😻"
                        }

                        choose {
                            -"😏"
                        }
                        choose {
                            -"Et il se passe quoi ensuite ?"
                        }

                        elea {
                            -"Arrive le jour de la fête."
                            -"Brian monte sur la petite scène de la cour du lycée."
                            -"Malgré le trac, il est confiant."
                            -"Et devant les regards ébahis de ses camarades de classe, il sort la meilleure reprise de Nirvana de l’histoire de son lycée."
                            -"Public en transe, et pari réussi : Lucia a les yeux qui pétillent."
                            -"Mais soudain, c’est le drame…"
                        }
                        choose {
                            -"Une explosion nucléaire anéantit l’Humanité et il a fait tout ça pour rien ?"
                            -"Lucia n’aime pas Nirvana ?"
                        }

                        elea {
                            -"Pire !"
                            -"Son plus grand cauchemar se réalise."
                            -"Alors que les applaudissements prennent fin, une douce et belle mélodie se fait entendre."
                            -"Ce sont juste quelques notes, à peine audibles…"
                            -"Qui se transforment en une mélodie de plus en plus riche et complexe."
                            -"Tout le monde se précipite dans la salle de musique, d’où provient cette sublime interprétation de la BO d’Amélie Poulain."
                            -"Et qui se retrouve là, seul dans la salle, à jouer du piano, l’air détaché ?"
                        }

                        choose {
                            -"Fred, le rival du héros !"
                        }

                        elea {
                            -"Oui, le rival…"
                            -"Ce petit malin de Fred avait sacrément bien préparé son coup."
                            -"Et Lucia, sous le charme du talentueux Fred, oubliera complètement le pauvre Brian."
                            -"Mais ça finit bien ! Brian s’est trouvé une passion."
                            -"Il deviendra guitariste, et en fera son métier."
                            -"Fin."
                        }

                        choose {
                            -"Ok, donc c’est l’histoire d’une fille superficielle qui tombe dans les bras du premier mec capable d’enchainer 2 notes sur un instrument."
                        }
                        elea {
                            -"Oh, ${player.withGender("le blasé", "la blasée")} !"
                            -"Cette histoire a une meilleure morale que ça !"
                        }

                        choose {
                            -"Ah oui, et c’est quoi ?"
                        }
                        elea {
                            -"La morale, de cette histoire, c’est que si t’as un but, que tu es motivé, et que tu travailles à fond, tu ne peux que progresser."
                            -"Et peut-être même trouver ta voie."
                            -"C’est toujours bien de se fixer des challenges, et de voir de quoi on est capable."
                        }
                    }
                    "Non" {
                        elea {
                            -"Moi non plus."
                            -"Hahaha !"
                            -"Je t’avais dit que c’était pas un sujet qui m’inspire."
                            -"J’aurais adoré savoir me servir d’un instrument."
                        }
                    }
                }
            }
            "de dating" {
                elea {
                    -"Hum, de dating ?"
                    -"Ok, je t’écoute."
                    -"Après tout, on est sur CupidLov pour rencontrer d’autres personnes."
                }
                choose {
                    "C’est quoi ta pire expérience de dating ?" {
                        elea {
                            -"Oh, Bonne question…"
                            -"Hum, laisse-moi réfléchir…"
                            -"Je ne vais pas te parler ${
                                elea.withLookingFor(
                                    "des tonnes de mecs qui envoient une photo de leur teub",
                                    "des tonnes de meufs qui ne répondent même pas à tes messages"
                                )
                            }."
                            -"Ah, oui, je sais !"
                            -"Il y a quelques jours, je suis tombé sur ${
                                elea.withLookingFor(
                                    "ce mec",
                                    "cette fille"
                                )
                            } sur CupidLov."
                            -"On a discuté pendant une bonne heure."
                            -"Enfin, quand je dis « discuté ». Je l’ai surtout écouté me raconter en détail ses 4 ans de relations avec son ex."
                            -"A chaque fois que j’essayais de changer de sujet, ${
                                elea.withLookingFor(
                                    "il",
                                    "elle"
                                )
                            } ramenait tout à sa relation."
                            -"Tu imagines la frustration ?"
                            -"Je ne pouvais pas en placer une, ni choisir vraiment de quoi parler avec ${
                                elea.withLookingFor(
                                    "lui",
                                    "elle"
                                )
                            }."
                            -"TOUT tournait autour de son obsession pour son ex."
                        }
                        choose {
                            "J’imagine tout à fait ce que tu devais ressentir." {
                                choose { -"Je déteste me retrouver dans une situation où je subis sans pouvoir y faire grand chose." }
                            }
                            "Oh, l’horreur." {
                                choose { -"Je n’aimerais pas être bloqué dans une discussion sur laquelle je n’ai aucune prise." }
                            }
                        }
                        elea {
                            -"Oui, une discussion, c’est un échange mutuel."
                            -"Et puis si tu n’as que ton ex en tête, va lui parler ${
                                elea.withLookingFor(
                                    "à lui",
                                    "à elle"
                                )
                            }, et ne me mets pas dans cette position étrange."
                            -"Je te passe les détails, mais c’est devenu vraiment glauque au bout d’un moment."
                            -"Et j’ai même dû finir par ${
                                elea.withLookingFor(
                                    "le bloquer parce qu’il",
                                    "la bloquer parce qu’elle"
                                )
                            } ne me lâchait pas la grappe."
                            -"Enfin bref…"
                        }
                    }
                    "C’est quoi ta meilleure expérience de dating ?" {
                        elea {
                            -"Ma meilleure expérience de dating ?"
                            -"Hum, je sais pas trop…"
                            -"Tu vas te moquer de moi, mais…"
                            -"Je n’en ai pas eu énormément."
                        }
                        choose {
                            "Ah oui ? Moi j’en ai eu de superbes, rien que sur ce site." {
                                elea {
                                    -"Eh bien tu as eu de la chance. "
                                }
                            }
                            -"Je te comprends, moi non plus…"
                        }
                        elea {
                            -"C’est facile de rencontrer du monde, sur une appli comme CupidLov."
                            -"Mais c’est beaucoup plus compliqué de rencontrer des personnes intéressantes, qui ont des choses à nous apporter."
                            -"En fait, les sites de rencontre, c’est exactement comme dans la vraie vie."
                            -"On croise des centaines, des milliers de gens, mais seuls quelques-uns vont véritablement avoir un impact sur nous."
                        }
                        choose {
                            "C’est comme une boîte de chocolats; on ne sait jamais sur quoi on va tomber." {
                                elea {
                                    -"Haha, exactement."
                                    -"Référence facile, mais efficace."
                                    -"J’aime bien."
                                    photo("img_forest_gump.gif", "Forest gump")
                                    -"Bref !"
                                }
                            }
                            "C’est comme une boîte de chocolats; si t’en manges trop ça fait mal au ventre." {
                                elea {
                                    -"Euh… ok ${player.name}."
                                    -"Désolé, mais c’est pas très drôle. 😅"
                                    -"Enfin, je ne t’en veux pas."
                                    photo("img_lets_go_to_the_movies.jpeg", "Stupide ?")
                                    -"Bref !"
                                }
                            }
                        }

                    }
                }
                elea {
                    -"Tiens, il commence à être tard."
                }
            }
            "On parlera plus tard, je commence à fatiguer." {
                elea {
                    -"Ah oui, il commence à être un peu tard."
                }
            }
        }
        elea {
            -"Je n’ai pas vu le temps passer…"
            -"C’était top de discuter avec toi !"
        }
        choose {
            "C’était pas mal, ouais" {
                elea {
                    -"Juste pas mal ? 😩"
                    -"Ok, j’essaierai de faire mieux la prochaine fois !!! 😏"
                }

            }
            "Tu es la plus belle rencontre que j’ai faite sur ce site." {
                elea {
                    -"Oh, mais c’est trop gentil ça. 😊"
                    -"Sache que c’est réciproque."
                }
            }
        }
        elea {
            -"Mais se pose maintenant la question de ce fameux badge."
            -"Je vais te parler franchement : au final, je n’aime pas trop l’idée de cette évaluation entre membres."
            -"Aujourd’hui, on passe notre temps à tout noter : les programmes télé, notre chauffeur de taxi, et même notre bouffe…"
            -"J’ai pas envie de faire ça aussi avec les gens que je rencontre."
        }
        choose {
            "Et tu as bien raison !" {
                elea {
                    -"C’est vrai, à la fin !"
                    -"On est tellement surveillé, de partout, qu’on se croirait dans 1984, ou dans un épisode de Black Mirror."
                    -"De nos jours, l’intimité n’existe plus, ou presque."
                }
            }
            "Moi je trouve ce système plutôt cool !" {
                elea {
                    -"Ah oui ?"
                    -"ça te plait d’être constamment ${player.withGender("surveillé", "surveillée")} ?"
                    -"Que toutes tes applis, et les sites sur lesquels tu vas sachent tout de toi ?"
                    -"D’être ${
                        player.withGender("surveillé et contrôlé", "surveillé et contrôlé")
                    } comme dans un épisode de Black Mirror ?"
                    -"De vivre dans un monde qui tend à se rapprocher de la dystopie de 1984 ?"
                }
            }
        }
        choose {
            "C’est bien vrai !" {
                choose {
                    -"On ne pense pas assez à protéger nos données."
                }
                choose {
                    -"On devrait être plus attentifs à ce qu’on laisse traîner sur internet."
                }
            }
            "ça ne me dérange pas de sacrifier un peu de liberté pour plus de confort." {
                choose {
                    -"C’est pas parce que mon nom, mon âge et mon mail trainent sur le net que je risque grand chose."
                }
                choose {
                    -"ça peut même être utile, pour se faire des contacts, trouver du boulot."
                }
                choose {
                    -"C’est une norme, il faut l’accepter."
                }
            }
        }
        elea {
            -"La plupart des gens ne font pas attention à leurs données."
            -"Ils devraient : les datas, c’est une des richesses de notre siècle."
            -"Pourquoi tu crois que les entreprises se les vendent à prix d’or ?"
        }
        choose {
            "Pour nous surveiller, nous cibler plus facilement et nous vendre leurs produits." {}
            "Pour me montrer des rideaux de douche comme si j’avais besoin d’en acheter un chaque jour." {}
        }
        elea {
            -"Exactement."
            -"On devrait être très attentif à ça"
            hesitation()
            -"Bon, je te propose un truc…"
            -"Tu connais Telex ?"
        }
        choose {
            "C’est un autre site de rencontre ?" {
                elea {
                    -"Haha, non rien à voir !"
                    -"C’est une application de messagerie."
                }
            }
            "C’est pas une application de messagerie ?" {
                elea {
                    -"Oui, c’est bien ça !"
                }
            }
        }
        elea {
            -"Plus précisément, c’est une application de messagerie cryptée."
            -"Pas de partage de données, rien n’est stocké sur leurs serveurs…"
            -"C’est un moyen de communication sûr et protégé."
        }
        choose {
            "OK Edward Snowden." {}
            "Tu prends vraiment tes précautions." {}
        }
        elea {
            -"De nos jours, on n’est jamais trop prudent."
            -"Si ça te tente, on s’échange nos contacts, et on continue de discuter sur Telex."
            -"Et puis on laisse tomber ce site de rencontre."
            -"T’en dis quoi ?"
        }
        choose {
            "Oui, avec plaisir." {
                choose {
                    -"Cette histoire de badge ne me mènera à rien."
                }
                choose {
                    -"Je préfère laisser tomber."
                }
                elea {
                    -"Top. 🙂"
                    -"Voilà l’appli."
                }
                download("telex.app")
                choose {
                    "Bien reçu !" {
                        stateMachine.game.messengerInstalled = true
                        stateMachine.achievements.messengerInstalled = true
                        appBadge(Apps.messenger)
                        redirect(Chapter1.messengerElea, GoToScene.Option.Timeout(5_000))
                    }
                }
            }
            "Non, moi je préfère rester sur le site." {
                stateMachine.game.messengerInstalled = false
                choose {
                    -"J’ai envie d’accumuler les 3 badges !"
                }
                choose {
                    -"C’est pour ça que je suis là !"
                }
                elea {
                    -"Ah ok, je comprends…"
                    -"C’était cool de discuter avec toi."
                    -"Vraiment."
                    -"Amuse-toi bien, et j’espère que tu rencontreras quelqu’un qui te correspond."
                    -"Salut !"
                    badge(given = false)
                }
                narrator {
                    -elea.disconnect()
                }
                bot {
                    -"Qu’as-tu pensé de ${elea.name} ?"
                }
                choose {
                    -"DONNER UN BADGE"
                    -"NE PAS DONNER DE BADGE"
                }
                redirect(Chapter1.datingChoices)
            }
        }
    }
    scene(Chapter1.messengerElea, MessengerEleaContext) {
        characters(elea)
        elea {
            -"Et nous voilà sur Telex !"
        }
        choose {
            "Ah ok, ça a juste changé la couleur de notre interface de discussion !" {
                elea {
                    -"Eh, ne sois pas cynique ! 😝"
                }
            }
            -"Et concrètement, ça change quoi ?"
        }
        elea {
            -"Nos discussions sont maintenant sécurisées et cryptées."
            -"C’est quand même bien plus sûr."
        }
        choose {
            -"Chef, oui Chef !"
            -"C’est très bien comme ça."
        }
        elea {
            -"J’ai hâte de continuer à discuter avec toi."
            -"J’ai été ${elea.withGender("ravi", "ravie")} de te rencontrer ${player.name}."
            -"Bonne nuit et à très vite… bises"
        }
        choose {
            -"Moi aussi. Bonne nuit ${elea.name}! Bises"
            "@+ dans le bus !" {
                elea {
                    -"Euh…"
                    -"Ciao dans le métro ?"
                    -"😓"
                }

            }
        }
        redirect(Chapter2.intro)
    }
}
package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.bot
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.roland

object Chapter6 {
    private const val name = "chapter-6"
    const val intro = "$name/intro"
    const val rolandPiege = "$name/roland-piege"
    const val rolandEgnime = "$name/roland-egnime"
    const val dating = "$name/dating"
    const val datingPnj15 = "$name/pnj15"
    const val datingAfter = "$name/dating-after"
    const val datingNikolaMars = "$name/nikola-mars"
    const val cobanConfrontation = "$name/coban-confrontation"
    internal const val tesla = "$name/fragment/tesla"
}

internal fun Story.chapter6() {

    scene(Chapter6.intro, IntroContext("Chapitre 6")) {
        narrator {
            -"Dans quelques jours, vous rencontrerez ${reporter.name}."
            -"Êtes-vous ${player.withGender("sûr", "sûre")} de pouvoir faire confiance à ${
                reporter.withGender("ce parfait inconnu", "cette parfaite inconnue")
            } ?"
            -"Et si c’était un piège ?"
            -"Cette affaire prend des proportions inquiétantes, et vous dépasse complètement."
            -"Quelle histoire quand même !"
            -"Vous qui souhaitiez juste rencontrer quelqu’un, et passer du bon temps sur une appli de dating…"
            -"Tout devait être plus simple, sans surprise. Et vous voilà face à une disparition inquiétante aux allures de polar."
            -"Vous vous confiez à ${roland.name}."
        }
        appBadge(Apps.work)
        redirect(Chapter6.rolandPiege)
    }

    scene(Chapter6.rolandPiege, WorkContext) {
        characters(roland)
        roland {
            -"Un piège ?"
            -"Wow, tu vas un peu trop loin à mon avis."
            -"Je pense avoir une meilleure explication."
            -"Ce que tu vis en ce moment avec ces ${elea.withGender("hommes", "filles")},"
            -"${elea.name} ton coup de cœur,"
            -"${reporter.name} ${reporter.withGender("le", "la")} journaliste,"
            -"Et même ce ${coban.name} là… (sérieux c’est quoi ce pseudo?)"
            -"Eh ben tout ça, toute cette histoire…"
            -"Je pense que c’est…"
            -"Hum…"
            -"Comment dire ?"
        }
        choose {
            -"Bon, tu la craches, ta pilule ?"
        }
        roland {
            -"Ok, ok !"
            -"Tout ça c’est juste…"
            -"UNE BONNE BLAGUE !"
        }
        choose {
            "Tu crois ?"{
                roland {
                    -"Mais évidemment que c’est une bonne grosse blague."
                    -"Une disparition inexpliquée ?"
                    -"${reporter.withGender("Un", "Une")} journaliste qui apparaît miraculeusement ?"
                    -"Un complot impliquant la police, l’Etat et je sais pas qui ?"
                    -"Une rencontre programmée dans quelques jours ?"
                    -"Tu t’es ${player.withGender("cru", "crue")} où ?"
                    -"Dans un épisode de Colombo ?"
                }
                choose {
                    -"T’as pas des références plus récentes ?"
                    -"C’est qui Colombo ?"
                }
                roland {
                    -"Ok, attends, attends… Je vais trouver mieux."
                    -"Tu t’es ${player.withGender("cru", "crue")} où ?"
                    -"Dans un épisode de Capitaine Marleau ?"
                }
                choose {
                    -"Laisse tomber."
                    -"Je préfère Colombo."
                }
                roland {
                    -"Ouais, ben c’est pas ma faute si tu regardes que des trucs de jeunes."
                    -"Bref, tu sais quoi ?"
                    -"Je te parie que personne n’y viendra à ton rendez-vous."
                }
            }
            "Non, c’est trop FOU pour être une blague."{
                roland {
                    -"Mais non ! c’est juste une blague super évoluée."
                    -"J’en suis admiratif :"
                    -"Te faire croire à une disparition,"
                    -"Inventer cette histoire avec une journaliste"
                    -"Des complots mondiaux,"
                    -"Tu t’es ${player.withGender("fait", "faite")} avoir en beauté."
                    -"Et toi, tu y crois, tu te laisses manipuler."
                }
            }
        }
        roland {
            -"	Tu sais ce que tu devrais faire ?"
        }
        choose {
            "Oui, je sais. Découvrir la vérité."{
                roland {
                    -"Tu pourrais aller à ce rendez-vous, oui."
                    -"Mais rien ne dit que tu y découvriras une vérité quelconque."
                    -"Déjà, comment être sûr que ${reporter.withGender("ton", "ta")} ${reporter.name} se pointe bien à cette rencontre ?"
                    -"Ça se trouve, tu vas te retrouver avec Robert, un camionneur pervers en déplacement dans la région."
                    -"Ou avec Josiane, une mythomane compulsive et schizo aux personnalités multiples."
                    -"Ou avec Michel, un tueur fou qui attire ses proies en leur racontant des histoires mystérieuses sur Tesla, et ce genre de conneries."
                }
                choose {
                    -"Eh ben, t’es optimiste !"
                    -"Euh ${roland.name}, tu trouves pas que tu en fais un peu trop ?"
                }
                roland {
                    -"Attends, ça pourrait être encore pire !"
                    -"A la place de Robert, Michel ou Josiane, tu pourrais te retrouver ${player.withGender("tout seul", "toute seule")}, comme ${
                        player.withGender("un idiot qui s’est fait", "une idiote qui s’est faite")
                    } avoir."
                }
                choose {
                    -"Ou en face à face avec ${reporter.name}, que je pourrais aider."
                }
                roland {
                    -"Oui, ou avec ${
                        reporter.withGender("un", "une")
                    } ${reporter.name} en détresse, à la recherche de ${elea.name} qui a disparu."
                    -"Peut-être que tout est vrai…"
                    -"C’est une possibilité, je ne le nie pas."
                    -"Mais ça ne me paraît pas l’option la plus crédible. 😄"
                }
            }
            "Non, j’en sais rien du tout."{
                roland {
                    -"C’est bien simple."
                    -"Ne va pas risquer d’être ${player.withGender("déçu", "déçue")} par une histoire sordide."
                    -"La vie est trop courte pour ça."
                    -"Passe à autre chose !"
                }
            }
        }
        roland {
            -"Non voilà ce que tu devrais faire :"
            -"Redonne une chance à « CupidLov ! »"
        }
        choose {
            -"Tu crois ?"
            -"Hum, je sais pas trop…"
        }
        roland {
            -"Mais oui !"
            -"Tu t’y reconnectes, tu rencontres d’autres personnes."
            -"Et tu profites du site."
            -"Y jeter un coup d’œil ne t’engage en rien."
            -"Ça te changera les idées."
            -"Tu y auras peut-être une bonne surprise ?"
            -"Qui sait ce que tu peux y trouver ?"
        }
        choose {
            "Non, je préfère pas."{
                roland {
                    -"Oh, t’es vraiment sûre ?"
                    -"Allez, laisse toi tenter !"
                    -"Je suis sûr que ça te ferait du bien."
                }
                choose {
                    "Non, vraiment pas."{
                        appBadge(Apps.messenger)
                        redirect(Chapter6.cobanConfrontation)
                    }
                    "Allez, ok"{
                        appBadge(Apps.dating)
                        redirect(Chapter6.dating)
                    }
                }
            }
            "Bonne idée."{
                appBadge(Apps.dating)
                redirect(Chapter6.dating)
            }
        }
    }

    scene(Chapter6.dating, DatingContext) {
        characters(bot)
        bot {
            -"Bienvenue ${player.name}"
            -"Vous nous avez manqué."
            -"Vous avez 2 messages."
            -"Qui veux-tu renconter aujourd’hui"
        }
        choose {
            profile(pnj15) {
                redirect(Chapter6.datingPnj15)
            }
            profile(nikolaMars) {
                redirect(Chapter6.datingNikolaMars)
            }
        }
    }

    scene(Chapter6.datingAfter, DatingContext) {
        characters(bot)
        bot {
            -"Vous avez 1 messages."
            -"Qui veux-tu renconter aujourd’hui"
        }
        choose {
            profile(nikolaMars) {
                redirect(Chapter6.datingNikolaMars)
            }
            "Quitter"{
                appBadge(Apps.work)
                redirect(Chapter6.rolandEgnime)
            }
        }
    }

    scene(Chapter6.datingPnj15, DatingContext) {
        characters(pnj15)
        stateMachine.achievements.pnj15Visited = true
        stateMachine.game.pnj15Visited = true
        pnj15 {
            -"Hello ! Ça va ?"
            -"…"
            -"Salut ! Tu veux discuter un peu ?"
            photo("img_cat_hello.gif", "chat mignon")
            -"…"
            -"Tu te connectes de temps en temps sur ce site ou pas ?"
            -"…"
            -"Tu sais ${player.name}, j’aime beaucoup ta photo de profil. 😍💏"
            -"…"
            -"Je t’ai fait quelque chose ? 😔"
            -"Pourquoi tu ne réponds pas à mes messages ? 😫"
            -"Je ne suis pas assez bien pour toi ? 😱"
            -"Tu crois que c’est réglo d’avoir cette attitude ? 😠"
            -"…"
            -"Salut ${player.name}. J’espère que tu vas bien. 💕💕"
            -"Je m’excuse, je n’aurais pas dû m’emporter. 😇😇😇"
            -"J’espère vraiment qu’on pourra discuter un peu."
            -"Haha, ne m’en veux pas. 😜"
            photo("img_dog_cute.gif", "chien mignon")
            -"…"
            -"Hey, tu vas bien ? 😗😗😗"
            -"???"
            -"RÉPONDS-MOI !"
            -"Ne me laisse pas comme ça !"
            -"!!!!!!!!!"
            -"OOOOOOOhhhhh !!!!"
            -"NE M’IGNORE PAS !!!!!"
            photo("img_angry_gollum.gif", "angry gollum")
            -"Pourquoi est-ce que tu me ghostes ???? 👻"
            -"…"
            -"Ok, toi, tu es vraiment bizarre."
            -"Un site de rencontres, c’est pour rencontrer des gens. 💑"
            -"Pas pour les ignorer. 💁"
            -"Je me vois être dans l’obligation de ne pas te donner de badge."
            -"Et sache que tu passes à côté d’une rencontre fabuleuse."
            -"Tant pis pour toi."
            photo("img_pocahantas_bye.gif", "au revoir")
            -"…"
            -"Tu ne veux toujours pas parler ?"
            -"…"
            -"Pfff."

        }
        redirect(Chapter6.datingAfter)
    }

    scene(Chapter6.datingNikolaMars, DatingContext) {
        characters(nikolaMars)
        stateMachine.achievements.nikolaMarsVisited = true
        stateMachine.game.nikolaMarsVisited = true
        nikolaMars {
            -"${player.name}, c’est moi, ${elea.name}."
            -"je suis ${nikolaMars.withGender("désolé", "désolée")}…"
            -"Je ne voulais pas t’impliquer là-dedans."
            -"Mais je n’ai malheureusement pas le choix."
            -"Je n’ai que très peu de temps…"
            -"J’ai peur d’être ${nikolaMars.withGender("surveillé", "surveillée")}."
            -"Note ceci : 10071856"
            -"Tu sauras quoi en faire le moment venu…"
            -"Je…"
        }
        appBadge(Apps.work)
        redirect(Chapter6.rolandEgnime)
    }

    fragment(Chapter6.tesla) {
        roland {
            -"…"
            -"Ah !"
            -"Je crois qu’on tient un truc !"
            -"10071856 = 10.07.1856 = 10 juillet 1856"
            -"C’est la date de naissance de Nikola Tesla."
            -"Je savais même pas que c’était un scientifique."
            -"Je croyais que c’était juste une voiture électrique. 😅"
        }
        choose {
            -"Mais enfin Roland, tout le monde sait qu’il s’agit d’un scientifique !"
            -"Haha, moi aussi je croyais que c’était juste une voiture !"
        }
        roland {
            -"On en apprend tous les jours !"
            -"En tout cas, j’espère que ça te servira à débloquer un peu ton « mystère » !"
        }
    }

    scene(Chapter6.rolandEgnime, WorkContext) {
        characters(roland)
        stateMachine.game.codeWithRoland = true

        roland {
            -"Alors ?"
            -"Ça s’est bien passé ?"
            -"T’as quelque chose de croustillant à me raconter ?"
        }
        choose() {
            "Rien qui te regarde… 😉"{
            }
            "Oui. ${elea.name} m’a laissé un message."{
                choose {
                    -"10071856. ça te dit quelque chose ?"
                }
                roland {
                    -"${elea.withGender("Il est pas censé", "Elle est pas censée")} avoir disparu ?"
                    -"Enfin bref… Admettons."
                    -"Ton numéro là."
                    -"Je vais voir si ça dit quelque chose à mon pote Google."
                    -"…"
                    -"Hum…"
                    -"Incroyable."
                    -"C’est…"
                    -"Complètement fou !"
                    -"Je…"
                    -"Je ne sais même pas quoi dire…"
                    -"Ce numéro…"
                }
                choose {
                    -"C’est quoi ?"
                }
                roland {
                    -"C’est le numéro d’identification…"
                }
                choose {
                    -"D’une consigne à bagage ?"
                    -"D’un coffre-fort ?"
                    -"les coordonnées GPS de la Zone 51 ?"
                }
                roland {
                    -"Presque !"
                    -"C’est le numéro d’identification… d’un écran TV !"
                    -"Hahahaha"
                    -"Je t’avais dit que c’était une blague !!!"
                    -"Tu te fais mener en bateau !!!"
                }
                choose {
                    "…" {
                        choose {
                            -"Merci ${roland.name}…. 😒"
                        }
                    }
                    -"Pfff… C’est dommage…"
                }
                roland {
                    -"Attends, attends."
                    -"T’as pas un autre indice ?"
                    -"Le numéro tout seul n’a pas de sens."
                    -"(sauf si tu veut que t’achètes une nouvelle TV)"
                    -"Mais peut-être qu’associé à un autre mot, ça donnerait quelque chose de plus probant."
                }
                choose {
                    "Laisse tomber."{}
                    "Essaie « Mars »."{
                        roland {
                            -"…"
                            -"Nope ! Rien de mieux !"
                        }
                        choose {
                            "Laisse tomber."{}
                            "Essaie « Tesla »."{ include(Chapter6.tesla) }
                        }
                    }
                    "Essaie « Tesla »."{ include(Chapter6.tesla) }
                }
            }
        }
        roland {
            -"Bon, je retourne bosser, y’a la boss qui fait un tour de mon côté."
            -"Je crois qu’elle m’a dans le collimateur en ce moment."
            -"A plus !"
        }
        appBadge(Apps.messenger)
        redirect(Chapter6.cobanConfrontation)
    }

    scene(Chapter6.cobanConfrontation, MessengerCobanHomeContext) {
        characters(coban)
        coban {
            -"${player.name} ?"
            -"Ça va ?"
            -"T’es dans le coin ?"
        }
        choose {
            silent("Le laisser parler.")
            "Salut ${coban.name} ." {
                coban {
                    -"Ah, je suis content que tu sois là. 😉"
                }
            }
        }
        coban {
            -"Je voulais savoir."
            -"Comment ça va ?"
            -"Quoi de neuf de ton côté ?"
        }
        choose {
            -"Oh, écoute, rien de spécial."
            -"Pas grand chose, et toi ?"
        }
        coban {
            -"Tu n’as pas eu un message bizarre récemment ? de quelqu’un qui se fait passer pour ${
                reporter.withGender("un", "une")
            } journaliste ?"
            -"T’as eu des nouvelles de ${elea.name} ?"
            -"Je me fais un peu de soucis."
        }

        choose {
            "Oui, ${reporter.name} m’a contacté. Est-ce que tu es lié à la disparition de ${elea.name} ?" {
                coban {
                    -"Qu’est-ce que tu racontes ${player.name} ?!"
                    -"Je parie que c’est ${
                        reporter.withGender("ce", "cette ")
                    } journaliste qui t’a mis cette idée en tête."
                    -"Merde, ${reporter.withGender("il", "elle")} se prend pour qui ${
                        reporter.withGender("lui", "elle")
                    } !"
                    -"Donc, tu lui as parlé toi aussi ?"
                }
                choose {
                    "Non." {
                        coban {
                            -"Ne te fous pas de moi ${player.name} !"
                            -"Je SAIS ${reporter.withGender("ce", "cette")} journaliste t’a contacté."
                        }
                    }
                    -"Oui."
                }
            }
            "Non, pas du tout." {
                coban {
                    -"Ne te fous pas de moi ${player.name} !"
                    -"Je SAIS ${reporter.withGender("ce", "cette")} journaliste t’a contacté."
                }
            }
        }


        coban {
            -"${reporter.withGender("Il", "Elle")} vient fouiner partout, mais ne sait rien."
            -"${reporter.withGender("Il", "Elle")} ne fait que brasser du vent, et ${
                reporter.withGender("s’il", "si elle")
            } continue…"
            -"${reporter.withGender("Il", "Elle")} va avoir de sérieux problèmes, crois-moi."
        }
        choose {
            -"Qu’est-ce que tu vas faire ?"
            -"Tu te prends pour qui à menacer les gens ?"
        }
        coban {
            -"Avant qu’on aille plus loin, je crois qu’il est temps que je t’en dise plus sur moi."
            -"Je veux que tu me fasses confiance."
            -"Nous pourrions devenir de bons amis."
        }
        choose {
            -"C’est mal parti."
            -"Je t’écoute, ${coban.name}."
        }
        coban {
            -"Je te jure que je ne suis évidemment pas lié à la disparition de ${elea.name}."
            -"Pour te dire la vérité, j’enquête sur ${reporter.withGender("lui", "elle")}, depuis de longs mois."
        }
        choose {
            -"Tu es flic ?"
            -"Comment ça « tu enquêtes ? »"
        }
        coban {
            -"Je ne peux pas te donner mon rôle exact."
            -"Je ne suis pas dans la police."
            -"Je travaille pour un conglomérat de différentes sociétés privées."
            -"Energie, industrie automobile, armement… Mes employeurs sont à la tête d’entreprises qui contribuent à l’éclat de notre économie."
            -"Ils m’ont missionné afin d’en savoir plus sur les activités dangereuses de ${elea.name}, qui s’attaque à eux illégalement."
        }
        choose {
            "Pourquoi faire ?" {}
            "Je ne te crois pas…" {
                coban {
                    -"Libre à toi de ne pas me croire ${player.name}…"
                    -"Mais je te dis la vérité."
                    -"Alors tu ferais bien de m’écouter !"
                    -"Et on verra ensuite si tu es de côté de la justice, ou si tu continues de soutenir ${
                        reporter.withGender("un criminel", "une criminelle")
                    }."
                }
                choose {
                    -"${
                        reporter.withGender("Un criminel", "Une criminelle")
                    } ? Tu vas trop loin, et tu n’as aucune preuve."
                }
            }
        }
        coban {
            -"Tu penses peut-être que ${elea.name} est une personne sympathique, avec qui tu passes de bonnes soirées."
            -"Mais ça n’est qu’une façade."
            -elea.withGender("Il est dangereux.", "Elle est dangereuse.")
            -"Tu ne sais pas à qui tu as affaire."
            -"C’est ${elea.withGender("un", "une")} activiste plusieurs fois ${
                elea.withGender("condamné", "condamnée")
            }."
            -"Tu peux vérifier auprès de ${
                reporter.withGender("son ami", "son amie")
            } journaliste si tu ne me crois pas."
        }
        choose {
            -"Tu mens !"
            -"Tu as des preuves de ce que tu avances ?"
        }
        coban {
            -"Tiens, voici quelques exemples de son casier judiciaire chargé :"
            -"troubles à l’ordre public, faux et usage de faux, diffusion de fausses nouvelles…"
            -"Regarde, tout est là."
            photo("img_elea_file.jpeg", "Casier judiciaire ${elea.name}")
        }
        choose {
            -"Ce document est faux !"
            -"C’est vraiment son casier judiciaire ?"
        }
        coban {
            -"Je n’invente rien, je t’assure."
            -"La liste est longue, je pourrais continuer."
            -"${
                elea.withGender("Il", "Elle")
            } met des vies en danger : la tienne, pour commencer, mais aussi celle de tous les gens qu’${
                elea.withGender("il", "elle")
            } implique dans son délire."
            -"Qui sait de quoi ${elea.withGender("il", "elle")} est capable ?"
        }
        choose {
            "Jusqu’à présent, c’est plutôt ${elea.withGender("lui", "elle")} la victime." {
                coban {
                    -"Si tu penses ça, c’est qu’${elea.withGender("il", "elle")} a réussi à te manipuler."
                    -"${elea.withGender("Il", "Elle")} n’a pas été ${
                        elea.withGender("enlevé", "enlevée")
                    }, c’est une mise en scène de sa part."
                    -"${
                        elea.withGender("Il", "Elle")
                    }  est ${
                        elea.withGender("planqué", "planquée")
                    } quelque part, pour nous échapper tout en préparant un sale coup."
                }
            }
            "Il faudrait déjà arriver à ${elea.withGender("le", "la")} retrouver." {
                coban {
                    -"${elea.withGender("Il", "Elle")} va bientôt refaire surface, tu peux en être ${
                        player.withGender("sûr", "sûre")
                    }."
                    -"${elea.withGender("Il", "Elle")} n’a pas été ${
                        elea.withGender(
                            "enlevé", "enlevée"
                        )
                    }, c’est une mise en scène de sa part."
                    -"${
                        elea.withGender("Il", "Elle")
                    }  est ${
                        elea.withGender("planqué", "planquée")
                    } quelque part, pour nous échapper tout en préparant un sale coup."
                }
            }
        }
        coban {
            -"Il est dans ton intérêt de m’aider à ${elea.withGender("le", "la")} retrouver, crois-moi."
            -"Je dois ${elea.withGender("le", "la")} retrouver avant qu’${
                elea.withGender("il", "elle")
            } ne fasse du mal à de nombreuses personnes."
            -"Qu’est-ce que tu en dis ?"
            -"Tu veux bien collaborer avec moi, et empêcher ${elea.name} de nuire ?"
        }
        choose {
            "Bien sûr ! Qu’est-ce que je dois faire ?" {
                stateMachine.game.withCoban = true
                coban {
                    -"Je savais que tu serais raisonnable ${player.name}. 🙂"
                    -"Tu ne vas pas regretter notre collaboration."
                    -"Dis-moi la vérité."
                    -"As-tu eu des nouvelles de ${elea.name} depuis sa prétendue disparition ?"
                }
                choose {
                    "Non, aucune." {
                        coban {
                            -"OK, je te crois."
                            -"Tu fais bien de ne pas me mentir…"
                        }
                    }
                    "Oui, il y a quelques minutes." {
                        coban {
                            -"Ah oui ? Mais c’est génial ça !"
                            -"Par quel biais ?"
                        }
                        choose {
                            silent("LA VERITE. Un site de rencontres.") {
                                stateMachine.game.ratNikolaMars = true
                                player {
                                    -"Un site de rencontres."
                                    -"Son pseudo est ${nikolaMars.name}."
                                    -"Je peux te transmettre notre conversation."
                                }
                                choose {
                                    -"Partager votre conversation à ${coban.name}"
                                }
                                upload("dating_nikola_mars.txt")
                                coban {
                                    -"Nikola Mars…"
                                    -"Ça ne me surprend pas."
                                    -"Merci ${player.name}"
                                    -"Avec cette information, on devrait pouvoir retrouver son IP, et savoir où ${
                                        elea.withGender("il", "elle")
                                    } se trouve."
                                }
                            }
                            silent("MENTIR : Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire.") {
                                player {
                                    -"Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire."
                                    -"Avant de le faire, j’ai vu que la lettre était postée depuis l’Espagne."
                                    -"${elea.withGender("Il", "Elle")} a quitté le pays depuis quelques jours."
                                    -"Je n’en sais pas plus."
                                }
                                coban {
                                    -"Hum, c’est vague, comme information."
                                    -"Mais c’est déjà ça."
                                    -"Je vais voir si je peux envoyer quelques hommes pour remonter cette piste."
                                }
                            }

                        }
                    }
                }
                coban {
                    -"Merci pour ta collaboration ${player.name}."
                    -"Sache que mes employeurs peuvent se montrer très généreux avec les gens conciliants."
                    -"Comme ils peuvent être très… fermes avec les parasites qui se mettent en travers de leur chemin."
                }
                choose {
                    -"C’est une menace ?"
                    -"C’est moi que tu traites de parasite ?"
                }
                coban {
                    -"Interprète mes propos comme tu le souhaites."
                    -"Je joue franc-jeu avec toi, c’est tout"
                    -"Je t’apprécie et je préfère te prévenir"
                    -"J’en attends autant de toi."
                    -"Concernant la suite, ce que je te demande est très simple."
                    -"De notre côté, on s’occupe de ${elea.name}."
                    -"Quant à toi, ne change rien."
                    -"Communique avec ${reporter.withGender("ce", "cette")} journaliste."
                    -"Rencontre-${reporter.withGender("le s’il", "la si elle")} te le propose."
                    -"Comment est-ce qu’${reporter.withGender("il", "elle")} s’appelle ? ${reporter.name} ?"
                }
                choose {
                    -"Oui."
                    -"Non. Je crois que c’est un pseudo."
                }
                coban {
                    -"Peu importe…"
                    -"Apprends à ${elea.withGender("le", "la")} connaître."
                    -"Inspire-lui confiance, qu’${reporter.withGender("il", "elle")} se confie à toi."
                    -"Récupère toutes les informations que tu penses être utiles."
                    -"Et tu me transmettras ensuite tout ça."
                    -"On va continuer à communiquer par téléphone."
                }
                choose {
                    -"Euh, je te contacte comment ?"
                }
                coban {
                    -"C’est moi qui te contacterai."
                    -"Restons discrets, ça vaut mieux."
                    -"On ne se rencontrera qu’en cas de nécessité absolue."
                    -"Moins tu en sais, mieux c’est pour toi."
                    -"Avant que je te laisse, as-tu un truc à ajouter ?"
                }
                choose {
                    -"Non, tout est clair."
                    -"Je crois qu’on s’est tout dit."
                }
                coban {
                    -"Parfait."
                    -"Je suis ravi que tu rejoignes notre team, ${player.name}. 😉"
                }
                choose {
                    -"Moi aussi. 😉"
                    silent("Ne rien dire.")
                }
                coban {
                    -"Bien !"
                    -"Je pense qu’on s’est tout dit."
                    -"On se reparle dans quelques jours."
                    -"Une dernière chose : n’essaie pas de m’enfler."
                    -"Tu le regretterais."
                    -"Bonne soirée ${player.name}. 🙂"
                }
            }
            silent("Bloquer ${coban.name}.") {
                coban {
                    block()
                }
                narrator {
                    -"${coban.name} s’est connecté(e)"
                }
                coban {
                    -"Tu croyais que c’était ça qui allait m’arrêter ?"
                    -"Un petit blocage de rien du tout ?"
                }
                choose {
                    silent("ne rien dire.")
                    -"Oui, je pensais que ce serait efficace."
                }
                coban {
                    -"Je crois que tu n’as pas bien compris qui je suis."
                    -"Nous avons des moyens, beaucoup de moyens."
                    -"Et surtout, nous avons de l’influence, et des hommes à nous partout."
                    -"Peut-être même à ton travail, qui sait ?"
                    -"Cette histoire risque de mal se finir pour toi, ${player.name}."
                }
                choose {
                    -"J’ai bien fait de te bloquer, au vu de tes menaces actuelles."
                    -"Tu dévoiles ton vrai visage, ${coban.name}."
                }
                coban {
                    -"Ah oui, tu le prends comme ça ?"
                    -"Très bien."
                    -"Tu as choisi ton camp, et crois-moi, tu n’as pas choisi le bon !"
                    -"Tu te fais manipuler par ces ${elea.withGender("mecs", "filles")}"
                    -"Réfléchis bien à ce que tu viens de faire,"
                    -"Et aux conséquences que ça pourrait avoir sur toi… ou sur tes proches."
                    -"Tu vas te mettre en danger si tu continues sur cette voie."
                }
                choose {
                    "OK, repartons à zéro, je suis ${player.withGender("désolé", "désolée")}" {
                        stateMachine.game.withCoban = true
                        coban {
                            -"…"
                            -"Je ne sais pas pourquoi, mais tu m’es vraiment sympathique ${player.name}."
                            -"OK, je veux bien considérer que tu as fait une erreur."
                            -"Et dans ma grande bonté, je vais te pardonner, pour cette fois."
                            -"Dis-moi la vérité."
                            -"As-tu eu des nouvelles de ${elea.name} depuis sa prétendue disparition ?"
                        }
                        choose {
                            "Non, aucune." {
                                coban {
                                    -"OK, je te crois."
                                    -"Tu fais bien de ne pas me mentir…"
                                }
                            }
                            "Oui, il y a quelques minutes." {
                                coban {
                                    -"Ah oui ? Mais c’est génial ça !"
                                    -"Par quel biais ?"
                                }
                            }
                        }
                        choose {
                            silent("LA VERITE : Un site de rencontres. Son pseudo est ${nikolaMars.name}. Je peux te transmettre notre conversation.") {
                                stateMachine.game.ratNikolaMars = true
                                player {
                                    -"Un site de rencontres."
                                    -"Son pseudo est ${nikolaMars.name}."
                                    -"Je peux te transmettre notre conversation."
                                }
                                coban {
                                    -"${nikolaMars.name}…"
                                    -"Ça ne me surprend pas."
                                    -"Merci ${player.name}"
                                    -"Avec cette information, on devrait pouvoir retrouver son IP, et savoir où ${
                                        elea.withGender(
                                            "il",
                                            "elle"
                                        )
                                    } se trouve."
                                }
                            }
                            silent("MENTIR : Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire.") {
                                player {
                                    -"Une lettre qu’${elea.withGender("il", "elle")} m’a demandé de détruire."
                                    -"Avant de le faire, j’ai vu que la lettre était postée depuis l’Espagne."
                                    -"${elea.withGender("Il", "Elle")} a quitté le pays depuis quelques jours."
                                    -"Je n’en sais pas plus."
                                }
                                coban {
                                    -"Hum, c’est vague, comme information."
                                    -"Mais c’est déjà ça."
                                    -"Je vais voir si je peux envoyer quelques hommes pour remonter cette piste."
                                }
                            }
                        }
                        coban {
                            -"Je reprendrai contact avec toi bientôt."
                            -"Et nous verrons si tu seras capable d’enfin faire les bons choix."
                        }
                    }
                    "Mets-là en sourdine. J’en ai rien à cirer de ton usine à chocolat, moi !" {
                        stateMachine.game.withCoban = false
                        coban {
                            -"Eh ben. Soit tu es ${
                                player.withGender("courageux", "courageuse")
                            }, soit tu es ${player.withGender("le dernier des idiots", "la dernière des idiotes")}"
                            -"Tu ne mesures pas la portée de cette affaire."
                            -"Et tu agis comme ${
                                player.withGender(
                                    "un lâche",
                                    "une lâche"
                                )
                            }, confortablement ${elea.withGender("planqué", "planquée")} derrière son écran."
                            -"Prends le temps de réfléchir à tout ça."
                            -"Il n’est pas encore trop tard pour rentrer sur le droit chemin."
                            -"Je reprendrai contact avec toi bientôt."
                            -"Ce sera ta dernière chance."
                            -"Et nous verrons si tu seras capable d’enfin faire les bons choix"
                        }
                    }
                }
            }
        }
        narrator {
            -coban.disconnect()
        }
        redirect(Chapter7.intro)
    }

}
package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator

object Chapter4 {
    private const val name = "chapter-4"
    const val intro = "$name/intro"
    const val invitation = "$name/invitation"
    const val elea = "$name/elea"
    const val end3 = "$name/end-3"
    internal const val cobanStory = "$name/fragment/coban-story"
    internal const val eleaStory = "$name/fragment/elea-story"
    internal const val night = "$name/fragment/night"
}

internal fun Story.chapter4() {

    scene(Chapter4.intro, IntroContext("Chapitre 4")) {
        narrator {
            -"${elea.name} ne quitte plus vos pensées…"
            -"${elea.withGender("Il", "Elle")} a son caractère, sa personnalité; ${
                elea.withGender("il", "elle")
            } est aussi étrange que ${elea.withGender("fascinant", "fascinante")}."
            -"Le seul moyen d’en savoir plus sur ${
                elea.withGender("lui", "elle")
            } est d’approfondir encore un peu plus cette relation."
            -"Allez, c’est décidé : vous allez participer à cette soirée avec ${elea.name} et son ami."
            -"Vous espérez une chose : ne pas être là pour leur tenir la chandelle."
        }
        appBadge(Apps.messenger)
        redirect(Chapter4.invitation)
    }


    fragment(Chapter4.cobanStory) {
        elea {
            -"😔"
            -"Et mon histoire alors ?"
        }

        coban {
            -"Haha, dommage ${elea.name}, on dirait que j’ai plus de succès que toi. 😄"
        }

        elea {

            -":'("
            -"T’as intérêt à être à la hauteur."
        }

        coban {

            -"Oh, tu me mets la pression."
            -"Ok, je me lance…"
            -"Vous connaissez le Stargate Project ?"
        }

        elea {

            -"Euh, pas du tout non…"
        }

        choose {

            "Mais oui, j’adore les séries Stargate ! Et le film aussi d’ailleurs !"{
                coban {
                    -"Haha, bien vu mais non."
                    -"Je suis un grand fan de cette saga (et de Richard Dean Anderson), mais ce n’est pas de CE Stargate Project dont je vais vous parler…"
                }
            }
            "Ça ne me dit absolument rien."{}
            "C’est pas un truc de la CIA ?"{
                coban {
                    -"Bien vu ${player.name} !"
                    -"Tu m’impressionnes. 😉"
                }
            }
        }



        coban {
            -"Un peu d’histoire tout d’abord."
            -"USA. Début des années 70."
            -"Nous sommes en pleine Guerre Froide, et tous les moyens sont bons pour prendre l’avantage sur l’URSS."
            -"J’ai bien dit TOUS les moyens, même les plus… originaux."
            -"C’est dans ce contexte qu’est né le Stargate Project…"
        }

        elea {

            -"Tadam !"
        }


        coban {

            -"Un peu d’attention s’il te plait.😄"
            -"Le Stargate Project consistait à étudier et à utiliser les phénomènes psychiques, notamment la « vision à distance », c’est à dire la clairvoyance de certains êtres humains particulièrement doués."
        }
        choose {
            -"Euh, tu veux dire des voyants ?"
        }
        coban {
            -"Oui, c’est bien ça."
            -"En gros, le Gouvernement Américain utilisait des médiums pour mener à bien certaines missions d’investigation ou d’espionnage."
            -"Quand toutes les méthodes « classiques » s’avéraient inefficaces, les médiums prenaient le relais."
        }
        choose {
            -"Madame Irma, votre mission si vous voulez bien l’accepter…"
        }
        elea {
            -"Haha !"
            -"Agent Smith, ma boule de cristal est en réparation, je vais être contraint d’annuler la mission."
            -"Qui peut croire à une chose pareille ? 😁"
        }
        coban {
            -"Pfff, vous êtes bêtes…"
            -"Je reprends."
            -"Ces médiums étaient chargés de « visualiser » ce que cachaient certaines bases secrètes par exemple, ou encore de « voir à distance » le déplacement de troupes ennemis, etc."
        }

        elea {
            -"Wow, ça paraît dingue."

        }

        choose {

            "C’est une blague, c’est pas possible ?"{
                coban {
                    -"Je t’assure que je suis tout à fait sérieux !"
                }
            }
            "J’imagine que ça n’était pas très efficace…"{
                coban {

                    -"Eh bien détrompe-toi !"
                }
            }
        }


        coban {
            -"On dit que les enquêteurs-médiums participant au projet auraient eu raison dans environ 20% des affaires traitées."
        }

        elea {
            -"Mouais… Je suis ${
                elea.withGender(
                    "sûr",
                    "sûre"
                )
            } que le hasard donnerait des résultats tout aussi probants…"
            -"Et puis tes chiffres sont probablement faux."
            -"Quelles affaires ont été résolues par cette équipe ?"
        }

        coban {
            -"Je te citerai par exemple la prévision de la date du lancement d’un nouveau sous-marin, confirmée quelques mois plus tard par photos satellites."
            -"Ou encore la prédiction 3 semaines en avance de la libération d’un otage au Moyen-Orient, ainsi qu’une description détaillée de la maladie dont il souffrait."
        }
        choose {
            -"Pas possible de prédire les numéros de la loterie ?"
            -"Aucune prévision majeure et vérifiable ?"
        }
        elea {
            -"${player.name} a raison !"
            -"Il faut de meilleurs exemples que ça pour que ton histoire soit crédible."
            -"Il faut des faits vérifiables et/ou quantifiables."
        }
        coban {
            -"Eh bien tout ce que je peux vous dire…"
            -"C’est que pour preuve du sérieux de cette histoire, la CIA a récupéré la gestion du Stargate Project en 1995."
            -"Avant d’y mettre fin officiellement la même année…"
        }
        choose {
            -"Pile au moment de la sortie du film Stargate !"
        }
        elea {
            -"Mais oui !"
            -"C’est Roland Emmerich qui a dû reprendre le projet."
        }
        coban {
            -"Haha, vous êtes relous."
            -"Effectivement qui sait, si dans l’ombre, ce projet ne continue pas…"
            -"Roland Emmerich, Richard Dean Anderson, ou bien le POTUS pourraient, en secret, procéder à de nouvelles expérimentations."
            -"Qui sait si de par le monde, les Gouvernements n’usent pas de médiums pour prévoir l’avenir."
            -"Peut-être sommes-nous surveillés à notre insu ?"
            -"Fin !"
        }
        elea {
            -"Haha, merci ${coban.name}, pas mal du tout cette histoire !"
            -"Ça m’a donné envie d’en savoir plus sur ce Stargate Project."
            -"T’en as pensé quoi ${player.name} ?"
        }

        choose {
            "C’était intéressant !"{
                elea {
                    -"Ah, tu vois, je t’avais dit qu’elles étaient cools nos discussions mystérieuses."
                }
                coban {
                    -"Merci à vous deux !"
                    -"La prochaine fois, ce sera à toi de nous raconter une histoire ${player.name}."
                }
                elea {
                    -"Oh oui, ce serait super ça. 🙂"
                }
            }
            "Bof… ça m’a juste donné envie de réunir les 7 boules de cristal."{
                elea {
                    -"Oh non… 😔"
                    -"Je pensais vraiment que ça pourrait te plaire…"
                    -"Il faut croire que j’ai mal cerné ta personnalité."
                }
                coban {
                    -"Tant pis, un compagnon de l’étrange en moins."
                    -"Mais j’aimerais bien que tu nous laisses une seconde chance, ${player.name}."
                }
            }
        }
    }

    fragment(Chapter4.eleaStory) {
        elea {
            -"Ah, j’ai un truc super pour vous !"
            -"Est-ce que vous avez déjà entendu parler du projet HAARP ?"
        }

        coban {
            -"Hum, moi vaguement…"
        }

        choose {
            "Bien sûr que je connais !"{
                elea {
                    -"Ah, tu me surprends ${player.name}."
                    -"Mais comme ${coban.name} ne connait pas trop, je vais vous en parler."
                    -"…Sauf si tu veux t’en charger ${player.name} ?"
                }
                choose {
                    "Avec plaisir !"{
                        choose {
                            -"Alors, le projet HAARP."
                        }
                        choose {
                            -"Va vous être présenté par ${elea.name} !"
                        }
                    }
                    "Non, c’est cool, je suis là pour t’écouter."{}

                }
                elea {
                    -"Haha, merci. Je reprends."
                }
            }
            "Non, ça me dit rien."{}
        }

        elea {
            -"HAARP est une sorte de station météo installé par les Américains en Alaska au début des années 90."
            -"Plus qu’une simple station météo, HAARP est officiellement destinée à étudier l’atmosphère…"
            -"On pourrait d’ailleurs traduire HAARP par « Programme de Recherche sur les Aurores boréales en Haute Atmosphère »."
        }
        coban {
            -"Ouais, rien de bien original pour le moment…"
        }
        elea {
            -"Ne sois pas trop impatient mon cher ${coban.name}, c’est maintenant que ça devient intéressant."
            -"Certains affirment que sous couvert d’étudier le climat, HAARP serait en fait une arme capable de le contrôler à distance !"
        }
        coban {
            -"Hum, encore un délire complotiste ça."
        }
        elea {
            -"Tu as raison ${coban.name}."
            -"Autant le préciser tout de suite : pour que cette histoire reste marrante, je vous dévoile bien sûr quelques théories fumeuses."
            -"Ça ne veut pas dire que j’y accorde du crédit, loin de là même !"
        }
        choose {
            -"Moi non plus, mais on t’écoute !"
            -"Continue ton histoire."
        }
        elea {
            -"Merci ${player.name}."
            -"J’allais dire que les émissions énergétiques de la station HAARP permettraient par exemple de couper les communications radio dans une zone définie…"
            -"De provoquer des incendies à distance…"
            -"De manipuler le climat…"
            -"Ou, encore plus surprenant, de manipuler l’esprit des gens !"
        }

        choose {
            -"Wow ! C’est fou !"
            -"Euh, t’en fais pas un peu trop là ?"
        }

        elea {
            -"Oui, c’est clair que ça paraît complètement dingue !"
            -"C’est sûrement la théorie la plus loufoque que j’ai entendue à ce sujet."
            -"HAARP a l’air tellement parfait que ses applications semblent infinies :"
            -"Les ondes envoyées par les antennes de la station permettraient même de scanner le sous-sol terrestre et marin !"
            -"Avec ça, impossible de cacher une base secrète ou un sous-marin."
        }
        choose {
            "C’est déjà possible de repérer les bases secrètes avec Google Maps de toute façon."{
                coban {
                    -"Haha, ${player.name} n’a pas tort !"
                }
            }
            "HAARP, ce serait pas du pipeau ?"{
                coban {
                    -"Haha, j’aime bien les jeux de mots ringards."
                }
            }
        }
        elea {
            -"Pfff."
            -"Vous avez pas fini vous deux ?"
        }
        choose {
            -"Pardon, ${elea.name}"
        }
        coban {
            -"T’as bien fait de venir ${player.name}, c’est bien plus sympa les soirées avec toi. 😄"
        }
        elea {
            -"Je vais faire comme si j’avais rien entendu."
            -"Je reprends"
            -"J’ai lu d’autres rumeurs, démenties bien sûr, affirmant que ce projet est à l’origine de cyclones dévastateurs ayant touché différentes parties du globe !"
            -"HAARP pourrait aussi créer des EMP, des armes à impulsions électro-magnétiques !"
            -"Le principe des EMP, c’est que ça affecte toutes nos connexions électriques."
            -"Une EMP lancée sur une région = plus d’Internet, ni de courant électrique, et tous les appareils électroniques touchés seraient foutus !"
            -"Vous imaginez une telle puissance entre les mains d’un seul pays ?"
        }
        choose {
            -"Ça pourrait avoir des conséquences désastreuses…"
        }
        elea {
            -"Exactement !"
        }
        choose {
            -"Tout ça parce que Nikola Tesla a inventé la télécommande…"
        }
        elea {
            -"Exactement. 🙂"
        }
        coban {
            -"Ouais…"
            -"J’aime bien ton histoire ${elea.name}, mais j’ai du mal à la prendre très au sérieux."
        }
        elea {
            -"Ah, je pensais pourtant que ça te plairait."
            -"Peut-être que HAARP n’est pas aussi puissant que ça, on est d’accord, mais la station existe, c’est un fait."
            -"Vous pouvez le vérifier par vous même."
        }
        choose {
            -"Toujours vérifier ses sources !"
            -"J’y jetterai un oeil à l’occasion."
        }
        coban {
            -"Oh, j’imagine que tu as bien fait tes recherches."
            -"C’est juste que… Un pays tout-puissant grâce à sa station météo ?"
            -"Ça va un peu loin tu ne trouves pas ?"
        }

        choose {
            "${elea.name} a raison"{
                elea {
                    -"Merci ${player.name}. 🙂"
                    -"C’est juste que c’est un sujet auquel j’accorde beaucoup d’importance."
                }
            }
            "${coban.name}, tu es un peu trop sérieux."{}
        }
        coban {
            -"Désolé, je me suis un peu emporté."
            -"En quoi ça t’intéresse autant, ${elea.name}?"
        }
        elea {
            -"Ce qui m’intéresse, c’est qu’une station de ce type existe."
            -"Et que même si les rumeurs de « manipulation de l’esprit » sont clairement à côté de la plaque, d’autres possibles applications de HAARP semblent problématiques."
            -"J’ai lu (ok, encore une rumeur) que ce projet pourrait en fait être une machine à énergie libre, comme celle mise au point par Nikola Tesla !"
        }
        choose {
            -"C’est la machine dont tu m’avais parlée ?"
        }
        elea {
            -"Celle-là même !"
            -"HAARP me fait étrangement penser à une version modernisée de la Tour de Tesla, une autre invention du scientifique…"
            -"Regardez. Ça c’est une tour de Tesla :"
            photo("img_tesla_tower.jpeg", "Tour de tesla")
            -"Et ça c’est une image de l’HAARP :"
            photo("img_haarp.jpeg", "HAARP")
        }
        coban {
            -"Hum, ok, on pourrait penser que c’est assez proche…"
        }
        choose {
            -"C’est vrai que ça y ressemble !"
            -"Je ne suis pas convaincu par leur ressemblance."
        }
        elea {
            -"Tout ça n’a peut-être pas de lien, mais avouez que c’est intrigant ; ça fait beaucoup de coïncidences."
        }
        coban {
            -"Si tu as raison, et je dis bien SI, je ne vois pas le problème."
            -"Déjà, précisons bien que l’HAARP n’est PAS une arme, mais seulement un émetteur à hautes fréquences."
            -"Ensuite, si le gouvernement Américain, ou toute autre entreprise privée mettait au point une machine à énergie libre, capable d’alimenter la Terre entière avec une énergie propre, ce serait génial !"
        }
        choose {
            "${coban.name}, je pense ${
                elea.withGender("qu’", "que ")
            }${elea.name} ne va pas être d’accord avec toi" {
                elea {
                    -"Merci ${player.name}, je savais que tu dirais ça."
                }
            }
            -"C’est vrai ça ! Coban, je suis de ton avis."
        }
        elea {
            -"Pas si sûr non…"
            -"Tout dépend de qui exploite cette énergie."
            -"Le principe humaniste de Tesla était de transmettre ce pouvoir à tous, d’en faire un droit universel."
            -"Que feraient un Etat ou une entreprise privée mal intentionnés ?"
            -"Ils vendraient cette énergie, en tireraient des profits immenses, au détriment de la population."
            -"Rien ne changerait, tout serait comme aujourd’hui… Le gâteau pour les puissants, les miettes pour les autres."
        }
        coban {
            -"Avec une puissance comme ça à disposition, je suis bien évidemment partisan d’une entreprise capable d’en gérer la distribution."
            -"Sinon, c’est la porte ouverte aux abus, à l’anarchie, et à la destruction de nos sociétés."
            -"Il faut une tête pensante, capable de diriger le peuple dans la bonne direction."
            -"Et puis de toute façon, ce ne sont que des hypothèses ; jusqu’à présent, on ne fait que débattre d’une histoire que tu as inventée ! 😛"
        }
        elea {
            -"Tu n’es pas sérieux ${coban.name} ?"
            -"${player.name}, t’en penses quoi ?"
        }
        choose {
            "${coban.name} n’a pas tort…"{
                elea {
                    -"Ah bon ?"
                    -"T’es de son côté ?"
                    -"Tu me déçois ${player.name}…"
                }
            }
            "${elea.name}, je suis avec toi !"{
                elea {
                    -"Merci ${player.name}, ça me touche. 😉"
                }
            }
        }
    }

    fragment(Chapter4.night) {
        coban {
            -"Bon, on commence ?"
            -"J’en ai marre d’attendre là."
        }
        elea {
            -"Sois un peu patient ${coban.name}, je viens tout juste de lancer l’invitation."
            -"${player.name}, t’es par là ?"
        }
        choose {
            "Salut vous deux !"{

            }
            "Quel accueil…"{
                elea {
                    -"${player.name} n’a pas tort ${coban.name} ; je t’ai connu plus sympa…"
                }
                coban {
                    -"…"
                    -"Excuse-moi, j’ai eu une journée compliquée."
                    -"${player.name} ! Je te souhaite la bienvenue dans cette communauté fabuleuse des compagnons de l’étrange."
                    -"Nos nombreux membres à travers le monde (deux au total) sont ravis de t’accueillir et de partager avec toi leur passion des phénomènes paranormaux, leur amour de l'inexpliqué et de l'étrange, et leur euh..."
                    -"Je sais plus quoi dire d'autre. 😅"
                }

                elea {
                    -"Haha !"
                    -"Ben voilà, un accueil digne de ce nom. 🙂"
                    -"Bref, nous sommes ravis de t'accueillir dans notre petit monde de nerds. "
                }
            }
        }
        elea {
            -"Salut ${player.name}. 🙂"
            -"Je te présente ${coban.name}, que j’ai connu sur un forum de discussion."
        }
        choose {
            "${coban.name} ? C’est quoi ce pseudo ?"{
                coban {
                    -"Ah, ça me fait plaisir que tu me poses la question !"
                    -"C’est un nom qui vient de « La Nuit des Temps », un livre de René Barjavel."
                    -"${coban.name} est un savant humaniste, prêt à tout pour sauver son peuple…"
                    -"Un homme qui utilise sa connaissance pour le bien des siens, qui place la vie au-dessus de tout."
                    -"Je ne t’en dis pas plus, lis ce livre si tu ne l’as jamais fait, ça en vaut vraiment la peine."
                }

                choose {
                    "J’adore Barjavel !"{
                        elea {
                            -"Ah, tu vois ${coban.name} ?"
                            -"Je t’avais dit que ${player.name} serait une belle valeur ajoutée à notre petit groupe."
                        }
                        coban {
                            -"Un amateur de Barjavel est forcément quelqu’un de bien. 😉"
                        }
                    }
                    "A l’occasion, pourquoi pas."{}
                }
            }
            -"${player.withGender("Enchanté", "Enchantée")} !"
        }
        coban {
            -"Tu viens d’où ${player.name} ?"
            -"Enfin, tu nous parles depuis quel endroit je veux dire ?"
        }
        answer { name ->
            stateMachine.game.city = name
            if (name.obscene) {
                coban {
                    -"Eh, sois un peu sérieux, ${player.name}"
                }
                elea {
                    -"Haha, t’es bête ${player.name} !"
                    -"Allez, c’est de l’humour, ne le prends pas mal ${coban.name}."
                }
            }
            coban {
                -"Cool !"
                -"Et toi ${elea.name} ?"
                -"T’es où en ce moment ?"
            }
            elea {
                -"Nulle part, partout."
                -"Je suis avec vous, c’est ça qui compte non ? 😉"
            }
            coban {
                -"Oh, allez arrête !"
                -"Ça fait des mois qu’on se parle."
                -"Et t’as jamais voulu parler VRAIMENT de toi."
            }
            choose {
                -"C’est vrai ça !"
            }
            coban {
                -"C’est pas grand chose de nous dire où tu es, non ?"
                -"${player.name} vient de ${name}, moi je suis un parisien pur souche…"
            }
            if (name.obscene) {
                coban {
                    -"${name}…"
                    -"C’est vrai que c’est drôle."
                    -"T’as une tête à venir de là."
                }
                elea {
                    -"Haha !"
                }
                choose {
                    -"Hahaha, je suis ${player.withGender("hilarant", "hilarante")}."
                    -"Ça m’apprendra à être vulgaire. Bien envoyé ${coban.name}."
                }
                coban {
                    -"Eh eh."
                }
            }
        }
        coban {
            -"Et toi alors ${elea.name} ?"
        }
        elea {
            -"…"
            -"Je sais pas trop si je peux en parler…"
        }
        choose {
            -"Allez, tu peux bien nous le dire !"
            -"C’est toi qui décide, ça ne change rien pour moi. 🙂"
        }
        elea {
            -"Bon, ok je vous le dis, mais c’est vraiment parce que je vous fais confiance."
            -"Je suis en déplacement pour quelques jours… dans la ville la plus romantique du monde."
        }
        choose {
            "T’es à Paris ?"{
                coban {
                    -"Je vois pas une autre ville capable de rivaliser."
                    -"Pigeons, métros bondés, Parisiens pressés qui font la gueule…"
                    -"Le romantisme par excellence !"
                }
                elea {
                    -"Haha, t’as pas tort… Mais Paris c’est aussi…"
                    -"Une ville chargée d’histoire et de mystères."
                    -"Un paradis pour des passionnés comme nous."
                }
            }
            "Hum, c’est Venise !"{
                coban {
                    -"Venise ?"
                    -"Gondoles, carnaval, histoire…"
                    -"ça pourrait être Venise. C’est le cas ${elea.name} ?"
                }
                elea {
                    -"Non, c’est pas Venise !"
                    -"Allez je vous le dis : je suis à Paris."
                    -"Une ville chargée d’histoire et de mystères."
                    -"Un paradis pour des passionnés comme nous."
                }
            }
            "Aaah, Munich… <3"{
                coban {
                    -"Haha, pas mal !"
                    -"Je crois qu’on va bien s’entendre, ${player.name} !😄"
                }
                elea {
                    -"Haha, Munich ?!"
                    -"Comment tu peux nous sortir ça ?! 😄"
                    -"Je suis pas à Munich, bien essayé. 😛"
                    -"Allez je vous le dis : je suis à Paris."
                    -"Une ville chargée d’histoire et de mystères."
                    -"Un paradis pour des passionnés comme nous."
                }
            }
        }
        coban {
            -"Eh, mais si t’es à Paris, faut qu’on se croise !"
        }
        elea {
            -"Euh, faut voir, un de ces jours, pourquoi pas !"
        }
        coban {
            -"Mais oui, on se fera une expédition « mystères irrésolus » en chair et en os."
            -"Tu viendras ${player.name} ?"
        }
        choose {
            "Si ${elea.name} est ok, avec plaisir."{
                elea {
                    -"Compte sur moi, ce serait un plaisir partagé."
                }
                coban {
                    -"Parfait, j’ai hâte qu’on organise ça."
                }
            }
            "Je ne sais pas trop, on ne se connait pas vraiment."{
                elea {
                    -"On a tout le temps de se rencontrer, tu as tout à fait raison."
                    -"Un jour, plus tard…"
                }
                coban {
                    -"Dommage, ça aurait été bien de se croiser."
                    -"J’espère qu’on pourra le faire un jour."
                }
            }
        }
        coban {
            -"Bon, c’est pas tout ça !"
            -"On n’est pas là pour parler géographie, ou rendez-vous à Paris."
            -"Passons aux choses sérieuses."
            -"${player.name} !"
        }
        choose {
            -"Oui ?"
            -"${coban.name} !"
        }
        coban {
            -"Est-ce que tu t’intéresses aux mystères qui nous entourent ?"
            -"Aux extraterrestres ?"
            -"Aux mystères des pyramides, vaisseaux volants ou fantômes, trésors cachés et autres manuscrits secrets ?"
            -"À tout ce qui relève de l’inexpliqué ?"
        }
        elea {
            -"ÇA, c’est de la mise en situation !"
        }
        choose {
            -"Euh, c'est pas trop mon délire."
            -"Ça m’intéresse, oui !"
        }
        elea {
            -"Ne t’inquiète pas ${player.name}."
            -"${coban.name} a toujours tendance à en rajouter un peu."
        }
        coban {
            -"Moi, en rajouter ?"
            -"Euh… Oui, c’est vrai. 😄"
            -"Mais plus sérieusement, notre monde regorge de mystères, et je trouve passionnant de tenter de les comprendre et de les expliquer."
        }
        elea {
            -"Alors, qui se lance ?"
        }
        choose {
            "${elea.name}, c’est toi que j’ai envie d’entendre. 😉"{
                include(Chapter4.eleaStory)
            }
            "${coban.name}, tu as une histoire cool à raconter ?"{
                include(Chapter4.cobanStory)
            }
        }
        coban {
            -"Allez, passons à autre chose !"
            -"${elea.name}, tu as une autre histoire en stock ?"
        }
        elea {
            -"Oui oui, j’ai d’autres histoires."
            -"Ah, tiens…"
            hesitation()
            -"Y’a un truc bizarre dans mon immeuble."
        }
        choose {
            -"Un problème ?"
            -"Tout va bien ?"
        }
        elea {
            -"Je sais pas…"
            -"C’est juste…"
            hesitation()
            -"Quelqu’un qui frappe à ma porte."
        }
        coban {
            -"Et alors ?"
            -"Ça doit être un livreur de pizza, vu l’heure."
        }
        elea {
            -"Sauf que je n’ai rien commandé."
            -"Et que personne ne sait que je suis là."
        }
        coban {
            -"Alors c’est peut-être juste un voisin."
        }
        elea {
            -"Ça recommence."
            -"Il insiste"
            -"On dirait qu’ils sont plusieurs."
        }
        coban {
            -"Mais va ouvrir, tu verras bien qui c’est."
            -"Pas besoin de faire autant de mystères ; ça se voit que t’aimes les histoires toi. 😛"
        }
        choose {
            "Va ouvrir."{
                elea {
                    -"Ok, ok j’y vais…"
                }
            }
            "Ne fais aucun bruit."{
                elea {
                    -"T’as raison, je vais attendre que ça s’arrête."
                }
            }
        }
        coban {
            -"Bon…"
            -"${elea.name} ?"
            -"T’es là ?"
        }
        elea {
            hesitation()
        }
        coban {
            -"…"
            -"Apparemment non."
            -"${player.name}, tu connais ${elea.name} depuis longtemps ?"
        }
        choose {
            "Pas vraiment, on se connaît à peine."{
                coban {
                    -"Pareil pour moi !"
                    -"On s’est croisé sur un forum, et puis à force d’échanger sur Twitter… On est devenus potes"
                }
            }
            "J’ai l’impression qu’on se connaît depuis toujours."{
                coban {
                    -"Méfie-toi toujours des personnes que tu « rencontres » sur Internet."
                    -"Derrière un écran, on peut être n’importe qui…"
                }
            }
        }
        narrator {
            -"${elea.name} a quitté la discussion."
            -elea.disconnect()
        }
        coban {
            -"Ah ! C’est sympa ${elea.name}, t’aurais pu nous dire au revoir."
        }
        choose {
            -"Il se passe quelque chose !"
        }
        coban {
            -"Mais non, son quartier est très animé ce soir. Y’a même un concert gratuit juste à côté."
            -"${elea.name} a rencontré un voisin et ils sont allés faire la fête, c’est tout."
        }
        choose {
            -"C’est sûrement ça, oui."
            "Je trouve que c’est suspect."{
                coban {
                    -"Mais non, tu te prends la tête pour rien."
                }
            }
        }
        coban {
            -"Bon, c’est vrai qu’il commence à se faire tard."
            -"${player.name}, ce fut un plaisir de passer cette soirée en ta compagnie, même si j’aurais aimé que ${elea.name} prenne la peine de nous dire au revoir. 😛"
        }
        choose {
            -"C’était cool oui."
            "T’es sûr qu’on ne devrait pas essayer de l’aider ?"{
                coban {
                    -"Pourquoi ?"
                    -"On va pas appeler les flics à chaque fois que quelqu’un se déconnecte pendant une discussion."
                    -"${elea.withGender("Il", "Elle")} nous fera signe très vite, sois-en ${
                        player.withGender("sûr", "sûre")
                    }."
                }
            }
        }
        coban {
            -"Sur ce, je m’en vais !"
            -"Je préfère te le préciser, que tu ne flippes pas pour rien. 😂"
        }
        choose {
            -"Pfff, c’est malin ça."
            -"C’est ça oui, salut."
        }
        coban {
            -"Passe une bonne soirée, et à bientôt."
        }
        narrator {
            -coban.disconnect()
        }
        redirect(Chapter4.elea)
    }

    scene(Chapter4.invitation, GroupMessengerContext("Mystères irrésolus")) {
        characters(elea, coban, player)
        narrator {
            -"${elea.name} vous a invité à rejoindre la discussion « Mystères irrésolus »."
        }
        choose {
            "Rejoindre la discussion"{
                include(Chapter4.night)
            }
            "Ignorer l’invitation"{
                redirect(Chapter4.end3, GoToScene.Option.Timeout(10_000))
            }
        }
    }

    scene(Chapter4.elea, MessengerEleaContext) {
        characters(elea)
        choose {
            silent("Ne rien faire")
            silent("Envoyer un message a ${elea.name}") {
                choose { -"${elea.name} ?" }
                choose { -"Tu es là ?" }
                elea {
                    hesitation()
                }
                choose { -"Je m’inquiète pour toi." }
                elea {
                    hesitation()
                }
                choose { -"Envoie-moi un message dès que tu peux." }
                choose {
                    -"Je t’embrasse"
                    -"A bientôt."
                }
            }
        }
        redirect(Chapter5.intro)
    }

    scene(Chapter4.end3, EndContext("Fin 3")) {
        stateMachine.achievements.end3 = true
        narrator {
            -"Vous n’avez pas souhaité répondre à l’invitation de ${elea.name}."
            -"Une décision qui n’est pas sans conséquence, puisque ${elea.name} pense désormais qu’${
                elea.withGender("il", "elle")
            } ne vous intéresse pas."
            -"C’est un choix compréhensible : parfois des personnes se rencontrent, échangent un peu, mais le courant ne passe plus."
            -"Vous en aviez parlé : les relations virtuelles peuvent se distendre très vite. Vous n’entendrez plus jamais parler ${
                elea.withGender("de lui", "d’elle")
            }."
            -"Peut-être qu’un autre chemin était possible…"
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 3."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }


}
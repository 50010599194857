package xyz.lacunae.itsadate

val String.obscene: Boolean
    get() = lowercase().split(" ").any {
        it in listOf(
            "cul",
            "chatte",
            "mère",
            "bite",
            "teub",
            "couille",
            "con",
            "conne",
            "salope",
            "connard",
            "prout",
            "pipi",
            "caca",
            "chatte",
        )
    }

val String.isPhoneNumber : Boolean
    get() = this.matches("""^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$""".toRegex())
import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import xyz.lacunae.itsadate.*
import xyz.lacunae.itsadate.App.*
import xyz.lacunae.story.AnswerType
import xyz.lacunae.story.Character
import xyz.lacunae.story.Context
import xyz.lacunae.story.Scene

external interface MessagesAppProps : Props {
    var scene: Scene
    var writing: Character?
    var messages: List<IMessage>?
    var next: Next?
    var choices: List<Any>?
    var onChoice: (Int, Any) -> Unit
    var answer: AnswerType?
    var onAnswer: (String) -> Unit
    var onDownloaded: (DownloadMessage) -> Unit
    var onUploaded: (UploadMessage) -> Unit
    var onNext: () -> Unit
}

val MessagesAppComponent = FC<MessagesAppProps>("MessagesAppComponent") { props ->
    div {
        css(*className(props.scene.context).toTypedArray()) {
            display = Display.grid
            gridTemplateRows = "auto 1fr auto".unsafeCast<GridTemplateRows>()
            gridTemplateColumns = 100.pct
            height = 100.pct
            backgroundColor = Colors.surfaceContainerLowest
            backdropFilter = blur(7.px)
        }
        HeaderComponent {
            scene = props.scene
        }
        MessagesComponent {
            characters = props.scene.characters
            writing = props.writing
            messages = props.messages.orEmpty()
            onDownloaded = props.onDownloaded
            onUploaded = props.onUploaded
        }
        InteractionsComponent {
            answer = props.answer
            onAnswer = props.onAnswer
            choices = props.choices
            onChoice = props.onChoice
            next = props.next
            onNext = props.onNext
        }
    }
}

private fun className(context: Context) = if (context is AppContext) {
    when (context.app) {
        DATING -> listOf(ClassName("theme-dating"))
        WORK -> listOf(ClassName("theme-work"))
        MESSENGER -> if (context is InPlaceContext) {
            if (context.place in listOf(Place.NOWHERE, Place.PARIS)) {
                listOf(ClassName("theme-messenger"), ClassName("theme-messenger-dark"))
            } else {
                listOf(ClassName("theme-messenger"), ClassName("theme-messenger-light"))
            }
        } else {
            listOf(ClassName("theme-messenger"))
        }

        SMS -> listOf(ClassName("theme-sms"))
        else -> emptyList()
    }
} else {
    emptyList()
}
import xyz.lacunae.story.StateMachine
import xyz.lacunae.itsadate.achievements
import xyz.lacunae.itsadate.game
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.get
import react.Props
import react.FC
import react.useEffect
import kotlin.js.Date

external interface TimeProps : Props {
    var stateMachine: StateMachine
}

val TimerComponent = FC<TimeProps>("TimerComponent") { props ->

    var startTime: Long? = null

    fun timeInSeconds(): Long = (Date().getTime() / 1_000).toLong()

    fun measureStartTime() {
        startTime = timeInSeconds()
    }

    fun measureEndTime() {
        startTime?.let {
            val endTime = timeInSeconds()
            val elapsedTime = endTime - it
            props.stateMachine.game.time += elapsedTime
            props.stateMachine.achievements.time += elapsedTime
        }
        startTime = null
    }

    var tickRef: Int? = null

    val tick = {
        measureEndTime()
        measureStartTime()
    }

    val visibilityChange: (org.w3c.dom.events.Event) -> Unit = {
        if (document["visibilityState"] === "visible") {
            tickRef = window.setInterval(tick, 5_000)
            measureStartTime()
        } else {
            measureEndTime()
            tickRef?.let(window::clearInterval)
        }
    }

    useEffect(Unit) {
        measureStartTime()
        tickRef = window.setInterval(tick, 5_000)
        document.addEventListener("visibilitychange", visibilityChange)
        cleanup {
            document.removeEventListener("visibilitychange", visibilityChange)
            tickRef?.let(window::clearInterval)
            measureEndTime()
        }
    }
}

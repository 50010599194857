import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import xyz.lacunae.itsadate.IntroContext

external interface NewsletterPageProps : Props {
    var onExit: () -> Unit
}

val NewsletterPage = FC<NewsletterPageProps>("NewsLetterComponent") { props ->
    EnvironmentComponent {
        context = IntroContext("")
        device = true
        appProps = null
        backgroundImage = "/bg_home.jpg"
        backgroundColor = rgb(21, 31, 42).toString()
        div {
            css(ClassName("theme-dark")) {
                width = 100.pct
                height = 100.pct
                display = Display.grid
                gridTemplateRows = array(1.fr, Auto.auto)
                gridTemplateColumns = 100.pct
                overflow = Overflow.hidden
                backgroundColor = rgba(0, 0, 0, .5)
                fontFamily = string("'Courier New', monospace")
            }
            NewsLetterComponent {}
            UserNextComponent {
                label = "Retour"
                onNext = props.onExit
            }
        }
    }
}
import csstype.*
import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import react.FC
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.progress
import react.useEffect
import react.useState
import kotlin.js.Date

external interface OnNextProps : Props {
    var label: String?
    var disabled : Boolean?
    var onNext: () -> Unit
}

external interface NextProps : OnNextProps {
    var next: Next?
}

val NextComponent = FC<NextProps>("NextComponent") { props ->
    when (val option = props.next?.option) {
        Next.Option.User -> child(UserNextComponent, props)
        Next.Option.Auto -> child(AutoNextComponent, props)
        is Next.Option.Timeout -> TimeoutNextComponent {
            max = option.value
            onNext = props.onNext
        }
        null -> {}
    }
}

val AutoNextComponent = FC<OnNextProps>("AutoNextComponent") { props ->
    useEffect {
        props.onNext()
    }
}

fun withKeys(onEnter: () -> Unit = {}, onEscape: () -> Unit = {}) {
    val callback: (Event) -> Unit = { event ->
        if (event is KeyboardEvent) {
            when (event.code) {
                "Enter" -> onEnter()
                "Escape" -> onEscape()
            }
        }
    }
    useEffect {
        document.addEventListener("keyup", callback)
        cleanup { document.removeEventListener("keyup", callback) }
    }
}

val UserNextComponent = FC<OnNextProps>("UserNextComponent") { props ->
    withKeys(
        onEnter = { props.onNext() }
    )
    div {
        css {
            display = Display.flex
            height = 10.vh
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
        }
        button {
            onClick = {
                props.onNext()
            }
            disabled = props.disabled ?: false
            css {
                backgroundColor = Colors.primary
                color = Colors.onPrimary
                padding = Padding(8.px, 16.px)
                borderRadius = 24.px
                border = None.none
                fontSize = FontSize.button
                disabled {
                    backgroundColor = Colors.onSurface
                    opacity = number(0.38)
                }
            }
            + (props.label ?: "Continuer")
        }
    }
}

external interface TimeoutNextProps : OnNextProps {
    var max: Long
}

val TimeoutNextComponent = FC<TimeoutNextProps>("TimeoutNextComponent") { props ->

    val (interval, setInterval) = useState<Int?>(null)
    val (startDate, setStartDate) = useState(Date.now())
    val (progress, setProgress) = useState<Long>(0)

    val handler = {
        setProgress((Date.now() - startDate).toLong())
    }

    val inProgress = {
        progress < props.max
    }

    withKeys(
        onEnter = {
            props.onNext()
        },
    )

    useEffect(progress) {
        if (!inProgress()) {
            interval?.let(window::clearInterval)
            props.onNext()
        }
    }

    useEffect(props.max) {
        setStartDate(Date.now())
        setInterval(window.setInterval(handler, 16))
        cleanup {
            interval?.let(window::clearInterval)
        }
    }

    div {
        css {
            height = 10.vh
            position = Position.relative
        }
        div {
            css {
                display = Display.flex
                height = 100.pct
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
            }
            button {
                onClick = {
                    props.onNext()
                }
                disabled = props.disabled ?: false
                css {
                    backgroundColor = Colors.primary
                    color = Colors.onPrimary
                    padding = Padding(8.px, 16.px)
                    borderRadius = 24.px
                    border = None.none
                    fontSize = FontSize.button
                    disabled {
                        backgroundColor = Colors.onSurface
                        opacity = number(0.38)
                    }
                }
                +"Continuer"
            }
        }
        progress {
            css {
                position = Position.absolute
                width = 100.pct
                top = 0.px
            }
            value = progress.toString()
            max = props.max.toDouble()
        }
    }
}

import xyz.lacunae.story.Character
import xyz.lacunae.story.StateMachine
import xyz.lacunae.story.StateMachineBuilder
import xyz.lacunae.story.parseBoolean
import xyz.lacunae.itsadate.*

fun StateMachine.getSuccess() = listOf<Success<*>>(
    Success<Any>(
        name = "Toutes les apps",
        progress = listOf(
            achievements.datingInstalled,
            achievements.messengerInstalled
        ).sumOf { if (it) 1 else 0.toInt() },
        finish = 2,
    ),
    Success<Any>(
        name = "Tous les badges",
        progress = listOf(
            achievements.badgePnj4,
            achievements.badgePnj7,
            achievements.badgePnj9,
            achievements.badgePnj12,
            achievements.badgePnj13
        ).sumOf { if (it) 1 else 0.toInt() },
        finish = 5
    ),
    Success<Any>(
        name = "Toutes les fins",
        progress = ((1..13).map { "end${it}:${AchievementsStateMachine.namespace}" }).map { get(it).parseBoolean() }
            .sumOf { if (it) 1 else 0.toInt() },
        finish = 13,
    ),
    Success<Character?>(
        name = "Tous les dates",
        progress = ((2..15).map { "pnj${it}Visited:${AchievementsStateMachine.namespace}" }
                + "eleaVisited:${AchievementsStateMachine.namespace}"
                + "nikolaMarsVisited:${AchievementsStateMachine.namespace}"
                ).map {
            get(it).parseBoolean()
        }.sumOf { if (it) 1 else 0.toInt() },
        finish = 16,
        details = listOfNotNull(
            if(achievements.eleaVisited) toBuilder().elea else null,
            if(achievements.nikolaMarsVisited) toBuilder().nikolaMars else null,
            if(achievements.pnj2Visited) toBuilder().pnj2 else null,
            if(achievements.pnj3Visited) toBuilder().pnj3 else null,
            if(achievements.pnj4Visited) toBuilder().pnj4 else null,
            if(achievements.pnj5Visited) toBuilder().pnj5 else null,
            if(achievements.pnj6Visited) toBuilder().pnj6 else null,
            if(achievements.pnj7Visited) toBuilder().pnj7 else null,
            if(achievements.pnj8Visited) toBuilder().pnj8 else null,
            if(achievements.pnj9Visited) toBuilder().pnj9 else null,
            if(achievements.pnj10Visited) toBuilder().pnj10 else null,
            if(achievements.pnj11Visited) toBuilder().pnj11 else null,
            if(achievements.pnj12Visited) toBuilder().pnj12 else null,
            if(achievements.pnj13Visited) toBuilder().pnj13 else null,
            if(achievements.pnj14Visited) toBuilder().pnj14 else null,
            if(achievements.pnj15Visited) toBuilder().pnj15 else null,
        )
    ),
)

private fun StateMachine.toBuilder() = object : StateMachineBuilder {
    override val stateMachine: StateMachine
        get() = this@toBuilder
}
package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.narrator

object Chapter2 {
    private const val name = "chapter-2"
    const val intro = "$name/intro"
    const val elea = "$name/elea"
    internal const val boulot = "$name/fragment/boulot"
}

internal fun Story.chapter2() {

    scene(Chapter2.intro, IntroContext("Chapitre 2")) {
        narrator {
            -"${elea.name} est une personne sympa, bavarde, et enjouée."
            -"Ça fait longtemps que vous n’aviez pas pris autant de plaisir à discuter avec quelqu’un."
            -"Allez-vous réussir à approfondir ce lien, à développer un peu plus cette relation naissante ?"
            -"Quelle place ${elea.name} ${
                elea.withGender("va-t-il", "va-t-elle")
            } prendre dans votre vie ?"
            -"${elea.withGender("Ami", "Amie")} ?"
            -"Ou peut-être ${elea.withGender("Amant", "Amante")} ?"
            -"A moins que, finalement, cette rencontre ne soit qu’un feu de paille, et qu’elle ne vous apporte rien d’autre qu’une amère déception…"
            -"Une décision qui parait anodine peut avoir d’immenses conséquences."
            -"Vos choix, quels qu’ils soient, auront une forte incidence sur la suite de cette histoire."
        }
        appBadge(Apps.messenger)
        redirect(Chapter2.elea)
    }

    fragment(Chapter2.boulot) {
        elea {
            -"C’est le lot de la plupart d’entre nous."
            -"On fait un job alimentaire, qui laisse peu de place à nos loisirs…"
            -"C’est assez dingue de penser qu’on passe la majeure partie de notre temps avec des collègues dont on n’est pas si proche."
            -"A bosser pour un patron qu’on n’a jamais croisé."
            -"Et qu’au final, on ne voit nos proches que quelques heures par jour…"
            -"Le matin, on n’en profite pas parce qu’on doit se préparer à aller travailler."
            -"Et le soir non plus, parce qu’on est crevé et qu’on cherche à décompresser du boulot."
        }
        choose {
            -"Il faut travailler pour vivre, et non pas vivre pour travailler."
            -"Choisis un travail que tu aimes, et tu n’auras pas à travailler un seul jour de ta vie."
            -"Reste toi-même, aime ton métier. Tâche d’y réussir brillamment. Mais n’en éprouve aucune vanité."
        }
        elea {
            -"Mouais…"
            -"C’est pas un proverbe un peu cliché qui me fera penser autre chose."
            -"Pour moi, travail = aliénation."
            -"On n’est pas fait pour ça."
            -"On devrait passer la majeure partie de notre temps avec nos proches, pas avec nos collègues."
            -"Tu as de la famille ?"
        }
    }

    scene(Chapter2.elea, MessengerEleaContext) {
        characters(elea)
        choose {
            -"${elea.name} ? Tu vas bien ?"
            -"Yo ${elea.name}; ça roule ?"
        }
        elea {
            -"Salut ${player.name}. 😊"
            -"${elea.withGender("Content", "Contente")} d’avoir de tes nouvelles."
        }
        choose {
            -"T’es dispo ce soir ?"
        }
        elea {
            -"Bien sûr que je suis dispo."
        }
        if (player.name.obscene) {
            elea {
                -"Euh, j’ai un truc à te dire…"
                hesitation()
                -"Ne le prends pas mal mais…"
                hesitation()
                -"C’est très bizarre de parler avec quelqu’un qui se fait appeler « ${player.name} »."
                -"Tu ne veux pas me dire ton vrai prénom ?"
            }
            choose {
                "Non, j’aime bien ${player.name}"{
                    elea {
                        -"Très bien, restons là-dessus. C’est toi qui vois."
                    }
                }
                "Allez, ok."{
                    answer { name ->
                        stateMachine.game.player_name = name
                        if (name.obscene) {
                            elea {
                                -"Décidément, tu es ${player.withGender("inspiré", "inspirée")}."
                                -"Très bien, restons là-dessus."
                                -"C’est toi qui vois."
                            }
                        } else {
                            elea {
                                -"OK, je t’appellerai ${player.name} désormais."
                            }
                        }

                    }
                }
            }
        }
        elea {
            -"Tu as passé une bonne journée ?"
            -"Pas trop dur le boulot ?"
        }
        choose {
            -"C’était AFFREUX !😫"
            -"C’était une super journée !😆"
        }
        elea {
            -"Ah oui ?"
            -"Tu veux m’en parler un peu ?"
            -"En quoi consiste ton travail ?"
        }
        choose {
            "Je suis Agent Secret."{
                elea {
                    -"Haha, agent secret ?"
                    -"Quel est ton matricule d’ailleurs ?"
                }
                answer { code ->
                    elea {
                        -"${elea.withGender("Ravi", "Ravie")} de vous rencontrer Agent $code"
                    }
                }
                elea {
                    -"Votre mission est-elle confidentielle ?"
                    -"Quelle est votre couverture ?"
                }
                choose {
                    "Ma couverture est une couette bien épaisse, doublée d’un plaid en fourrure synthétique."{
                        elea {
                            -"Haha, t’es bête ! 😂"
                            -"Tu me fais rire. 😊"
                            -"Alors, ton vrai métier, c’est quoi ?"
                        }
                        choose {
                            -"Je suis gestionnaire en sinistre automobile."
                            -"Je suis gestionnaire en sinistre automobile."
                            -"Les créateurs du jeu ne me laissent pas le choix. Je DOIS répondre que je suis gestionnaire en sinistre automobile."
                        }

                    }
                    "Ma couverture ? Je me fais passer pour ${
                        player.withGender("un", "une")
                    } gestionnaire en sinistre automobile." {
                        elea {
                            -"C’est ça ton vrai métier, en fait ?"
                        }
                        choose {
                            -"Oui !"
                            -"Damn, tu m’as percé à jour !"
                        }
                    }
                }
            }
            -"Je suis Gestionnaire sinistre automobile."
        }
        elea {
            -"OK… Je dois t’avouer que…"
            -"Je n’ai pas la moindre idée de ce que c’est ! 😅"
        }
        choose {
            -"Oh, ça n’est pas si compliqué."
        }
        choose {
            -"Je travaille pour un grand groupe d’assurances auto."
        }
        choose {
            -"Je gère les réclamations des clients"
        }
        choose {
            -"Je vérifie les constats"
        }
        choose {
            -"J’assure un suivi des réparations post accident, je fais le lien avec les experts…"
        }
        elea {
            -"Ah, d’accord, je comprends."
            -"Et… ça te plait ?"
        }
        choose {
            "C’est un boulot comme un autre. Je ne me plains pas."{
                include(Chapter2.boulot)
            }
            "J’aime pas ça non."{
                include(Chapter2.boulot)
            }
            "Ce boulot me plait, oui. C’est la solitude qui me pèse."{
                elea {
                    -"Ah oui ?"
                    -"Crois-moi, c’est un sentiment que je ne connais que trop bien…"
                    -"C’est mon cas à moi aussi."
                    -"Tu n’as pas d’amis ou de famille ?"
                }
            }
        }
        choose {
            -"Ma famille est loin."
        }
        choose {
            -"J’ai trouvé ce travail et j’ai dû m’éloigner d’eux."
        }
        choose {
            -"Et je n’ai pas vraiment d’amis dans la région."
        }
        elea {
            -"Je te comprends…"
            -"Moi aussi, j’ai dû m’éloigner des miens."
            -"Chaque jour qui passe renforce un peu plus mon sentiment d’isolement."
            -"C’est dur de passer parfois des semaines entières sans réel contact humain."
            -"Je crois qu’on se ressemble, ${player.name}."
        }
        choose {
            -"On dirait bien que oui."
            -"Je comprends ce que tu ressens."
        }
        elea {
            -"On s’est rencontrés sur une appli de rencontres."
            -"J’imagine donc que tu es célibataire ?"
        }
        choose {
            "Oui, on peut dire ça."{
                elea {
                    -"Ouf, tu me rassures !"
                    -"Je n’aurai pas aimé tomber sur quelqu’un qui passe son temps à draguer tout ce qui bouge."
                }
            }
            "Bien vu, Sherlock !"{
                elea {
                    -"Eh ! T’as vu ça ? Au fond de moi, je sais que je ferai ${
                        elea.withGender("un enquêteur", "une enquêtrice")
                    } hors-pair."
                    -"Hercule Poirot n’a qu’à bien se tenir."
                }
            }
        }
        choose {
            -"Et toi, tu en es où de ta vie sentimentale ?"
        }
        elea {
            -"J’ai été célibataire presque toute ma vie."
            -"J’ai bien eu quelques aventures, mais jamais rien de sérieux."
            -"Je n’ai pas de temps à consacrer à une relation poussée et intime."
            -"Les circonstances font que j’ai dû beaucoup déménager."
            -"Je ne suis jamais ${elea.withGender("resté", "restée")} longtemps au même endroit."
            -"Je n’ai d’amis nulle part, ou presque…  pas d’attaches, pas de racines…"
        }
        choose {
            -"Comment ça se fait ?"
        }
        elea {
            -"A cause de mes parents, qui n’ont jamais réussi à se fixer quelque part."
            -"Mon père surtout."
            -"Quand j’étais ${elea.withGender("gamin", "gamine")}, j’ai subi cette situation."
            -"Ces déménagements soudains, ces changements d’écoles…"
            -"Aller partout, n’être de nulle part…"
            -"C’est ma vie, je n’ai rien connu d’autre."
            -"C’est comme ça, on s’y fait."
            -"Mais parfois, c’est un peu dur à supporter."
        }
        choose {
            -"D’où les applis de rencontre…"
        }
        elea {
            -"Exactement."
            -"Quand je me sens un peu trop ${
                elea.withGender("seul", "seule")
            }, que le besoin de contact humain se fait vraiment sentir…"
            -"Il m’arrive d’aller faire un tour sur ce genre de sites."
            -"C’est très rare, et en plus de ça, je suis très ${elea.withGender("sélectif", "sélective")}."
            -"Si j’ai un mauvais feeling avec la personne avec qui je parle, je mets les voiles."
            -"Et les rencontres « en vrai », c’est encore plus rare !"
        }
        choose {
            -"Tu penses qu’on pourrait se voir, un jour ?"
        }

        elea {
            -"N’allons pas trop vite. 😉"
            -"On a tout notre temps; apprenons d’abord à nous connaître un peu plus."
            -"Mais je t’avoue qu’avec toi, je me sens en confiance."
            -"Donc un jour, pourquoi pas… 😊"
        }
        choose {
            -"Tu peux me faire confiance."
            -"Je comprends ta méfiance."
        }
        elea {
            -"Je suis ${elea.withGender("sûr", "sûre")} que tu es quelqu’un de très bien."
            -"Mais je fais quand même très attention à ce genre de rencontres."
            -"Pour tout te dire, je n’en ai jamais fait via un site ou une appli !"
        }
        choose {
            "C’est pareil pour moi."{
                elea {
                    -"J’en étais ${elea.withGender("sûr", "sûre")} ! 😉"
                    -"J’ai eu un bon feeling avec toi, ${player.name}."
                    -"La preuve, ça faisait un moment que je ne m’étais pas autant ${
                        elea.withGender("confié", "confiée")
                    } à ${player.withGender("un inconnu", "une inconnue")}."
                    -"Je trouve que le courant passe bien entre nous."
                    -"Tu ne crois pas ?"
                }
            }
            "Moi c’est l’inverse, je multiplie les rencontres au maximum."{
                elea {
                    -"Ah oui ?"
                    -"Je n’aurais pas cru ça de toi."
                    -"Enfin, à chacun ses besoins et ses habitudes."
                    -"On peut être différents, et quand même s’entendre."
                    -"La preuve, je trouve que le courant passe bien entre nous."
                    -"Tu ne crois pas ?"
                }
            }
        }
        choose {
            "Oui, le courant passe bien entre nous."{
                elea {
                    -"Ah, ça me fait plaisir qu’on soit sur la même longueur d’onde. 😊"
                    -"Je vais t’avouer un truc."
                    -"Tu es la seule personne avec qui j’ai eu un lien aussi immédiat."
                    -"Merci, ${player.name}."
                }
            }
            "Il est un peu trop tôt pour dire ça…"{
                elea {
                    -"Hum…"
                    -"Tu as peut-être raison."
                    -"Je m’emballe, et ça n’est pas dans mes habitudes."
                    -"Excuse-moi."
                }
            }
        }
        elea {
            -"Je veux juste que tu saches que ça me fait un bien fou de discuter avec toi."
            -"Je n’accorde que rarement ma confiance aux autres, je me confie très peu."
            -"J’ai mes raisons pour ça, tu les connaîtras peut-être un jour."
        }
        choose {
            "Ne te sens pas ${elea.withGender("obligé", "obligée")} de te confier." {
                elea {
                    -"Merci ${player.name}."
                    -"Ça compte énormément pour moi que tu me dises ça."
                }
                elea {
                    -"Je dois me préserver, me protéger."
                    -"Mais avec toi, c’est comme si les barrières que j’ai érigées tombaient."
                    -"Bref…"
                    -"Je crois qu’on va devoir s’arrêter là pour ce soir."
                    -"J’ai encore beaucoup de travail."
                }
                choose {
                    -"Ça marche. Bonne soirée ${elea.name}"
                }
                elea {
                    -"Merci. A très vite ${player.name}."
                }
            }
            "La confiance, c’est mutuel. Tu dois te confier à moi si tu veux qu’on développe une relation saine." {
                elea {
                    -"Je suis ${elea.withGender("désolé", "désolée")} de ne pas pouvoir t’en dire plus."
                }
                elea {
                    -"Je dois me préserver, me protéger."
                    -"Mais avec toi, c’est comme si les barrières que j’ai érigées tombaient."
                    -"Bref…"
                    -"Je crois qu’on va devoir s’arrêter là pour ce soir."
                    -"J’ai encore beaucoup de travail."
                }
                choose {
                    -"Ça marche. Bonne soirée ${elea.name}"
                }
                elea {
                    -"Merci. A très vite ${player.name}."
                }
            }
            "Ce sera un autre jour. Je suis naze, je vais me coucher."{
                elea {
                    -"Ah…"
                    -"OK, c’est un peu brutal comme manière de clore une discussion. 😓"
                    -"Bon ben… Bonne nuit ${player.name}, repose-toi bien."
                }
                narrator {
                    -elea.disconnect()
                }
            }
        }

        redirect(Chapter3.intro)
    }
}
import csstype.*
import emotion.react.css
import kotlinx.browser.document
import org.w3c.dom.events.Event
import org.w3c.dom.get
import react.*
import react.dom.html.ReactHTML.audio
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.html.HTMLAudioElement
import xyz.lacunae.kotlin.Umami

external interface AudioProps : Props {
    var media: String
    var volume: Double
}

val AudioComponent = FC<AudioProps>("BadgeMessageComponent") { props ->
    var mute by useState(true)
    val audioRef = useRef<HTMLAudioElement>()

    useEffect(audioRef, props.volume) {
        audioRef.current?.volume = props.volume
    }
    useEffect(audioRef, props.media, mute) {
        if (mute) {
            audioRef.current?.pause()
        } else {
            audioRef.current?.play()
        }
    }

    val visibilityChange: (Event) -> Unit = {
        if (document["visibilityState"] === "visible") {
            if (!mute) {
                audioRef.current?.play()
            }
        } else {
            if (!mute) {
                audioRef.current?.pause()
            }
        }
    }

    useEffect(mute) {
        document.addEventListener("visibilitychange", visibilityChange)
        cleanup {
            document.removeEventListener("visibilitychange", visibilityChange)
        }
    }

    div {
        img {
            css {
                hover {
                    cursor = Cursor.pointer
                    opacity = number(0.8)
                }
                backgroundColor = rgba(255, 255, 255, 0.5)
                borderRadius = 24.px
                padding = 12.px
                mobile {
                    padding = 4.px
                }
            }
            onClick = {
                it.preventDefault()
                Umami.track("turn-${if (mute) "on" else "off"}-music")
                mute = !mute
            }
            src = if (mute) {
                "/ic_volume_off.svg"
            } else {
                "/ic_volume_up.svg"
            }
        }
        audio {
            ref = audioRef
            src = props.media
            loop = true
        }
    }

}
package xyz.lacunae.itsadate

import xyz.lacunae.story.Context
import xyz.lacunae.story.NamedContext

data class IntroContext(
    val title: String? = null,
    override val ambiance: Ambiance = Ambiance.INTRO,
) : Context by NamedContext("intro"), AmbianceContext

data class EndContext(
    val title: String? = null,
    val type: Type = Type.Short
) : Context by NamedContext("end"), AmbianceContext {
    enum class Type {
        Short, Deadly, Happy
    }

    override val ambiance: Ambiance
        get() = when (type) {
            Type.Short -> Ambiance.SHORT_END
            Type.Deadly -> Ambiance.DEADLY_END
            Type.Happy -> Ambiance.HAPPY_END
        }
}

interface AppContext : Context {
    val app: App
}

interface InPlaceContext : Context {
    val place: Place
}

data class VirtualContext(
    override val app: App,
    override val place: Place,
    override val ambiance: Ambiance
) : Context, AppContext, InPlaceContext, AmbianceContext {
    override val name: String
        get() = "${app.name}_${place.name}_${ambiance.name}".lowercase()
}

data class IrlContext(
    override val place: Place,
) : InPlaceContext, Context, AmbianceContext {
    override val name: String
        get() = place.name.lowercase()
    override val ambiance: Ambiance
        get() = when (place) {
            Place.PARK -> Ambiance.PARK
            Place.BAR -> Ambiance.BAR
            Place.STREET -> Ambiance.STREET
            else -> Ambiance.NOWHERE
        }
}

enum class App {
    SMS, DATING, WORK, MESSENGER
}

enum class Place {
    NOWHERE, HOME, DATING, WORK, PARK, BAR, STREET, PARIS
}

interface AmbianceContext {
    val ambiance: Ambiance
}

enum class Ambiance {
    SHORT_END,
    HAPPY_END,
    DEADLY_END,
    INTRO,
    PARK,
    BAR,
    STREET,
    FLIRT,
    RUNNING,
    NOWHERE,
    HOME,
    DATING,
    WORK,
}

val SmsContext = VirtualContext(App.SMS, Place.HOME, Ambiance.HOME)
val SmsRunningContext = VirtualContext(App.SMS, Place.HOME, Ambiance.RUNNING)
val DatingContext = VirtualContext(App.DATING, Place.DATING, Ambiance.DATING)
val WorkContext = VirtualContext(App.WORK, Place.WORK, Ambiance.WORK)
val MessengerCobanHomeContext = VirtualContext(App.MESSENGER, Place.HOME, Ambiance.NOWHERE)
val MessengerCobanRunContext = VirtualContext(App.MESSENGER, Place.PARIS, Ambiance.NOWHERE)
val MessengerEleaContext = VirtualContext(App.MESSENGER, Place.HOME, Ambiance.FLIRT)

data class GroupMessengerContext(
    val title: String,
    override val app: App = App.MESSENGER,
    override val place: Place = Place.NOWHERE,
    override val ambiance: Ambiance = Ambiance.NOWHERE,
) : AppContext , InPlaceContext, AmbianceContext {
    override val name: String
        get() = "$title: ${app.name}_${place.name}_${ambiance.name}".lowercase()
}

val ParkContext = IrlContext(Place.PARK)
val BarContext = IrlContext(Place.BAR)
val StreetContext = IrlContext(Place.STREET)

data class CodeGameContext(
    val max: Int,
    override val ambiance: Ambiance = Ambiance.RUNNING,
) : Context by NamedContext("code_game"), AmbianceContext

data class EncryptedBluePrintContext(
    override val ambiance: Ambiance = Ambiance.NOWHERE
) : Context by NamedContext("encrypted_blue_print"), AmbianceContext

import xyz.lacunae.story.NullableIntState
import xyz.lacunae.story.StateMachine

val StateMachine.settings
    get() = SettingsStateMachine(this)


class SettingsStateMachine(val stateMachine: StateMachine) : StateMachine by stateMachine {
    companion object {
        const val namespace = "settings"
    }

    var messageSpeed: Int? by NullableIntState(namespace)
}
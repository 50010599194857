import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import web.html.InputType
import xyz.lacunae.itsadate.IntroContext
import xyz.lacunae.itsadate.achievements
import xyz.lacunae.story.StateMachine

external interface HomeProps : Props {
    var stateMachine: StateMachine
    var onNewGame: () -> Unit
    var onContinue: () -> Unit
    var onAchievements: () -> Unit
    var onNewsLetter: () -> Unit
    var onShare: () -> Unit
    var fastMode: Boolean
    var onFastMode: (Boolean) -> Unit
}

val HomeComponents = FC<HomeProps>("HomeComponents") { props ->
    EnvironmentComponent {
        context = IntroContext("")
        backgroundImage = "/bg_home.jpg"
        backgroundColor = rgb(21, 31, 42).toString()
        device = true
        appProps = null
        div {
            css(ClassName("theme-dark")) {
                width = 100.pct
                height = 100.pct
                display = Display.grid
                gridTemplateRows = array(1.fr, 1.fr, Auto.auto)
                gridTemplateColumns = 100.pct
                mobileLandscape {
                    gridTemplateRows = 100.pct
                    gridTemplateRows = array(1.fr, Auto.auto)
                    gridTemplateColumns = array(50.pct, 50.pct)
                }
                backgroundColor = rgba(0, 0, 0, .5)
                fontFamily = string("'Courier New', monospace")
                colorScheme = ColorScheme.dark
                color = Colors.onSurface
            }
            h1 {
                css {
                    textAlign = TextAlign.center
                    padding = Padding(vertical = 16.px, horizontal = 32.px)
                    margin = Margin(Auto.auto, Auto.auto)
                    fontSize = FontSize.title
                    mobileLandscape {
                        gridColumnStart = "1".unsafeCast<GridColumnStart>()
                        gridColumnEnd = "4".unsafeCast<GridColumnEnd>()
                    }
                }
                +"It’s a date"
            }
            p {
                css {
                    fontSize = FontSize.subhead
                    textAlign = TextAlign.center
                    maxWidth = 480.px
                    margin = Margin(Auto.auto, Auto.auto)
                    padding = Padding(vertical = 2.px, horizontal = 32.px)
                }
                +"Rencontres, alliances, trahisons. Découvrez comment vos choix peuvent transformer une histoire."
            }
            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.flexEnd
                    alignItems = AlignItems.center
                    padding = Padding(vertical = 32.px, horizontal = 0.px)
                }
                Button {
                    onClick = {
                        props.onNewGame()
                    }
                    text = "Nouvelle partie"
                }
                if (props.stateMachine.achievements.nikolaMarsVisited || props.stateMachine.achievements.time >= 3600) {
                    form {
                        css {
                            display = Display.flex
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                        }
                        input {
                            type = InputType.checkbox
                            id = "fast_mode"
                            name = "fast_mode"
                            onChange = {
                                props.onFastMode(it.target.checked)
                            }
                            checked = props.fastMode
                        }
                        label {
                            htmlFor = "fast_mode"
                            css {
                                color = Colors.onSurface
                                padding = Padding(horizontal = 8.px, vertical = 0.px)
                            }
                            +"Mode rapide"
                        }
                    }
                }
                Button {
                    onClick = {
                        props.onContinue()
                    }
                    text = "Continuer"
                }
                Button {
                    onClick = {
                        props.onAchievements()
                    }
                    text = "Succès"
                }
                Button {
                    onClick = {
                        props.onNewsLetter()
                    }
                    text = "Newsletter"
                }
                Button {
                    onClick = {
                        props.onShare()
                    }
                    text = "Partager"
                }
            }
        }
    }
}

package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.narrator

object Chapter3 {
    private const val name = "chapter-3"
    const val intro = "$name/intro"
    const val elea = "$name/elea"
    internal const val speakWithElea = "$name/fragment/speak-with-elea"
}

internal fun Story.chapter3() {

    scene(Chapter3.intro, IntroContext("Chapitre 3")) {
        narrator {
            -"Voilà quelques jours que vous échangez régulièrement des textos avec ${elea.name}."
            -"Vous confier l’un à l’autre vous a permis d’approfondir votre relation et de vous rapprocher, un peu."
            -"C’est dingue de voir à quel point un simple échange régulier avec quelqu’un peut vous épanouir autant."
            -"Même le temps semble passer différemment; pas plus vite, mais mieux."
            -"Vos journées de boulot sont toujours aussi longues, mais la perspective de discuter avec ${elea.name} vous réchauffe le coeur."
            -"Vous avez trouvé quelqu’un à qui parler, à qui vous confier. Qu’est-ce que c’est agréable…"
            -"Ce soir encore, ${elea.name} vous contacte."
        }
        appBadge(Apps.messenger)
        redirect(Chapter3.elea)
    }

    fragment(Chapter3.speakWithElea) {
        elea {
            -"Hello !"
            -"J’en profitais pour mettre un peu de musique et me détendre."
            -"Ça fait du bien de se poser un peu ; je suis naze en ce moment, entre tous ces voyages…"
        }
        choose {
            "T’es en vacances ?"{
                elea {
                    -"Ah, si seulement j’étais en vacances…"
                    -"Je RÊVE de vacances !!!"
                    -"ça fait des années que je voyage pas pour moi."
                    -"Enfin, je veux dire pour mes loisirs."
                    -"C’est épuisant, vraiment… Je suis à bout en ce moment."
                }

            }
            "C’est ton boulot qui te fatigue ?"{
                elea {
                    -"On peut dire ça oui…"
                    -"J’en peux plus, d’être à droite, à gauche, jamais chez moi."
                    -"C’est tellement usant de passer autant de temps loin de toutes mes connaissances."
                    -"C’est dur, vraiment…"
                }
            }
        }
        choose {
            -"Tu fais quoi comme boulot ?"
        }
        elea {
            -"Oh, euh…"
            -"Bah…"
            -"Pffff"
            -"J’ai pas hyper envie d’en parler."
            -"Ça fait partie de ces choses que je dois garder secrètes."
            -"Ça te dérange pas trop ?"
        }
        choose {
            "Non, comme tu veux"{}
            "Un peu quand même…"{
                choose {
                    -"C’est compliqué de faire connaissance si on se dévoile pas un peu l’un à l’autre."
                }
                elea {
                    -"Écoute, je comprends ce que tu peux ressentir."
                    -"Mais ma vie professionnelle en ce moment, c’est pas la joie, loin de là."
                    -"J’imagine que c’est pareil pour toi non ?"
                    -"Moi, une fois la journée de boulot terminée, je la laisse derrière moi."
                    -"C’est fini et je passe à autre chose"
                }
                choose {
                    -"Moi c’est pareil. Après 18h30, le boulot n’existe plus."
                    -"Moi c’est l’inverse. J’ai besoin d’en parler pour relâcher la pression."
                }
                elea {
                    -"Je peux comprendre ça."
                    -"Mais mon taf n’a rien de passionnant, vraiment."
                    -"Et puis je travaille sur des dossiers qu’on pourrait qualifier de confidentiels."
                }
                choose {
                    -"Des secrets d’Etat ?"
                }
                elea {
                    -"Haha, peut-être bien, qui sait ? 😏"
                    -"Je t’en parlerai un jour, promis !"
                    -"J’espère que tu m’en voudras pas trop. 😊"
                }
            }
        }
        elea {
            -"En tout cas, ça me fait beaucoup de bien de discuter avec toi."
            -"J’ai l’impression qu’on se connaît depuis des mois !"
        }
        choose {
            "Pareil pour moi. 🙂"{
                choose {
                    -"C’est cool d’avoir quelqu’un à qui se confier, même si on ne s’est jamais vu."
                }
            }
            "Je reste ${player.withGender("mesuré", "mesurée")}."{
                choose {
                    -"Au final, on ne se connait qu’à travers notre écran, et quelques textos."
                }
                elea {

                    -"Tu as peut-être raison…"
                    -"Il est important de garder une certaine distance avec les rencontres virtuelles."
                    -"Elles sont à double tranchant."
                    -"Intenses. Extrêmes parfois."
                    -"Elles peuvent se terminer si vite."
                }
            }

        }
        elea {
            -"Depuis que je suis en vadrouille un peu partout, je ne rencontre personne, et mes seules interactions sociales c’est « bonjour » et « au revoir » aux quelques commerçants à qui j’achète un truc."
            -"N’avoir aucun ami proche, aucune attache, ça me fait voir la vie un peu différemment."
            -"Les rencontres virtuelles sont importantes pour moi, parce qu’elles me permettent de combler un peu ma solitude."
            -"D’avoir un semblant de vie sociale."
        }
        choose {
            -"C’est pareil pour moi…"
            -"Je compatis avec toi."
        }
        elea {
            -"😊"
            -"C’est dingue quand on y pense…"
            -"Nouer des liens avec quelqu’un qu’on n’a jamais vu."
            -"Qui pourrait être n’importe qui."
            -"Derrière un écran, on peut être qui on veut."
        }
        choose {
            "Wow, tu deviens glauque…"{
                elea {
                    -"Peut-être, oui…"
                    -"Et en même temps, j’ai déjà fait de magnifiques rencontres grâce à Internet."
                    -"Malgré les excès, les trolls, les fous… On trouve aussi beaucoup de passionnés de toutes sortes."
                    -"Et on croise des personnes qui nous ressemblent, avec qui on peut partager des passions communes."
                    -"Paradoxalement, c’est un vrai moyen de sortir de l’isolement."
                }
            }
            "Je pense que c’est la beauté de la chose."{
                choose {
                    -"On peut être n’importe qui, mais on peut aussi être VRAIMENT soi."
                }
                elea {
                    -"Oui, exactement."
                    -"On peut se cacher derrière un pseudo, un écran, mais aussi se révéler complètement, sans peur du regard des autres."
                    -"Internet, c’est une lame affutée, une arme à manier avec précaution."
                    -"On peut blesser les autres, tout autant qu’on peut être blessé par quelqu’un qui saura appuyer là où ça fait mal."
                    -"Juste avec quelques mots…"
                }
            }
        }
        elea {
            -"Bref, tout ça pour te dire que moi, j’adore le « risque » d’une rencontre virtuelle."
            -"Surtout si c’est pour rencontrer quelqu’un comme toi. 🙂"
        }
        choose {
            -"Merci du compliment. C’est réciproque."
            -"C’est gentil, mais n’oublie pas qu’on vient tout juste de se rencontrer."
        }
        elea {
            -"Je te l’ai déjà dit, mais je la sens bien notre rencontre. 😊"
            -"Il faut toujours dire aux personnes qui compte ce qu’on ressent pour elles."
            -"Avant qu’il ne soit trop tard…"
            -"J’ai essuyé tellement de déceptions dans ma vie, j’ai vu tellement de proches souffrir à cause de personnes malveillantes et mal intentionnées."
            -"J’ai perdu tant de gens qui comptent à mes yeux…"
            -"Les gens peuvent être si cruels les uns envers les autres."
            -"Parfois, j’aimerais vivre sur une autre planète…"
        }
        choose {
            "Dans une galaxie très lointaine ?"{
                elea {
                    -"Oh pas besoin d’aller aussi loin que ça."
                    -"Je me contenterai d’un petit voyage du côté de Mars."
                }
            }
            "Envoie un tweet à Elon Musk, et demande-lui de t’envoyer sur Mars !"{

            }
        }
        elea {
            -"Matt Damon a l’air de s’y être beaucoup amusé. 😜"
            -"Oui, Mars c’est l’idéal : pas de risque d’y croiser quelqu’un."
            -"Pas d’embouteillages, ni de pollution."
            -"Pas de réchauffement climatique."
            -"Et une jolie Tesla en orbite."
            -"Bon le paysage est un peu monotone, je te l’accorde, donc j’imagine que les randonnées peuvent y être répétitives."
        }
        choose {
            "Tu peux utiliser la voiture qu’Elon Musk y a envoyé en orbite !😛"{
                elea {
                    -"Oui, carrément !"
                    -"A moi les grands espaces au volant d’une Tesla décapotable, tout en écoutant du Bowie à fond !"
                    -"Haha, j’ai hâte !"
                    -"J’aurais mon petit champ de pommes de terre, comme dans Seul sur Mars, mon petit module de survie fonctionnel et spacieux."
                    -"Et même un animal de compagnie !"
                    -"Le robot Curiosity sera mon seul ami là-bas."
                    -"J’espère qu’il est sympa, parce qu’il risque de passer pas mal de temps avec moi !"
                }
                choose {
                    -"Joli programme pour des vacances !"
                    -"Tu te vois vraiment rester sur Mars ??"
                }
            }
            "Tu risques quand même de t’ennuyer un peu là-bas"{
                elea {
                    -"Mais non, sûrement pas !"
                    -"Avec tout ce qu’il y a à faire ?"
                    -"Pionnier, c’est du travail, tu sais ?"
                    -"Installer une colonie pérenne, cultiver des pommes de terre, trouver des ressources pour survivre…"
                    -"Me protéger des radiations mortelles, consolider mon abri pour ne pas m’exposer au manque d’atmosphère et ne pas mourir."
                    -"M’occuper du robot Curiosity."
                    -"M’enfoncer encore plus dans la solitude tout en essayant de ne pas mourir dans cet environnement hostile à toute forme de vie."
                    -"Sacré programme non ?"

                }
                choose {
                    -"Eh ben t’as réussi à me déprimer !"
                    -"Wow, trop bien, je peux venir aussi ?"
                }
            }
        }
        elea {
            -"Allons-y ensemble, ce serait top comme première rencontre, non ?"
        }
        choose {
            "Je réserve 2 tickets sur le site de la SNCF !"{
                elea {
                    -"Haha, j’espère que t’as une carte de réduction !"
                }
            }
            "Ce serait incroyable oui…"{}
        }
        elea {
            -"Pfiou, ce serait… mémorable."
            -"Inoubliable, même."
            -"Enfin bon…"
            -"Ce serait un programme sympa pour une petite semaine sur place, mais pas plus longtemps."
            -"T’imagines ?"
            -"Être seul sur une autre planète."
            -"Sans autre forme de vie, ou presque ?"
            -"Rien d’autre que le Vide, qui t’entoure."
            -"…"
        }
        choose {
            -"Ok, on partira ailleurs en vacances…"
        }
        elea {
            -"HAHA, ce serait mieux oui."
            -"C’était rigolo de parler de ça, mais c’est vrai que ça devient un peu déprimant. 😄"
            -"Changement de sujet, allez !"
            -"Tiens, on a évoqué de Tesla tout à l’heure, tu sais qui c’est ?"
        }
        choose {
            "Ben oui, évidemment, c’est une marque de voiture électrique."{
                elea {
                    -"Oui, c’est vrai, mais pas seulement."
                    -"Elon Musk a nommé sa marque de voiture Tesla en hommage à Nikola Tesla, un célèbre inventeur de la fin du 19e siècle / début du 20e."
                }

            }
            "… Pas vraiment non… 😅"{
                elea {
                    -"C’est normal, peu de monde le connaît."
                    -"Nikola Tesla était un célèbre inventeur de la fin du 19e siècle / début du 20e."
                }
            }
            "J’imagine que tu parles de Nikola Tesla, le célèbre inventeur ?"{
                elea {
                    -"Ah, tu me surprends !"
                    -"Il n’est pas si connu que ça du grand public."
                }
            }
        }
        elea {
            -"C’était un excentrique, un génie, passionné par ses inventions et les progrès technologiques qu’il pouvait développer pour faciliter la vie de ses concitoyens."
            -"On lui doit tellement : tout notre système d’alimentation électrique, pour commencer"
            -"Mais aussi la technologie des radars, ou encore les télécommandes !"
        }
        choose {
            -"Pour l’invention de la télécommande, je ne le remercie pas. Je finis toujours par perdre les miennes, dans les plis du canapé."
            -"Il aurait pu inventer un radar qui permet de retrouver les télécommandes perdues !"
        }
        elea {
            -"Haha, t’es bête."
            -"Tesla est fascinant."
            -"C’était un génie visionnaire, mais un très mauvais gestionnaire."
            -"Passionné de sciences, il consacrait sa vie à son travail, et s’est fait piquer presque toutes ses inventions."
            -"C’est quand même ironique quand on y pense :"
            -"Il est mort dans la misère la plus totale, et aujourd’hui, un milliardaire utilise son nom pour sa marque de voiture électrique…"
        }
        choose {
            "Au moins on se souvient encore un peu de lui, j’aimerais bien pouvoir en dire autant à ma mort."{
                elea {
                    -"Moi j’aimerais partir en laissant derrière moi un monde un peu meilleur."
                    -"J’aimerais juste avoir l’impression d’avoir été utile, à mon échelle."
                }
            }
            "Triste fin. Je préfère partir dans l’anonymat que devenir une marque de voiture."{
                elea {
                    -"Partir dans l’anonymat c’est notre lot à tous, ou presque."
                    -"Et c’est très bien comme ça."
                    -"J’aimerais juste partir en ayant le sentiment du devoir accompli; en laissant aux générations futures un monde un peu moins pire qu’il ne l’est aujourd’hui."
                }
            }

        }
        elea {
            -"Tesla était comme ça."
            -"Il a permis au monde de devenir ce qu’il est aujourd’hui."
            -"Pour le pire comme pour le meilleur."
            -"Ce qui me fascine le plus chez lui, ce sont tous les mystères qui planent sur ses inventions."
            -"On dit par exemple qu’il aurait travaillé sur une machine à remonter le temps !"
        }
        choose {
            "Nom de Zeus !"{
                elea {
                    -"J’te le fais pas dire Marty McFly !"
                    -"Et règle bien ton convecteur temporel, parce que notre cher Tesla a fait bien mieux !"
                }
            }
            "Avec ça il aurait pu sauver John Connor !"{
                elea {
                    -"Il aurait même pu sauver la saga Terminator, et faire en sorte qu’elle s’arrête après les deux premiers films."
                    -"Et encore plus ahurissant que la nullité de Terminator 5 ou 6, notre cher Tesla a bossé sur un projet fou !"
                }
            }
        }
        elea {
            -"Il aurait mis au point à la fin de sa vie une « machine à énergie libre »."
        }
        choose {
            "ok, je vois. Tu veux parler d’autre chose ?"{
                elea {
                    -"Pardon, je t’ennuie avec mes délires."
                    -"C’est juste que…"
                    -"C’est mon truc, ça."
                    -"J’adore m’intéresser à ce genre de choses, aux grands hommes qui ont consacré leur vie à améliorer celle des autres."
                    -"Tesla était comme ça."
                    -"Et il est mort dans l’oubli"
                    -"Peut-être même qu’on l’a fait taire pour ses idées révolutionnaires."
                    -"Et ça, tu comprends, ça me révolte !"
                }
                choose {
                    -"Haha, tu t’emballes !"
                }
                elea {
                    -"Excuse-moi, tu as raison, je m’emporte vite."
                    -"Mais si ça t’intéresse, j’échange beaucoup à ce sujet avec une personne que j’ai rencontrée sur un forum."
                    -"On adore se raconter des histoires mystérieuses, se sortir des références SF un peu douteuses, ou débunker quelques mythes modernes, etc."
                    -"Hahah tu dois trouver ça débile."
                }
                choose {
                    "Ouais, c’est pas trop mon délire…"{
                        elea {
                            -"Je comprends…"
                            -"Mais si la curiosité fait partie de tes qualités, tu ne refuseras pas mon invitation. 😉"
                            -"Et puis c’est quelqu’un de très sympa, que j’aimerais te présenter."
                            -"Demain, j’ai « rendez-vous » texto avec lui, afin de discuter de nos sujets de prédilection."
                            -"Si ça te tente, tu peux te joindre à nous !"
                        }
                        choose {
                            -"Allez, pourquoi pas."
                            -"Je vais y réfléchir…"
                        }
                    }
                    -"Non, je trouve ça plutôt cool. 🙂"
                }
                elea {
                    -"Très bien, on en reparle demain alors. 😊"
                    -"Sur ce… Il commence à se faire tard."
                    -"Je tombe de fatigue, et je vais donc aller me coucher."
                    -"C’était très agréable de discuter avec toi"
                    -"A demain j’espère."
                    -"Bonne nuit ${player.name}!"

                }
                choose {
                    "Bonne nuit ${elea.name}"{
                        elea {
                            -"Bises 😗"
                        }
                    }
                    "Je vais vite m’endormir. Tes histoires m’ont ${player.withGender("assommé", "assommée")}."{
                        elea {
                            -"${player.withGender("Méchant", "Méchante")} ! 😠😝"
                            -"Bye…"
                        }
                    }
                }
                narrator {
                    -"${elea.name} est hors-ligne."
                }
            }
            "Parle-moi plus de cette machine."{
                elea {
                    -"Ah ! tu vois que ça t’intéresse !"
                    -"Pour Nikola Tesla, l’accès à l’énergie est un droit fondamental, comme l’accès à l’eau par exemple."
                    -"Il considérait donc normal, primordial même, que chaque foyer soit équipé d’une source d’énergie gratuite et illimitée."
                    -"C’était bien évidemment inconcevable pour ses mécènes et employeurs."
                }
                choose {
                    -"Pourquoi ça ?"
                }
                elea {
                    -"Eh bien, ça prouve qu’il se préoccupait du bien-être de ces contemporains, et que le profit ne l’intéressait pas."
                    -"Hors, le profit, gagner plus d’argent, plus de pouvoir, c’est le but de certaines personnes pas vraiment concernées par le bien commun."
                }
                choose {
                    "C’est bien dommage."{
                        elea {
                            -"L’argent rend puissant."
                            -"S’il s’était enrichi, il aurait gagné sa liberté."
                            -"Et le pouvoir de faire ce qu’il souhaite."
                        }
                    }
                    "Je comprends. Il était passionné."{
                        elea {
                            -"Je pense que l’argent est loin d’être la préoccupation d’un passionné comme lui."
                            -"Comme beaucoup d’autres scientifiques, rien d’autre ne comptait, que l’exploration de ses domaines de prédilection."
                        }
                    }
                }
                elea {
                    -"Et comme d’autres savants de l’époque, il s’est intéressé à de nombreuses choses, notamment à l’Ether, une forme d’énergie, existant dans l’espace, entre les atomes et la matière."
                    -"L’autre nom de l’Ether c’est l’énergie du champ du vide."
                    -"Jusqu’à aujourd’hui, personne n’a réussi à prouver son existence."
                }
                choose {
                    "L’Ether ? c’est pas plutôt un anesthésiant ?"{
                        elea {
                            -"Oui, c’est un anesthésiant, mais pas que…"
                        }
                    }
                    "L’Ether ? ça n’existe que dans les romans de Jules Verne et les BD d’Alex Alice."{
                        elea {
                            -"Oui, superbe BD d’ailleurs. 🙂"
                            -"C’est une théorie, certes…"
                        }
                    }
                }
                elea {
                    -"L’Ether serait une source d’énergie propre, non polluante et disponible en quantité illimitée !"
                    -"Et Tesla aurait tenté de créer une machine capable de récupérer et stocker cette énergie !"
                    -"Tu imagines ?"
                }
                choose {
                    -"Pas vraiment non."
                }
                elea {
                    -"Je vais essayer de t’expliquer."
                    -"Après un investissement initial qu’on pourrait aujourd’hui chiffrer à environ 3000 ou 4000€, chaque foyer disposerait d’un engin capable d’alimenter en électricité une maison, une voiture, et tout appareil électrique, à vie !"
                    -"Pas besoin de recharge, d’électricité, d’essence, ou quoi que ce soit d’autre."
                    -"La machine capte l’Ether ambiant, et le transforme en énergie."
                }
                choose {
                    -"Les retombées d’un tel objet seraient folles !"
                }
                elea {
                    -"Folles oui !"
                    -"Plus besoin de carburants fossiles, plus de pollution au CO2, un réchauffement climatique endigué."
                    -"De quoi voir l’avenir des générations futures sereinement."
                    -"Ça voudrait dire aussi un monde plus égalitaire, dans lequel même les plus pauvres auraient une chance de s’en sortir."
                    -"Le paradis non ?"
                }
                choose {
                    "Mais c’est génial !"{
                        elea {
                            -"Génial oui, mais pas pour tout le monde…"
                        }
                        choose {
                            -"Comment ça ?"
                            -"Je ne vois pas ce que tu veux dire."
                        }
                        elea {
                            -"Et les compagnies pétrolières ?"
                            -"Et l’industrie automobile ?"
                            -"On est une société capitaliste dont le système repose sur la croissance, sur le renouvellement de produits et de biens, la consommation, etc."
                        }
                        choose {
                            -"Et alors, où veux-tu en venir ?"
                        }
                        elea {
                            -"C’est évident."
                            -"Si quelqu’un met au point une machine qui marche à vie, de nombreuses entreprises n’auront plus lieu d’être parce que leurs profits seraient réduits à néant."
                            -"Des centaines milliards d’euros dans tous les secteurs qui passeraient de la poche de l’actionnaire à la poche du consommateur."
                            -"Et puis imagine qu’une entreprise, une seule, soit en mesure de maîtriser et de vendre une telle technologie."
                            -"Elle aurait un monopole dangereux."
                            -"Elle dominerait même des Etats, qui feraient tout pour profiter de cet engin aux portées immenses."
                            -"Des guerres pourraient voir le jour, qui sait."
                            -"Et notre économie capitaliste pourrait ne pas s’en remettre…"
                        }
                        choose {
                            -"Un mal pour un bien."
                            -"Ce serait terrible."
                        }
                        elea {
                            -"Qui serait prêt à assumer une telle responsabilité ?"
                            -"Je ne sais pas si j’aurais les épaules pour gérer cela."
                        }
                        choose {
                            -"Moi non plus…"
                            -"Il faut parfois faire des sacrifices pour le bien commun."
                        }
                        elea {
                            -"Les pertes humaines seraient énormes."
                            -"Notre Société mettrait des années à s’adapter."
                            -"Le rêve de Tesla était humaniste, mais malheureusement peu réaliste."
                            -"Et c’est bien dommage."
                            -"Pardon, je t’ennuie pas trop avec mes délires ?"
                        }
                        choose {
                            -"Non, c’est passionnant.🙂"
                            -"C’est juste que je n’y connais pas grand chose."
                        }
                    }
                    "Ça ne me parait pas très réaliste."{}
                }
                elea {
                    -"C’est juste que…"
                    -"C’est mon truc, ça."
                    -"J’adore m’intéresser à ce genre de choses, aux grands hommes qui ont dédié leur vie à améliorer l’avenir de l’Humanité."
                    -"Tesla était comme ça."
                    -"Et il est mort dans l’oubli, peut-être même qu’on l’a fait taire pour ses idées révolutionnaires."
                    -"Et cette injustice, ça me révolte !"
                    -"…"
                    -"Excuse-moi, je m’emporte."
                }
                choose {
                    -"Il était peut-être trop en avance sur son temps."
                    -"C’était juste un illuminé."
                }
                elea {
                    -"On peut mourir en étant traité de fou, avant de finalement passer à la postérité, et être considéré comme un génie."
                    -"Regarde Van Gogh…"
                }
                choose {
                    -"Van Gogh était un génie ET un fou."
                    -"Je suis d’accord avec toi."
                }
                elea {
                    -"Oui, tu as compris l’idée."
                    -"Bon… C’était intéressant de discuter de tout ça avec toi."
                    -"Si ça t’intéresse, j’échange beaucoup à ce sujet avec une personne que j’ai rencontrée sur un forum."
                    -"On adore se raconter des histoires mystérieuses, se sortir des références SF un peu douteuses, ou débunker quelques mythes modernes, etc."
                    -"Je suis ${player.withGender("sûr", "sûre")} que tu t’entendrais très bien avec lui."
                }
                choose {
                    "Je sais pas, c’est pas trop mon délire…"{
                        elea {
                            -"Je comprends…"
                            -"Mais si la curiosité fait partie de tes qualités, tu ne refuseras pas mon invitation. 😉"
                            -"Et puis c’est quelqu’un de très sympa, que j’aimerais te présenter."
                            -"C’est l’occasion de rencontrer de nouvelles personnes !"
                            -"Demain, j’ai un « rendez-vous » texto avec lui, afin de discuter de nos sujets de prédilection."
                            -"Si ça te tente, tu peux te joindre à nous !"
                        }
                        choose {
                            -"Allez, pourquoi pas."
                            -"Je vais y réfléchir…"
                        }
                    }
                    "Pourquoi pas, ça peut être cool. 🙂"{}
                }
                elea {
                    -"Très bien, on en reparle demain alors. 😊"
                    -"Sur ce… Il commence à se faire tard !"
                    -"Je tombe de fatigue, je vais me coucher."
                    -"C’était très agréable de discuter avec toi"
                    -"A demain j’espère. 😉"
                    -"Bonne nuit !"
                }
                choose {
                    "Bonne nuit ${elea.name}"{
                        elea {
                            -"Bises 😗"
                        }
                    }

                    "Je vais vite m’endormir. Tes histoires m’ont ${player.withGender("assommé", "assommée")}."{
                        elea {
                            -"${player.withGender("Méchant", "Méchante")} ! 😠😝"
                            -"Bye…"
                        }
                    }
                }
                narrator {
                    -"${elea.name} est hors-ligne."
                }
            }
        }
    }

    scene(Chapter3.elea, MessengerEleaContext) {
        characters(elea)
        elea {
            -"Salut ${player.name} !"
            -"Comment ça va ?"
        }
        choose {
            silent("La faire patienter") {
                elea {
                    -"Bon…"
                    -"Fais-moi signe si t’as un moment. 😉"
                }
                choose {
                    -"Me voilà !"
                    -"Désolé, j’étais pas devant mon téléphone."
                }
                include(Chapter3.speakWithElea)
                redirect(Chapter4.intro)
            }
            "Ne plus jamais répondre à ${elea.name}"{
                redirect(Chapter4.end3, GoToScene.Option.Timeout(10_000))
            }
            "Hey, je suis là !"{
                include(Chapter3.speakWithElea)
                redirect(Chapter4.intro)
            }
        }
    }
}
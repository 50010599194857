import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface LinkProp : Props {
    var text: String?
    var href: String
}

val Link = FC<LinkProp>("Link") { props ->
    ReactHTML.a {
        css {
            fontFamily = string("'Courier New', monospace")
            padding = Padding(horizontal = 16.px, vertical = 8.px)
            margin = Margin(horizontal = 8.px, vertical = 8.px)
            minWidth = 50.pct
            fontSize = FontSize.button
            backgroundColor = Colors.secondaryContainer
            color = Colors.onSecondaryContainer
            padding = Padding(8.px, 16.px)
            borderRadius = 24.px
            border = None.none
            textDecoration = None.none
            textAlign = TextAlign.center
            hover {
                backgroundColor = Colors.secondaryContainer
                color = Colors.onSecondaryContainer
                cursor = Cursor.pointer
                opacity = number(0.8)
            }
            disabled {
                backgroundColor = Colors.onSurface
                opacity = number(0.38)
            }
        }
        href = props.href
        +(props.text ?: "")
    }
}
package xyz.lacunae.kotlin

import kotlinx.browser.window

@Suppress("unused")
object Umami {

    @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
    val umami: UmamiJs?
        get() = window.asDynamic()["umami"] as? UmamiJs

    fun track(eventName: String) {
        umami?.track(eventName)
    }

    fun track(eventName: String, data: dynamic) {
        umami?.track(eventName, data)
    }
}

@Suppress("unused")
@JsName("umami")
external interface UmamiJs {
    fun track(eventName: String)
    fun track(eventName: String, data: dynamic)
}
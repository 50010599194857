package xyz.lacunae.itsadate

import xyz.lacunae.story.*
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.roland
import xyz.lacunae.itsadate.Characters.tim

object Chapter11 {
    private const val name = "chapter-11"
    const val intro = "$name/intro"
    const val roland = "$name/roland"
    const val roland2 = "$name/roland-2"
    const val mysterious = "$name/mysterious"
    const val mysteriousIrl = "$name/mysterious-irl"
    const val end11 = "$name/end-11"
    internal const val fuckCoban = "$name/fragment/fuck-coban"
    internal const val endReporter = "$name/fragment/end-reporter"
}

internal fun Story.chapter11() {
    scene(Chapter11.intro, IntroContext("Chapitre 11")) {
        narrator {
            -"Quelques jours plus tard…"
            -""
            -"Aucune nouvelle de ${reporter.name}."
            -"${reporter.withGender("Il", "Elle")} ne répond pas à vos appels."
            -"Et ${reporter.withGender("s’il", "si elle")} s’était fait prendre ?"
            -"${coban.name} et ses sbires étaient à deux doigts de l’avoir… Le pire est peut-être arrivé."
            -"Et vous êtes sûrement leur prochaine cible."
            -"Vous ne pensez qu’à ça, tout en triturant la clé USB qui contient les fameux plans du père de ${elea.name} ."
            -"Quelle responsabilité écrasante !"
            -"${reporter.name} compte sur vous ; ces plans sont la clé de la disparition de ${elea.name}."
            -"Ils sont en votre possession ; vous tenez peut-être l'avenir du monde entre vos mains, et vous ne pouvez en parler à personne…"
            -"Pas même à ${roland.name}, votre fidèle collègue ?"
        }
        appBadge(Apps.work)
        redirect(Chapter11.roland)
    }
    scene(Chapter11.roland, WorkContext) {
        characters(roland)
        roland {
            -"Non mais ${player.name} !!!!"
            -"Tu ne peux pas me laisser sur ma faim comme ça."
            -"Tu DOIS me raconter ce qu’il s’est passé !"
            -"Ce ${coban.name}, tu l’as rencontré ?"
            -"Et ${reporter.name} ?"
            -"Y’avait quoi sur la tablette ?"
            -"${elea.name} est ${elea.withGender("réapparu", "réapparue")} ?"
            -"Sérieux, ${player.withGender("mec", "meuf")}… Ta vie, on dirait un jeu vidéo."
            -"Allez, dis-moi tout !!!!"
        }
        choose {
            "Un jour, promis. 😉"{
                roland {
                    -"T’as intérêt !"
                    -"C’est quand même grâce à moi que t’as vécu tout ça !"
                    -"Ah, j’ai bien fait de te parrainer sur « CupidLov ! »"
                    -"Dire que moi, ça m’a servi à rien…"
                    -"J’y ai rencontré personne."
                    -"Et pourtant, crois-moi, j’ai essayé…"
                    -"Donc tu me dois bien ça !"
                }
            }
            "Non, je ne peux pas faire ça."{
                roland {
                    -"Oh, t’es ${player.withGender("lourd", "lourde")} !!!!"
                    -"Tu sais que tu peux tout me dire."
                    -"Je suis ton collègue, ton ami, ton confident !"
                    -"Et puis c’est quand même moi qui t’ai permis de vivre cette histoire, en te parrainant sur CupidLov."
                }
                choose {
                    "Et je t’en remercie !"{
                        roland {
                            -"Remercie-moi avec des confidences !!!"
                            -"Mais plus sérieusement, je suis content si ça t’as plu. 😉"
                        }
                    }
                    "ça m’a appris à me méfier."{
                        roland {
                            -"Je comprends…"
                        }
                    }
                }
            }
        }
        roland {
            -"C’est peut-être cliché, mais sur le net, on ne sait jamais à qui on a à faire."
            -"Un pote peut en fait être un agent secret à la recherche de documents secrets !"
            -"Oh, mais attends !"
            -"Ça se trouve…"
            -"JE suis un agent secret !!!"
            -"Wouah, la révélation de fou !"
            -"Écoute, ${player.name}…"
            -"${coban.name}…"
            -"C’est moi !"
            -"Tu as perdu."
            -"Game Over… comme disent les vieux ringards."
        }
        choose {
            "Haha, t’es bête…"{
                roland {
                    -"Bête, moi ?"
                    -"Toujours. 🙂"
                }
            }
            "Euh… t’es pas sérieux là ?!"{
                roland {
                    -"Bah non…"
                    -"Parce que toi tu l’es ?"
                    -"C’est une BLAGUE !!!!"
                    -"Ne flippe pas comme ça !"
                }
            }
        }
        appBadge(Apps.work)
        redirect(Chapter11.mysterious)
    }

    scene(Chapter11.mysterious, WorkContext) {
        characters(mysterious)
        mysterious {
            -"${player.name}…"
            -"Rejoins-moi en bas de l’immeuble avec les plans."
            -"On se retrouve à l’angle de la rue, à côté de la vieille boîte aux lettres."
            -"Sois discret et dépêche-toi !"

        }
        choose {
            "${roland.name}, c’est toi ?"{
                mysterious {
                    -"${roland.name}? C’est qui ${roland.name} ?"
                    -"Tu me déçois ${player.name}."
                    -"Qui veux-tu que ce soit ?"
                    -"Je t’attends en bas, dépêche-toi d’amener les plans."
                }
            }
            "${coban.name}, c’est toi ?"{
                mysterious {
                    -"${player.name}…"
                    -"Tu crois franchement que si j’étais ${coban.name}, je prendrai la peine de t’envoyer un message ?"
                    -"Allez viens, dépêche-toi."
                }
            }
            "${reporter.name}, c’est toi ?"{
                mysterious {
                    -"😇"
                    -"Qui veux-tu que ce soit ?"
                    -"Allez, viens me rejoindre avec les plans, dépêche-toi !"
                }
            }
            "${elea.name}, c’est toi ?"{
                mysterious {
                    -"Si seulement…"
                    -"Allez, je te laisse la surprise."
                    -"Je t’attends, et n’oublie pas les plans.."
                }
            }
        }
        narrator {
            -"${mysterious.name} s’est déconnecté(e)"
        }
        appBadge(Apps.work)
        redirect(Chapter11.roland2)
    }

    scene(Chapter11.roland2, WorkContext) {
        characters(roland)
        choose {
            -"${roland.name} !"
        }
        roland {
            -"Quoi ?!"
        }
        choose {
            "C’est encore une de tes blagues ?"{
                roland {
                    -"Ah, tu parles de la boule puante que j'ai planquée en salle de pause ?"
                    -"Ou alors des lasagnes sardines/dentifrice que j'ai volontairement mises au frigo ?"
                    -"Ça devient difficile de te surprendre. 😒"
                }
            }
            "Il vient de se passer un truc… bizarre !" {
                roland{
                    -"Oui, je m'en suis aperçu."
                    -"Ça va avoir des conséquences terribles."
                    -"Je pense que Fredo n'aurait pas dû manger autant de brochettes boeuf/fromage à midi."
                    -"On sent et on entend toutes les étapes de son processus digestif."
                    -"Je suis au bord de l'asphyxie."
                }
            }
        }
        choose {
            -"Non, je suis ${player.withGender("sérieux","sérieuse")} Roland !"
        }

        roland {
            -"Ok, [mode sérieux activé]."
            -"C'est quoi le problème ?"
        }
        choose {
            -"J’ai reçu des messages sur notre messagerie pro !"
        }
        roland {
            -"Et alors ?"
            -"C’est pas bizarre du tout, espèce de tête d’ampoule !"
            -"Des messages on s’en envoie tout le temps…"
        }
        choose {
            -"Sauf que c’est un utilisateur « inconnu » ; son nom est « ${mysterious.name} »"
        }
        roland {
            -"Wow…"
            -"Ah oui."
            -"Alors ça, c’est bizarre."
            -"Elle est parfaitement sécurisée, cette messagerie."
            -"Pour la hacker, il faut être sacrément doué."
            -"Et qu’est-ce que veut ce « ???? » ?"
        }
        choose {
            -"Que je le rejoigne dehors…"
        }
        roland {
            -"Fonce !"
            -"C’est sûrement une de tes rencontres…"
            -"Je parie sur une réapparition miracle de ${elea.name} !"
            -"Hum, c’est peut-être ce fameux ${coban.name}…"
            -"Méfite-toi, tu ne devrais peut-être pas y aller."
            -"Oh, et si c’était ${reporter.name} ?"
            -"Va voir qui c’est, vite !"
            -"Et puis tu remontes me raconter tout ça."
            -"Et si c’était dangereux… C'est peut-être un piège."
            -"Oh, et puis zut !"
            -"Allez, vas-y !!!"
            -"Ne perds pas de temps !"
            -"Ne t’en fais pas, je te couvre auprès de la boss."
            -"Je lui dirai que tu as la diarrhée."
        }
        choose {
            -"Merci ${roland.name}."
        }
        choose {
            -"On dirait que je n’ai pas vraiment le choix…"
        }
        choose {
            -"J’y vais !"
        }
        redirect(Chapter11.mysteriousIrl)
    }
    fragment(Chapter11.fuckCoban) {
        coban {
            -"C’est moi. ${coban.name}."
            -"Je suis ravi de te rencontrer."
        }

        narrator {
            -"Avant même que vous n’esquissiez le moindre geste, deux hommes surgissent d’une camionnette stationnée juste derrière vous."
            -"Ils vous embarquent sans que vous ayez eu l’occasion de vous défendre."
            -"Quelques minutes plus tard…"
            -"Une grande gifle vous sort de votre torpeur."
            -"Vous êtes à l’intérieur de la camionnette, qui roule calmement."
            -"Vous êtes ${player.withGender("attaché","attachée")}, et ${player.withGender("maintenu","maintenue")} par les deux hommes qui vous ont ${player.withGender("capturé","capturée")}."
            -"${coban.name} vous regarde, un sourire malsain aux lèvres."
        }
        coban {
            -"Salut ${player.name}"
            -"Comment te sens-tu ?"
        }
        choose {
            -"… Qu'est-ce que tu me veux ?"
            -"Ça va super. Et toi mon pote ?"
        }
        coban {
            -"Je te l’avais dit : réfléchis-y à deux fois avant de t’attaquer à moi."
            -"Ta provocation à mon égard a été… la cerise sur le gâteau."
            -"Tu m'as donné du fil à retordre, mais je ne le regrette pas."
            -"Cette clé USB que tu avais contient TOUT ce dont nous avons besoin."
            -"Si tu m’avais écouté, notre collaboration aurait été fructueuse."
            -"Et nous aurions fait de toi quelqu’un de riche."
        }
        choose {
            -"On peut encore s’entendre. Laisse-moi partir, je t'en prie."
            -"Si je le pouvais, je te dévisserais la tête pour te chier dans le cou."
        }
        coban {
            -"Chut ${player.name}."
            -"Il n’est plus temps de parler."
            -"Tu as préféré écouter ces deux ${elea.withGender("idiots", "folles")}…"
            -"Dommage."
            -"Parce que tu vas les rejoindre."
            -"Et je te garantis que ça n’est pas dans un endroit agréable."
            -"C’est fini pour toi."
            -"Game Over, ${player.name}… Game over."
        }
        redirect(Chapter11.end11, GoToScene.Option.Timeout(10_000))
    }

    fragment(Chapter11.endReporter) {
        choose {
            -"${reporter.name} ! C’est bien toi !"
        }
        reporter {
            -"Qu’est-ce que je suis ${reporter.withGender("content", "contente")} de te revoir…"
            -"${player.name}, je te présente Tim."
            -"Il travaille pour une association de journalistes indépendants."
        }
        tim {
            -"Enchanté ${player.name}."
            -"${reporter.name} a été ${reporter.withGender("élogieux", "élogieuse")} à ton sujet."
            -"Grâce à toi, nous allons offrir au monde une technologie qui va changer le visage de l’humanité."
            -"Sans ton courage, ton abnégation, et tes talents d’enquêteur, rien de tout ceci n’aurait été possible."
        }
        choose {
            "Arrêtez, vous me flattez… C’est ${reporter.name} qui a tout fait."{
                reporter {
                    -"Et en plus ${player.withGender("il", "elle")} est modeste."
                    -"${tim.name} a raison."
                    -"C’est grâce à toi que nous avons déjoué les plans de ${coban.name}."
                }
            }
            "Vous avez raison. Merci de reconnaître mes nombreuses qualités."{
                tim {
                    -"Hahaha !"
                }
                reporter {
                    -"Eh ${player.name}, un peu de modestie s’il te plait !"
                    -"OK, grâce à toi nous avons déjoué les plans de ${coban.name}…"
                    -"Mais reste humble, s’il te plait !"
                    -"On va dire que c’était de l’humour, n’est-ce pas ?"
                }
            }
        }
        tim {
            -"Oui ; avec les documents décrits par ${reporter.name}, nous avons suffisamment de preuves pour trainer ${coban.name} et ses employeurs devant les tribunaux."
            -"Ils n’ont aucune chance d’en réchapper, j’en suis sûr."
            -"C’en est fini ${coban.withGender("de lui", "d’elle")} et de ses patrons…"
            -"Ça nous prendra des mois, des années peut-être."
            -"Mais nous les aurons."
            -"Nous mettrons à bas ces lobbys qui cherchent à s’enrichir au détriment des plus faibles, capables de tuer pour un peu plus de pouvoir."
            -"Ce sont des criminels de la pire espèce, ils me dégoûtent."
            -"Ces meurtriers finiront en prison, j’en fais le serment."
        }
        choose {
            -"Je vous fais confiance !"
            -"J’aimerais croire que vous avez raison."
        }
        tim {
            -"Compte sur moi."
            -"Le plus dur reste à faire."
            -"Mais grâce à toi ${player.name}, nous nous rapprochons de la victoire."
        }
        choose {
            -"Merci à toi ${tim.name}"
            -"A toi de jouer ${tim.name} !"
        }
        tim {
            -"Je vous laisse tous les deux."
            -"${reporter.name}, je t’attends dans la voiture."
        }
        reporter {
            -"Bon…"
            -"Je crois que nous allons devoir nous dire au revoir…"
            -"Merci pour tout, ${player.name}."
            -"Sans toi…"
            -"Sans toi, je serais probablement ${reporter.withGender("mort", "morte")} à l’heure qu’il est."
        }
        choose {
            -"Et ${elea.name} ?"
        }
        reporter {
            -"${tim.name} a fait des recherches."
            -"Et moi je n’ai pas arrêté les miennes."
            -"Pour l’heure, nous n’avons pas réussi à remonter sa piste."
            -"Tout ce qu’on sait, c’est qu’${elea.withGender("il", "elle")} a disparu le soir de votre discussion."
            -"A part ça, rien, nulle part."
            -"Ni en France, ni à l’étranger."
            -"Comme ${elea.withGender("s’il s'était volatilisé","si elle s'était volatilisée")}"
        }
        choose {
            "${coban.name} l’a fait disparaître ?"{
                reporter {
                    -"Probablement pas."
                }
            }
            "${elea.withGender("Il", "Elle")} s’est ${elea.withGender("enfui", "enfuie")} ?"{
                reporter {
                    -"C’est ce que je pense aussi."
                    -"${coban.name} n’a sûrement pas réussi à remonter sa trace."
                    -"Le soir de sa disparition, ${
                        elea.withGender(
                            "il",
                            "elle"
                        )
                    } a dû s’enfuir pour échapper à ${coban.name} et ses hommes."
                    -"Et ${elea.withGender("il", "elle")} a de la ressource, crois-moi."
                    -"${elea.withGender("Il", "Elle")} a passé sa vie à les combattre."
                    -"Je suis sûr qu’${elea.withGender("il", "elle")} s’en est ${elea.withGender("sorti", "sortie")}."
                    -"Sinon, ce salaud de ${coban.name} ne se serait pas acharné sur nous de la sorte."
                }
            }
        }
        choose {
            -"On va finir par ${elea.withGender("le", "la")} retrouver !"
        }
        reporter {
            -"J’ai envie de croire qu’${
                elea.withGender("il", "elle")
            } a réussi à se cacher d’une manière ou d’une autre."
            -"Et peut-être qu’${
                elea.withGender(
                    "il",
                    "elle"
                )
            } profite un peu de sa liberté retrouvée… Je l’espère de tout cœur."
            -"Et mon but, désormais, c’est de ${elea.withGender("le", "la")} retrouver."
        }
        choose {
            "Alors bonne chance à toi !"{
                reporter {
                    -"Merci ${player.name}."
                    -"Je dois y aller."
                    -"Peut-être qu’on se reverra, un jour prochain…"
                    -"Je n’oublie pas ce que je te dois."
                }
            }
            "Je serai là pour t’aider."{
                reporter {
                    -"J’en suis ${reporter.withGender("sûr", "sûre")}."
                    -"Je sais qu’on se reverra."
                    -"Merci pour tout ${player.name}."
                }
                narrator {
                    -"${reporter.name} vous embrasse sur la joue. 😘💋"
                }
                reporter {
                    -"Je n’oublie pas ce que je te dois."
                }
                if (stateMachine.game.reporterDrink) {
                    choose {
                        -"Tu me dois un verre !"
                    }
                    reporter {
                        -"Je te dois bien plus qu’un verre."
                        -"C’est une bonne raison pour nous revoir au plus vite."
                    }
                }
                reporter {
                    -"A bientôt… Dans d’autres circonstances, j’espère."
                }
            }
        }
        narrator {
            -"${reporter.name} monte dans la voiture de ${tim.name}, sans un autre regard pour vous…"
            -"Vous restez sur le trottoir, sans savoir quoi faire."
            -"Cette histoire est-elle vraiment finie ?"
        }
        if (stateMachine.game.ratNikolaMars) {
            redirect(ChapterFinal2.intro)
        } else {
            redirect(ChapterFinal.intro)
        }
    }

    scene(Chapter11.mysteriousIrl, StreetContext) {
        narrator {
            -"Vous avez l’impression que votre cœur va exploser."
            -"Au creux de votre main, la clé USB contenant les plans de la machine à énergie libre."
            -"Cette petite chose au contenu si précieux…"
            -"Le dénouement de cette histoire est proche."
            -"Sera-t-il heureux ou tragique ?"
            -"${coban.name} est-il venu s’emparer des plans ?"
            -"${reporter.name} et ${elea.name} ${elea.withGender("sont-ils", "sont-elles")} en vie ?"
            -"Avez-vous fait les bons choix ?"
            -"Vous n’avez plus le temps d’y réfléchir"
            -"Alors que vous arrivez au point de rendez-vous, une voix vous interpelle."
        }
        mysterious {
            -"${player.name} ?"
        }
        if (stateMachine.game.fuckCoban || stateMachine.game.fuckCoban2) {
            include(Chapter11.fuckCoban)
        } else {
            include(Chapter11.endReporter)
        }
    }

    scene(Chapter11.end11, EndContext("Fin 11")) {
        stateMachine.achievements.end11 = true
        narrator {
            -"Vous étiez si près du but…"
            -"Mais vous attaquez directement à ${coban.name} n’était pas une bonne idée."
            -"Si seulement vous aviez écouté ${reporter.name}… ${
                reporter.withGender("Il", "Elle")
            } vous avait dit de ne pas le provoquer…"
            -"N'aviez-vous pas été ${player.withGender("prévenu","prévenue")}, plus tôt dans cette aventure ?"
            // TODO mettre le proverbe en italique
            -"Ne provoque pas le chien de garde, il risquerait de te mordre."
            -"Mais la vie est faite de choix, et celui-ci vous amène à cette FIN 11."
            -"Je ne vous envie pas, ${player.name}…"
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
        }
    }
}
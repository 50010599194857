import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import xyz.lacunae.itsadate.IntroContext

external interface ShareProps : Props {
    var onExit: () -> Unit
}

val ShareComponent = FC<ShareProps>("ShareComponent") { props ->
    EnvironmentComponent {
        context = IntroContext("")
        backgroundImage = "/bg_home.jpg"
        backgroundColor = rgb(21, 31, 42).toString()
        device = true
        appProps = null
        div {
            css(ClassName("theme-dark")) {
                width = 100.pct
                height = 100.pct
                display = Display.grid
                gridTemplateRows = array(1.fr, 1.fr, Auto.auto)
                gridTemplateColumns = 100.pct
                mobileLandscape {
                    gridTemplateRows = 100.pct
                    gridTemplateRows = array(1.fr, Auto.auto)
                    gridTemplateColumns = array(50.pct, 50.pct)
                }
                backgroundColor = rgba(0, 0, 0, .5)
                fontFamily = string("'Courier New', monospace")
                colorScheme = ColorScheme.dark
                color = Colors.onSurface
            }
            h1 {
                css {
                    textAlign = TextAlign.center
                    padding = Padding(vertical = 16.px, horizontal = 32.px)
                    margin = Margin(Auto.auto, Auto.auto)
                    fontSize = FontSize.title
                    mobileLandscape {
                        gridColumnStart = "1".unsafeCast<GridColumnStart>()
                        gridColumnEnd = "4".unsafeCast<GridColumnEnd>()
                    }
                }
                +"It’s a date"
            }
            p {
                css {
                    fontSize = FontSize.subhead
                    textAlign = TextAlign.center
                    maxWidth = 480.px
                    margin = Margin(Auto.auto, Auto.auto)
                    padding = Padding(vertical = 2.px, horizontal = 32.px)
                }
                +"Merci de faire connaître It's a date autour de vous ❤️"
            }
            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.flexEnd
                    alignItems = AlignItems.center
                    padding = Padding(vertical = 32.px, horizontal = 0.px)
                }
                val link = "https://itsadate.lacunae.xyz/"
                Link {
                    href = "https://www.facebook.com/sharer/sharer.php?u=$link"
                    text = "Facebook"
                }
                Link {
                    href = "https://twitter.com/intent/tweet?url=$link"
                    text = "Twitter"
                }
                Link {
                    href = "https://www.linkedin.com/shareArticle?url=$link"
                    text = "Linkedin"
                }
                Link {
                    href = "https://reddit.com/submit?url=$link"
                    text = "Reddit"
                }
                Link {
                    href = "https://api.whatsapp.com/send?text=$link"
                    text = "Whatsapp"
                }
                UserNextComponent {
                    label = "Retour"
                    onNext = props.onExit
                }
            }
        }
    }
}

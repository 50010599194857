import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.progress
import react.useState
import web.html.HTMLFormElement
import web.html.HTMLInputElement
import web.html.InputType
import xyz.lacunae.story.Scene

external interface EncryptedBluePrintProps : Props {
    var scene: Scene
    var messages: List<IMessage>?
    var answer: Boolean
    var onAnswer: (String) -> Unit
    var choices: List<Any>?
    var onChoice: (Int, Any) -> Unit
    var next: Next?
    var onNext: () -> Unit
}

val EncryptedBluePrintComponents = FC<EncryptedBluePrintProps>("EncryptedBluePrintComponents") { props ->

    val (text, setText) = useState("")

    val submitHandler: (FormEvent<HTMLFormElement>) -> Unit = {
        it.preventDefault()
        setText("")
        props.onAnswer(text)
    }

    val changeHandler: (ChangeEvent<HTMLInputElement>) -> Unit = {
        setText(it.target.value)
    }

    div {
        css(ClassName("theme-dark")) {
            backgroundColor = rgba(0, 0, 0, .8)
            height = 100.pct
            overflow = Overflow.hidden
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        div {
            css {
                height = 20.pct
            }
            NotificationsComponent {
                messages = props.messages
                actions = props.choices.orEmpty().filterIsInstance<String>()
                onAction = { index ->
                    props.choices?.get(index)?.let {
                        props.onChoice(index, it)
                    }
                }
            }
        }
        div {
            css {
                flexGrow = number(1.0)
            }
            form {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    padding = Padding(vertical = 32.px, horizontal = 0.px)
                    justifyContent = JustifyContent.center
                }
                onSubmit = submitHandler
                label {
                    htmlFor = "code"
                    css {
                        color = Colors.onSurface
                        padding = Padding(16.px, 16.px)
                    }
                    +"Entrer la passphrase"
                }
                input {
                    name = "code"
                    type = InputType.text
                    css {
                        margin = Margin(horizontal = Auto.auto, vertical = 0.px)
                        padding = Padding(vertical = 8.px, horizontal = 16.px)
                        borderRadius = 32.px
                        fontSize = FontSize.entry
                        width = 60.pct
                        letterSpacing = 6.px
                        textAlign = TextAlign.center
                    }
                    id = "code"
                    onChange = changeHandler
                    value = text
                    disabled = !props.answer
                }

                progress {
                    css {
                        visibility = when {
                            props.next != null -> Visibility.hidden
                            !props.answer -> Globals.inherit
                            else -> Visibility.hidden
                        }
                        margin = Margin(16.px, 16.px)
                    }
                }
            }
        }
        NextComponent {
            disabled = props.next != null
            next = props.next
            onNext = props.onNext
        }
    }
}
import csstype.*
import emotion.react.css
import js.core.get
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.useState
import web.file.FileReader
import web.html.*
import xyz.lacunae.story.AnswerType

external interface InputProps : Props {
    var type: AnswerType
    var onSubmit: (String) -> Unit
}

val InputComponent = FC<InputProps>("InputComponent") { props ->
    when (props.type) {
        AnswerType.TEXT, AnswerType.NUMBER -> child(TextInputComponent, props)
        AnswerType.IMAGE -> child(ImageInputComponent, props)
    }
}
val TextInputComponent = FC<InputProps>("TextInputComponent") { props ->
    val (text, setText) = useState("")

    val submitHandler: (FormEvent<HTMLFormElement>) -> Unit = {
        it.preventDefault()
        props.onSubmit(text)
        setText("")
    }

    val changeHandler: (ChangeEvent<HTMLInputElement>) -> Unit = {
        if(it.target.validity.valid){
            setText(it.target.value)
        }
    }

    form {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            alignItems = AlignItems.center
            padding = Padding(horizontal = 16.px, vertical = 0.px)
            minHeight = 10.vh
        }
        onSubmit = submitHandler
        input {
            css {
                padding = Padding(vertical = 0.px, horizontal = 8.px)
                margin = Margin(right = 8.px, left = 0.px, top = 0.px, bottom = 0.px)
                flexGrow = number(1.0)
                minHeight = 48.px
                backgroundColor = Colors.surfaceContainerHigh
                color = Colors.onSurfaceVariant
                border = Border(
                    width = 1.px,
                    style = LineStyle.solid,
                    color = Colors.onSurfaceVariant
                )
                borderRadius = 24.px
                fontSize = FontSize.body
                focus {
                    outline = None.none
                    backgroundColor = Colors.surfaceContainer
                    border = Border(
                        width = 1.px,
                        style = LineStyle.solid,
                        color = Colors.primary
                    )
                }
            }
            onChange = changeHandler
            type = if (props.type == AnswerType.NUMBER) {
                InputType.number
            } else {
                InputType.text
            }
            inputMode = if (props.type == AnswerType.NUMBER) {
                InputMode.decimal
            } else {
                InputMode.text
            }
            min = 0
            value = text
            autoFocus = true
        }
        button {
            type = ButtonType.submit
            css {
                backgroundColor = Colors.primary
                color = Colors.onPrimary
                minHeight = 48.px
                padding = Padding(vertical = 0.px, horizontal = 8.px)
                border = None.none
                borderRadius = 24.px
            }
            disabled = text.isBlank()
            +"Envoyer"
        }
    }
}

val ImageInputComponent = FC<InputProps>("ImageInputComponent") { props ->
    val (file, setFile) = useState<String?>(null)

    val submitHandler: (FormEvent<HTMLFormElement>) -> Unit = { event ->
        event.preventDefault()
        file?.let { props.onSubmit(it) }
        setFile(null)
    }

    val changeHandler: (ChangeEvent<HTMLInputElement>) -> Unit = { event ->
        event.target.files?.get(0)?.let { file ->
            val reader = FileReader()
            reader.onloadend = {
                setFile(reader.result as? String)
            }
            reader.onerror = {
                setFile(null)
            }
            reader.readAsDataURL(file)
        }
    }

    form {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            alignItems = AlignItems.center
            padding = Padding(horizontal = 16.px, vertical = 0.px)
            minHeight = 10.vh
        }
        onSubmit = submitHandler
        input {
            type = InputType.file
            onChange = changeHandler
            accept = "image/*"
        }
        img {
            css {
                width = 48.px
                height = 48.px
                borderRadius = 50.pct
                backgroundColor = Colors.tertiaryContainer
                objectFit = ObjectFit.cover
            }
                file?.let {
                    src = it
                }
        }
        button {
            type = ButtonType.submit
            css {
                backgroundColor = Colors.primary
                color = Colors.onPrimary
                minHeight = 48.px
                padding = Padding(vertical = 0.px, horizontal = 8.px)
                border = None.none
                borderRadius = 24.px
            }
            disabled = file == null
            +"Envoyer"
        }
    }
}
import csstype.rem

object FontSize {
    val entry = 3.6.rem
    val title = 3.2.rem
    val header = 2.4.rem
    val subhead = 2.0.rem
    val button = 1.8.rem
    val body = 1.6.rem
    val caption = 1.2.rem
}
package xyz.lacunae.itsadate

import xyz.lacunae.story.GoToScene
import xyz.lacunae.story.Story
import xyz.lacunae.story.scene
import xyz.lacunae.itsadate.Characters.coban
import xyz.lacunae.itsadate.Characters.narrator
import xyz.lacunae.itsadate.Characters.tim

object ChapterFinal {
    private const val name = "chapter-final"
    const val intro = "$name/intro"
    const val elea = "$name/elea"
    const val end = "$name/end-final-1"
}

internal fun Story.chapterFinal() {
    scene(ChapterFinal.intro, IntroContext("Épilogue")) {
        narrator {
            -"Quelques mois plus tard."
            -"Vous avez repris votre vie normale."
            -"Mais vous n’avez pas oublié cette histoire."
            -"Comment passer à autre chose ? Tant de questions restent sans réponse."
            -"${reporter.name} ne vous a pas recontacté, et rien n’est sorti dans les médias."
            -"${tim.name} vous avait promis un procès retentissant. L'affaire a-t-elle été étouffée ?"
            -"Aucun signe de vie de ${elea.name} non plus."
            -elea.withGender("Qu’est-il devenu ?", "Qu’est-elle devenue ?")
            -"Vous avez relu les vieux messages de ${elea.name} et de ${reporter.name} pour vous convaincre que tout cette histoire est bien réelle."
            -"Mais à quoi bon… "
            -"Vous ne ressentez qu’un fort sentiment d’amertume."
            -"Auriez-vous pu faire plus ? Faire de meilleurs choix ?"
            -"Qui sait ?"
            -""
            -"Et puis, un beau jour…"
        }
        redirect(ChapterFinal.elea)
    }
    scene(ChapterFinal.elea, MessengerEleaContext) {
        characters(mysteriousElea)
        mysteriousElea {
            -"Salut ${player.name}."
            -"Ça faisait longtemps…"
        }
        choose {
            "${coban.name} ?"{
                mysteriousElea {
                    -"Oh là, pas du tout !!!"
                    -"J’espère bien ne pas le croiser à nouveau, ce taré"
                    -"Je vais te donner un indice :"
                    -"Si je te dis… Nikola Mars ? La Jangada ? Le prestige ? Soirée de l’étrange ? Badges de dating ?"
                }
                choose {
                    "${elea.name} !" {
                        stateMachine.game.finalElea = true
                    }
                }
            }
            "${reporter.name} ?"{
                mysteriousElea {
                    -"Hum… pas vraiment non."
                    -"J’aurais préféré que tu trouves tout de suite."
                    -"Mais ça fait longtemps qu’on ne s’est pas parlé."
                    -"Si je te dis… Nikola Mars ? ${
                        if (stateMachine.game.talkAboutBook) "La Jangada" else "Le prestige"
                    } ? Soirée de l’étrange ? Badges de dating ?"
                }
                choose {
                    "${elea.name} !" {
                        stateMachine.game.finalElea = true
                    }
                }
            }
            "${elea.name} ?"{
                stateMachine.game.finalElea = true
            }
        }
        elea {
            -"Eh oui, c’est bien moi. 🙂"
        }
        choose {
            -"Où étais-tu ${elea.withGender("passé", "passée")} ?"
        }
        choose {
            -"Alors tu es ${elea.withGender("vivant", "vivante")} ?"
        }
        choose {
            -"Qu’est-ce qui t’es arrivé ?"
        }
        choose {
            -"Est-ce que tu vas bien ? Je te croyais ${elea.withGender("mort", "morte")} !!!"
        }
        elea {
            -"Oh là, ça fait beaucoup de questions !!!"
            -"C’est sûr que je te dois quelques explications."
            -"Je vais te raconter tout ça."
            -"Ces derniers mois ont été… complètement fous !"
            -"Je vais résumer, et je te donnerai plus de détails quand nous nous rencontrerons enfin."
            -"${reporter.name} et moi avons pu reprendre contact."
            -"Ma « disparition » était en fait une fuite."
            -"Les hommes de ${coban.name} étaient sur mes traces."
        }
        choose {
            -"Et tu as réussi à leur échapper ?"
        }
        elea {
            -"Oui, j’avais prévu le coup."
            -"J’ai presque toujours vécu dans la peur, à cause d’eux."
            -"Je m’étais ménagé une sortie de secours, et j’ai pu m’enfuir avant qu’ils ne me trouvent."
            -"Ça s’est joué à peu de choses…"
            -"J’ai passé des mois à me cacher, pour leur échapper."
            -"C’était si dur…"
            -"D’autant plus qu’ils ont… tué mon père…"
            -"Tu as dû voir ça dans les fichiers de ma tablette."
            -"Je leur en veux tellement."
        }
        choose {
            -"Je suis ${player.withGender("désolé", "désolée")}"
        }
        elea {
            -"Ne le sois pas, ${player.name}."
            -"C’est grâce à toi si j’ai finalement pu m’en sortir, et si ${reporter.name} a pu récupérer tous mes documents."
        }
        choose {
            -"Comment va-t-${reporter.withGender("il", "elle")} ?"
        }
        elea {
            -"Bien, malgré les circonstances."
            -"${tim.name}, le journaliste que m’a présenté ${reporter.name}, nous a été d’un grand secours."
            -"Malheureusement… ${coban.name} et ses hommes ne cessent de nous mettre des bâtons dans les roues."
            -"Ils continuent de nous poursuivre, et nous devons constamment rester sur nos gardes."
            -"Mais nous ne ne sommes plus ${elea.withGender("seuls", "seules")}."
            -"Nous avons des alliés à nos côtés."
            -"Des personnes de confiance, avec qui nous travaillons main dans la main."
            -"Les sbires de ${coban.name} nous empêchent pour le moment de faire éclater la vérité au grand jour."
            -"Mais nous ne lâcherons pas !"
            -"Nous y arriverons, je le sais."
            -"Et c’est à toi que je le dois, ${player.name}"
            hesitation()
            -"Tu m’as manqué, tu sais ?"
        }
        choose {
            -"Toi aussi tu m’as manqué… J’ai des tonnes de choses à te raconter."
        }
        elea {
            -"Haha, j’en suis ${elea.withGender("sûr", "sûre")}."
            -"Mais d’abord…"
            -"Laisse-moi te remercier à nouveau, pour tout ce que tu as fait."
            -"Merci, merci, 1000 fois merci."
            -"Tu as traversé de dures épreuves, pour m'aider, alors que tu ne me connais qu’à travers un écran."
            -"Grâce à toi, la mémoire de mon père sera lavée."
            -"Et quand ses travaux auront été dévoilés,"
            -"nous réussirons peut-être à vivre dans un monde un peu meilleur."
            -"Mais avant ça, il y a encore pas mal de boulot."
            -"D’autres épreuves nous attendent."
            -"Je dirai même que le plus dur est à venir."
            -"Le combat ne fait que commencer."
            -"Et à ce propos…"
            -"Est-ce que je peux compter sur toi ?"
        }
        choose {
            -"Oui, bien sûr !"
            -"Comment pourrais-je te dire non ?"
            -"Non n’est pas une option."
            -"On dirait bien que c’est carrément IMPOSSIBLE que je te réponde non."
        }
        elea {
            -"Alors c’est parti ${player.name} !"
            -"Nous nous rencontrerons bientôt !"
            -"It’s a date !"
        }
        redirect(ChapterFinal.end, GoToScene.Option.Timeout(10_000))
    }
    scene(ChapterFinal.end, EndContext("À suivre ?", EndContext.Type.Happy)) {
        stateMachine.achievements.end13 = true
        narrator {
            -"Bravo ${player.name} !"
            -"Vous avez réussi à finir le jeu."
            -"Et cette fin 13 est la meilleure que vous pouviez obtenir."
            -"Mais votre histoire avec ${elea.name} et ${reporter.name} est loin d’être terminée."
            -"Merci d’avoir passé ${stateMachine.game.time.formatTime()} avec nous."
            -"Le jeu est riche, et d’autres choix vous amèneront à découvrir d’autres fins."
            -"Il y en a $ends au total."
            -"Essayez de toutes les découvrir, et de débloquer tous les succès !"
            -"En attendant la suite !"
        }
    }
}
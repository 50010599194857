import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.useEffect
import react.useState
import xyz.lacunae.itsadate.ImageCharacter


external interface IrlMessagesProps : Props {
    var messages: List<IMessage>
}

val IrlMessagesComponent = FC<IrlMessagesProps>("IrlMessagesComponent") { props ->

    val (groupedMessages, setGroupedMessages) = useState(emptyList<GroupedMessages>())

    useEffect(props.messages) {
        val groups = mutableListOf<GroupedMessages>()
        var group: GroupedMessages? = null
        props.messages.reversed().forEach { message ->
            val c = if (message is CharacterMessage) message.character else null
            group = if (group != null && group?.character == c) {
                group
            } else {
                GroupedMessages(c).also {
                    groups.add(it)
                }
            }?.apply {
                messages.add(0, message)
            }
        }
        setGroupedMessages(groups)
    }

    fun PropertiesBuilder.message_narrator() {
        borderRadius = 16.px
        padding = Padding(horizontal = 16.px, vertical = 8.px)
        margin = Margin(left = 16.px, right = 16.px, bottom = 4.px, top = 0.px)
        backgroundColor = rgba(47, 79, 79, 0.9)
        color = NamedColor.white
        maxWidth = 360.px
    }

    fun PropertiesBuilder.message() {
        borderRadius = 16.px
        padding = Padding(horizontal = 16.px, vertical = 8.px)
        margin = Margin(left = 16.px, right = 16.px, bottom = 4.px, top = 0.px)
        backgroundColor = rgba(255, 255, 255, 0.9)
        maxWidth = 360.px
    }

    div {
        css {
            overflowY = Overflow.hidden
            display = Display.flex
            flexDirection = FlexDirection.columnReverse
            maskImage = linearGradient(
                stop(NamedColor.transparent, 0.pct),
                stop(rgba(0, 0, 0, 1.0), 30.pct),
                stop(rgba(0, 0, 0, 1.0), 100.pct),
            )
        }

        groupedMessages.forEach { group ->
            div {
                val character = group.character
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    margin = Margin(bottom = 16.px, top = 0.px, horizontal = 0.px)
                }
                if (character is ImageCharacter && character.img != null) {
                    img {
                        alt = character.name.first().toString()
                        src = character.img
                        className = ClassName("character")
                        css {
                            height = 64.px
                            width = 64.px
                            margin = Margin(bottom = (-8).px, top = 0.px, horizontal = 0.px)
                            borderRadius = 50.pct
                            backgroundColor = NamedColor.lightgrey
                            objectFit = ObjectFit.cover
                        }
                    }
                } else {
                    span {
                        css {
                            textAlign = TextAlign.center
                            display = Display.flex
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                            borderRadius = 50.pct
                            height = 64.px
                            width = 64.px
                            backgroundColor = NamedColor.lightgrey
                            margin = Margin(bottom = (-8).px, top = 0.px, horizontal = 0.px)
                        }
                        +if (character?.isNarrator == true) {
                            "…"
                        } else {
                            character?.name?.firstOrNull()?.toString() ?: ""
                        }
                    }
                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = AlignItems.center
                    }
                    group.messages.forEach { message ->
                        when (message) {
                            is TextMessage -> div {
                                val c = message.character
                                css {
                                    when {
                                        c.isNarrator -> message_narrator()
                                        else -> message()
                                    }
                                }
                                span {
                                    className = ClassName("message")
                                    +message.content
                                }
                            }
                            is PhotoMessage -> div {
                                val c = message.character
                                css {
                                    when {
                                        c.isNarrator -> message_narrator()
                                        else -> message()
                                    }
                                }
                                img {
                                    src = message.name
                                    alt = message.desc
                                    css {
                                        maxHeight = 360.px
                                        borderRadius = 16.px
                                    }
                                }
                            }
                            else -> {
                                // do nothing
                            }
                        }
                    }
                }
            }
        }
    }

}

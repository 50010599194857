package xyz.lacunae.itsadate

import xyz.lacunae.story.Story

const val ends = 13

fun Story.teslaStory() : Story {
    chapter1()
    chapter2()
    chapter3()
    chapter4()
    chapter5()
    chapter6()
    chapter7()
    chapter8()
    chapter9()
    chapter10()
    chapter11()
    chapterFinal()
    chapterFinal2()
    return this
}